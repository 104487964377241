<template>
<div>
  <DxButton v-if="varianty.length > 0"  id="savetopdf" type="default" icon="pdffile" :text="defaultLabel" :hint="defaultLabel"  @click="togglePopup" ref="savetopdfButton" :class="{ 'ClassOfButton': true, 'orange-button': this.ColorOfButton === 'orange' }" :height="customHeight"/>
  <DxPopup id="PopupSettingsToolbar" v-model:visible="popup" :drag-enabled="false" :hide-on-outside-click="true"
    :show-close-button="false" :showTitle="false" width="auto" height="auto" :focusStateEnabled="true" :shading="false"
    :animation="animationSettingsPopup" @Hiding="hiding = true; hidden = false" @Hidden="hiding = false; hidden = true">
    <DxPosition :at=PositionAT :my=PositionMY :collision=PositionCollision of="#savetopdf" />

    <div>
      <div class="clMenuRadekUSER" v-for="item in varianty" :key="item" height="100%">
        <DxButton :text="item.content" :hint="item.content" styling-mode="outlined" class="idMenuButtonNovy" type="default"
          @click="SaveToPDF(item.content, item.detail);" height="100%" width="auto"></DxButton>
      </div>
    </div>


  </DxPopup>
</div>
</template>
  
<script>
import axios from "axios";

export default {
  name: 'PrintVariant',
  props: {
    PrintID: {
      type: Number,
      required: true
    },
    Report: {
      type: String,
      required: true
    },
    Hint: {
      type: String,
      required: false,
      default: 'Tisk'
    },
    Label: {
      type: String,
      required: false,
    },
    PositionAT: {
      type: String,
      required: false,
      default: 'right top'
    },
    PositionMY: {
      type: String,
      required: false,
      default: 'left top'
    },
    PositionCollision: {
      type: String,
      required: false,
      default: "fit none"
    },
    ColorOfButton:{
      type: String,
      required: false,
      default: '' // orange jinak funguje klasicky jako bezny DxButton
    },
    customHeight:{
      type: String,
      required: false,
      default: 'auto'
    }
  },
  watch: {
    Report(newReport) {
      this.handleParentPropChange(newReport);
    },
    ColorOfButton(){
      
    }
  },
  computed: {
    defaultLabel() {
      return this.Label || this.$t('central-ExportPDF');
    }
    

  },
  data() {
    return {
      varianty: [],
      prevReport: '',
      popup: false,
      hidden: false,
      hiding: false,
      animationSettingsPopup: {
        show: {
          type: 'pop',
          duration: 300,
          from: {
            scale: 0.55
          }
        },
        hide: {
          type: 'pop',
          duration: 300,
          to: {
            opacity: 0,
            scale: 0.55
          },
          from: {
            opacity: 1,
            scale: 1
          }
        }
      },
    };

  },
  mounted() {
    this.LoadVariants();
    this.$log.debug('Mounted ' + this.Report + ' ' + this.PrintID);
    this.LoadBarvyTheme();
  },
 
  methods: {
    LoadBarvyTheme(){
      var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var lightercolortheme = this.lightenColor(FillColorBarvaTheme,100);
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeLighter', lightercolortheme);
    },
    lightenColor(rgbColor, factor) {
          
          const match = rgbColor.match(/^rgb\((\d+), (\d+), (\d+)\)$/);
          if (!match) {
              throw new Error("Invalid RGB color format");
          }

          // Extrakce stavajicich hodnot
          const red = parseInt(match[1]);
          const green = parseInt(match[2]);
          const blue = parseInt(match[3]);

          // Kalkulace svetlejsi barvy podle zadaneho faktoru
          const newRed = Math.min(255, red + factor);
          const newGreen = Math.min(255, green + factor);
          const newBlue = Math.min(255, blue + factor);

          return `rgb(${newRed}, ${newGreen}, ${newBlue})`;
          },
    togglePopup() {
      if (this.hidden || !this.hiding) {
        this.popup = !this.popup;
        this.hidden = true
      }
    },
    handleParentPropChange(newReport) {
      this.$log.debug('Report prop changed:', this.prevReport, '->', newReport);
      this.LoadVariants(newReport)
      // Perform any necessary actions when the EmpId prop changes
      this.prevReport = newReport; // Update the previous value
    },
    LoadVariants() {
      var self = this
      self.varianty.length = 0
      self.$log.debug("Loading print variants " + this.Report);
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('PRINTVARIANTS:' + this.Report,
        '', 1, 50,
        function (result) {
          self.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.varianty[x] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
              detail: result.List.value.items[x].Detail.value,
            };
          }      
          self.UrlQueryParams()
        }, window.this.ApiError());
    },
    async downloadItem(url, name, suffix) {
      this.$log.debug(suffix)
      suffix = '.pdf'
      let self = this;
      axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
        .then(function (response) {
          switch (suffix) {
            case '.pdf': {
              self.$log.debug(response)
              const blob = new Blob([response.data], { type: 'application/' + suffix });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              self.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              self.popup = false
              break;
            }
          }
        })
        .catch(function (error) {
          self.$log.debug(error)
          self.$log.debug(JSON.stringify(error))
        })
    },
    setSelectedValue(e) {
      window.this.$log.debug(e);
      window.this.$log.debug(this.PrintID);
      if (e.value != null) {
        this.SaveToPDF(e.value)
      }
    },
    open() {
        window.loader = this.$loading.show({ loader: 'dots' });
    },
    hide() {
        window.loader.hide()
    },
    SaveToPDF(value, detail) {
      var g = new window.General(window.channel, window.message);
      let self = this;
      self.$log.debug(self.Report)
      self.$log.debug(self.value)
      self.$log.debug(self.PrintID)
      this.open();
      g.Print(self.Report, value, self.PrintID,
        function (result) {
          self.$log.debug(result);
          self.hash = window.URL1 + '/js/download/' + result.Value.value;
          self.$log.debug(self.hash + self.Report)
          self.downloadItem(self.hash, detail + ' (' + self.PrintID + ')' + '.pdf');
          self.hide();
          // self.$refs.sbSelectBox.instance.reset();
        }, window.this.ApiError());

    }
  },

};
</script>
<style scoped>
.clMenuRadekUSER {
  text-align: center;
  height: 40px;
  width: 100%;
  border: 0.5px rgba(90, 85, 85, 0.25) solid;
  border-top: 0;
 /* border-bottom: 1;*/
  border-left: 0;
  border-right: 0;

  font-family: Arial, Helvetica, sans-serif;
}

.idMenuButtonNovy {
  height: 100%;
  width: 100%;
  border: 0px none;
  text-align: center;
  cursor: pointer;
}
.ClassOfButton {
  color: none;
  border-color: none !important;
  border: 0px;
}
.ClassOfButton:hover{background: var(--colorByThemeLighter);
  border-color: none !important;
  border: 0px;
  color: none;}
.orange-button {
  background: rgb(255, 166, 60);
  border-color: none !important;
  border: 0px;
  color: black;
}
.orange-button:hover {
  background: var(--colorByThemeLighter);
  border-color: none !important;
  border: 0px;
  color: none;
}
#savetopdf{
  margin-right: 5px;
  margin-left: 5px;}
</style>
  