<template>
  <div>
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000691212?lang=cs"
      URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000691212?lang=en" PopisLinku='clanky.dotazovac'></HelpLink>

    <DxScrollView height="90%" direction="vertical" showScrollbar="always">
      <DxPopup width="25%" height="auto" v-model:visible="popupPrilohy" :show-title="true" :drag-enabled="false" @hiding="HidingPopupPrilohy"
        :hide-on-outside-click="true" :show-close-button="true" :title="$t('granty-PrilozeneFaktury')">
        <DxScrollView height="auto" direction="vertical" showScrollbar="onScroll">
          <div v-if="this.PrilohyID!=0">
            <Attachments :Disabled="true" :Pozid="this.PrilohyID" :FileUploadFileType="1" :ListFilesfileType="2" :FileDeleteFileType="1"/>
         <!--   
          <div class="soubory" v-for="item in Soubory" :key="item">
              <DxButton id="download" icon="download" text="" @click="GetHash(item.fileName, item.fileID)" />
              <a href="#" id={{item.id}} @click="GetHash(item.fileName, item.fileID)">{{ item.fileName }}
              </a>&nbsp;({{ item.fileSizeTXT }}) - {{ $t('granty-NascanovanyDne') }} {{ item.fileDate.toLocaleDateString()
              }}
              <br>
              <br>
            </div>
            -->
          </div>
        </DxScrollView>
      </DxPopup>
      <DxPopup :width="600" :height="400" :title="popupTitle" v-model:visible="popupVisible" @shown="onShownDrillDown"
        :hide-on-outside-click="true" :resize-enabled="true" :drag-enabled="true" :show-title="true">
        <template #content>
          <DxDataGrid ref="drilldown" :data-source="drillDownDataSource" :columns="DrillDownColumns"
            :hoverStateEnabled="true" :rowAlternationEnabled="true" :allow-column-reordering="true" :show-borders="true"
            :column-auto-width="true" :noDataText="$t('central-NenalezenyZadneZaznamy')"
            :height="(vyskaZobrazitelna / 2)">
          </DxDataGrid>
        </template>
      </DxPopup>
      <div class="clNadpisAktualniStranky" height="50px">
            <h2 id="nadpisDotazovac" class="nadpisH2Moduly prevent-select">{{ $t('dotazovac.dotazovac') }}</h2> 
        </div>
      
      <div style="margin-left: 20px;">
        <div id="container1">
          <div id="inner">
            <div class="child">
              <div id="selectbox" width='300px' style="padding-top: 2px;" >
                <!--<DxSelectBox v-if="false" :id="100" width='300px' :dropDownOptions="{ minWidth: 500 }" height="45px"
                  :data-source="dataSource" display-expr="content" value-expr="id" :grouped="true" :value="Dotid"
                  :label="$t('central-Vyberte')" placeholder=""
                  @value-changed="this.jsonData = []; this.page = 1; setSelectedValue($event);"
                  >
                </DxSelectBox>-->
                <CustomDropDownBox :Disabled="false" :Validation="false" :id="100" :Value="Dotid" :DataSource="dataSource"
                  @value-changed="this.jsonData = []; this.page = 1; setSelectedValue($event);" >
                </CustomDropDownBox>
              </div>
            </div>
            <div class="child" >
              <DxButton height="45" width="45" icon="preferences" text="" type="outlined" styling-mode="contained"
                id="preferences" @click="changeBoolHlavicka()" :hint="titOtevritZavritPreference" class="centralOutlinedBox " />
            </div>
            <div class="child" id="napoveda" v-show="Hlavicka  && picked">
              <DxTextArea v-show="hintShow" class="upozorneni" width="500px" height="100px" line-height='2.5'
                :label="$t('central-Napoveda')" :read-only="true" :value="queryHint" />
            </div>
          </div>
        </div>
        <div id="params" v-show="Hlavicka">
          <div id="inner">
            <h3 v-if="params.length > 0">{{ $t('dotazovac-ParametryDotazu') }}</h3>
            <div class="child" v-for="item in params" :key="item">
              <div v-bind:title="item.Content" v-if="item.Component == 'T'">
                <DxTextBox width='300px' height="45px" :label="item.Detail" :id="item.Id"
                  :value="item.Content" :placeholder="item.Detail"  @value-changed="setParamValue($event);" styling-mode="outlined"
                  class="upozorneni " />
              </div>
              <div @mouseover="getTitle(item)" v-bind:title="getTitle(item)" v-if="item.Component == 'L'">
                <DxSelectBox width='300px' height="45px" value-expr="id" :value="item.selected" :label="item.Detail" :dropDownOptions="{ minWidth: '500px' }"
                  :id="item.Id" :data-source="item.DataSource" :search-enabled="true"
                  display-expr="content" :placeholder="item.Detail" @value-changed="setParamValue($event);"
                  class="upozorneni" />
              </div>
              <div v-if="item.Component == 'D'">
                <DxDateBox :value="new Date()" :placeholder="item.Detail" :id="item.Id"
                  :label="item.content" height="50px" width="160px" type="date" @value-changed="setParamValue($event);"
                  display-format="dd. MM. yyyy" :applyButtonText="$t('dotazovac-Potvrdit')"
                  :cancelButtonText="$t('dotazovac-Zrusit')" class="upozorneni " :accept-custom-value="false"
                  :open-on-field-click="true" />
              </div>
            </div>
          </div>
        </div>
        <DxButton v-show="Hlavicka" height="auto" width="auto" :text="$t('dotazovac-Zobraz')" type="outlined"
          styling-mode="contained" id="zobraz" :disabled="!picked" @click="GetQuery();" />
      </div>

      <!-- 
      <div class="dx-field">
         <DxCheckBox id="checkbox1" v-model:value="checked" @value-changed="this.page=1;TableData();" /> Pouze nenulové
      </div>
      -->
      <div class="table">
        <!-- 
      <DxDataGrid :show-borders="true" data-source="data/TestData.json" />
      
      -->
      <hr class="hrModuly">
        <div class="bordersDataGridPozadavkyModuly" v-if="this.Zobrazeni == 'normalgrid' "> <!--&& picked-->
          <DxDataGrid column-resizing-mode="widget" ref="mygrid" id="DataGrid" :data-source="jsonData"
            :showScrollbar="true" :allow-column-resizing="true" :hoverStateEnabled="true" :rowAlternationEnabled="true"
            :allow-column-reordering="true" :show-borders="true" :column-auto-width="true"
            :height="(vyskaZobrazitelna/1.3)" column-max-width="150px" width="100%"
            :noDataText="$t('central-NenalezenyZadneZaznamy')" @exporting="onExporting" @ContentReady="this.debouncedContentReady()" 
            :editing-texts="{ saveRowChanges: 'Ulozit', cancelRowChanges: 'Smazat', }">

            <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form"
              :editRow="$t('central-Upravit')" :texts="{
                saveRowChanges: 'Povrdit', cancelRowChanges: 'Zrušit', editRow: '', deleteRow: 'Smazat',
                confirmDeleteTitle: 'Varování', confirmDeleteMessage: 'Opravdu si přejete smazat tento záznam?',
              }" />
            <template #prilohy="{ data }">
              <DxButton @click="PrilohyClick(data.value)" v-if="data.value > 0" icon="download" width="40px" height="20px">
              </DxButton>
            </template>
            <template #detailPozadavku="{ data }">
              <DxButton @click="Redirect(data.value)" icon="info" width="40px" height="20px"></DxButton>
            </template>
            <DxLoadPanel :enabled="true" :text="$t('central-Loading')" />
            <DxPaging :enabled="false" />
            <DxStateStoring :enabled="true" type="localStorage" :storage-key="storageKey" />
            <DxToolbar>

              <DxItem locate-in-menu="never" name="searchPanel" :visible="true" width="150px" />
              <DxItem locate-in-menu="auto" name="groupPanel" />

              <DxItem location="after" locate-in-menu="always" widget="dxButton">
                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto"
                  :text="$t('central-ObnovitPuvodniRozlozeni')" type="success"
                  style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined"
                  @click="GridLayoutReset" />
              </DxItem>
              <DxItem locate-in-menu="auto" name="columnChooserButton" />

              <DxItem locate-in-menu="auto" name="exportButton" />



            </DxToolbar>
            <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
            <DxGrouping :auto-expand-all="true" />
            <DxExport :enabled="true" :formats="['xlsx']" :allow-export-selected-data="false"
              :texts="{ exportAll: $t('central-ExportVseExcel') }" />
            <DxSearchPanel width="300px" :visible="true" :placeholder="$t('central-Hledat')" />
            <DxFilterRow :visible="true" />
            
            <DxFilterPanel :visible="true" :texts="{
              clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
            }" />
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition" value="text" />
            <DxHeaderFilter :visible="true" />
            <DxColumnChooser :enabled="true" allowSerach="true" mode="dragAndDrop" :title="$t('central-VyberSloupce')"
              :emptyPanelText="$t('central-PretahneteSemSloupecProZmizeni')" />
            <DxSelection mode="multiple" />
            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
            <DxColumnFixing :enabled="true" :texts="{ 
              fix: $t('central.ColumnFixing.fix'), leftPosition: $t('central.ColumnFixing.leftPosition'), rightPosition: $t('central.ColumnFixing.rightPosition'), unfix: $t('central.ColumnFixing.unfix')
            }" />
          </DxDataGrid>
        </div>
        <div class="bordersDataGridPozadavkyModuly" v-if="this.Zobrazeni == 'pivotgrid'">
          <DxPivotGrid id="pivotgrid" ref="pivot" :data-source="jsonData" :allow-sorting-by-summary="true"
            :allow-filtering="true" :show-borders="true" :show-column-grand-totals="true" :show-row-grand-totals="true"
            :show-row-totals="true" :show-column-totals="true" @ContentReady="onContentReadyPivot" :allow-sorting="true"
            :allow-expand-all="true" @cell-click="onCellClickPivot" :height="(vyskaZobrazitelna / 1.3)" column-max-width="150px" width="100%"
            :texts="{ noData: $t('central-NenalezenyZadneZaznamy') }">
            <DxFieldChooser :enabled="true" :height="400" />
            <DxFieldPanel :show-column-fields="true" :show-data-fields="true" :show-filter-fields="true"
              :show-row-fields="true" :allow-field-dragging="true" :visible="true" />
          </DxPivotGrid>

        </div>

      </div>
    </DxScrollView>
  </div>
</template>
<script>
import Attachments from '@/components/Attachments.vue';
import CustomDropDownBox from '@/components/CustomDropDownBox.vue';
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxPivotGrid, {
  DxFieldChooser,
  DxFieldPanel,
} from 'devextreme-vue/pivot-grid';
import {
  DxHeaderFilter,
  DxFilterRow,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxStateStoring,
  DxColumnFixing,
  DxColumnChooser,
  DxLoadPanel, DxToolbar,
  DxSorting
} from 'devextreme-vue/data-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import { isUndefined } from 'lodash';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { DxExport } from 'devextreme-vue/data-grid';
import HelpLink from '@/components/HelpLink.vue';
import debounce from 'lodash/debounce';


const PivotData = new PivotGridDataSource({
  // ...
});
const dataSource = new DataSource({
  group: 'category',
  // ...
});

 
export default {
  name: 'MajetekView',
  data() {

    return {
      PrilohyID: 0,
      Hlavicka: true,
      NiceUrl: false,
      MountingNiceUrl: true,
      LookupValue: -1,
      MountingQueries: true,
      ParamsQuery: {},
      Dotid: -1,
      popupPrilohy: false,
      DrillDownColumns: [],
      popupTitle: '',
      popupVisible: false,
      drillDownDataSource: null,
      PivotFieldsLoaded: false,
      NormalGridContentLoaded: false,
      Zobrazeni: 'normalgrid',
      picked: false,
      dataSource,
      jsonData: [],
      PivotData,
      columns: [],
      search: '',
      disable: false,
      page: 1,
      checked: true,
      params: [],
      dotaz: [],
      queryHint: '',
      GridRows: [],
      popup: false,
      hintShow: false,
      storageKey: 'Dotazovac0',
      msg: this.$t("dotazovac.dotazovac"),
      filterBuilderPopupPosition: {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
      },
      vyskaZobrazitelna: 250
    };

  },
  computed: {
    dataGrid: function () {
      let self = this;
      return self.$refs.mygrid.instance ;
    },
    dataGridDrillDown: function () {
      let self = this;
      return self.$refs.drilldown.instance;
    },
    titOtevritZavritPreference: function () {
            if (this.Hlavicka==true) {
                return this.$t('central.zavritNastaveni')+String.fromCharCode(160)+this.$t('dotazovac.Dotazu') 
            }
            else { 
                return this.$t('central.otevritNastaveni')+String.fromCharCode(160)+this.$t('dotazovac.Dotazu')}
            
    },
    

    /*  dataGridPivot: function () {
        let self = this;
        return self.$refs.pivot.instance;
      },
      */
  },
  unmounted() {
    window.removeEventListener("resize", this.debouncedWindowResize);
  },
  mounted() {
    window.addEventListener("resize", this.debouncedWindowResize);
    this.DrawerSelected()
    this.$log.debug('mounted this.$route',this.$route)
    //this.GetQuery()
    // this.Granty();
    if (!window.location.search.includes('?')) this.MountingQueries = false
    if (isUndefined(this.$route.params.Niceurl)) {
      this.ParamsQuery = this.$route.query
      this.MountingNiceUrl = false
    }
    this.Dotazy();

    //this.TableData();
    //window.localStorage.setItem("refresh-page", 'Dotazovac');
    setTimeout(() => {
      this.$nextTick(() => {
        this.debouncedWindowResize()
      });
    }, 1000);
  },
  components: {
    DxScrollView,
    Attachments,
    CustomDropDownBox,
    DxHeaderFilter,
    DxFilterRow,
    DxFilterPanel,
    DxFilterBuilderPopup,
    DxExport,
    DxStateStoring,
    DxColumnFixing,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxPivotGrid,
    DxFieldChooser,
    DxFieldPanel,
    HelpLink,
    DxSorting
  },
  methods: {
    debouncedContentReady: debounce(
  function () {
    this.$log.debug("debouncedContentReady");
    this.NormalGridContentLoaded = false;
    this.onContentReadyFromDebounce(this.dataGrid);
  },
  750,
  { leading: true, trailing: true } 
),
    debouncedWindowResize: debounce(function() {
      this.$log.debug("debouncedWindowResize");
      this.handleMainViewChange();      
    }, 500,{
      leading: false,    
      trailing: true   
    }), 
    HidingPopupPrilohy(){
        this.$nextTick(() => {
        this.dataGrid.columnOption('invoiceid', 'cellTemplate', 'prilohy')
        this.dataGrid.columnOption('pozid', 'cellTemplate', 'detailPozadavku')
        this.dataGrid.columnOption('invoiceid', 'caption', this.$t('central-Prilohy'))
        this.dataGrid.refresh();
          });

    },
    changeBoolHlavicka(){
      this.dataGrid.off("contentReady");
      this.Hlavicka = !this.Hlavicka;
      this.dataGrid.on("contentReady");
    },
    getTitle(item) {
      //this.$log.debug('getTitle(item)');
      //this.$log.debug('item',item);
      var ItemContent = item.Content;
      var LookupArray = item.DataSource._items;
      //this.$log.debug('item.DataSource._items',LookupArray);
      if ((Array.isArray(LookupArray))  && (ItemContent>0))  {
        //this.$log.debug('ITEM NALEZEN',LookupArray.find(data => data.id == ItemContent))
        var foundItem = LookupArray.find(data => data.id == ItemContent)
        return foundItem ? foundItem.content : undefined;
        } else{
          //this.$log.debug('item.DataSource._items.length',LookupArray.length)
          return ''
        } 
     
      //return foundLookItem.content;
        
      /*if (item.Component == 'T') {
        return item.selected || ''; 
      } 
      else if (item.Component == 'L') {
        if (Array.isArray(item.DataSource)) {
        const selectedItem = item.DataSource.find(data => data.id === item.selected);
        return selectedItem ? selectedItem.content : '';
        }
      } 
      else if (item.Component == 'D') {
        return item.selected ? new Date(item.selected).toLocaleDateString() : '';
      }
      return '';*/

    },
    handleMainViewChange() {
      let self = this;
      var element = document.getElementById("ScrollViewAPP");
      if (element) {
        var height = element.clientHeight;
        if ((height < 500) && (height > 300)) { height = 550 }
        if (height <= 300) { height = 450 }
        self.vyskaZobrazitelna = height;
      }
      self.debouncedContentReady();
    },
    Redirect(id) {
      //window.RedirectURL = window.location.href
      this.$log.debug('Redirect',window.location)
      window.open(
        '/Pozadavky?Vypis=0&Stav=4&' + 'Pozid=' + id + '&Typ=' + 1,
        '_blank' // <- This is what makes it open in a new window. a
      );
      //  this.$router.push('/Pozadavky?Vypis=0&Stav=4&' + 'Pozid=' + id + '&Typ=' + 1 + '&Return=Dotazovac')
    },
    addParamsToLocation(params, clear) {
      /*  if (this.$route.query.Return != undefined) {
          this.$router.push('/' + this.$route.query.Return)
          //this.$router.back() 
          return 1;
        }*/
      this.$log.debug(this.$route)
      this.$log.debug(params)
      if (!this.MountingNiceUrl) this.$route.path = '/Dotazovac'
      if (clear) {
        history.pushState(
          history.state,
          null,
          this.$route.path
        )
      }
      else {
        this.ParamsQuery = { ...this.ParamsQuery, ...params }
        params = this.ParamsQuery
        history.pushState(
          history.state,
          null,
          this.$route.path +
          '?' +
          Object.keys(params)
            .map(key => {
              return encodeURIComponent(key) + '=' +
                //encodeURIComponent(params[key]
                (encodeURIComponent(params[key]) == 'undefined' ? '' : encodeURIComponent(params[key]))
            })
            .join('&')
        )
      }
      this.ParamsQuery = {}
    },
    UrlQueryParams() {
      window.this.$log.debug(this.$route)
      if (Object.keys(this.$route.query).length != 0) {
        if (this.$route.query.Dotid != undefined) {
          this.Dotid = parseInt(this.$route.query.Dotid)
        }
      }
    },
    CellSettings(e) {
      var self = this
      if (e.value > 0 && e.column.dataField == 'invoiceid' && e.rowType == "data") {
        //window.this.$log.debug(e)
        e.cellElement.addEventListener('click', function () {
          self.PrilohyClick(e.value)
        });
      }
      if (e.column.dataField == 'pozid' && e.rowType == "data") {
        //  window.this.$log.debug(e)
        e.cellElement.addEventListener('click', function () {
          self.$router.push('/Pozadavky?Vypis=0&Stav=4&' + 'Pozid=' + e.value + '&Typ=' + 1 + '&Return=Dotazovac')
        });
      }
    },
    async downloadItem(url, name, suffix) {
      //window.this.$log.debug(suffix)
      suffix = ".pdf";
      axios.get(url, { headers: { "Accept": "application/pdf" }, responseType: "blob" })
        .then(function (response) {
          switch (suffix) {
            case ".pdf": {
              // window.this.$log.debug(response)
              const blob = new Blob([response.data], { type: "application/" + suffix });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              // window.this.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              break;
            }
          }
        })
        .catch(function (error) {
          window.this.$log.debug(error);
          window.this.$log.debug(JSON.stringify(error));
        });
    },
    GetHash(FileName, FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(FileID, function (result) {
        //window.this.$log.debug(result);
        self.hash = window.URL1 + "/js/download/" + result.Value.value;
        //window.this.$log.debug(self.hash + " " + FileName)
        self.downloadItem(self.hash, FileName);
        if (result.Error.value.ErrorNum.value != 0)
          notify({
            message: self.$t("central-NepodariloSeStahnout") + result.Error.value.ErrorTextCZ.value + "\".",
            position: {
              my: "center top",
              at: "center top",
            },
          }, "error", 5000);
      }, window.this.ApiError());
    },
    PrilohyClick(id) {
      this.PrilohyID=id
      window.this.$log.debug(id)
      let self = this;
      self.popupPrilohy = true;
      this.HidingPopupPrilohy()
    },
    onCellClickPivot(e) {
      if (e.area === 'data') {
        const pivotGridDataSource = e.component.getDataSource();
        const rowPathLength = e.cell.rowPath.length;
        const rowPathName = e.cell.rowPath[rowPathLength - 1];
        this.drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(e.cell);
        this.popupTitle = `${rowPathName || 'Celkem/Total'
          } `;
        this.popupVisible = !this.popupVisible;
      }
    },
    onShownDrillDown() {
      this.dataGridDrillDown.updateDimensions();
    },
    DrawerSelected() {
      var e = window.Drawer
      if (window.prevElement != undefined && window.prevPosition != undefined)
        window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
      this.$log.debug(window.Drawer)
      this.$log.debug(this.$route.meta.id)
      this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
      for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
        // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
        if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
          e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
          window.prevElement = e
          window.prevPosition = x
        }
      }
    },
    setParamValue(e) {
      this.$log.debug('setParamValue',e);
      this.$log.debug(e.element.id)
      this.$log.debug(this.params)
      switch (this.params[e.element.id - 1].Component) {
        case 'T':
          this.params[e.element.id - 1].Content = e.value
          //this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value})

          break;
        case 'L':
          this.params[e.element.id - 1].Content = e.value
          // this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value })
          break;
        case 'D':
          this.params[e.element.id - 1].Content = e.value.toISOString().slice(0, 10)
          // this.addParamsToLocation({ [this.params[e.element.id - 1].ParamValue]: e.value })
          //this.params[e.element.id - 1].Content = String(e.value).slice(0, 10);
          break;


      }
      // var nazev = e.element.id
      //   e.value
    },
    onExporting(e) {
      this.$log.debug('test uvnitr onexporting')
      if (e.format === "xlsx") {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Export");
        exportDataGridToExcel({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
          customizeCell: function (options) {
            const excelCell = options;
            excelCell.font = { name: 'Arial', size: 12 };
            excelCell.alignment = { horizontal: 'left' };
            excelCell
          }
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            const datum = new Date();
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),

              this.msg + " " + datum.toISOString('cs-CZ').substring(5, 10) + " " + datum.toTimeString('cs-CZ').substring(0, 9) + ".xlsx"
            );
          });
        });
        e.cancel = true;
      }
    },
    onContentReadyFromDebounce(GridInstance){
      this.$log.debug('Calling onContentReadyFromDebounce');
      if (!this.NormalGridContentLoaded) {
        this.$log.debug('Calling onContentReadyFromDebounce true');
        this.NormalGridContentLoaded = true;
        this.setupGridColumns(GridInstance);
      }
    },
    /*setupGridColumns(grid) {
  if (!grid) return;

  const colCount = grid.columnCount();
  if (colCount <= 0) return;

  for (let x = 0; x < colCount; x++) {
    let columnCaption = grid.columnOption(x, 'caption');

    if (!columnCaption) continue;

    if (columnCaption.startsWith("DX")) {
      grid.columnOption(x, 'format', ",##0.00");
      grid.columnOption(x, 'caption', this.$t(columnCaption.substring(2).trim()));
    } 

   
    switch (columnCaption) {
      case "POZID":
        grid.columnOption(x, 'caption', this.$t('central-DetailPozadavku'));
        grid.columnOption(x, 'visibleIndex', 0);
        break;
      case "INVOICEID":
        grid.columnOption(x, 'caption', this.$t('central-Prilohy'));
        grid.columnOption(x, 'visibleIndex', 0);
        break;
    }
    if (columnCaption.includes("|")) {
      const [localCaption, englishCaption] = columnCaption.split("|").map(part => part.trim());
      const language = window.localStorage.getItem("jazyk") || 'default';
      grid.columnOption(x, 'caption', language === 'en' ? englishCaption : localCaption);
    }
  }
},*/
setupGridColumns(grid) {
  if (!grid) {this.hide();return;}

  const colCount = grid.columnCount();
  if (colCount <= 0) {this.hide();return;}
  grid.off("contentReady");
  const language = window.localStorage.getItem("jazyk") || 'default';

  // Helper function 
  const handleDXColumn = (index, caption) => {
    const formattedCaption = caption
        .split('|') // Split by the '|' separator
        .map(part => part.trim().substring(3)) // Trim and remove 'dx ' (3 characters)
        .join(' | '); // Join back with the ' | ' separator
    this.$log.debug('handleDXColumn',formattedCaption);
    grid.columnOption(index, 'format', ",##0.00");
    const [localText, englishText] = formattedCaption.split("|").map(part => part.trim());
    grid.columnOption(index, 'caption', language === 'en' ? englishText : localText);
  };

  // Helper function 
  const handleSpecificColumns = (index, caption) => {
    if (caption === "POZID") {
      grid.columnOption(index, 'caption', this.$t('central-DetailPozadavku'));
      grid.columnOption(index, 'visibleIndex', 0);
      
    } else if (caption === "INVOICEID") {
      grid.columnOption(index, 'caption', this.$t('central-Prilohy'));
      grid.columnOption(index, 'visibleIndex', 0);
      
    }
  };

  // Helper function 
  const handleLanguageColumn = (index, caption) => {
    const [localText, englishText] = caption.split("|").map(part => part.trim());
    grid.columnOption(index, 'caption', language === 'en' ? englishText : localText);
  };

  
  for (let i = 0; i < colCount; i++) {
    const columnCaption = grid.columnOption(i, 'caption');
    if (!columnCaption) continue;

    const upperCaption = columnCaption.toUpperCase();

    if (upperCaption.startsWith("DX")) {
      handleDXColumn(i, columnCaption);
    } else if (upperCaption === "POZID" || upperCaption === "INVOICEID") {
      handleSpecificColumns(i, upperCaption);
    } else if (columnCaption.includes("|")) {
      handleLanguageColumn(i, columnCaption);
    }
  }
  grid.columnOption('invoiceid', 'cellTemplate', 'prilohy');
  grid.columnOption('pozid', 'cellTemplate', 'detailPozadavku');
  grid.on("contentReady");
},

    onContentReadyPivot(e) {
      //this.$log.debug(e.component.getDataSource().fields().length)
      if (this.PivotFieldsLoaded == false && e.component.getDataSource().fields().length > 0) {
        //this.$log.debug('Pocet sloupcu:'+e.component.columnCount())
        this.$log.debug(e)
        this.$log.debug(e.component.getDataSource())
        this.$log.debug(e.component.getDataSource().fields())
        this.DrillDownColumns = e.component.getDataSource().fields()


        for (var x = 0; x < e.component.getDataSource().fields().length; x++) {
          if (e.component.getDataSource().fields()[x].caption.substring(0, 2) === "Dx") {
            // e.component.columnOption(x, 'format', ",##0.00")
            e.component.getDataSource().fields()[x].caption = e.component.getDataSource().fields()[x].caption.substring(2)
          }

          if (e.component.getDataSource().fields()[x].caption.substring(0, 2) === "Rx") {
            // e.component.columnOption(x, 'format', ",##0.00")
            e.component.getDataSource().field(e.component.getDataSource().fields()[x].caption, { area: 'row' });
            e.component.getDataSource().fields()[x].caption = e.component.getDataSource().fields()[x].caption.substring(2)
            e.component.getDataSource().load();
            //  e.component.columnOption(x, 'caption', e.component.columnOption(x, 'caption').substring(2))
          }
          if (e.component.getDataSource().fields()[x].caption.substring(0, 2) === "Cx") {
            //  e.component.columnOption(x, 'format', ",##0.00")
            e.component.getDataSource().field(e.component.getDataSource().fields()[x].caption, { area: 'column' });
            e.component.getDataSource().fields()[x].caption = e.component.getDataSource().fields()[x].caption.substring(2)
            e.component.getDataSource().load();
            //  e.component.columnOption(x, e.component.getDataSource().fields()[x].caption, e.component.getDataSource().fields()[x].caption.substring(2))
          }
          if (e.component.getDataSource().fields()[x].caption.substring(0, 2) === "Mx") {
            e.component.getDataSource().field(e.component.getDataSource().fields()[x].caption, { area: 'data', summaryType: 'sum', format: ",##0.00" });
            e.component.getDataSource().fields()[x].caption = e.component.getDataSource().fields()[x].caption.substring(2)
            e.component.getDataSource().load();
            //  e.component.columnOption(x, 'format', ",##0.00")
            //  e.component.columnOption(x, 'caption', e.component.columnOption(x, 'caption').substring(2))

          }

        }
        this.PivotFieldsLoaded = true
      }

    },

    GridLayoutReset(e) {
      this.$log.debug(e)
      this.$log.debug(this.dataGrid)
      this.dataGrid.state(null);
      /*
      this.NormalGridContentLoaded = false
      var valueObject = {
        component: this.dataGrid,
      };
      this.onContentReady(valueObject)
      */
      
      
    },
    GetQuery() {

      var self = this;
      var gen = new window.General(window.channel, window.message);
      var pole = []
      var niceurlpole = { Dotid: self.dotaz.toString() }
      for (var x = 0; x < self.params.length; x++) {
        //  this.$log.debug(result.List.value.items[x]);
        /*   if (!this.MountingNiceUrl) {
             if (isUndefined(self.params[x].Content)) self.addParamsToLocation({ Dotid: self.dotaz, [self.params[x].ParamValue]: '' })
             else
               self.addParamsToLocation({ Dotid: self.dotaz, [self.params[x].ParamValue]: self.params[x].Content })
           }
           */
        if (isUndefined(self.params[x].Content)) self.params[x].Content = ''
        var i = pole.length;
        pole[i] = {
          Value: self.params[x].ParamValue,
          Detail: self.params[x].Content,
          Id: x,
        };
        niceurlpole[self.params[x].ParamValue] = self.params[x].Content
      }
      //   if (self.params.length == 0 && !this.MountingNiceUrl) self.addParamsToLocation({ Dotid: self.dotaz })
      self.$log.debug(pole)
      self.$log.debug(niceurlpole)
      window.loader = this.$loading.show({ loader: 'dots' });
      if (self.dotaz!=-1) {
        gen.GetQuery(self.dotaz, pole,
          function (result) {
            self.NormalGridContentLoaded = false
            self.PivotFieldsLoaded = false
            self.$log.debug(result)
            //this.$log.debug(JSON.parse(result.Value.value))
            self.jsonData = [];
            self.storageKey = 'Dotazovac' + self.dotaz;
            if (result.Error.value.ErrorNum.value == 0) {
              self.Hlavicka = false;
              self.PivotFieldsLoaded = false
              if (result.Value.value != '') {
                self.jsonData = JSON.parse(result.Value.value)
              } else self.jsonData = []
              /*let cols = self.$refs.mygrid.instance.option("columns");
              let info = [];
              for (var i = 0; i < cols.length; i++) {
                info.push(self.$refs.mygrid.instance.columnOption(cols[i].dataField));
              }
            self.$log.debug(info);      */    }
            else {
              self.jsonData = []
              notify({
                message: result.Error.value.ErrorTextCZ.value,
                position: {
                  my: 'center top',
                  at: 'center top',
                },
              }, 'error', 5000);
            }
            if (!self.MountingNiceUrl) self.GetNiceUrl(niceurlpole, true)
            self.MountingQueries = false
            self.MountingNiceUrl = false
            window.loader.hide()
          }, window.this.ApiError());
        }
    },
    GetQueryParamLookup(paramName, ItemIndex) {
      var self = this
      var gen = new window.General(window.channel, window.message);
      window.this.$log.debug(paramName)
      const data = new DataSource({
        // ...
      });
      if (self.dotaz!=-1) {
      gen.GetQueryParamLookup(self.dotaz, paramName,
        function (result) {
          self.$log.debug(result)

          const store = data.store();
          store.clear()

          for (var x = 0; x < result.List.value.items.length; x++) {
            if (x == 0) self.params[ItemIndex].selected = result.List.value.items[x].Value.value
            store.insert({
              content: result.List.value.items[x].Detail.value,
              id: result.List.value.items[x].Value.value,

            })
              .then(
                () => {
                  data.reload();
                },
                (error) => { self.$log.debug(error) /* ... */ }
              );

          }
          if (self.LookupValue > 0) {
            self.params[ItemIndex].selected = self.LookupValue
            self.LookupValue = -1
          }
          self.$log.debug(data)
        }, window.this.ApiError());
      return data;}
    },
    GetQueryParams() {
      this.Hlavicka = true
      var self = this;
      var gen = new window.General(window.channel, window.message);
      window.this.$log.debug(window.this.ApiError())
      window.this.$log.debug(this.$route)
      window.this.$log.debug(window.location)
      window.this.$log.debug(self.$route.query)
      window.this.$log.debug(self.ParamsQuery)
      this.params.length = 0
      // console.info(window._Network_state ? 'Online' : 'Offline');
      if (self.dotaz!=-1) {
      gen.GetQueryDetail(self.dotaz,
        function (result) {

          self.queryHint = result.Value.value;
          self.hintShow = result.Value.value != '';
          //window.this.$log.debug(self.resultQueryHint);
        }, window.this.ApiError());
      gen.GetQueryParams(self.dotaz,
        function (result) {
          self.$log.debug(result)
          if (result.List.value.items[0].Detail.value == 'P') self.Zobrazeni = 'pivotgrid'
          else self.Zobrazeni = 'normalgrid'
          for (var x = 1; x < result.List.value.items.length; x++) {
            //  self.$log.debug(result.List.value.items[x]);
            var i = self.params.length;
            self.params[i] = {

              Value: result.List.value.items[x].Value.value,
              Id: result.List.value.items[x].Id.value + 1,
              Detail: result.List.value.items[x].Detail.value,
              Component: result.List.value.items[x].Value.value[0],
              ParamValue: (result.List.value.items[x].Value.value).slice(1),
            };
            if (Object.hasOwn(self.$route.query, self.params[i].ParamValue) && self.MountingQueries) {
              self.$log.debug('Query')
              self.params[i].Content = self.$route.query[self.params[i].ParamValue]
              if (self.params[i].Component == 'L') self.LookupValue = self.$route.query[self.params[i].ParamValue]
            }
            else if (Object.hasOwn(self.ParamsQuery, self.params[i].ParamValue) && self.MountingNiceUrl) {
              self.$log.debug('NICEURL')
              self.params[i].Content = self.ParamsQuery[self.params[i].ParamValue]
              if (self.params[i].Component == 'L') self.LookupValue = JSON.stringify(self.ParamsQuery[self.params[i].ParamValue])
            }
            if (self.params[i].Component == 'D') self.params[i].Content = new Date().toISOString().slice(0, 10)
            if (self.params[i].Component == 'L') {
              self.params[i].DataSource = self.GetQueryParamLookup(self.params[i].ParamValue, i)
            }

          }
          if (self.MountingQueries || self.MountingNiceUrl) {
            self.GetQuery()
          }// else self.GetNiceUrl({ Dotid: self.dotaz, [self.params[x].ParamValue]: self.params[x].Content })
          //self.Zobrazeni = 'pivotgrid'  

          self.$log.debug(self.params)
        }, window.this.ApiError());}
    },
    SearchDelay(search) {
      let self = this;
      clearTimeout(this.timer)
      // window.this.$log.debug(search)
      this.timer = setTimeout(() => {
        self.page = 1
        this.search = search
        //self.TableData();
      }, 1000)

    },
    setSelectedValue(e) {
      this.picked = false
      window.this.$log.debug(window.channel)
      window.this.$log.debug('setSelectedValue(e)',e);
      switch (e.element.id) {
        case 'dropdown':
          if (e.value > 0) {
            this.picked = true
            window.this.$log.debug('dropdown value>0',e.value);
            this.dotaz = e.value
            window.this.$log.debug(window.location.search);
            if (!this.MountingNiceUrl) {
              if (window.location.search.includes('&') && this.MountingQueries)
                this.addParamsToLocation({}, false)
              //  else this.addParamsToLocation({}, true) pokud chci pri zmene dotazu vymazat url
            }
            this.GetQueryParams();
          }
          else if (e.value < 0){
            window.this.$log.debug('dropdown value<0',e.value);
            this.dotaz =-1;
            this.params.length = 0
            this.picked = false
          }
          else {
            window.this.$log.debug('dropdown else',e.value);
            this.dotaz =-1;
            this.params.length = 0
            this.picked = false 
          }
          break;
        case '100':
          this.dotaz = e.value
          window.this.$log.debug(window.location.search);
          if (!this.MountingNiceUrl) {
            if (window.location.search.includes('&') && this.MountingQueries)
              this.addParamsToLocation({}, false)
            //  else this.addParamsToLocation({}, true) pokud chci pri zmene dotazu vymazat url
          }
          this.GetQueryParams()
          break;
        /*
            case '2':
              this.zamestnanec = e.value
              break;
            case '3':
              this.typpozadavek = e.value
              break;
            case '4':
              this.stavpozadavek = e.value
              break;
            
        case '5':
            this.stavpozadavek = e.value
            break;
        case '6':
            this.stavpozadavek = e.value
            break;
        case '7':
            this.stavpozadavek = e.value
            break;
            */
      }

      // this.SkipDiacritics(this.typpozadavek2.content)
      //  this.pozadavek=e.value
      // this.selectedValue = e.value;
    },
    GetNiceUrl(url, click) {
      var self = this
      var gen = new window.General(window.channel, window.message);
      self.$log.debug(this.$route.params.Niceurl);
      self.$log.debug(url);
      self.$log.debug(JSON.stringify(url));
      var niceurlparam = this.$route.params.Niceurl
      if (isUndefined(this.$route.params.Niceurl)) niceurlparam = ''
      gen.GetNiceUrl(niceurlparam, JSON.stringify(url),
        function (result) {

          self.$log.debug(result);
          if (result.Id.value == 0) {
            if (click) self.addParamsToLocation(url, false)
            else self.UrlQueryParams()
          }

          else {
            if (url != '') self.NiceUrl = true
            var r = /\d+/;
            self.$log.debug(JSON.parse(result.Url.value));
            self.$log.debug(result.Url.value.match(r)[0]);
            self.ParamsQuery = JSON.parse(result.Url.value)
            if (niceurlparam != '' && url == '') {
              self.Dotid = parseInt(self.ParamsQuery.Dotid)
            }
            else {
              // self.addParamsToLocation({}, true)
              self.$route.href = '/' + result.Component.value + '/' + result.NiceUrl.value
              self.$log.debug(self.$route);
              history.pushState(
                history.state,
                null,
                self.$route.path + '/' + result.NiceUrl.value
              )
            }
          }


        }, window.this.ApiError());
    },
    Dotazy() {
      var self = this
      window.this.$log.debug(dataSource.store())
      var gen = new window.General(window.channel, window.message);
      const store = dataSource.store();
      store.clear()
      gen.ListLookupDetail('QUERIES', 1, 50,
        function (result) {
          self.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            //  self.$log.debug(result.List.value.items[x]);
            store.insert({
              id: result.List.value.items[x].Id.value,
              content: result.List.value.items[x].Value.value,
              category: result.List.value.items[x].Detail.value,
              ident: result.List.value.items[x].Ident.value,

            })
              .then(
                () => {
                  //  self.$log.debug(dataObj)
                  dataSource.reload();
                },
                (error) => { self.$log.debug(error) /* ... */ }
              );
          }
          self.GetNiceUrl('', false)

        }, window.this.ApiError());
    },
    ShowDetail(e) {
      window.this.$log.debug(e)
      var asset = new window.Asset(window.channel, window.message);
      var self = this;
      asset.GetAssetDetail(e.data.internalid,
        function (result) {
          self.majetekNazev = result.Name.value
          self.zam = result.OwnerName.value,
            self.invC = result.Id.value
          self.vyrC = result.SerialNumber.value,
            self.porizeno = result.InEvidenceFrom.value
          self.porCena = result.Value.value.toFixed(2)
          // self.zarazeno= 
          self.$log.debug(result)
        }, window.this.ApiError());
      this.popup = true;
    },
    RefreshDataGrid() {
      this.dataGrid.refresh()
        .then(function () {
          // ...
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          // ...
        });
    },
    open() {
      window.loader = this.$loading.show({ loader: 'dots' });
    },
    hide() {
      if (window.loader) {
        window.loader.hide();
        window.loader = null; // Clear the reference after hiding
      } else {
        console.warn("No active loader to hide.");
      }
    },

  },

}
</script>
<style scoped>
#container1 {
  overflow: hidden;
  margin-bottom: 0px;
}

h3 {
  margin-top: 5px;
}

#params {
  overflow: hidden;
  margin-bottom: 10px;
}


#DataGrid {
  margin-right: 100px;
}

#DataGridCely {
  margin-right: 100px;
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.226);
  padding: 10px 10px 10px 10px;
}

#zobraz {
  margin-bottom: 0px;
  background-color: var(--colorZelenaAgree) ;
}

#zobraz:hover {
  background-color: var(--colorByThemeLighter);
  color: white;
}

#obaleniDataGrid {
  border-style: ridge;
  margin-top: 10px !important;
  border-color: rgba(128, 128, 128, 0.226);
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

#nadpisDotazovac {
  text-align: center;
}

.content {
  display: flex;
  justify-content: space-between;

}
#napoveda{  padding-top: 5px;}
</style>