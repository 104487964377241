<template>

  <div class="clNadpisAktualniStranky" height="50px">
    <h2 id="MajetekHeader" class="nadpisH2Moduly prevent-select">{{ $t('DochazkaNazev') }}</h2>
  </div>
  <DxScrollView class="dxScrollViewPozadavky" direction="horizontal" showScrollbar="always">
    <div class="container">
      <div class="selectboxZam" @mouseover="getTitle(zamestnanci,ValueZam)" v-bind:title="getTitle(zamestnanci,ValueZam)">
        <DxSelectBox :id="2" height="45px" width="350px" :label="$t('pozadavky-Zamestnanec')"
          @initialized="GetEmployeesInit($event)" :data-source="zamestnanci" display-expr="content" value-expr="id"
          :value="ValueZam" @closed="SetValue" :placeholder="$t('central-Placeholder')"
          @value-changed="setSelectedValue($event)" :search-enabled="true" @opened="ClearValue" @input="SearchInputEMP"
          noDataText="">
        </DxSelectBox>

      </div>
      <div v-if="SliderVisible" class="slider-container">
        <h3>{{ $t('Dochazka.ViditelneHodiny') }}</h3>
        <DxRangeSlider ref="Slider" :start="StartHours" :end="EndHours" :min="0" :max="24"
          value-change-mode="onHandleRelease" @value-changed="handleValueChange" class="custom-slider" :tooltip="{
            enabled: true,
            format: format,
            showMode: 'always',
            position: 'top',
          }">
        </DxRangeSlider>
      </div>
    </div>
    <DxScheduler ref="Scheduler" time-zone="Europe/Prague" :data-source="Attendance" v-model:current-date="currentDate"
      :views="customViews" :height="650" :start-day-hour="StartHours" :end-day-hour="EndHours"
      v-model:current-view="currentView" data-cell-template="dataCellTemplate" date-cell-template="dateCellTemplate"
      @content-ready="onContentReady" :customize-date-navigator-text="customizeDateNavigatorText"
      @appointment-rendered="onAppointmentRendered" @option-changed="onOptionChanged"
      all-day-panel-template="allDayPanelTemplate" text-expr="text" :adaptivity-enabled="false"
      appointment-tooltip-template="appointmentTooltipTemplate" :on-appointment-form-opening="onAppointmentFormOpening"
      :appointment-template="appointmentTemplate" @appointment-adding="AddAppointment"
      :on-toolbar-preparing="onToolbarPreparing" @appointment-updating="AddAppointment" :editing="{
        allowDragging: false,
        allowUpdating: false,
        allowDeleting: false,
        allowResizing: false,
        allowAdding: false,
      }">
      <template #appointmentTooltipTemplate="{ data }">
        <div class="dx-tooltip-appointment-item flex">
          <!-- Circle Marker -->
          <div class="dx-tooltip-appointment-item-marker">
            <div style="
            width: 16px;
            height: 16px;
            border-radius: 50% !important;
            background-color: #1f3b59; 
            display: inline-block;
          ">
            </div>
          </div>
          <div class="dx-tooltip-appointment-item-content">

            <div class="dx-tooltip-appointment-item-content-date">
              {{ data.appointmentData.startDate.toLocaleTimeString([lang], { hour: '2-digit', minute: '2-digit' }) }} -
              {{ data.appointmentData.endDate.toLocaleTimeString([lang], { hour: '2-digit', minute: '2-digit' }) }}
            </div>
            <div class="dx-tooltip-appointment-item-content-subject">{{ data.appointmentData.text }}</div>
          </div>
          <div v-if="data.appointmentData.CanEdit == undefined || data.appointmentData.CanEdit"
            class="dx-tooltip-appointment-item-delete-button-container">
            <DxButton icon="trash" styling-mode="text" @click="deleteAppointment(data.appointmentData, $event)">
            </DxButton>
          </div>
        </div>
      </template>
      <template #dataCellTemplate="{ data: cellData }">
        <div v-if="cellData && cellData.startDate" :class="{ 'disable-date': isDisabledDate(cellData.startDate) }"
          style="position: relative; height: 100%; overflow: hidden;">
          <!-- Week View: Display total weekly hours in the all-day row -->
          <div
            v-if="(currentView === 'Week' || currentView === 'Týden') && cellData.allDay && calculateDailyHours(cellData) > 0"
            :key="cellData.startDate"
            style="text-align: right; margin-right:5px; font-size: 13px; margin-top:2px !important;font-weight: bold;">
            {{ formatHoursAndMinutes(calculateDailyHours(cellData)) }}
          </div>


          <!-- Month View: Display day number and daily hours -->
          <div v-if="currentView === 'Month' || currentView === 'Měsíc'" :key="cellData.startDate"
            style="position: relative; height: 100%; overflow: hidden;">
            <!-- Day Number in Top-Left Corner -->
            <div style="position: absolute; top: 2px; left: 2px; font-size: 12px; color: gray; z-index: 1;">
              {{ new Date(cellData.startDate).getDate() }}
            </div>

            <!-- Daily Hours in Top-Right Corner -->
            <div v-if="calculateDailyHours(cellData) > 0"
              style="position: absolute; top: 2px; right: 2px; font-size: 12px; color: none; text-align: right; z-index: 2; font-weight: bold;">
              {{ formatHoursAndMinutes(calculateDailyHours(cellData)) }}
            </div>
          </div>
        </div>
      </template>
      <template #dateCellTemplate="{ data: cellData }">
        <div>
          <div v-if="(currentView === 'Week' || currentView === 'Týden')" :class="{
            'disable-date': cellData.date && isDisabledDate(cellData.date)
              }">
            {{ cellData.text }}
          </div>
          <div v-else :class="{
            'disable-date': cellData.date && isWeekend(cellData.date)
              }">
            {{ cellData.text }}
          </div>
        </div>
      </template>
    </DxScheduler>
  </DxScrollView>
</template>

<script>
const now = new Date();
import { isNull } from 'lodash';
import { DxScheduler } from 'devextreme-vue/scheduler';
import DxRangeSlider from 'devextreme-vue/range-slider';
//import Toolbar from 'devextreme/ui/toolbar';
import { locale } from 'devextreme/localization';

import { isUndefined } from 'lodash';
import DxScrollView from 'devextreme-vue/scroll-view';

export default {
  name: 'DochazkaView',
  components: {
    DxScheduler,
    DxRangeSlider,
    DxScrollView
    //Toolbar
  },
  data() {
    return {
      //  customViews: [this.$t('Dochazka.Mesic'), this.$t('Dochazka.Tyden')],
      customViews: [
        { type: 'month', name: this.$t('Dochazka.Mesic'), maxAppointmentsPerCell: 'unlimited' },
        { type: 'week', name: this.$t('Dochazka.Tyden'), maxAppointmentsPerCell: 'unlimited' }, // cislo nebo unlimited
      ],
     // lang:   window.jazyk==undefined ? 'cs' : window.jazyk,
      lang:  window.localStorage.getItem("jazyk"),
      selectedValue: null,
      SliderVisible: false,
      StartHours: 6,
      EndHours: 20,
      ValueZam: null,
      zamestnanci: [{ "content": '', "id": 0 }],
      currentDate: now,
      // currentDate: new Date(2023, 5, 2, 11, 30), // now
      Attendance: [],
      currentView: this.$t('Dochazka.Mesic'),
      AttendanceTypes: [],
      selectBoxAttributes: {
        minWidth: 600,
        minHeight: 0
      },
    };
  },
  computed: {
    totalWeekHours() {
      if (this.currentView !== 'Week' && this.currentView !== 'Týden') return 0;
      if (!this.Attendance || this.Attendance.length === 0) return 0;
      this.$log.debug('Pocitam week hours')
      const weekStart = this.getStartOfWeek(this.currentDate);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 7);

      // Reset weekEnd time to midnight
      weekEnd.setHours(0, 0, 0, 0);

      const weeklyRecords = this.Attendance.filter(record => {
        const recordDate = new Date(record.startDate);
        return recordDate >= weekStart && recordDate < weekEnd;
      });

      const totalHours = weeklyRecords.reduce((total, record) => {

        return total + this.calculateHours(record.startDate, record.endDate);
      }, 0);
      this.$log.debug('Celkem week hours ', totalHours)
      return totalHours;
    },
    totalMonthHours() {

      if (this.currentView !== 'Month' && this.currentView !== 'Měsíc') return 0;
      if (!this.Attendance || this.Attendance.length === 0) return 0;
      this.$log.debug('Volam total month hours')
      // Get the start and end dates of the current month
      const monthStart = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      const monthEnd = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);

      const monthlyRecords = this.Attendance.filter(record => {
        const recordDate = new Date(record.startDate);
        return recordDate >= monthStart && recordDate < monthEnd;
      });

      const totalHours = monthlyRecords.reduce((total, record) => {
        return total + this.calculateHours(record.startDate, record.endDate);
      }, 0);
      //  console.log(totalHours + ' total hours')
      return totalHours;
    },
  },
  unmounted() {
    window.localStorage.setItem("DisplayHourStart", this.StartHours)
    window.localStorage.setItem("DisplayHourEnd", this.EndHours)
  },
  mounted() {
    this.DrawerSelected();
    this.ListAttendanceTypes();
    if (window.localStorage.getItem("jazyk") === 'en') locale('en');
    else locale('cs');

  },
  methods: {
    isWeekend(date){
      if (!date) return false;

    const day = new Date(date).getDay();
    if (day === 0 || day === 6) {
      // Zakázat víkendy
      return true;
    }
    },
    getTitle(datasource,item) {
        this.$log.debug('getTitle(item)');
        this.$log.debug('item',item,'datasource',datasource);
        var ItemContent = item;
        var LookupArray = datasource;
        this.$log.debug('item.DataSource._items',LookupArray,'ItemContent',ItemContent);
        if ((Array.isArray(LookupArray))  && (ItemContent>0))  {
          this.$log.debug('ITEM NALEZEN',LookupArray.find(data => data.id == ItemContent))
          var foundItem = LookupArray.find(data => data.id == ItemContent)
          return foundItem ? foundItem.content : undefined;
          } else{
            this.$log.debug('item.DataSource._items.length',LookupArray.length)
            return ''
          }
    }, 
    onContentReady() {
      // Ensure that the DOM is fully updated
      this.$nextTick(() => {
        this.UpdateToolbarLabel();

      });
      //   this.$refs.Scheduler.instance.repaint()
    },
    UpdateToolbarLabel() {
      this.$log.debug('Updating toolbar label');

      // Get the Scheduler instance and element
      const schedulerInstance = this.$refs.Scheduler.instance;
      const schedulerElement = schedulerInstance.element();

      // Find the toolbar center element
      const toolbarCenterElement = schedulerElement.querySelector('.dx-scheduler-header .dx-toolbar-center');

      if (toolbarCenterElement) {
        // Check if the custom label already exists
        let labelElement = toolbarCenterElement.querySelector('.custom-toolbar-label');
        if (!labelElement) {
          // Create a new label element
          labelElement = document.createElement('div');
          labelElement.classList.add('custom-toolbar-label');
          toolbarCenterElement.appendChild(labelElement);
        }

        if (this.currentView === 'Month' || this.currentView === 'Měsíc') {
          // Update the label text
          const totalMonthHoursFormatted = this.formatHoursAndMinutes(this.totalMonthHours);
          const labelText = `${this.$t('Dochazka.CelkemMesic')} <strong>${totalMonthHoursFormatted}</strong>`;
          labelElement.innerHTML = labelText;
          labelElement.style.display = 'block';
        } else {
          // Hide the label if not in month view
          labelElement.style.display = 'none';
        }
      }
    },
    AddAppointment(e) {
      this.$log.debug(e)
      //   var self=this;
      //  const attend = new window.Attend(window.channel, window.message);
      var data, id, type, startDate, endDate, description

      if (!isUndefined(e.appointmentData)) {
        data = e.appointmentData
        id = 0
      }
      else {
        data = e.newData
        id = data.id
      }
      type = data.AttendanceType
      startDate = data.startDate
      endDate = data.endDate
      if (!isUndefined(data.description)) description = data.description
      else description = ''
      this.$log.debug(id)
      this.$log.debug(type)
      this.$log.debug(startDate)
      this.$log.debug(endDate)
      this.$log.debug(description)
      /*
      attend.SetAttendance( id,type,startDate,endDate, description,
          function (result) {
              self.$log.debug(result);
          },
          window.this.ApiError());
          */
    },

    openAppointment(appointmentData) {
      this.$refs.Scheduler.instance.showAppointmentPopup(appointmentData, false);
    },
    deleteAppointment(appointmentData, e) {

      this.$log.debug(e)
      this.$log.debug(appointmentData)
      // Delete the appointment
      this.$refs.Scheduler.instance.deleteAppointment(appointmentData);

      // Close the tooltip
      this.$refs.Scheduler.instance.hideAppointmentTooltip();

      // Prevent the default click behavior
      e.event.stopPropagation();
    },
    appointmentTemplate(model) {
      const attendanceTypeContent = model.appointmentData.text || "";
      const startTime = model.appointmentData.startDate
        ? model.appointmentData.startDate.toLocaleTimeString([this.lang], { hour: '2-digit', minute: '2-digit' })
        : '';
      const endTime = model.appointmentData.endDate
        ? model.appointmentData.endDate.toLocaleTimeString([this.lang], { hour: '2-digit', minute: '2-digit' })
        : '';

      return `
      <div class="dx-item-content dx-scheduler-appointment-content">
        <div class="dx-scheduler-appointment-content-details" style="text-align: left;">
          <div class="dx-scheduler-appointment-content-date">${startTime} - ${endTime}</div>
        </div>
      <div class="dx-scheduler-appointment-title">${attendanceTypeContent}</div>
      </div>
    `;
    },

    onAppointmentFormOpening(e) {
      //e.component.repaint();
      const form = e.form;
      const formItems = form.option("items");
      const mainGroup = formItems.find(item => item.name === "mainGroup");

      // Determine if this is a new appointment
      const isNewAppointment = !e.appointmentData.id;
      this.$log.debug(e.appointmentData)
      if (mainGroup && mainGroup.items) {
        if (isNewAppointment) {
          form.option('readOnly', false);
          // New appointment: Clear AttendanceType and text
          form.updateData('AttendanceType', null);
          form.updateData('text', '');
        } else {
          // Existing appointment: Set text based on AttendanceType
          if (e.appointmentData.AttendanceType) {
            const selectedItem = this.AttendanceTypes.find(
              type => type.id === e.appointmentData.AttendanceType
            );
            if (selectedItem) {
              form.updateData('text', selectedItem.content);
            }
          }
        }

        // Set up the form items
        mainGroup.items = mainGroup.items.map((item) => {
          if (item.dataField === "text") {
            return {
              name: 'AttendanceType',
              dataField: "AttendanceType",
              editorType: "dxSelectBox",
              colSpan: 2,
              label: {
                text: this.$t('Dochazka.Udalost')
              },
              editorOptions: {
                dataSource: this.AttendanceTypes,
                placeholder: this.$t('Dochazka.UdalostPlaceholder'),
                displayExpr: 'content',
                valueExpr: 'id',
                // Ensure the value is set correctly
                value: e.appointmentData.AttendanceType || null,
                elementAttr: {
                  id: "attendance-type"
                },
                width: "100%",
                onValueChanged: (args) => {
                  const selectedItem = this.AttendanceTypes.find(type => type.id === args.value);
                  if (selectedItem) {
                    form.updateData('AttendanceType', selectedItem.id);
                    form.updateData('text', selectedItem.content);
                  }
                }
              },
              validationRules: [
                {
                  type: "required",
                  message: this.$t('Dochazka.UdalostValidace') // Customize your validation message
                }
              ]
            };
          }
          // Remove "repeat" field if present
          if (item.items) {
            this.$log.debug(item.items)
            item.items = item.items.filter(subItem => subItem.dataField !== "repeat"); // repeat moznosti
            item.items = item.items.filter(subItem => subItem.dataField !== "allDay"); // all day tlacitko
          }
          // Update labels for date fields
          if (item.items) {
            item.items.forEach((subItem) => {
              if (subItem.dataField === "startDate") {
                subItem.label.text = this.$t('Dochazka.DatumOd');
              }
              if (subItem.dataField === "endDate") {
                subItem.label.text = this.$t('Dochazka.DatumDo');
              }
            });
          }
          if (item.dataField === "description") {
            item.label.text = this.$t('Dochazka.Poznamka');
          }
          return item;
        });
        this.$log.debug(e.appointmentData.CanEdit)
        if (!isUndefined(e.appointmentData.CanEdit) && !e.appointmentData.CanEdit) {
          form.option('readOnly', true);
          // mozna trochu pouziti bazuky na kralicka nebot jen potrebuju odstranit readonly u description, chtelo by to asi udelat mene slozite, ale funguje to
          mainGroup.items = mainGroup.items.map((item) => {
            if (item.dataField === "description") {
              item.label.text = this.$t('Dochazka.Poznamka');

              // Make description field editable
              item.editorOptions = item.editorOptions || {};
              //   item.editorOptions.readOnly = false; // Ensure it's editable
            }
            /*
            else {
                // Set other fields to read-only if CanEdit is false
                item.editorOptions = item.editorOptions || {};
                item.editorOptions.readOnly = !e.appointmentData.CanEdit;
            }
                */
            return item;
          });
        }

        let toolbarItems = e.popup.option('toolbarItems');
        this.$log.debug(toolbarItems)
        this.$log.debug('Toolbar items:', JSON.stringify(toolbarItems, null, 2));
        let doneButton = toolbarItems.find(item => item.shortcut === 'done');
        if (doneButton) {
          doneButton.options.text = this.$t('central-Ulozit'); // Replace with your desired text in Czech
          // Optional: Modify other properties if needed
          // doneButton.options.icon = 'your-icon';
        } else {
          this.$log.debug('Done button not found');
        }
        let cancelButton = toolbarItems.find(item => item.shortcut === 'cancel');
        if (cancelButton) {
          // Check if 'options' exists; if not, add it
          if (cancelButton.options) {
            cancelButton.options.text = this.$t('central-Zrusit'); // Replace with your desired text in Czech
          } else {
            // If 'options' doesn't exist, create it
            cancelButton.options = {
              text: this.$t('central-Zrusit'), // Your cancel text
              // onClick: cancelButton.onClick // Preserve the original onClick functionality
            };
          }
        } else {
          this.$log.debug('Cancel button not found');
        }
        e.popup.option('toolbarItems', toolbarItems);


        form.option("items", formItems);
        form.option('formData', e.appointmentData);


        //  form.option('toolbarItems', toolbarItems);

      }
    },

    ListAttendanceTypes() {
      let self = this;
      self.AttendanceTypes.length = 0
      var gen = new window.General(window.channel, window.message);
      gen.ListLookup('DOCHAZKA_TYPY', '', 1, 25,
        function (result) {
          window.this.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {

            //  window.this.$log.debug(result.List.value.items[x]);
            var i = self.AttendanceTypes.length;

            self.AttendanceTypes[i] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };
          }
          self.$refs.Scheduler.instance.repaint()
        }, window.this.ApiError());
    },
    onAppointmentRendered(e) {
      var el = e.appointmentElement;
      // Remove the title attribute to prevent the default tooltip
      el.removeAttribute('title');
      el.onmouseenter = function () {
        e.component.showAppointmentTooltip(e.appointmentData, e.appointmentElement, e.targetedAppointmentData);
      };
      el.onmouseleave = function () {
        //    e.component.hideAppointmentTooltip();  
      };
    },
    SetDisplayHours() {
      var prevEnd = this.EndHours;
      var newStart = parseInt(window.localStorage.getItem("DisplayHourStart"))
      var newEnd = parseInt(window.localStorage.getItem("DisplayHourEnd"))
      this.$log.debug(window.localStorage.getItem("DisplayHourEnd"), 'setdisplayhours')
      if (newStart < newEnd) {

        // Nastaví nové hodnoty najednou
        if (prevEnd == newStart) {
          this.EndHours = newEnd;
          this.$nextTick(() => {
            this.StartHours = newStart;
          });
        }
        else {
          this.StartHours = newStart;
          this.EndHours = newEnd;
        }
      }
    },
    handleValueChange(e) {
      this.$log.debug(e);
      // const prevStart = e.previousValue[0];
      const prevEnd = e.previousValue[1];
      const newStart = e.start;
      const newEnd = e.end;
      if (newStart < newEnd) {

        // Nastaví nové hodnoty najednou
        if (prevEnd == newStart) {
          this.EndHours = newEnd;
          this.$nextTick(() => {
            this.StartHours = newStart;
            this.$nextTick(() => {
              this.SetTotalWeekHoursDiv()

            });
          });
        }
        else {
          this.StartHours = newStart;
          this.EndHours = newEnd;

        }
        this.$nextTick(() => {
          this.SetTotalWeekHoursDiv()

        });
      }


    },
    format(value) {
      return `${value}h`;
    },
    isDisabledDate(date) {
      if (!date) return false;

      const day = new Date(date).getDay();
      if (day === 0 || day === 6) {
        // Zakázat víkendy
        return true;
      }

      const year = new Date(date).getFullYear();
      const holidays = this.getCzechPublicHolidays(year);

      // Zkontrolujte, zda je datum státní svátek
      return holidays.some(holiday => {
        return holiday.getTime() === new Date(date).setHours(0, 0, 0, 0);
      });
    },
    getCzechPublicHolidays(year) {
      const holidays = [];

      // Pevné datumy svátků
      holidays.push(new Date(year, 0, 1));  // 1. ledna - Den obnovy samostatného českého státu
      holidays.push(new Date(year, 4, 1));  // 1. května - Svátek práce
      holidays.push(new Date(year, 4, 8));  // 8. května - Den vítězství
      holidays.push(new Date(year, 6, 5));  // 5. července - Den slovanských věrozvěstů Cyrila a Metoděje
      holidays.push(new Date(year, 6, 6));  // 6. července - Den upálení mistra Jana Husa
      holidays.push(new Date(year, 8, 28)); // 28. září - Den české státnosti
      holidays.push(new Date(year, 9, 28)); // 28. října - Den vzniku samostatného československého státu
      holidays.push(new Date(year, 10, 17)); // 17. listopadu - Den boje za svobodu a demokracii
      holidays.push(new Date(year, 11, 24)); // 24. prosince - Štědrý den
      holidays.push(new Date(year, 11, 25)); // 25. prosince - 1. svátek vánoční
      holidays.push(new Date(year, 11, 26)); // 26. prosince - 2. svátek vánoční

      // Proměnné datumy svátků
      // Velikonoční pondělí
      const easterMonday = this.getEasterDate(year);
      easterMonday.setDate(easterMonday.getDate() + 1); // Velikonoční pondělí je den po Velikonoční neděli
      holidays.push(new Date(easterMonday));

      return holidays;
    },
    getEasterDate(year) {
      let f = Math.floor,
          G = year % 19,
          C = f(year / 100),
          H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
          I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
          J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
          L = I - J,
          month = 3 + f((L + 40) / 44),
          day = L + 28 - 31 * f(month / 4);
      return new Date(year, month - 1, day);
    },
    ClearValue(e) {
      if (e.component.option("text") != '')
        e.component.reset()
    },
    SetValue(e) {
      if (e.component.option("text") == '') {
        var self = this
        this.$log.debug(e)
        var gen = new window.General(window.channel, window.message);
        var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
        self.zamestnanci.length = 0;
        //  self.$log.debug(employee)
        gen.ListEmp(':MYSUB:LIMIT',
          function (result) {
            //  self.$log.debug(result.fResponseObject.result.Result.List.length)
            //    self.$log.debug(result);
            for (var x = 0; x < result.List.value.items.length; x++) {
              self.zamestnanci[self.zamestnanci.length] = {
                content: result.List.value.items[x].Value.value,
                id: result.List.value.items[x].Id.value,
              };

            }
            e.component.getDataSource().reload();
            e.component.option("value", EmpID)
            e.component.repaint()
          }, window.this.ApiError());

      }
    },
    SearchInputEMP(e) {
      let self = this;
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        self.GetEmployees(e)
      }, 1000)

    },
    onOptionChanged(e) {

      if (e.name === 'currentView' || e.name === 'currentDate') {
        this.$log.debug(e);
        this.$nextTick(() => {
          this.ListAttendance();
        });
        if (e.value == "Month" || e.value == 'Měsíc') {
          this.SliderVisible = false
          e.component.option('endDayHour', 24);
          this.$nextTick(() => {
            e.component.option('startDayHour', 0);
            
            window.localStorage.setItem("DisplayHourStart", this.StartHours)
            window.localStorage.setItem("DisplayHourEnd", this.EndHours)
            //this.UpdateToolbarLabel();
          });
        } else if (e.value == 'Week' || e.value == 'Týden') {
          this.$log.debug('Volam display hours')
          this.SliderVisible = true
          this.SetDisplayHours();
          e.component.option('startDayHour', this.StartHours);
          e.component.option('endDayHour', this.EndHours);

          //e.component.repaint()
        }


      }

      // console.log('Scheduler currentDate after:', this.$refs.Scheduler.instance.option('currentDate'));
    },
    customizeDateNavigatorText(e) {
      // this.$log.debug(e)
      const formatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };

      if (this.lang === 'en') {

        const formattedStartDate = e.startDate.toLocaleString('en', formatOptions);
        const formattedEndDate = e.endDate.toLocaleString('en', formatOptions);
        if (this.currentView === 'day' | 'timelineDay') return formattedStartDate;
        if (this.currentView === 'Month' || this.currentView == 'Měsíc') return e.startDate.toLocaleString('en', { year: 'numeric', month: 'long' }); // text je long, numeric cislo
        return formattedStartDate + ' - ' + formattedEndDate;
      }
      else {

        return e.text;
      }
    },
    setSelectedValue(e) {
      this.$log.debug(e)
      this.$log.debug(!isNull(e.value))
      switch (e.element.id) {
        case '1':

          break;
        case '2':

          this.ValueZam = e.value
          if (!isNull(e.value)) {
            this.$nextTick(() => {
              this.$log.debug(e);
              this.$log.debug(this.$refs.Scheduler.instance.getStartViewDate());
              this.$log.debug(this.$refs.Scheduler.instance.getEndViewDate());
              this.ListAttendance();
            });
          }

          break;
        case '3':

          break;
        case '4':

          break;
      }
    },
    GetEmployeesInit(e) {
      this.$log.debug(e)
      var self = this;
      this.ValueZam = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
      var gen = new window.General(window.channel, window.message);
      self.zamestnanci.length = 0;
      gen.ListEmp(':MYSUB:LIMIT',
        function (result) {
          //  self.$log.debug(result.fResponseObject.result.Result.List.length)
          //    self.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[self.zamestnanci.length] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };

          }
          e.component.getDataSource().reload();
          e.component.repaint();
        }, window.this.ApiError());
    },
    GetEmployees(e) {
      this.$log.debug(e)
      var self = this;
      var employee = e.component.option("text")
      var gen = new window.General(window.channel, window.message);
      self.zamestnanci.length = 0;
      //  self.$log.debug(employee)
      gen.ListEmp(':MYSUB:LIMIT' + employee,
        function (result) {
          //  self.$log.debug(result.fResponseObject.result.Result.List.length)
          //    self.$log.debug(result);
          for (var x = 0; x < result.List.value.items.length; x++) {
            self.zamestnanci[self.zamestnanci.length] = {
              content: result.List.value.items[x].Value.value,
              id: result.List.value.items[x].Id.value,
            };

          }
          e.component.getDataSource().reload();
        }, window.this.ApiError());
    },

    formatHoursAndMinutes(decimalHours) {
      if (!decimalHours || isNaN(decimalHours)) {
        return '0h 0m';
      }
      const totalMinutes = Math.round(decimalHours * 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}h ${minutes}m`;
    },
    SetTotalWeekHoursDiv() {
      // Remove existing element if it exists
      const existingDiv = document.getElementById('total-week-hours-div');
      if (existingDiv) {
        existingDiv.remove();
      }

      if (this.currentView === 'Week' || this.currentView === 'Týden') {
        this.$log.debug('Nastavuji novy div')
        // Vyčkejte na další tick, aby byl DOM aktualizován
        const schedulerElement = this.$refs.Scheduler.$el;
        if (schedulerElement) {
          this.$log.debug('settotalweekhours');
          const allDayCell = schedulerElement.querySelector('.dx-scheduler-header-panel-empty-cell');
          if (allDayCell) {
            const newDiv = document.createElement('div');
            newDiv.id = 'total-week-hours-div';
            newDiv.innerHTML = this.$t('Dochazka.CelkemTyden') + ` <strong>${this.formatHoursAndMinutes(this.totalWeekHours)}</strong>`;  // Použití innerHTML pro vykreslení HTML
            //newDiv.textContent = `${this.formatHoursAndMinutes(this.totalWeekHours)}`;
            newDiv.style.position = 'absolute';
            newDiv.style.top = '15%';
            newDiv.style.left = '0';
            newDiv.style.width = '100%';
            newDiv.style.textAlign = 'center';
            newDiv.style.whiteSpace = 'break-spaces'; // Enable line break
            // Set parent element as relative
            allDayCell.style.position = 'relative';

            // Add new div before the original cell content
            allDayCell.insertBefore(newDiv, allDayCell.firstChild);
          }
        }
      }
    },
    calculateDailyHours(cellData) {
      // this.$log.debug('volam dailyhours')
      if (!cellData || !cellData.startDate) {
        return 0;
      }
      const dailyRecords = this.Attendance.filter(record =>
        this.isSameDay(record.startDate, cellData.startDate)
      );
      const totalHours = dailyRecords.reduce(
        (total, record) => total + this.calculateHours(record.startDate, record.endDate),
        0
      );
      // this.$log.debug(cellData.startDate,' pocet hodin ' +totalHours)
      return totalHours;
    },

    calculateHours(startDate, endDate) {
      if (!startDate || !endDate) {
        return 0;
      }
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return 0;
      }
      const hours = (end - start) / (1000 * 60 * 60);
      return hours;
    },

    isSameDay(date1, date2) {
      if (!date1 || !date2) {
        return false;
      }
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
        return false;
      }
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    getStartOfWeek(date) {
      const d = new Date(date);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Set Monday as the first day
      d.setDate(diff);
      // Reset time to midnight
      d.setHours(0, 0, 0, 0);
      return d;
    },
    DrawerSelected() {
      var e = window.Drawer
      if (window.prevElement != undefined && window.prevPosition != undefined)
        window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
      this.$log.debug(window.Drawer)
      this.$log.debug(this.$route.meta.id)
      this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
      for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
        // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
        if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
          e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900'
          window.prevElement = e
          window.prevPosition = x
        }
      }
    },
    ListAttendance() {
      const DateFrom = this.$refs.Scheduler.instance.getStartViewDate();
      const DateTo = this.$refs.Scheduler.instance.getEndViewDate();
      let self = this;
      const attend = new window.Attend(window.channel, window.message);
      this.$log.debug(DateFrom);
      this.$log.debug(DateTo);
      // Odstraněno nastavování currentDate
      attend.ListAttendance(
        this.ValueZam,
        DateFrom,
        DateTo,
        function (result) {
          self.$log.debug('Attendance data loaded:', result);

          const newAttendance = result.List.value.items.map((item) => {
            const typeId = item.AttendanceType.value;
            const typeItem = self.AttendanceTypes.find(type => type.id === typeId);

            return {
              text: typeItem ? typeItem.content : item.Description.value,
              startDate: new Date(item.DateFrom.value),
              endDate: new Date(item.DateTo.value),
              AttendanceType: typeId,
              id: item.AttendanceID.value,
              CanEdit: false,
              Description: ''
            };
          });
          self.Attendance = newAttendance;
          self.$refs.Scheduler.instance.option('dataSource', self.Attendance);

          self.$nextTick(() => {
            if (self.currentView === 'Week' || self.currentView === 'Týden') {
              self.SetTotalWeekHoursDiv();
            }
            if (self.currentView === 'Month' || self.currentView === 'Měsíc') {
              self.UpdateToolbarLabel();
            }
          });
        },
        window.this.ApiError()
      );
    },
  },
};
</script>

<style scoped>
.custom-appointment-tooltip {
  padding: 10px;
}

.appointment-tooltip-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.appointment-tooltip-buttons {
  display: flex;
  gap: 10px;
}

.appointment-tooltip-buttons .dx-button {
  padding: 5px 10px;
}

.dx-scheduler-group-header,
.dx-scheduler-date-table-cell {
  position: relative;
}

.dx-scheduler-date-table-other-month.dx-scheduler-date-table-cell {
  opacity: 1;
  color: rgba(0, 0, 0, 0.3);
}

.dx-scheduler-date-table-cell .dx-template-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 6px;
}

:deep(.dx-scheduler-date-table-cell) {
  height: 25px !important;
}

:deep(.dx-scheduler-date-table-row) {
  height: 25px !important;
}

:deep(.dx-scheduler-time-panel-cell) {
  height: 25px !important;
}

:deep(.dx-scheduler-time-panel-row) {
  height: 25px !important;
}

:deep(.dx-scheduler-cell-sizes-horizontal.dx-scheduler-all-day-table-cell) {
  text-align: center;
}

:deep(.dx-item-content.dx-toolbar-item-content) {
  padding: 0px !important;
}

:deep(.dx-scheduler-header-panel-cell) {
  height: 100%;
  font-weight: bold;
  /* Nastaví tučný text */
  text-align: center;
  /* Zarovná text na střed */
  width: 100px;
  /* Zúžení sloupce hlavičky, uprav dle potřeby */
  vertical-align: middle;
  /* Vertikální zarovnání na střed */
  /* padding-bottom: 5px; */
}

.disable-date {
  z-index: 1; /* Nižší z-index, aby nepřekrývala nadpis */
  background-image:
    repeating-linear-gradient(135deg,
      rgba(160, 160, 160, 0.2),
      rgba(160, 160, 160, 0.2) 4px,
      transparent 4px,
      transparent 9px);
  height: 100%;
  width: 100%;
}

:deep(.dx-scheduler-all-day-title) {
  visibility: hidden;
  /* Skrytí původního textu */
  position: relative;
}

.custom-height-slider {
  height: 75px;
}

.dx-field>.dx-rangeslider {
  flex: 1;
}

.container {
  height: 75px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.selectboxZam {
  margin-top: 15px !important;
  margin-left: 20px;
  margin-bottom: 10px;
}

.slider-container h3 {
  margin-bottom: 40px;
  margin-top: 0px;
}

.slider-container {
  margin-left: 50px;
}

:deep(.custom-slider .dx-slider-handle) {
  width: 11px;
  /* Nastavte požadovanou šířku */
  height: 11px;
  /* Nastavte požadovanou výšku */
  border-radius: 50%;
  /* Zajistí, že budou kulaté */
  background-color: var(--colorByTheme);
  /* Změní barvu podle potřeby */
  transform: translateY(-45%);
  /* Posune bod vertikálně, aby byl vycentrován */
  border: none;
}

:deep(.custom-slider .dx-slider-track) {
  height: 3px;
  /* Výška slideru */
}

:deep(.dx-slider .dx-state-hover.dx-slider-handle:not(.dx-state-active)) {
  border: none;
}

:deep(.dx-slider .dx-state-focused.dx-slider-handle:not(.dx-state-active)) {
  border: none;
}

:deep(.custom-slider .dx-slider-range) {
  height: 3px;
  /* Výška aktivní části slideru (vybrané hodnoty) */
  background-color: var(--colorByTheme);
  /* Barva aktivní části */
}

:deep(.dx-scheduler-time-panel-cell) {
  border-right: 3px solid var(--colorByTheme);
}

:deep(.dx-scheduler-header-panel-container) {
  border-bottom: 4px solid var(--colorByTheme);
}

:deep(.dx-toolbar-items-container) {
  background-color: rgba(100, 100, 100, 0.2);
}

:deep(.dx-toolbar-before) {
  margin-left: 5px;
}

:deep(.dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected) {
  background-color: var(--colorByThemeLighter);
}

:deep(.dx-scheduler-container) {
  border: 2px solid var(--colorByTheme);
}

:deep(.dx-scheduler-appointment-content-date) {
  text-align: left;
  display: flex;
}

:deep(.dx-scheduler-appointment-content-details) {
  white-space: normal;
}

:deep(.custom-toolbar-label) {
  font-size: 16px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.dx-scheduler-appointment-content *) {
  overflow: hidden;
  /* Skryje přetečení */
  white-space: normal;
  /* Povolení automatického zalomení textu */
  word-wrap: break-word;
  /* Umožní zalamování slov, když přesáhnou šířku */
  display: block;
  /* Zajistí, že text správně využije šířku */
  max-width: 100%;
  /* Zajistí, že text bude využívat plnou šířku kontejneru */
}
:deep(.disable-date.dx-template-wrapper) {
  height: calc(100% - 20px); /* Omezí výšku o výšku záhlaví */
  width: 100%;
  position: relative;
  z-index: 1;
}
</style>