<template> 

    <div class="dx-field">
            <DxDropDownBox     
                ref="dropdownbox"
                v-model:value="treeBoxValue"
                v-model:opened="isTreeBoxOpened"
                :show-clear-button="false"
                :data-source="DataSource"
                value-expr="id"
                display-expr="content" 
                :dropDownOptions="{ minWidth: 500 }"
                height="45px"
                width='300px'
                >
                <template #content="{  }">
                    <DxTreeView
                    :data-source="treeDataSource"
                    expand-event="click"
                    key-expr="id"
                    selection-mode="single"
                    display-expr="text"
                    :select-by-click="true"
                    :select-nodes-recursive="true"
                    @item-click="onTreeItemClick"
                    @item-expanded="onItemExpanded"
                    />
                </template>
            </DxDropDownBox>
        </div>
</template>

<script>
import DxDropDownBox from 'devextreme-vue/drop-down-box';
export default {
    //POSTPONED NOT NEEDED ATM
    name: 'CustomDropDownBox',
    props: {
        Disabled: {
            type: Boolean,
            required: true,
        },
        Value: {
            type: Number,
            required: true,
        },
        Width: {
            type: String,
            required: false,
            default: '300'
        },
        Validation: {
            type: Boolean,
            required: false,
            default: true,
        },
        Label: {
            type: String,
            required: false,
        },
        DataSource: {
            type: Object,
            required: true,
        }

    },
    watch: {
        Value(newValue) {
            this.handleParentPropChange(newValue);
        },
    },
    components: {
        DxDropDownBox
    },
    computed: {
        defaultLabel() {
            return this.Label || this.$t('central-ObjednatOd');
        }
    },
    data() {
        return {
            treeDataSource: [],
            treeBoxValue: null,
            isTreeBoxOpened: false,
        };
    },
    mounted() {
        this.$log.debug(this.DataSource);
        this.$log.debug(this.DataSource._store._array);
        
        setTimeout(() => {
            this.treeDataSource = this.DataSource._store._array
           this.treeDataSource = this.TransformToTreeDataSource(this.treeDataSource);
            this.$log.debug(this.treeDataSource) 
        }, 500);
        
    },
    methods: {
        onItemExpanded(e) {
            this.$log.debug(e)
            this.$log.debug(this.treeDataSource)
            // e.component.collapseAll()
            for (let x = 1; x < this.treeDataSource.length+1; x++) {
            if(e.node.key != -x) e.component.collapseItem(-x)
           }
            
        },
        onTreeItemClick(e) {
            this.$log.debug(e);        

           /* if (!e.itemData.items || e.itemData.items.length === 0) {
                this.treeBoxValue = e.itemData.id
                this.$log.debug(e.component.getDataSource())
            }
            */
            this.treeBoxValue = e.itemData.id
            var valueObject = {
                value: this.treeBoxValue,
                element: {
                    id: 'dropdown',

                }
            };
            this.$emit('value-changed', valueObject);
            if(this.treeBoxValue>0)  this.isTreeBoxOpened = false;
        },
        TransformToTreeDataSource(originalDataSource) {
            const transformedDataSource = [];
            const categoriesMap = new Map();

            originalDataSource.forEach(item => {
                if (!categoriesMap.has(item.category)) {
                    const categoryId = categoriesMap.size + 1; // Identifikátor rodiče jako číslo
                    categoriesMap.set(item.category, { id: -categoryId, text: item.category, items: [] });
                }
              //  const categoryId = categoriesMap.get(item.category).id;
                categoriesMap.get(item.category).items.push({ id: item.id, text: item.content });
            });

            categoriesMap.forEach(category => {
                transformedDataSource.push(category);
            });

            return transformedDataSource;
        },
        handleParentPropChange(newValue) {
            this.$log.debug('datasourcechanged prop changed: '+ newValue)
            this.treeBoxValue = newValue
            var valueObject = {
                value: this.treeBoxValue,
                element: {
                    id: 'dropdown',

                }
            };
            this.$emit('value-changed', valueObject);
          //  this.prevDataSource = newDataSource; // Update the previous value
        },
        
       
    },
};
</script>
<style scoped>

</style>