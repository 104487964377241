export const KnihovnaMedia = [
  {
    "ID": 1,
    "name": "Článek/Kapitola",
    "nameEN": "Article",
    "value": 1
  },
  {
    "ID": "2",
    "name": "Časopis/Periodikum",
    "nameEN": "Časopis/Periodikum",
    "value": 2
  },
  {
    "ID": "3",
    "name": "Kniha",
    "nameEN": "Book",
    "value": 4
  },
  {
    "ID": "4",
    "name": "Publikační poplatky",
    "nameEN": "Publikační poplatky",
    "value": 8
  },
  {
    "ID": "5",
    "name": "Databáze",
    "nameEN": "Database",
    "value": 16
  },
  {
    "ID": 6,
    "name": "Ostatní",
    "nameEN": "Other",
    "value": 32
  },
];
export default {
  getKnihovnaMedia() {
    return KnihovnaMedia;
  },
};