<template>
  <DxTooltip
      :target="'#' + 'infoIcon' + InstanceId"
      show-event="mouseenter"
      hide-event="mouseleave"
      :position="'top'"
      :animation="animationConfig"
      :hide-on-outside-click="false"
      style="text-align: left;"
    >
      <template #content>
        <div style="text-align: left; " >
          
          <span style="white-space: nowrap; ">{{ $t("Prilohy.VelikostSouboru") }}</span>
          <span style="white-space: nowrap;">{{ ' '+ $t("Prilohy.MaximalniPocetSouboru") }}</span>
          <br>
          <span style="white-space: nowrap;">{{ $t("Prilohy.PovolenePripony") + LabelHintEnabledExtensionsWords }}</span>
        </div>
      </template>
    </DxTooltip>
    <div id="prilohyDiv" class="PrilohyPozadavky DxSelectBoxResponsive"> 
      
                  <span style="margin-top: 5px" class="PrilohyPozadavkyNadpis" :class="{'clheaderbold': BoldHeaderWord == true}"> 
                    <i v-if='enabledUploadOfFiles==true' :id="'infoIcon'+InstanceId" class="dx-icon-info large-icon"></i> <!-- Ikonka info -->
                    {{ translatedStringHeader }}  
                   </span>
                   <!-- 100 MB = 100 * 1024 * 1024  = 104 857 600 B -->
          <DxFileUploader v-if='enabledUploadOfFiles==true' :disabled="Disabled" :multiple="true" :max-file-size="104857600" @value-changed="AttachmentValidation"
            upload-mode="instantly" :upload-file="FileUpload" :selectButtonText="$t('central-VybratSoubory')" :uploadButtonText="$t('central-Nahrat')" 
            :labelText="$t('central-NeboPretahneteZde')" readyToUploadMessage="" :uploadedMessage="$t('central-SouborNahran')" labelMode="floating" 
            :uploadFailedMessage="$t('central-SouborSeNenahral')" :showFileList="false" :invalidMaxFileSizeMessage="$t('central-VybratSoubory')" @onUploadError="handleUploadError"
            :invalidFileExtensionMessage="$t('central-VybratSoubory')"  class="VyrMajetkuBoxes DxSelectBoxResponsive" :allowed-file-extensions="LabelHintEnabledExtensionsWords"  />
          <div class="soubory" v-for="item in Soubory" :key="item">  
              <PDFViewer :FileName="item.fileName" :FileID="item.fileID" />
              <a href="#" :id="item.id" @click="GetHash(item.fileName, item.fileID)">{{ item.fileName }}</a>&nbsp;({{ item.fileSizeTXT }})
              <DxButton v-if='enabledFilesDelete==true' class="custom-icon-button" style="margin-left: 5px" :disabled="Disabled" id="close" icon="remove" text="" @click="FileDelete(item.fileID)" />
          </div>
      </div>
  </template>
  
  <script>
import axios from "axios";
//import { Base64 } from 'js-base64';
import PDFViewer from '@/components/PDFViewer.vue';
import notify from 'devextreme/ui/notify'; 
import { DxTooltip } from 'devextreme-vue/tooltip'; 
  export default {
    name: 'PrintVariant',
    components: {
      PDFViewer,
      DxTooltip,
    },
    props: {
      Disabled: {
        type: Boolean,
        required: true,
      },
      Pozid: {
        type: Number,
        required: true,
      },
      FileUploadFileType: {
        type: Number,
        required: true,
        default:1,
      },
      ListFilesfileType:{
        type: Number,
        required: true,
        default:1,
      },
      FileDeleteFileType:{
        type: Number,
        required: true,
        default:1,
      },
      enabledUploadOfFiles:{
        type: Boolean,
        required: false,
        default:true,
      },
      enabledFilesDelete:{
        type: Boolean,
        required: false,
        default:true,
      },
      LabelHeaderWordKey:{
        type: String,
        required: false,
        default:'pozadavky-Prilohy'
      },
      LabelHintEnabledExtensionsWords:{
        type: Array,
        required: false,
        default() {
          return ['.doc', '.docx', '.xls', '.xlsx', '.xlsm', '.png', '.jpg', '.jpeg', '.pdf', '.txt', '.rtf', '.zip', '.ods', '.odt'];
        }
      },
      InstanceId: {
      type: String,
      required: false,
      default:''
      },
      BoldHeaderWord:{
        type: Boolean,
        required: false,
        default: false
      },
        },
    data() {
      return {
        hideTimeout: null,
        MaxFileSize: 100*1024*1024, // 100 MB
        ValidExtensions: ['.doc', '.docx', '.xls', '.xlsx', '.xlsm', '.png', '.jpg', '.jpeg', '.pdf', '.txt', '.rtf', '.zip', '.ods', '.odt'],
        UrlNahledy: window.URL1,
        varianty: [],
        Soubory: [],
        isResetting: false,
        animationConfig: {
        show: { type: 'fade', from: 0, to: 1, duration: 300 },
        hide: { type: 'fade', from: 1, to: 0, duration: 300 },
          
      }
      };
    },
    mounted() {
      this.$log.debug('Mounted prilohy' + this.Disabled + this.Pozid);
      this.Prilohy()
      this.$log.debug('Konec mounted');
  
       
    },
    computed:{
      translatedStringHeader() {
            return this.$t(this.LabelHeaderWordKey);
        },
      translatedStringNapovedaPovolenePrilohy() {
            return this.$t(this.LabelHintEnabledExtensionsWordKey);
        },
    },
    methods: {
      
      handleUploadError(e) {
      // Handle the upload error
      alert('Upload failed: ' + e.error.message);
      },
      AttachmentValidation(e) {
      if (this.isResetting) {
        this.isResetting = false;
        return;
      }
      
      this.$log.debug(e);
      const files = e.value;
      const invalidExtensionFiles = files.filter(file => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        return !this.LabelHintEnabledExtensionsWords.includes(extension);
      });
      const invalidSizeFiles = files.filter(file => file.size > this.MaxFileSize);

      if (invalidExtensionFiles.length > 0) {
        const invalidExtensionFileNames = invalidExtensionFiles.map(file => file.name).join(', ');
        notify({
          message: this.$t('Prilohy.NespravnaPripona')+`${invalidExtensionFileNames}`,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'error', 10000);
      }

      if (invalidSizeFiles.length > 0) {
        const invalidSizeFileNames = invalidSizeFiles.map(file => file.name).join(', ');
        notify({
          message: this.$t('Prilohy.PrekrocenaMaxVelikost')+`${invalidSizeFileNames}`,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'error', 10000);
      }

      if (invalidExtensionFiles.length === 0 && invalidSizeFiles.length === 0) {
        e.component.upload();
      }
      this.isResetting = true;
      this.$nextTick(() => {
        e.component.reset();
      });
    },
    FileDelete(FileID) {
        var self = this;
        var gen = new window.General(window.channel, window.message);
       // var poz = window.localStorage.getItem("pozid")
        gen.FileDelete(self.FileDeleteFileType, self.Pozid, FileID,
          function (result) {
            window.this.$log.debug(result);
            var zprava = result.ErrorTextCZ.value
            if (window.localStorage.getItem("jazyk") == 'en') zprava = result.ErrorTextEN.value
            if (result.ErrorNum.value != 0) {
              notify({
                message: zprava,
                position: {
                  my: 'center top',
                  at: 'center top',

                },
              }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            } else {
                notify({
                message: zprava,
                position: {
                  my: 'center top',
                  at: 'center top',

                },
              }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
              self.Prilohy();
            }
           
          }, window.this.ApiError());

      },
      async downloadItem(url, name, suffix) {
        window.this.$log.debug(suffix)
        suffix = '.pdf'
        axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
          .then(function (response) {
            switch (suffix) {
              case '.pdf': {
                window.this.$log.debug(response)
                const blob = new Blob([response.data], { type: 'application/' + suffix });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name;
                link.click();
                window.this.$log.debug(link)
                window.URL.revokeObjectURL(link.href);
                break;
              }
            }
          })
          .catch(function (error) {
            window.this.$log.debug(error)
            window.this.$log.debug(JSON.stringify(error))
          })
      },
      GetHash(FileName, FileID) {
        let self = this;
        var gen = new window.General(window.channel, window.message);
        gen.GetFileHash(FileID,

          function (result) {
            window.this.$log.debug(result);
            self.$log.debug(window.URL1 + '/js/download/' + result.Value.value)
            self.hash = window.URL1 + '/js/download/' + result.Value.value;
            window.this.$log.debug(self.hash + " " + FileName)
            self.downloadItem(self.hash, FileName);

          }, window.this.ApiError());
      },
      Prilohy() {
        window.this.$log.debug('Prilohy Vstup');
        let self = this;
        self.Soubory.length = 0
        var gen = new window.General(window.channel, window.message);
       // var poz = window.localStorage.getItem("pozid")
        gen.ListFiles(self.ListFilesfileType, self.Pozid,
          function (result2) {
            window.this.$log.debug(result2);

            for (var x = 0; x < result2.List.value.items.length; x++) {
              self.Soubory[x] = {
                fileName: result2.List.value.items[x].FileName.value,
                fileExtension: result2.List.value.items[x].FileExtension.value,
                fileID: result2.List.value.items[x].FileID.value,
                fileDate: result2.List.value.items[x].Date.value,
                fileSizeTXT: result2.List.value.items[x].FileSizeTXT.value,

              }
            }

          }, window.this.ApiError());
          window.this.$log.debug('Konec Prilohy');
      },
      FileUpload(file) {
    window.this.$log.debug(file);
    var self = this;
    var gen = new window.General(window.channel, window.message);
    var reader = new FileReader();

    reader.onload = (function (theFile) {
        //2526260
        window.this.$log.debug(theFile.name);
        // var poz = window.localStorage.getItem("pozid")
        return function (e) {
            var arrayBuffer = e.target.result;
            var binaryString = '';
            var bytes = new Uint8Array(arrayBuffer);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
                binaryString += String.fromCharCode(bytes[i]);
            }

            gen.FileUpload(self.FileUploadFileType, self.Pozid, theFile.name, binaryString,
                function (result) {
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    } else {
                        window.this.$log.debug(result);
                        window.this.$log.debug('FileUpload Vnitrek');
                        self.Prilohy();
                    }
                }, window.this.ApiError());
        }
    })(file);

    reader.readAsArrayBuffer(file);
},
    },

  };
  </script>
<style scoped>
#close{
  position: relative;
  top: 1px;
}
 :deep(.dx-icon) {
  font-size: 20px; /* Nastavte velikost fontu podle potřeby */
  height: 4px;
  width: 4px;
}
  .large-icon {
    position: relative;
    top: 2.5px;
    left: -3px;
    font-size: 16px;
  }
  .soubory {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .soubory a {
    white-space: nowrap; /* Keep the text on one line */
  }
  #prilohyDiv{
    font-size: normal;
  }
  .clheaderbold{
    font-weight: bold;
  }
</style>
  