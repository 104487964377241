<template>
  <div>
    <div class="dx-field" >
          <div class="dx-field-label" >Custom format</div>
          <div class="dx-field-value" >
            <DxDateRangeBox 
              id="test"
              :value="initialValue"
              display-format="EEEE, MMM dd"
            />
          </div>
      </div>
  </div>

</template>
<script>
import DxDateRangeBox from 'devextreme-vue/date-range-box';
const now = new Date();
const msInDay = 1000 * 60 * 60 * 24;
const initialDates = [
  new Date(now.getTime() - msInDay * 3),
  new Date(now.getTime() + msInDay * 3),
];
export default {
  name: 'TestRangeBox',
  components: {
    DxDateRangeBox,
  },
  data() {

    return {
      initialValue: initialDates
    };
  }
  
}

</script>
<style scoped>
:deep(.dx-daterangebox .dx-dropdowneditor-icon::before){
    content: "\f026";
    
}
:deep(.dx-icon-to::before) {
    content: "\f00e";
}
#test{
  background-color: pink;
}
.demo-container {
  height: 690px;
}

.dx-field {
  padding: 8px;
}

.selected-days-wrapper {
  font-size: 12px;
  opacity: 0.5;
}

.multiline-label {
  padding-top: 0;
}

</style>