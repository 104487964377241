<template >
<div>
    <div class="Panels">
        <div class="Panel1">
                    
        </div>
        <div class="Panel2">
            <h1 id="nadpisNotFound2">{{$t('errory-Universal')}}</h1>
            
            <div class="UOCHB_Loading_ERROR" height="auto" width="auto">
                <img id="imgError" src="./../../assets/images/Loading/ErrorGIF/Uochb_Error.gif" :title="$t('errory-ZkusitZnovu')" @click="Reload()">
            </div >

            <DxButton  :text="$t('errory-ZkusitZnovu')" styling-mode="contained" id="zkusZnovu"
                @click="Reload()" />
             
        </div>
    </div>  
</div>
     

<!--<div id="app">
        <DxScrollView height="90%">
            <img src="@/assets/images/uochblogo.png" alt="" id="LogoUOCHB" padding="">
            <h1>Stránku se nepodařilo načíst</h1>
            <h3>Kontaktujte správce sítě </h3>
            
           
        </DxScrollView>
    </div>-->
</template>

<script>

    export default {
    name: 'ErrorUniversal',
    data() {
        return {
        }
    },

    mounted() {
    },

    methods: {
        Reload() {
          //  this.$router.push('/Aktualne');
           // window.RedirectURL = '/Aktualne'
            setTimeout(() => {
               this.$router.push('/Aktualne');
               window.location.reload();
           }, 100);
        },

    }

      
}
</script>

<style scoped>
#LogoUOCHB{
    
    align-self: center;
    align-items: center;
}

html {
	height: 100%;
}

body {
	min-height: 100%;
}



h1{
    text-align: center;
    font-size: 1cm;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 300;
    background-color: none;
    color: black;
    order: 1;

}
#nadpis{
  height: auto;
  display: flex;
  justify-content: center;
  position:sticky;
  top: 20%; 
  left: 40%;
    transform: translate(-50%, -50%);

    align-items: center;
    text-shadow: none;
    text-decoration: none;
    
}
.UOCHB_Loading_ERROR {
    display: flexbox;
    margin: 0;
    align-self: center;
    text-align:center;

    height: 500px;
	width: 500px;
    scale: 75%;
    order: 2;
	opacity: 1;
}



#zkusZnovu{
  background-color: initial;
  background-image: linear-gradient(rgba(0, 32, 91,0.1), rgba(0, 32, 91,0.8));
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor:pointer;
  display: flex;
  vertical-align: bottom;
  flex-direction: column;
  font-size: 18px;
  height: 85px;
  justify-content: center;
  line-height: 1;
  margin:70px;
  outline: none;
  
  padding: 0 15px;
  text-align:center;
  text-decoration: none;
  transition: all 150ms;
  vertical-align:bottom;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  order: 3;
  width: max-content;
  align-self: center;
  text-align:center;
  
}
#imgError{
    cursor: pointer;
}
#zkusZnovu:hover {
  box-shadow: rgba(0, 32, 91,0.8) 0 2px 8px;
  opacity: .85;
}

#zkusZnovu:active {
  outline: 0;
}

#zkusZnovu:focus {
  box-shadow: rgba(0, 32, 91, 0.8) 0 0 0 3px;
}

.Panel2{
    
    
    float: left;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9) !important;/* rgba(255, 255, 255, 0.8);*/
    border: 1px solid rgba(132, 133, 136, 0.5);
    border-radius: 8px;
    display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
    display: flex;
    vertical-align: top;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
    position: relative;
	overflow:hidden;
    
}
.Panels{
    
    margin: 60px;
    margin-left: 250px;
    margin-right: 250px;
    display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    align-items:flex-start;
    vertical-align: top;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
    position: relative;
	overflow: hidden;
    height: 85vh;
    top: 5vh;
}
@media only screen and (max-width: 768px) {
    .UOCHB_Loading_ERROR {scale: 25%;
    margin-top: 0;
margin-bottom: 0;}
.h1{margin-bottom: 0;
margin-top: 0;}
    .Panels{ margin-left: 50px;
    margin-right: 50px;}

}/*xs */
@media only screen and (min-width: 778px) and (max-width: 992px) {
  
}
/*sm */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .UOCHB_Loading_ERROR {scale: 50%;}
    .h1{visibility: hidden;}   
}/*md */
@media only screen and (min-width: 1200px) {
   
} /*lg */





</style>
