<template>
    
    <div id="app">
        <div class="login">
            <div class="logoWrapper" id="LogoUOCHBSurround">
                <img src="@/assets/images/uochblogo.png" alt="" id="LogoUOCHB" padding="" >
            </div>
        <!--  <h2>Přihlášení Intranet ÚOCHB AV ČR</h2> -->
        <form action="your-action" @submit="onFormSubmit($event)"> 
            <div class="content">
                       <div class="c1 " id="content1">              
                        <label class="dx-field-label" id="LabelUserID" >{{$t('login-OsobniCislo')}} </label> 
                        <div class="c3 dx-swatch-light" id="OsCisloBox" >
                        <DxTextBox id="dxTextBoxOsCislo" class="dx-field-value dx-swatch-light" v-model:value="input.username" :use-submit-behavior="true" placeholder=''>
                            <DxValidator>
                                <DxNumericRule :message="$t('login-OsobniCisloMusiBytCislo')" />
                                <DxRequiredRule :message="$t('login-OsobniCisloMusiBytVyplneno')" />
                            </DxValidator>
                        </DxTextBox> 
                        
                        </div></div>
                        
                        <div class="c2 c1 dx-swatch-light" id="content2">
                        <label class="dx-field-label" id="LabelPassword">{{$t('login-Heslo')}}</label>
                        <DxTextBox id="dxTextBoxHeslo" width="250px" mode="password" class="dx-field-value" :use-submit-behavior="true"  v-model:value="input.password"   placeholder=''>
                            <DxValidator v-if="false">
                                <DxRequiredRule :message="$t('login-HesloMusiBytVyplneno')" />
                            </DxValidator>
                        </DxTextBox> 
                        </div>
                        
                        
                    <div class="ErrorMessage"> <label for="prihlasit" id="labelprihlasit">{{ text }}</label> </div> 
                    
                                
                
            
                <div class="LoginButton" >
                    <!--DxButton height="auto" width="auto" text="Web" type="default" styling-mode="contained" id="Odkaz1" @click="callWeb()"/>-->
                    
                    <DxButton height="auto" width="auto" :text="$t('login-Prihlasit')" type="default" styling-mode="contained" id="prihlasit" :use-submit-behavior="true"  
                       />
                    
                </div>
                <div id="ulozitOsCisloDIV" class="dx-swatch-light">
                <dxCheckBox :text="$t('login-Zapamatovat')" id="ulozitOsCislo" v-model:value="zapamatovat" @value-changed="CheckboxClick"></dxCheckBox>
            </div>
                <br> 
                <div class="SpodniMenu">
                <label class="dx-field-label" id="LabelOthers">{{$t('login-NeboBysteRadeji')}}</label>
                <br>
                        <div class="SpodniMenuButtons">
                            
                            <DxButton   :text="$t('login-OKBase')" type="default" styling-mode="contained" id="OKBASE"   
                            @click="callOKBASE();" />

                            <DxButton  text="Intraweb" type="default" styling-mode="contained" id="INTRAWEB"   
                            @click="callWeb();" />
                            
                        </div>
                </div>
                
            </div>

        </form>
    </div>
        
 
</div>
</template>

<script>
import notify from 'devextreme/ui/notify';
    export default { 
        name: 'LoginApp',
        data() {
            return {
                callback: window.callback,
                zapamatovat: true,
                text: "",
                input: {
                    username: "",
                    password: ""

                },
            }
        },

    mounted() {
        if (window.localStorage.getItem('ZapamatovatOsC') != undefined)
            this.zapamatovat = JSON.parse(window.localStorage.getItem('ZapamatovatOsC'))
        if(this.zapamatovat==true && window.localStorage.getItem('user') != undefined )
        this.input.username=JSON.parse(window.localStorage.getItem('user')).EmployeeID.value.toString()
        //console.log(window.prevElement)
        if (window.prevElement != undefined && window.prevPosition != undefined && window.prevElement.element.childNodes.length !=0)
            window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
            window.prevElement=undefined
        this.$log.debug('loguju')
       },

    methods: {
        onFormSubmit(e) {

            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e) 
            if (this.input.password != '') this.myFunctionLogin();
            else notify({
                message: this.$t('login-HesloMusiBytVyplneno'),
                position: {
                    my: 'center top',
                    at: 'center top',

                },
            }, 'error', 4000); //'info' - barva templatu, 'success', 'warning', 'error'  
            e.preventDefault();
        },
        CheckboxClick(e) {
            this.$log.debug(e)
            window.localStorage.setItem('ZapamatovatOsC', JSON.stringify(this.zapamatovat))
        },
        callWeb(){
            window.open("https://intraweb.uochb.cas.cz/rozcestnik-33.html"); 
        },
        callOKBASE(){
            window.open("https://uochb.okbase.cz/okbase/web-client/login?dataSource=defaultDataSource&organization=1");
        },
        callUOCHB(){
            window.open("https://www.uochb.cz/cs");
        },
        myFunctionLogin() {    
            let self = this; // toto je potřeba, aby ve funkci LoginZamGetSession se šlo dostat k @router, protože ve funkci se this vztahuje ke konkrétní funkci
           // if (!Number.isInteger(this.input.username)) self.$log.debug('integer');    pokud by se to nekdo pokousel volat ne z naseho prostredi ale jen tak tu funcki tak to hodi chybu
            window.auth.LoginZam(this.input.username,this.input.password,function (aresult) {
                self.$log.debug(aresult);      
                //console.log("login zam");
                if(aresult.ErrorNum.value!=0){
                    self.input.password='';
                        notify({
                            message: aresult.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 4000); //'info' - barva templatu, 'success', 'warning', 'error'  
                    }
                else {
                    //if(self.callback) self.callback() TODO nechvata
                    
                   while (window.this.LoginNeededQue.length>0) {
                                     window.this.LoginNeededQue.shift()();
                    }
                    self.$emit("authenticated", true);
                    //var redirectURL = window.RedirectURL
                    self.$log.debug("redirecturl")
                    self.$log.debug(window.localStorage.getItem("redirecturl"))
                    var redirectURL
                    if(window.localStorage.getItem("redirecturl")!=undefined) redirectURL = window.localStorage.getItem("redirecturl")
                    else redirectURL = '/Aktualne'
                    window.localStorage.setItem("redirecturl", '/Aktualne')
                    if (redirectURL.includes('//')) redirectURL = '/' + redirectURL.split('/').slice(3).join('/');
                    self.$log.debug('routuji sem')
                    self.$log.debug(redirectURL)
                    self.$router.push(redirectURL);
                    window.auth.GetRefreshToken(function (result) {
                        self.$log.debug('getrefreshtoken');
                        window.localStorage.setItem("session-token", result)
                        self.$log.debug(result);
                    }, window.this.ApiError());
                    setTimeout(() => {
                        window.this.LoadBarvyTheme();
                    }, 300);
                    
                    }
            }, window.this.ApiError());
          

        },
     

      }
    }        
</script>

<style scoped>

:deep(.dx-texteditor-input) {
  color: rgb(0, 0, 0) !important;
  background-color: white !important;
}
:deep(.dx-checkbox-text){color: black !important;}
:deep(.dx-checkbox-icon){background-color: white !important;}
:deep(.dx-texteditor-container){margin-left: 1px;}  
.login {
    background-color: rgba(255, 255, 255, 0.90);/* rgba(255, 255, 255, 0.8);*/
    border-radius: 8px;
    border-spacing: 10px;
    border: 3px solid rgb(0, 32, 91);
    border-style:solid;
    padding: 20px;
    padding-top: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 
}/*
#app{
    display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}*/
.LoginButton{
    display: flex;
    align-items: stretch;
    justify-content: right;
    right: 15%;
    left: 15%;
    
   }

#LogoUOCHB{
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15%;
    left: 35%;
    scale: 100%;
    /*padding-bottom: 10px;*/
    src: url("@/assets/images/uochblogo.png" );
    margin: auto;
    width: 200px;
    height: auto;
}
#Rectangle1{
    width: 350px;
    height: 350px;
    background-color: rgb(0, 0, 0);
    vertical-align: top;

}
#LogoUOCHBSurround{ /* Logo navrchu */
   /* padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px; */
    border-style: ridge;
    border-color: rgb(0, 32, 91,0.8);
    border-top: 0cm;
    border-left: 0cm;
    border-right:0cm;
}
.dx-field-value-static,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
    width: 15%;
}

 .div.dx-field-label {
     width: none;
     float: none;
 }
.dx-field-value{
    float: none;
    width: none;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-size: 0.5cm;
    font-family: "Nesto";
  /*  src: local("Nesto"),   url('@/assets/fonts/Nesto/Nestocopper42-1GVw2.ttf') format("truetype");*/
    padding-top: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    padding-left: 2x;
}
.dx-field-value-static {
     float: none;
     color: rgb(0, 0, 0);
    }
.dx-field-label:disabled {
    float: none;
}
#labelprihlasit{
    padding-top:   0px;
    padding-right: 5px;
    padding-bottom:10px;
    padding-left:  5px;
    /*font-family: "Nesto";*/
   /* src: local("Nesto"),   url('@/assets/fonts/Nesto/Nestocopper42-1GVw2.ttf') format("truetype");*/
    color: rgb(255, 0, 0);
    /**/
    font-style: italic;
    font-weight: normal;
    font-size: 0.4cm;
    /**/
    display: flex;
    align-items: right;
    justify-content: right;    
    }
#TextBoxPassword{
    text-align: center;
}
#Odkaz1
{

  align-items: left;
  background-color: initial;
  background-image: linear-gradient(rgba(0, 32, 91,0.2), rgba(0, 32, 91,1));
  border-radius: 8px;
  border-width: 0px;
  box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: left;
  line-height: 1;
  margin-right: 40px;
  outline: none;
  overflow: hidden;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
#Odkaz1:hover {
  box-shadow: rgba(0, 32, 91,0.8) 0 2px 8px;
  opacity: .85;
}

#Odkaz1:active {
  outline: 0;
}

#Odkaz1:focus {
  box-shadow: rgba(0, 32, 91, 0.8) 0 0 0 3px;
}
#ulozitOsCisloDIV{
    height: 25px;
    left: 25%;
    margin-bottom: -10px;
    margin-top: -10px;
    color: black !important;
}
#ulozitOsCislo{
    font-size: 10px;
    height: 25px;
   
}
#prihlasit {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgba(0, 32, 91,0.2), rgba(0, 32, 91,1));
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
#OKBASE, #INTRAWEB{
 align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgba(0, 32, 91,0.2), rgba(0, 32, 91,1));
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 32, 91,0.1),0 3px 6px rgba(0, 32, 91,0.25);
  box-sizing: border-box;
  color: rgba(255, 255, 255,0);
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
#OKBASE{
    align-self: left;
    height: 45px;
    background-image: linear-gradient(rgba(207, 159, 1, 0.2), rgba(207, 159, 1,1));
    background-image: url('./../assets/images/logo_OKBASE.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 50px;
    
    margin-right: 5px;
    margin-left: 45px;
    background-size: 40px;
  background-position: center;
    border-style: ridge;
    border-width: 0.05px;
    border-color: rgba(207, 159, 1,1);
}
#INTRAWEB{
    align-self: right;
    height: 45px;
    background-image: linear-gradient(rgba(85, 85, 85, 0.2), rgba(85, 85, 85,1));
    background-image: url('./../assets/images/logo_UOCHB.png');
    background-position: 0 0;
  background-repeat: no-repeat;
    width: 50px;
    margin-right: 5px;
    margin-left: 45px;
    background-size: 40px;
    background-position: center;
    border-style: ridge;
    border-width: 0.05px;
    border-color: rgba(85, 85, 85,1);}
#prihlasit{
    margin-bottom: 5px;
}
#OKBASE:hover{
  background-image: url('./../assets/images/logo_OKBASE.png');
  background-image: linear-gradient(rgba(207, 159, 1, 0.2), rgba(207, 159, 1,1));
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 95px;
  color: rgba(255, 255, 255,100);
  text-size-adjust: 2cm;
  top: 0px;
  top: 20px;
  background-size: auto;
  background-position: center;
  margin-left: 25px;
  margin-right: -20px;

}
#INTRAWEB:hover{
  background-image: url('./../assets/images/logo_UOCHB.png');
  background-image: linear-gradient(rgba(85, 85, 85, 0.2), rgba(85, 85, 85,1));
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 95px;
  color: rgba(255, 255, 255,100);
  background-size: 40px;
  background-size: auto;
  background-position: center;
  margin-left: 25px;
}



#prihlasit:hover {
  box-shadow: rgba(0, 32, 91,0.8) 0 2px 8px;
  opacity: .85;
}

#prihlasit:active {
  outline: 0;
}

#prihlasit:focus {
  box-shadow: rgba(0, 32, 91, 0.8) 0 0 0 3px;
}



#LabelUserID,  #LabelPassword{
    float: none;
    width: none;
    width: 240px;
    color: rgb(0, 32, 91);
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 18px;
    font-weight: bold;
    margin-top:2px;
    margin-bottom:0px;
    display: block;
    text-align: left;
    line-height: 110%;
}

#labelprihlasit{
    padding-bottom: 10px;
}
#LabelOthers{
    display: block;
    text-align: center;
    line-height: 150%;
    font-size: .85em;
    width: 240px;
    color: rgb(0, 0, 0);
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-style: italic;
}
.SpodniMenu{
    border-style: ridge;
    border-color: rgba(0, 32, 91,0.5);
    margin-top: 5px;
    border-left: 0cm;
    border-right:0cm;
    border-bottom: 0cm;
    border-style:ridge;
}
#dxTextBoxHeslo, #dxTextBoxOsCislo {
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 16px;
    width: 120px;
    color:rgb(0, 0, 0);
}
#dxTextBoxOsCislo, #dxTextBoxHeslo{
    padding-left: 0px;
    border-style: ridge;
    border-color: rgba(0, 32, 91,0.5);
    margin-top: 5px;
    margin-left: 5px;
    margin-right:5px;
    margin-bottom: 5px;
    border-style:ridge;
}
#dxTextBoxOsCislo .dx-field-value{
    padding-left: 1px;
    margin-left: 1px;
    text-align: center;
}
#content1, #content2{
    padding-top: 10px;
    margin-bottom: 10px;
}
#OsCisloBox {
    display:block;
    align-items: left;
    width: 20px;
    line-height: 100%;
    margin: 0;
}
.SpodniMenuButtons{
    display: contents;
    align-items: center;
    justify-items:center;
    margin-left: 30px;
    width: auto;
    
       
}/*
@media screen and (max-width: 320px), 
       screen and (max-height: 480px),
       screen and (max-width: 360px) and (max-height: 640px),
       screen and (max-width: 375px) and (max-height: 667px),
       screen and (max-width: 414px) and (max-height: 736px),
       screen and (max-width: 768px) and (max-height: 1024px),
       screen and (max-width: 1024px) and (max-height: 768px),
       screen and (max-width: 1366px) and (max-height: 768px),
       screen and (max-width: 1440px) and (max-height: 900px),
       screen and (max-width: 1920px) and (max-height: 1080px),
       screen and (max-width: 2560px) and (max-height: 1440px) {
    .login{
        width: 305px;
        height: 550px;
    }
}*/




</style>
