<template>
  <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000690398?lang=cs" 
                   URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000690398?lang=en" 
                PopisLinku='clanky.vyrazeniMajetku'></HelpLink>
   <div height="calc(100% - 57px)">
        <DxPopup id="popup2" v-model:visible="popupAlert" :show-title="true" :drag-enabled="false"
                :hide-on-outside-click="true" :show-close-button="true" :title="$t('central-Varovani')" 
                :contentTemplate="popupAlertTemplate" :width="400" :height="200">
                <template #SmazatPoz> 
                    <h3>{{$t('majetekVyrazeni-OpravduSiPrejeteSmazat')}}</h3>                   
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ano')"
                    @click="SetState(-99, true); popupAlert=false " />
                  <DxButton class="button1" height="auto" width="auto" type="default" styling-mode="contained" :text="$t('central-Ne')"
                    @click="popupAlert=false" />
                </template>
        </DxPopup>
        
        <div id="h2ab">
          <DxButton icon="back" :text="$t('majetekVyrazeni-VratitSeZpet')" height="auto" type="outlined" styling-mode="contained" id="link" 
        @click="$router.push('Pozadavky')" />
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="h">{{ $t('majetekVyrazeni-VytvoreniVyrazeniMajetku')}}</h2>
        <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{$t('majetekVyrazeni-VyrazeniMajetku')}}{{ poz +' ('+state+')' }} </h2>
        </div>
        <form action="your-action" @submit="onFormSubmit($event)">
          <div id="obaleniFormulare">
          <DxResponsiveBox single-column-screen="sm" id="PrvniResponsiveBox">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxItem>
              <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
              <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="0" :col="0" :colspan="4" screen="xs" />
              <div  v-if="false">
                <div class="child DxSelectBoxResponsive"  >
                  <DxSelectBox :disabled="stateid!=4" :id="1" width='auto' height="45px" :label="$t('majetekVyrazeni-Pres')" :data-source="vias"
                    display-expr="content" value-expr="id" placeholder="" :value="Via[0].id" @value-changed="setSelectedValue">
                    <DxValidator>
                      <DxRequiredRule :message="$t('majetekVyrazeni-PresMusiBytVyplneno')" />
                    </DxValidator>
                  </DxSelectBox>
                </div>
          
              </div>
            </DxItem>
            <DxItem>
              <DxLocation :row="2" :col="0" :colspan="4" screen="lg" />
              <DxLocation :row="2" :col="0" :colspan="4" screen="md" />
              <DxLocation :row="2" :col="0" :colspan="4" screen="sm" />
              <DxLocation :row="2" :col="0" :colspan="4" screen="xs" />
              <div>
                <div class="DxSelectBoxResponsive">
                  <DxSelectBox :disabled="stateid!=4" :id="2" width='100%' height="45px" :label="$t('majetekVyrazeni-VyrazovanyMajetek')" 
                    :data-source="majetky" display-expr="content" value-expr="id" placeholder="" @value-changed="setSelectedValue" @initialized="GetAssetsInit" @opened="SetFocusSelectBox"
                    :search-enabled="true" search-mode="contains" :search-timeout="1000" @input="SearchInput" :value="MajetekInternalID"
                     noDataText="">
                    <DxValidator>
                      <DxRequiredRule :message="$t('majetekVyrazeni-VyrazMajetekMusiBytVyplnen')" />
                    </DxValidator>
                  </DxSelectBox>
                </div>
              </div>
            </DxItem>
            <DxItem>
              <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
              <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="1" :col="0" :colspan="4" screen="xs" />
              <div>
              <div class="child DxSelectBoxResponsive">
                <DxSelectBox :disabled="stateid!=4" :id="4" width='auto' height="45px" :label="$t('majetekVyrazeni-ZpusobVyrazeni')"
                  :data-source="zpusoby" display-expr="content" value-expr="id" placeholder="" :value="zpusob[0].id"  
                  @value-changed="setSelectedValue" >
                  <DxValidator>
                    <DxRequiredRule :message="$t('majetekVyrazeni-ZpusobVyrazeniMusiBytVyplnen')" />
                  </DxValidator>
                </DxSelectBox>
                </div>
                </div>
              </DxItem>
            <DxItem width="400px">
              <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
              <DxLocation :row="3" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="3" :col="0" :colspan="3" screen="sm" />
              <DxLocation :row="3" :col="0" :colspan="4" screen="xs" />
              <div>
              <div class="DxSelectBoxResponsive">
                <DxTextArea :disabled="stateid!=4" :id="3" :label="$t('majetekVyrazeni-Duvod')" width="auto" height="100%" v-model:value="duvod"
                   @value-changed="setSelectedValue">
                  <DxValidator>
                    <DxRequiredRule :message="$t('majetekVyrazeni-DuvodMusiBytVyplnen')" />
                  </DxValidator>
                </DxTextArea>          
              </div>
              </div>
            </DxItem>
           
            </DxResponsiveBox>
            </div>
          <div id="obaleniFormulare" class="obaleniFormulare2" padding-top="5px" v-if="!h">          
          <DxResponsiveBox single-column-screen="sm" id="" class="ResponsiveBoxClass" >
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="0.5" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />

            <DxItem>
              <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
              <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
              <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
              <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />
                <div v-if="!h">
                  <Attachments :Disabled="stateid != 4" :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/> 
                </div>
            </DxItem>

            <DxItem>
              <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
              <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
              <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
              <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                <div class="DxSelectBoxResponsive"  v-if="!h">
                  <DxTextArea class="DxSelectBoxResponsive" :disabled="stateid!=4" :id="3" :label="$t('majetekPrevod-Komentar')" width="auto" :height="100" @value-changed="setSelectedValue" v-model:value="komentar"
                    placeholder="" />
                </div>
            </DxItem>
          </DxResponsiveBox>
                   </div>
          <DxResponsiveBox single-column-screen="sm" id="PosledniResponsiveBox" class="ResponsiveBoxClass SpodniMenuDetailPozadavku">
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxRow :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />
            <DxCol :ratio="1" />

              <DxItem >
                <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />
                <div id="ResponsiveBoxItemActionButtons" v-if="h">
                      <DxButton icon="add" id="vytvorit" height="auto" width="auto" :text="$t('majetekVyrazeni-ZalozeniVyrazeniMajetku')" :use-submit-behavior="true"
                        type="success"  />
                
                      </div>
              </DxItem>

              <DxItem >
                <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!h">  
                  <div id="ResponsiveBoxItemActionButtons" v-if="!h">
                              <div class="child" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                                <DxButton class="button1" height="100%" width="auto" type="default" styling-mode="contained"
                                  :text="item.nazev" :id="item.id" @click="SetState(item.id)" :class="item.class" :icon="item.icon"
                                  v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                        </div>
                  </div>
                </DxScrollView>
                  
              </DxItem>
          </DxResponsiveBox>       
        </form>
 </div>   
</template>

<script>
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import { isNull, isUndefined } from 'lodash';
import HelpLink from '@/components/HelpLink.vue';
import DxScrollView from 'devextreme-vue/scroll-view';
import Attachments from '@/components/Attachments.vue';

export default {
  name: "VyrazeniView",
  components: {
    Attachments,
    HelpLink,
    DxScrollView

  },
  data() {
    return {   
            //  pattern: /^\d{}/i,
            SetQueryValue: false,
            PocetKolonek: 3,
            MountingData: false,
            stateid: 4,
            state: 'Uložený',
            popupAlert: false,
            popupAlertTemplate: '',
             actionsPozadavky: [],
                duvod: "",
                zpusoby: [],
                zpusob: [{ content: '', id: -1 }],
                majetky:[{ content: '', id: -1 }],
                majetek: [{ content: '', id: -1 }],
              pozMajetek: [],
              objGrant: [], 
              MajetekInternalID: null,
              Via:[{ content: '', id: -1 }],
              komentar:'',
              meny:[],
              mena: { "content": 'CZK' , "id": 129 },
              suppliers: [],
              supplier: "",
              vias: [],          
              poz: '',
              invC: '',
              h: true,
              AssetNumber: '',
               
            }
  },
  computed: {
    dataGrid: function () {
      let self = this;
      return self.$refs.mygrid.instance;
    },
  },
  created(){
    window.MountCount=0
    this.ListVias();
  },
  mounted() {
    this.MountingData = true
   // this.GridOptions()
    //window.localStorage.setItem("refresh-page", 'Vyrazenimajetku')
    this.UrlQueryParams()
    if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) {     
      this.h=false
      this.poz = window.localStorage.getItem("pozid")
      this.DisplayRequest();
   //   this.Prilohy()
      
    }        
    this.LoadBarvyTheme();
  },
        
  methods: { 
    LoadBarvyTheme(){
    var elementBarvaTheme = document.getElementsByClassName('dx-theme-accent-as-background-color')[0];
    var computedStyleBarvaTheme = window.getComputedStyle(elementBarvaTheme);
    var FillColorBarvaTheme = computedStyleBarvaTheme.fill;
    var rgbaColorTheme = FillColorBarvaTheme.replace("rgb", "rgba").replace(")", ", 0.25)");
        //console.log('Background Color:', FillColor);
        document.documentElement.style.setProperty('--colorByTheme', FillColorBarvaTheme);
        document.documentElement.style.setProperty('--colorByThemeQuarterOpacity', rgbaColorTheme);
        },
    UrlQueryParams() {
      window.this.$log.debug(this.$route)
      if (Object.keys(this.$route.query).length != 0) {
        if (this.$route.query.id != undefined) {
          window.localStorage.setItem("pozid", '') 
          this.SetQueryValue = true
         // this.MajetekInternalID = parseInt(this.$route.query.id)
        }
      }
    },
    SetFocusSelectBox(e) {
      this.$log.debug(e)
      e.element.focus();
    },
     GetAssetsInit(e) {
            var asset = new window.Asset(window.channel, window.message);
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value
            let self = this;
            self.majetky.length = 0;
            var y = 0;
            asset.GetAsset(EmpID, true, '', 0, 0,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        if (result.List.value.items[x].Locked.value == false) {
                            self.majetky[y] = {
                                content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value + ' (' +
                                result.List.value.items[x].From.value.toLocaleString("cs-CZ", { minimumFractionDigits: 2 }).slice(0, -8) + ')',
                                id: result.List.value.items[x].InternalId.value,
                                invc: result.List.value.items[x].Id.value,
                                date: result.List.value.items[x].From.value
                            };
                            y++

                        }

                  }
                  if(self.SetQueryValue) self.MajetekInternalID = parseInt(self.$route.query.id)
                    e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
    FileDelete(FileID) {
      var self = this;
      var gen = new window.General(window.channel, window.message);
      gen.FileDelete(1, self.poz, FileID,
        function (result) {
          window.this.$log.debug(result);
          self.Prilohy();
        }, window.this.ApiError());

    },
    async downloadItem(url, name, suffix) {
      window.this.$log.debug(suffix)
      suffix = '.pdf'
      axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
        .then(function (response) {
          switch (suffix) {
            case '.pdf': {
              window.this.$log.debug(response)
              const blob = new Blob([response.data], { type: 'application/' + suffix });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = name;
              link.click();
              window.this.$log.debug(link)
              window.URL.revokeObjectURL(link.href);
              break;
            }
          }
        })
        .catch(function (error) {
          window.this.$log.debug(error)
          window.this.$log.debug(JSON.stringify(error))
        })
    },
    GetHash(FileName, FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(FileID,

        function (result) {
          window.this.$log.debug(result);
          self.hash = window.URL1+'/js/download/' + result.Value.value;
          window.this.$log.debug(self.hash + " " + FileName)
          self.downloadItem(self.hash, FileName);

        }, window.this.ApiError());
    },
    FileUpload(file) {
      window.this.$log.debug(file)
      var self = this;
      var gen = new window.General(window.channel, window.message);
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        window.this.$log.debug(theFile.name)
        return function (e) {
          gen.FileUpload(1, self.poz, theFile.name,
            e.target.result,
            function (result) {
              window.this.$log.debug(result);
              self.Prilohy()
            }, window.this.ApiError());
        }
      })(file);
      reader.readAsBinaryString(file);

    },  
   SetState(stav, alert) {
      window.this.$log.debug(alert + ' alert<<<??>')
      let self = this;
      var wh = new window.WorkFlow(window.channel, window.message);

      if (stav == -1) {
        window.this.$log.debug('konec')
        this.popupAlert = true
        this.popupAlertTemplate = 'SmazatPoz'
        return 1;
      }
      if (alert == true) stav = -1
      self.open();
      wh.SetState(self.poz, stav, '',
        function (result) {
          window.this.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            self.DisplayRequest();
            if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

            var stavText;
            switch (stav) {
              case -1: stavText = self.$t('pozadavky-StavZrusen')
                break;
              case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                break;
              case 4: stavText = self.$t('pozadavky-StavUlozeny')
                break;
            }
            notify({
              message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'success', 5000);
          }
          self.hide();
        }, window.this.ApiError());

    },
        DisplayRequest() {
          window.this.$log.debug('display request')
          let self = this;
          self.actionsPozadavky.length = 0;
          var wh = new window.WorkFlow(window.channel, window.message);
          wh.GetRequest(self.poz,
            function (result2) {
              window.this.$log.debug(result2);
              self.state = result2.ReqStateText.value;
              self.stateid = result2.ReqState.value;
              for (var x = 0; x < result2.Actions.value.items.length; x++) {
                var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[self.actionsPozadavky.length] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
            };
              }
            }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
        },
    ListReasons() {
          this.$log.debug('listReasons')
            let self = this;
            self.zpusoby.length = 0;
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('ZpusobVyrazeni', '', 1, 50,
                function (result) {
                     window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                      //  var i = self.zpusoby.length;
                        self.zpusoby[x] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                        
                  }
                  if (self.h)
                    self.zpusob[0] = self.zpusoby[0]
                  else
                    self.GetAssetDispose(self.poz)
                }, window.this.ApiError());

        },
        GetAssetDispose(id) {
            var self = this;           
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetAssetDispose(id,
                function (result) {
                  
                    window.this.$log.debug(result); 
                        self.Via[0] = { content: result.Request.value.ReqViaText.value, id: result.Request.value.ReqVia.value }
                        self.zpusob[0] = { content: result.MethodTXT.value, id: result.Method.value }
                      /*   self.majetek[0]={
                            content: result.AssetItem.value.Id.value + ' ' + result.AssetItem.value.Name.value,
                            id: result.AssetItem.value.InternalId.value,
                            event: 'GetAssets',
                            invc: result.AssetItem.value.Id.value
                    }; */
                    self.MajetekInternalID=result.AssetItem.value.InternalId.value
                     self.majetek[0].content=result.AssetItem.value.Id.value + ' ' + result.AssetItem.value.Name.value + ' (' +
                    result.AssetItem.value.From.value.toLocaleString("cs-CZ", { minimumFractionDigits: 2 }).slice(0, -8) + ')',
                     self.majetek[0].id=result.AssetItem.value.InternalId.value,
                     self.majetek[0].event='GetAssets',
                     self.majetek[0].invc=result.AssetItem.value.Id.value
                    self.majetky[self.majetky.length+1] = self.majetek[0]
                        self.duvod = result.Reason.value;
                  self.komentar = result.Request.value.Comment.value;
                        if (self.komentar != '') self.PocetKolonek++
                        }, window.this.ApiError());
        },
        SetAssetDispose(param) {
            var self = this;    
            var wh = new window.WorkFlow(window.channel, window.message);
            var RequestID = this.poz
            if(param == 'vytvorit') RequestID=0;  
            window.this.$log.debug(RequestID)
            window.this.$log.debug(self.duvod)
            window.this.$log.debug(self.zpusob[0].id)
            window.this.$log.debug(self.majetek[0].id+' majetek id')
            window.this.$log.debug(self.komentar)
            window.this.$log.debug(self.Via[0].id)
            
            wh.SetAssetDispose(RequestID,self.duvod, self.zpusob[0].id, self.MajetekInternalID, self.komentar, self.Via[0].id,
                        function (result) {
                            if(RequestID==0 && result.Error.value.ErrorNum.value==0 ) {
                                window.localStorage.setItem("pozid", result.Value.value)
                                self.poz = result.Value.value
                                self.h = false;
                                self.DisplayRequest();
                          } 
                            else if(result.Error.value.ErrorNum.value!=0) {
                              notify({
                                  message: result.Error.value.ErrorTextCZ.value,
                                  position: {
                                    my: 'center top',
                                    at: 'center top',

                                  },
                                }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                            } else {
                              notify({
                                message: self.$t('pozadavky-VyrazeniMajetkuZmenyUlozeny'),
                                position: {
                                  my: 'center top',
                                  at: 'center top',
                                },
                              }, 'success', 3000);
                              if(self.poz!='') self.DisplayRequest();
                            }                                               
                            window.this.$log.debug(result);                       
                        }, window.this.ApiError());
                                            
        },
        GetAssets(assets) {
            if (assets.component != undefined)
            {
                var e = assets
                var filter = e.component.option("text")
            }
                var asset=new window.Asset(window.channel,window.message);  
            var EmpID= JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 

            let self = this;
            self.majetky.length=0;
          var y = 0;
          window.this.$log.debug(filter);
                asset.GetAsset(EmpID, true, filter,0,1,
                function (result) {

                    window.this.$log.debug(result);
                  for (var x = 0; x < result.List.value.items.length; x++) {   
                    if (result.List.value.items[x].Locked.value == false) {
                      self.majetky[y] = {
                        content: result.List.value.items[x].Id.value + ' ' + result.List.value.items[x].Name.value + ' (' +
                          result.List.value.items[x].From.value.toLocaleString("cs-CZ", { minimumFractionDigits: 2 }).slice(0, -8) + ')',
                            id: result.List.value.items[x].InternalId.value,
                            event: 'GetAssets',
                            invc: result.List.value.items[x].Id.value,
                            date: result.List.value.items[x].From.value
                    };
                        y++
                      }                   
                    
                    }  
                    e.component.getDataSource().reload();   
            },window.this.ApiError());    
            },
    SearchInput(e) {         
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetAssets(e)                      
            }, 1000)

        },
      
    onFormSubmit(e) {
    
      window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
      window.this.$log.debug(e)
     
      
      if (e.submitter.attributes[0].ownerDocument.activeElement.id == "vytvorit") {
        notify({
          message: this.$t('pozadavky-VyrazeniMajetkuZalozeno'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
          this.MountingData=false
          this.SetAssetDispose("vytvorit")
          
          
      }
      else {
        notify({
          message: this.$t('pozadavky-VyrazeniMajetkuZmenyUlozeny'),
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
          this.SetAssetDispose("upravit")

      }
      e.preventDefault();
    },
    setSelectedValue(e) {
      if (!this.h) window.MountCount++
      window.this.$log.debug(e);
      window.this.$log.debug(window.MountCount + 'MountCount');
      window.this.$log.debug(this.PocetKolonek + 'Dokolika');
      if (window.MountCount == this.PocetKolonek + 1) this.MountingData = false // tohle neni uplne dobry napad delat
      switch (e.element.id) {
        case '1':
          this.Via[0].id = e.value      
          break;
        case '2':
          this.MajetekInternalID = e.value
          /*
          this.majetek[0] = e.value
          if(!isNull(e.value))
          this.majetek[0].event='GetAssets'
              if (isUndefined(this.majetek[0]) || isNull(e.value)) {
                this.majetek[0] = { content: '', id: 0 }
              if (isUndefined(this.majetek[0].id))
                this.majetek[0].id = 0 
              }
          if (!isNull(e.value) && !isUndefined(e.value.event) && !this.h && !this.MountingData) {
                this.SetAssetDispose('upravit')               
              }     
              return 0
              */
          break;
        case '3':
          break;
        case '4':
          this.zpusob[0].id = e.value
          break;
      }
        if (!isUndefined(e.value) && !isNull(e.value) && !this.h && !this.MountingData)
      this.SetAssetDispose("upravit")
      // this.SkipDiacritics(this.typpozadavek2.content)
      //  this.pozadavek=e.value
      // this.selectedValue = e.value;
    },
    open() {
      window.loader = this.$loading.show({
        loader: 'dots'
      });
    },
    hide() {
      window.loader.hide()
    },

        ListVias()
        {
            let self = this;
            self.vias.length = 0;
            var gen=new window.General(window.channel,window.message);
              gen.ListLookup('REQUEST_VIAS[3]','',1,50,
              function (result) {
               // window.this.$log.debug(result);
                for (var x = 0; x < result.List.value.items.length; x++) {

                  //  window.this.$log.debug(result.List.value.items[x]);
                  var i = self.vias.length;
                  self.vias[i] = {
                    content: result.List.value.items[x].Value.value,
                    id: result.List.value.items[x].Id.value,
                  };
                }
                if(self.h)
                self.Via[0] = self.vias[0]
              //  else
               // self.PolozkyObjednavkyGrid(self.poz)
               self.ListReasons()
              }, window.this.ApiError());
              
    },
          },
    }
      
</script>

<style scoped>
.VyrMajetkuBoxes{
  margin: 10px;
}
#upravit {
  margin-left:20px;

}
.actionsbuttons {
    clear: both; 
    flex-direction: row;
    display: inline-block;
}


#prevest {
    background-color: #ffa500;
    border-color: #ffa500;
    margin-left: 100px;
}
.dx-row.dx-data-row.dx-column-lines {
  background-color: #ffa500;
}
/*

.table{
  width: auto;
}
*/
#button{
  margin-right: 40px;
}
 label{
  margin-right:20px;
 }
 #text1{
  text-align: top;
  height: 70px;
  width: 300px;
 }
 input{
  text-align: top;
  margin-right: 40px;
 }
 .dx-field{margin-left: 10px;}
 #dx-field-Komentar{margin-left: 10px;
  
 }
 #PrvniResponsiveBox{
  width: 100%;
margin-bottom: 5px;
}


 
    #obaleniFormulare{border-style: ridge;
    border-color: rgba(128, 128, 128, 0.1);
    border-radius: 10px;
    padding: 10px;
height: auto;
margin-top: 10px !important;
margin-bottom: 10px; background-color: rgba(128, 128, 128, 0.075);

    }
#h2a,#h2b{
  text-align: center;
  margin: 10px 0px 0px;
}
#link{float: left;
  
}
#dx-field-ZpusobVyrazeni{margin-left: 0px;}
    @media only screen and (max-width: 768px) {
    
  }/*xs */
  @media only screen and (min-width: 778px) and (max-width: 992px) {

    #dx-field-ZpusobVyrazeni{margin-left: 0px;}
}/*sm */
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    
    #dx-field-ZpusobVyrazeni{margin-left: 25px;}
    }/*md */
  @media only screen and (min-width: 1200px) {
    
  } /*lg */
  #ResponsiveBoxItemActionButtons{flex-direction: row !important; justify-content: flex-start !important;} 
  .buttonDefault:hover,.buttonUlozitProUpravy:hover,.buttonKeSchvaleni:hover,.buttonSmazat:hover,.buttonKEditaci:hover{
    background-color: var(--colorByThemeLighter);
    border: 0px;
    
}
.buttonKeSchvaleni{
  background: #5cb83c;
  border-color: none;
  border: 0px;
}
.buttonUlozitProUpravy{
    background: #3cb8b8;
    border-color: none; 
    border: 0px;
}
.buttonSmazat{
    background: #d9534f;
  border-color: none;
  border: 0px;
}
.buttonKEditaci{
    background: #ffcc33;
    border-color: none;
    border: 0px; 
}
.buttonDefault{
    background: none;
    border-color: none;
    border: 0px;
}

</style>