<template>
    <DxPopup v-if="false" ref="christmas" width="1000" height="650" :showCloseButton="true"
        :visible="true" :drag-enabled="true" :focusStateEnabled="true" :shading="true">
        <div id="inpopupwrapper" class="background-wrapper">
        <svg class="greeting-svg prevent-select" width="1000" height="600" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <path id="text-circle" d="M-50,80 A350,250 10 0,1 500,100" />
                <path id="underline" d="M-50,100 A350,250 10 0,1 490,110" />
            </defs>
            <text dominant-baseline="middle" text-anchor="middle">
                <textPath href="#text-circle" startOffset="50%">
                    {{ $t('Vanoce.StastneAVesele') }}
                </textPath>
            </text>
            <path d="M-50,100 A350,250 10 0,1 490,110" fill="none" stroke="#000" stroke-width="4"
                stroke-linecap="round" />
            <path d="M-50,100 A350,250 10 0,1 490,110" fill="none" stroke="#FFD700" stroke-width="2"
                stroke-linecap="round" />
        </svg>
        <div class="ground">
            <div class="snowglobe">
                <div class="sg-bg"></div>
                <div class="grnd-snow">
                    <div class="snow-surface"></div>
                </div>
                <div class="tree">
                    <div class="tree-shadow"></div>
                    <div class="branch-bot">
                        <div class="branch-bot-snow"></div>
                        <div class="branch-shadow"></div>
                    </div>
                    <div class="branch-mid">
                        <div class="branch-mid-snow"></div>
                        <div class="branch-shadow"></div>
                    </div>
                    <div class="branch-top">
                        <div class="branch-top-snow"></div>
                    </div>
                    <div class="star"></div>
                    <div class="baubles"></div>
                </div>
                <div class="sm-shadow"></div>
                <div class="snowman">
                    <div class="snowman-bot"></div>
                    <div class="snowman-top"></div>
                    <div class="coal"></div>
                    <div class="carrot"></div>
                </div>
                <div class="falling-snow-wrap">
                    <div class="falling-snow">
                        <div class="flakes1">
                            <p>*</p>
                        </div>
                        <div class="flakes2">
                            <p>*</p>
                        </div>
                        <div class="flakes3">
                            <p>*</p>
                        </div>
                        <div class="flakes4">
                            <p>*</p>
                        </div>
                        <!-- New flakes that start immediately -->
                        <div class="flakes5">
                            <p>*</p>
                        </div>
                        <div class="flakes6">
                            <p>*</p>
                        </div>
                    </div>
                </div>

                <div class="sg-fg"></div>
                <div class="stand-curved-wrap">
                    <div class="stand-curved"></div>
                </div>
            </div>
        </div>
    </div>
    </DxPopup>
    <div style="position: relative" class="background-wrapper">
        <svg class="greeting-svg prevent-select" width="1000" height="600" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <path id="text-circle" d="M-50,80 A350,250 10 0,1 500,100" />
                <path id="underline" d="M-50,100 A350,250 10 0,1 490,110" />
            </defs>
            <text dominant-baseline="middle" text-anchor="middle">
                <textPath href="#text-circle" startOffset="50%">
                    {{ $t('Vanoce.StastneAVesele') }}
                </textPath>
            </text>
            <path d="M-50,100 A350,250 10 0,1 490,110" fill="none" stroke="#000" stroke-width="4"
                stroke-linecap="round" />
            <path d="M-50,100 A350,250 10 0,1 490,110" fill="none" stroke="#FFD700" stroke-width="2"
                stroke-linecap="round" />
        </svg>
        <div class="ground">
            <div class="snowglobe">
                <div class="sg-bg"></div>
                <div class="grnd-snow">
                    <div class="snow-surface"></div>
                </div>
                <div class="tree">
                    <div class="tree-shadow"></div>
                    <div class="branch-bot">
                        <div class="branch-bot-snow"></div>
                        <div class="branch-shadow"></div>
                    </div>
                    <div class="branch-mid">
                        <div class="branch-mid-snow"></div>
                        <div class="branch-shadow"></div>
                    </div>
                    <div class="branch-top">
                        <div class="branch-top-snow"></div>
                    </div>
                    <div class="star"></div>
                    <div class="baubles"></div>
                </div>
                <div class="sm-shadow"></div>
                <div class="snowman">
                    <div class="snowman-bot"></div>
                    <div class="snowman-top"></div>
                    <div class="coal"></div>
                    <div class="carrot"></div>
                </div>
                <div class="falling-snow-wrap">
                    <div class="falling-snow">
                        <div class="flakes1">
                            <p>*</p>
                        </div>
                        <div class="flakes2">
                            <p>*</p>
                        </div>
                        <div class="flakes3">
                            <p>*</p>
                        </div>
                        <div class="flakes4">
                            <p>*</p>
                        </div>
                        <!-- New flakes that start immediately -->
                        <div class="flakes5">
                            <p>*</p>
                        </div>
                        <div class="flakes6">
                            <p>*</p>
                        </div>
                    </div>
                </div>

                <div class="sg-fg"></div>
                <div class="stand-curved-wrap">
                    <div class="stand-curved"></div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'XmasDecoration',
};
</script>
<style scoped>
.background-wrapper {
    position: relative;
    width: 100%;
    height: 550px;
   /* background: radial-gradient(circle at center, #6b2e13 0%, #9b3715 30%, #ffffff 60%); */
    background: radial-gradient(circle at center, #6b2e13 0%, #9b3715 35%, #ffffff00 60%);
    background-size: 750px 750px; /* Nastavení velikosti kruhu */
    background-repeat: no-repeat; /* Zabránění opakování gradientu */
    background-position: center; /* Umístění gradientu do středu */
}

@keyframes sfanim {
    0% {
        transform: translate(0, -400px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.greeting-text {
    position: absolute;
    top: 10px;
    width: 100%;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    font-size: 60px;
    color: #FFD700;
    text-shadow: 2px 2px #000;
    /* Přidáme stín pro lepší čitelnost */
    z-index: 5;
}

.greeting-svg {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    overflow: visible;
}

.greeting-svg text {
    fill: #FFD700;
    font-size: 60px;
    /* Upravte velikost podle potřeby */
    font-family: 'Great Vibes', cursive;
    letter-spacing: 5px;
    stroke: #000;
    stroke-width: 0.75px;
}
</style>
<style scoped lang="css">
#inpopupwrapper{
    position: absolute;
}
.snowglobe {
    width: 400px;
    height: 400px;
    margin: auto;
    position: relative;
    top: -320px;

}

.branch-top {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 40px;
    height: 75px;
    background-color: #62838c;
    position: relative;
    top: 5px;
    left: 30px;
}

.branch-top-snow {
    background: linear-gradient(135deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(225deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(315deg, #dbe8fc 25%, transparent 25%), linear-gradient(45deg, #dbe8fc 25%, transparent 25%);
    background-size: 32px 32px;
    width: 100%;
    height: 100%;
    position: absolute;
}

.branch-mid {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 75px;
    height: 115px;
    background-color: #2d4959;
    position: absolute;
    top: 35px;
    left: 12.5px;
}

.branch-mid-snow {
    background: linear-gradient(135deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(225deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(315deg, #dbe8fc 25%, transparent 25%), linear-gradient(45deg, #dbe8fc 25%, transparent 25%);
    background-size: 32px 32px;
    width: 100%;
    height: 100%;
    position: absolute;
}

.branch-bot {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 100px;
    height: 125px;
    background-color: #142833;
    position: absolute;
    top: 80px;
}

.branch-bot-snow {
    background: linear-gradient(135deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(225deg, #dbe8fc 25%, transparent 25%) -16px 0, linear-gradient(315deg, #dbe8fc 25%, transparent 25%), linear-gradient(45deg, #dbe8fc 25%, transparent 25%);
    background-size: 32px 32px;
    width: 100%;
    height: 100%;
    position: absolute;
}

.branch-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(18, 67, 79, 1) 0%, rgba(255, 255, 255, 0) 100%);
    mix-blend-mode: multiply;
}

.tree {
    width: 100px;
    height: 205px;
    position: relative;
    left: 200px;
    top: 60px;
}

.sg-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(to bottom, rgba(240, 240, 240, 0.8) 0%, rgba(220, 220, 220, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    z-index: 1;
    /* Behind everything else */
}

.sg-fg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 3;
    /* Foreground glow */
}

.grnd-snow {
    width: 380px;
    height: 380px;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    left: 10px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 68%, rgba(240, 240, 240, 1) 68%, rgba(235, 239, 245, 1) 79%, rgba(197, 211, 232, 1) 100%);
}

.snow-surface {
    width: 356px;
    height: 40px;
    background: #fff;
    position: relative;
    top: 236px;
    border-radius: 100%;
    margin: auto;
}

.star {
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-bottom: 14px solid gold;
    border-left: 20px solid transparent;
    transform: rotate(35deg);
    position: absolute;
    top: 0;
    left: 30px;
}

.star:before {
    border-bottom: 16px solid gold;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -9px;
    left: -13px;
    content: '';
    transform: rotate(-35deg);
}

.star:after {
    position: absolute;
    top: 0.6px;
    left: -21px;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-bottom: 14px solid gold;
    border-left: 20px solid transparent;
    transform: rotate(-70deg);
    content: '';
}

.falling-snow-wrap {
    overflow: hidden;
    opacity: 0.99;
    height: 260px;
    width: 400px;
    position: absolute;
    top: 0;
}

.falling-snow {
    width: 380px;
    height: 380px;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    left: 10px;
    overflow: hidden;
    opacity: 0.99;
}

.flakes1,
.flakes2,
.flakes3,
.flakes4,
.flakes5,
.flakes6 {
    width: 400px;
    height: 800px;
    position: absolute;
    top: -400px;
    color: #fff;
    transform: translateY(-600px);
    /* Move flakes off-screen at the top */
}

.flakes1 {
    opacity: 0.4;
    animation: sfanim linear 30s infinite;
    text-shadow:
        303px 117px, 32px 89px, 323px 119px, 98px 183px, 126px 235px,
        0px 171px, 380px 61px, 269px 17px, 0px 151px, 121px 344px,
        229px 136px, 237px 280px, 303px 30px, 211px 314px, 378px 285px,
        10px 287px, 93px 345px, 292px 324px, 223px 292px, 156px 160px,
        253px 58px, 205px 195px, 145px 106px, 79px 312px, 182px 359px,
        279px 99px, 349px 124px, 5px 33px, 216px 147px, 388px 117px,
        70px 295px, 149px 318px, 96px 66px, 129px 217px, 138px 218px,
        241px 310px, 231px 368px, 18px 327px, 173px 213px, 118px 10px,
        246px 208px, 159px 244px, 268px 376px, 167px 262px, 85px 238px,
        277px 47px, 386px 192px, 259px 364px, 325px 327px, 279px 201px;
}

.flakes2 {
    opacity: 1;
    animation: sfanim linear 20s infinite;
    animation-delay: 15s;
    /* Starts halfway through flakes1's duration */
    text-shadow:
        375px 85px, 11px 189px, 254px 284px, 5px 186px, 266px 205px,
        388px 198px, 180px 207px, 36px 113px, 74px 195px, 238px 190px,
        384px 135px, 1px 194px, 45px 38px, 131px 250px, 258px 20px,
        157px 205px, 96px 249px, 325px 219px, 132px 188px, 167px 111px,
        303px 8px, 340px 220px, 394px 28px, 187px 248px, 217px 124px,
        356px 130px, 33px 258px, 238px 262px, 357px 86px, 253px 298px,
        68px 286px, 164px 162px, 119px 98px, 221px 57px, 126px 37px,
        282px 3px, 11px 55px, 219px 132px, 60px 97px, 41px 29px,
        247px 51px, 217px 144px, 304px 0px, 214px 21px, 287px 257px,
        76px 4px, 376px 235px, 169px 72px, 245px 290px, 66px 217px;
}

.flakes3 {
    opacity: 0.4;
    animation: sfanim linear 30s infinite;
    animation-delay: 7.5s;
    /* Starts a quarter into flakes1's duration */
    text-shadow:
        350px 60px, 25px 100px, 300px 200px, 50px 150px, 200px 250px,
        100px 0px, 370px 80px, 280px 30px, 10px 180px, 130px 360px,
        240px 120px, 250px 290px, 310px 50px, 220px 320px, 390px 280px,
        20px 270px, 80px 330px, 280px 310px, 230px 270px, 160px 170px,
        260px 70px, 210px 180px, 150px 90px, 90px 300px, 190px 370px,
        290px 110px, 360px 140px, 15px 40px, 220px 130px, 380px 100px,
        60px 280px, 140px 310px, 110px 80px, 140px 200px, 150px 230px,
        250px 320px, 240px 380px, 30px 340px, 180px 220px, 120px 20px,
        240px 210px, 170px 250px, 270px 380px, 160px 270px, 90px 240px,
        280px 60px, 380px 180px, 260px 370px, 330px 340px, 290px 210px;
}

.flakes4 {
    opacity: 1;
    animation: sfanim-short linear 20s infinite;
    animation-delay: 22.5s;
    /* Starts three-quarters into flakes1's duration */
    text-shadow:
        360px 90px, 20px 190px, 260px 270px, 15px 170px, 270px 190px,
        390px 210px, 190px 220px, 40px 120px, 70px 180px, 240px 200px,
        390px 150px, 5px 200px, 50px 50px, 140px 260px, 260px 30px,
        150px 220px, 100px 260px, 330px 230px, 140px 190px, 170px 100px,
        310px 20px, 350px 230px, 390px 40px, 190px 260px, 220px 130px,
        360px 140px, 30px 270px, 240px 280px, 360px 100px, 250px 310px,
        80px 290px, 160px 150px, 110px 90px, 230px 50px, 130px 30px,
        290px 10px, 20px 70px, 230px 140px, 70px 90px, 50px 20px,
        250px 60px, 220px 150px, 310px 10px, 220px 30px, 290px 260px,
        70px 10px, 370px 240px, 180px 80px, 250px 300px, 60px 200px;
}

.flakes5 {
    opacity: 0.6;
    animation: sfanim-short linear infinite;
    animation-duration: 25s;
    /* You can adjust the duration */
    /* Positions for flakes5 */
    text-shadow:
        45px 80px, 130px 190px, 220px 50px, 310px 250px, 75px 300px,
        160px 120px, 250px 180px, 340px 60px, 100px 220px, 190px 350px,
        280px 140px, 370px 290px, 55px 30px, 145px 270px, 235px 100px,
        325px 200px, 85px 240px, 175px 320px, 265px 80px, 355px 180px,
        125px 160px, 215px 260px, 305px 40px, 395px 140px, 65px 240px,
        155px 340px, 245px 20px, 335px 120px, 25px 220px, 115px 320px,
        205px 100px, 295px 200px, 385px 300px, 50px 160px, 140px 260px,
        230px 60px, 320px 160px, 10px 260px, 100px 360px, 190px 40px,
        280px 140px, 370px 240px, 45px 340px, 135px 80px, 225px 180px,
        315px 280px, 5px 120px, 95px 220px, 185px 320px, 275px 20px,
        365px 120px, 75px 220px, 165px 320px, 255px 100px, 345px 200px;
}

.flakes6 {
    opacity: 0.9;
    animation: sfanim linear infinite;
    animation-duration: 21s;
    /* You can adjust the duration */
    /* Positions for flakes6 */
    text-shadow:
        60px 90px, 150px 190px, 240px 290px, 330px 390px, 90px 140px,
        180px 240px, 270px 340px, 360px 40px, 30px 130px, 120px 230px,
        210px 330px, 300px 70px, 390px 170px, 75px 270px, 165px 370px,
        255px 50px, 345px 150px, 15px 250px, 105px 350px, 195px 30px,
        285px 130px, 375px 230px, 60px 330px, 150px 110px, 240px 210px,
        330px 310px, 20px 90px, 110px 190px, 200px 290px, 290px 60px,
        380px 160px, 70px 260px, 160px 360px, 250px 80px, 340px 180px,
        10px 280px, 100px 380px, 190px 50px, 280px 150px, 370px 250px,
        45px 350px, 135px 130px, 225px 230px, 315px 330px, 5px 70px,
        95px 170px, 185px 270px, 275px 370px, 365px 90px, 80px 190px,
        170px 290px, 260px 40px, 350px 140px, 25px 240px, 115px 340px;
}

@keyframes sfanim {
    0% {
        transform: translateY(-600px);
        /* Start above the visible area */
    }

    100% {
        transform: translateY(600px);
        /* End below the visible area */
    }
}

@keyframes sfanim-short {
    0% {
        transform: translateY(0px);
        /* Start just above the screen */
    }

    100% {
        transform: translateY(600px);
        /* End slightly below the screen */
    }
}

.baubles {
    width: 16px;
    height: 16px;
    background-color: rgb(191, 17, 49);
    border-radius: 100%;
    position: absolute;
    top: 40px;
    left: 40px;
    box-shadow: 8px 44px 0 0 rgba(150, 0, 40, 1), -8px 80px 0 0 rgba(206, 0, 55, 1), 20px 100px 0 0 rgba(206, 0, 37, 1), -24px 132px 0 0 rgba(191, 17, 49, 1), 32px 140px 0 0 rgba(191, 17, 49, 1);
}

.tree-shadow {
    width: 200px;
    height: 20px;
    background: radial-gradient(ellipse at center, rgba(13, 84, 102, 1) 0%, rgba(138, 174, 188, 0) 65%, rgba(206, 223, 235, 0) 100%);
    position: absolute;
    bottom: -10px;
    left: -35px;
}

.stand-curved {
    height: 180px;
    width: 550px;
    border: 100px solid rgb(128, 0, 0);
    position: absolute;
    bottom: 0;
    border-radius: 50%;
}

.stand-curved-wrap {
    width: 560px;
    height: 133.3333333333px;
    position: absolute;
    bottom: -136px;
    margin-left: -80px;
    position: relative;
    clip-path: polygon(30% 10%, 70% 10%, 80% 100%, 20% 100%);
    z-index: 1;
}

.ground {
    width: 100%;
    height: 170px;
    position: absolute;
    bottom: 0;
    /* Position at the bottom */
    left: 0;
    /*   background: radial-gradient(ellipse at center, rgba(1, 1, 1, 1) 0%, rgba(1, 1, 1, 0.7) 10%, rgba(1, 1, 1, 0) 20%),
    linear-gradient(to bottom, rgba(37, 5, 6, 1) 0%, rgba(99, 19, 7, 1) 40%, rgba(164, 65, 33, 1) 100%); 
    background: radial-gradient(
      ellipse at bottom,
      rgba(37, 5, 6, 1) 0%,
      rgba(99, 19, 7, 1) 40%,
      #ffffff 70%
    ); Upravený gradient pozadí */
    z-index: 1;
    /* Ensure it's behind the globe */
}

.sm-shadow {
    width: 100px;
    height: 20px;
    background: radial-gradient(ellipse at center, rgba(13, 84, 102, 0.4) 0%, rgba(138, 174, 188, 0) 65%, rgba(206, 223, 235, 0) 100%);
    position: absolute;
    bottom: 125px;
    left: 90px;
}

.snowman {
    position: relative;
}

.snowman-bot {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, #dbe8fc 100%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 100px;
}

.snowman-top {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, #dbe8fc 100%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: -28px;
    left: 108px;
}

.coal {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 120px -10px 0 0 black, 137px -12px 0 0 black, 119px 0 0 0 black, 125px 2px 0 0 black, 132px 2px 0 0 black, 138px -2px 0 0 black, 130px 15px 0 0 black, 132px 27px 0 0 black, 132px 39px 0 0 black;
}

.carrot {
    width: 0;
    height: 0;
    border-left: 20px solid #f98e13;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    position: absolute;
    left: 130px;
    top: -6px;
}
</style>