<template>
  <div>
      <DxButton style="margin-bottom: 3px" v-if="!Disabled" :text="defaultLabel" :hint="defaultHint" icon="background" :id="computedbtnZmenaVlastnika" :class="computedclassZmenaVlastnika"
      type="default" styling-mode="contained" @click="popupZmenaVlastnika = true;" class="actionButtonsPozadavkyResizableA" />
              <DxPopup id="popup" v-model:visible="popupZmenaVlastnika" :show-title="true" :drag-enabled="false" :hide-on-outside-click="false"
                          :show-close-button="true" :title="defaultTitle" width="565px" height="auto">
                            <div id="pop">
                                  <div id="inner" >
                                      <br>
                                      <div class="child" style="display: flex; align-items: center;">
                                          <Employees :Disabled="false" :EmpId="Owner" @value-changed="setSelectedValue" :Label="$t('pozadavky-Zamestnanec')" width="auto" :Validation="false"/> 
                                      </div>
                                      <div v-if="!ChangeWatcher" class="child">
                                          <DxButton class="button1" id="ownerchanged" height="auto" width="auto" type="default" styling-mode="contained"
                                      :text="$t('central-Potvrdit')" :hint="$t('central-Potvrdit')" @click="ChangeOwner()" />      
                                      </div>
                                      <div v-else class="child">
                                          <DxButton class="button1" id="ownerchanged" height="auto" width="auto" type="default" styling-mode="contained"
                                      :text="$t('central-Potvrdit')" :hint="$t('central-Potvrdit')" @click="ChangeRequestWatcher()" />   
                                      <DxButton style="margin-left:20px" icon="revert"  height="auto" width="auto" type="default" styling-mode="contained"
                                      :text="$t('central-OdebratSledujiciho')" :hint="$t('central-OdebratSledujiciho')" @click="ChangeRequestWatcher(0)" />   
                                      </div>
                                </div>                   
                            </div>
              </DxPopup>
  </div>
</template>
  
<script>
import notify from 'devextreme/ui/notify';
import Employees from '@/components/Employees.vue';
export default {
  name: 'ChangeOwner',
    components: {
    Employees,
  },
  props: {
    Disabled: {
      type: Boolean,
      required: true
    },
    RequestID: {
      type: Number,
      required: true
    },
    Label: {
      type: String,
      required: false,
    },
    ChangeWatcher: {
      type: Boolean,
      required: false,
      default: false
    },
    Title: {
      type: String,
      required: false,

    },
  },
  watch: {
  },
  computed: {
    defaultHint() {
      if (this.ChangeWatcher) {return this.$t('central-EditovatSledujiciho');}
      else {return this.$t('central-EditovatVlastnika');}
    },
    defaultLabel() {
      if (this.Label == 'none') {return '';}
      else {return this.Label || this.$t('central-EditovatVlastnika');}
    },
    defaultTitle() {
      if (this.Title == 'none') {return '';}
      else {return this.Title || this.$t('central-EditovatVlastnika');}
    },
    computedbtnZmenaVlastnika(){
      if (this.Label == 'none') {return 'btnZmenaVlastnikaSmall'}
      else {return 'btnZmenaVlastnika'}
    },
    computedclassZmenaVlastnika(){
      if (this.Label == 'none') {return 'btnClassZmenaVlastnikaSmall'}
      else {return 'btnClassZmenaVlastnika'}
    },
    
    


  },
  data() {
    return {
      popupZmenaVlastnika: false,
      Owner: 0,
      heightButtonu:'100%',
      widthButtonu:'100%',

    };

  },
  mounted() {
    this.$log.debug('ChangedOwner MOUNT')
  },
 
  methods: {
    setSelectedValue(e) {
      window.this.$log.debug(e);
      switch (e.element.id) {
        case 'employee':
          this.Owner = e.value
          //this.zverejnit = e.value
          break;
      }
    }, 
    ChangeRequestWatcher(watcher=this.Owner) {
      var wh = new window.WorkFlow(window.channel, window.message);
      let self = this;
      this.$log.debug(self.Owner, 'changerequestwatcher')
      wh.SetRequestWatcher(this.RequestID, watcher,
        function (result) {
          self.$log.debug(result);
          if (result.ErrorNum.value != 0) {
            notify({
              message: result.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            notify({
              message: self.$t('central-SledujiciZmenen'),
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            self.popupZmenaVlastnika = false
            var valueObject = {
              value: 'success',
              element: {
                id: 'watcherchanged'
              }
            };
            self.$emit('owner-changed', valueObject);
          }
        }, window.this.ApiError());
    },
    ChangeOwner() {
      var wh = new window.WorkFlow(window.channel, window.message);
      let self = this;
      this.$log.debug(self.Owner)
      wh.SetRequestOwner(this.RequestID, self.Owner,
        function (result) {
          self.$log.debug(result);
          if (result.Error.value.ErrorNum.value != 0) {
            notify({
              message: result.Error.value.ErrorTextCZ.value,
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
          }
          else {
            notify({
              message: self.$t('central-VlastnikZmenen'),
              position: {
                my: 'center top',
                at: 'center top',

              },
            }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
            self.popupZmenaVlastnika = false
            var valueObject = {
              value: 'success',
              element: {
                id: 'ownerchanged'
              }
            };
            self.$emit('owner-changed', valueObject);
          }
        }, window.this.ApiError());
    },
   
  },

};
</script>
<style scoped>

#btnZmenaVlastnika:hover,#btnZmenaVlastnikaSmall:hover{
    background-color: var(--colorByThemeLighter);
    
    
}
#btnZmenaVlastnika{
  scale:100%;
  background: #b8b63c;
  z-index:10;
}
#btnZmenaVlastnikaSmall{
  padding-top: 0px !important;
    height: auto;
    width: auto;
   
    max-height: 20px;
    scale: 0.7;
  z-index:10;
}
:deep(.btnClassZmenaVlastnikaSmall > .dx-button-content){
  padding-top:0px !important;
}


</style>
  