<template>
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000693610?lang=cs"
        URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000693610?lang=en" PopisLinku="clanky.granty">
    </HelpLink>
    <div id="Granty">
        <DxPopup height="auto" width="25%" v-model:visible="popupPrilohy" :show-title="true" :drag-enabled="true"
            :hide-on-outside-click="true" :show-close-button="true" :title="$t('granty-PrilozeneFaktury')">
            <DxScrollView height="auto" direction="both" showScrollbar="always">
                <div id="all">
                    <div class="soubory" v-for="item in Soubory" :key="item">

                        <!-- <DxButton id="download" icon="download" text="" @click="GetHash(item.fileName,item.fileID)" /> -->
                        <PDFViewer :FileName="item.fileName" :FileID="item.fileID" />
                        <a href="#" id={{item.id}} @click="GetHash(item.fileName, item.fileID)">{{ item.fileName }}
                        </a>&nbsp;({{ item.fileSizeTXT }}) - {{ $t('granty-NascanovanyDne') }} {{
                            item.fileDate.toLocaleDateString() }}
                        <br>
                        <br>
                    </div>
                </div>
            </DxScrollView>
        </DxPopup>
        <DxPopup height="80%" width="75%" v-model:visible="popupVisible" :show-title="true" :drag-enabled="false"
            :hide-on-outside-click="true" :show-close-button="true" :title="$t('granty-DetailPolozky')">
            <DxScrollView direction="vertical" showScrollbar="always">
                <div id="all">
                    <DxDataGrid ref="popupgrid" id="DataGridPopUp" :data-source="dataSource"
                        :allow-column-reordering="true" :column-auto-width="true" :allow-column-resizing="true"
                        :show-borders="true" :noDataText="$t('central-NenalezenyZadneZaznamy')" :column-min-width="120"
                        :column-max-width="500" :hoverStateEnabled="true" :rowAlternationEnabled="true" width="100%">
                        <DxLoadPanel :enabled="true" :text="$t('central-Loading')" />
                        <DxToolbar>
                            <DxItem name="groupPanel" location="before" locate-in-menu="auto" />


                            <DxItem location="after" locate-in-menu="auto">
                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto"
                                    :text="$t('central-ObnovitPuvodniRozlozeni')" type="success"
                                    style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined"
                                    @click="GridLayoutReset('popupgrid')" />
                            </DxItem>
                            <DxItem name="exportButton" location="after" locate-in-menu="auto" />

                            <DxItem name="searchPanel" location="after" locate-in-menu="auto" :visible="true" />
                        </DxToolbar>
                        <DxColumn data-field="prilohydownload" data-type="boolean" :caption="$t('pozadavky-Prilohy')"
                            :visible="true" cell-template="prilohy" width="50px" />
                        <template #prilohy="{ data }">
                            <DxButton @click="PrilohyClick(data)" v-if="data.data.prilohydownload == true" icon="download"
                                width="35" height="35"></DxButton>
                        </template>
                        <DxColumn data-field="source" :caption="$t('granty-Zdroj')" width="max-content"
                            word-wrap="break-word" alignment="center" />
                        <DxColumn data-field="category" :caption="$t('granty-Rozliseni')" width="250px"
                            word-wrap="break-word" alignment="center" />
                        <DxColumn data-field="description" :caption="$t('granty-Popis')" width="250px" />
                        <DxColumn data-field="date" data-type="date" :caption="$t('granty-Vznik')" format="dd. MM. yyyy"
                            width="50px" alignment="center"></DxColumn>
                        <DxColumn format=",##0.00" data-field="value" :caption="$t('granty-Castka')"
                            width="max-content" />
                        <DxColumn data-field="prilohy" data-type="object" :caption="$t('granty-Faktury')"
                            :visible="false" />
                        <DxPaging :enabled="false" />
                        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" />
                        <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                        <DxGrouping :auto-expand-all="true" />
                        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')"
                            :clear-text="$t('central.Sorting.clearText')"
                            :descending-text="$t('central.Sorting.descendingText')" />
                        <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                    </DxDataGrid>
                    <br>
                </div>
            </DxScrollView>
        </DxPopup>

        <div class="clNadpisAktualniStranky" height="50px">
            <h2 id="nadpisGranty" class="nadpisH2Moduly prevent-select ">{{ $t('granty') }}
                <DxButton icon="preferences" text="" type="outlined" styling-mode="contained" id="preferences"
                    class="centralOutlinedBox " @click="this.Hlavicka = !this.Hlavicka;" height="20px" />
            </h2>
        </div>


        <div id="container" v-show="Hlavicka">

            <div id="inner" style="margin-left: 20px;">

                <div class="child" style="padding:5px;"  @mouseover="getTitle(granty,grant)" v-bind:title="getTitle(granty,grant)">
                    <DxSelectBox :id="2" width='250px' height="50px" :label="$t('granty-Grant')" :data-source="granty"
                        display-expr="content" class="selectbox " placeholder="" :dropDownOptions="{ minWidth: 500 }"
                        :search-enabled="true" :accept-custom-value="false" :value="granty[0]" :v-model="grant"
                        @value-changed="setSelectedValue" noDataText="">
                    </DxSelectBox>
                    <div>
                        <DxButton ref="GrantButtonRef" :text="$t('granty-Vykaz')" type="default" id="vykaz"
                            @click="Granty();" />
                    </div>
                </div>
                <div class="child" style="padding:5px;">
                    <DxDateBox :ref="DateBoxRef" :id="1" :disabled-dates="getDisabledDates"
                        :label="$t('granty-DatumVykazu')" height="50px" width="160px" type="date"
                        @value-changed="setSelectedValue" display-format="dd. MM. yyyy" @initialized="SetDate"
                        :value="povolene[0]" :applyButtonText="$t('central-Potvrdit')"
                        :cancelButtonText="$t('central-Zrusit')" class="selectbox " :accept-custom-value="false"
                        :open-on-field-click="true" />
                    <div>
                        <DxButton ref="RozpisButtonRef" :text="$t('granty-Rozpis')" type="default" id="rozpis"
                            @click="Rozpis" />
                    </div>

                </div>
                <div class="child" style="padding:5px; padding-top: 7.5px">
                    <DxButton v-if="this.zobrazeni" height="43" id="tiskpdf" icon="pdffile"
                        :text="this.$t('central-ExportPDF')" type="default" styling-mode="contained"
                        @click="this.PrintVykaz()" />
                    <PrintVariants v-else Report="DetailCerpani" :PrintID="this.grant.id" />
                </div>
            </div>
        </div>
        <div v-if="zobrazeni" @click="GetFundingDetail" id="template"> </div>
        <div v-else id="report">
            <hr class="hrModuly">
            <div class="bordersDataGridPozadavkyModuly">
                <DxDataGrid :column-min-width="150" :data-source="dataSourceDet" :showScrollbar="true"
                    :allow-column-resizing="true" :hoverStateEnabled="true" :rowAlternationEnabled="false"
                    :allow-column-reordering="true" :show-borders="true" :column-auto-width="false" ref="rozpisgrid"
                    id="rozpisgrid" width="auto" height="730px" :noDataText="$t('central-NenalezenyZadneZaznamy')"
                    @row-prepared="RowsFundingDetailCreated" @exporting="onExporting">
                    <DxSelection mode="multiple" />
                    <DxLoadPanel :enabled="true" :text="$t('central-Loading')" />
                    <DxColumn data-field="prilohydownload" data-type="boolean" :caption="$t('pozadavky-Prilohy')"
                        :visible="true" cell-template="prilohy" />
                    <template #prilohy="{ data }">
                        <DxButton @click="PrilohyClick(data)" v-if="data.data.prilohydownload == true" icon="download"
                            width="35" height="35"></DxButton>
                    </template>
                    <DxStateStoring :enabled="true" type="localStorage" storage-key="GridGrantyRozpis" />
                    <DxToolbar>
                        <DxItem name="groupPanel" location="before" locate-in-menu="auto" />

                        <DxItem location="after" locate-in-menu="auto">
                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto"
                                :text="$t('central-ObnovitPuvodniRozlozeni')" type="success"
                                style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined"
                                @click="GridLayoutReset('rozpisgrid')" />
                        </DxItem>

                        <DxItem name="exportButton" location="after" locate-in-menu="auto" />

                        <DxItem name="searchPanel" location="after" locate-in-menu="auto" :visible="true" />
                    </DxToolbar>
                    <DxExport :enabled="true" :formats="['xlsx']" :allow-export-selected-data="false"
                        :texts="{ exportAll: $t('central-ExportVseExcel') }" />

                    <DxColumn :group-index="0" data-field="variable" :caption="$t('granty-Promenna')" />
                    <DxColumn data-field="source" :caption="$t('granty-Zdroj')" />
                    <DxColumn data-field="category" :caption="$t('granty-Rozliseni')" />
                    <DxColumn data-field="description" :caption="$t('granty-Popis')" />
                    <DxColumn data-field="date" data-type="date" :caption="$t('granty-Vznik')" format="dd. MM. yyyy">
                    </DxColumn>
                    <DxColumn data-field="estimation" width="250px" :visible="false" />
                    <DxColumn data-field="highlight" width="250px" :visible="false" />
                    <DxColumn format=",##0.00" data-field="value" :caption="$t('granty-Castka')" />
                    <DxColumn data-field="prilohy" data-type="object" :caption="$t('granty-Faktury')"
                        :visible="false" />
                    <DxSummary>
                        <DxGroupItem column="value" summary-type="sum" :show-in-group-footer="false"
                            :align-by-column="true" value-format=",##0.00" />
                    </DxSummary>
                    <DxPaging :enabled="false" />
                    <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" />
                    <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                    <DxGrouping :auto-expand-all="true" />
                    <DxSorting :ascending-text="$t('central.Sorting.ascendingText')"
                        :clear-text="$t('central.Sorting.clearText')"
                        :descending-text="$t('central.Sorting.descendingText')" />
                    <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                    <DxFilterRow :visible="true" />
                    <DxFilterPanel :visible="true" :texts="{
                        clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                    }" />
                    <DxFilterBuilderPopup value="text" />
                    <DxHeaderFilter :visible="true" />
                </DxDataGrid>
            </div>

        </div>

    </div>
</template>

<script>
import DataSource from 'devextreme/data/data_source';
import axios from 'axios'
import notify from 'devextreme/ui/notify';
import PrintVariants from '@/components/PrintVariants.vue';
import PDFViewer from '@/components/PDFViewer.vue';
import {
    DxColumn, DxSummary, DxGroupItem, DxStateStoring, DxExport, DxHeaderFilter,
    DxFilterRow,
    DxFilterPanel,
    DxFilterBuilderPopup,
    DxLoadPanel,
    DxToolbar,
    DxSorting
} from 'devextreme-vue/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import HelpLink from '@/components/HelpLink.vue';

const DateBoxRef = 'my-DateBox';

const dataSource = new DataSource({
    // ...
});
const dataSourceDet = new DataSource({
    // ...
});
export default {
    name: "GrantyView",
    data() {
        return {
            Hlavicka: true,
            DateBoxRef,
            zobrazeni: true,
            date: "",
            dateList: "",
            dataSource,
            dataSourceDet,
            jazykovaPromennaProDatum: "",
            popupPrilohy: false,
            popupVisible: false,
            Soubory: [],
            granty: [],
            grant: [],
            datum: [],
            povolene: [],
            DatumGrantCislo: 0,
        };
    },
    components: {
        DxColumn, DxSummary, DxGroupItem, DxStateStoring, DxExport, DxHeaderFilter,
        DxFilterRow,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxLoadPanel,
        DxToolbar,
        HelpLink,
        PrintVariants,
        DxSorting,
        PDFViewer
    },
    computed: {
        dataGridPopUp: function () {
            let self = this;
            return self.$refs.popupgrid.instance;
        },
        DateBox: function () {
            return this.$refs[DateBoxRef].instance;
        }
    },
    mounted() {
        if (this.$i18n.locale == "cs") {
            this.jazykovaPromennaProDatum = "dd. MM. yyyy";
        }
        if (this.$i18n.locale == "en") {
            this.jazykovaPromennaProDatum = "mm-dd-yyyy";
        }
        this.DrawerSelected();
        //this.Prilohy('FP947/22')
        //window.localStorage.setItem("refresh-page", "Granty");
        this.ListFunding();
    },
    methods: {
        PrintVykaz() {
            if (this.grant != undefined) {


                // Získejte odkaz na váš div
                let divToPrint = document.getElementById('template');

                // Vytvořte kopii obsahu pro tisk
                let clonedDiv = divToPrint.cloneNode(true);

                // Vytvořte nový dokument pro tisk
                let printWindow = window.open('', 'GrantovyVykaz');

                // Přidejte kopii divu do nově vytvořeného dokumentu
                printWindow.document.body.appendChild(clonedDiv);

                // Zavřete nové okno po dokončení tisku
                printWindow.onafterprint = function () {
                    printWindow.close();
                };
                let filename = 'GrantovyVykaz (' + this.grant.id + ')' + '.pdf';
                printWindow.document.title = filename;
                // Zavolejte tisk
                printWindow.print();
            }
        },
        getTitle(datasource,item) {
            this.$log.debug('getTitle(item)');
            this.$log.debug('item',item,'datasource',datasource);
            var ItemContent = item;
            var LookupArray = datasource;
            this.$log.debug('LookupArray',LookupArray,'ItemContent',ItemContent);
            if ((Array.isArray(LookupArray))  && (ItemContent>''))  {
            this.$log.debug('ITEM NALEZEN',LookupArray.find(data => data.id == ItemContent.id))
            var foundItem = LookupArray.find(data => data.id == ItemContent.id)
            return foundItem ? foundItem.content : undefined;
            } else{
                this.$log.debug('LookupArray.length',LookupArray.length)
                return ''
            }
        }, 
        ButtonStylingMode() {
            //  this.$log.debug('styling mode')
            if (this.zobrazeni) {
                this.$log.debug('styling mode 1')
                this.$refs.RozpisButtonRef.instance.option('stylingMode', 'outlined')
                this.$refs.GrantButtonRef.instance.option('stylingMode', 'contained')
            }
            else {
                this.$log.debug('styling mode 2')
                this.$refs.RozpisButtonRef.instance.option('stylingMode', 'contained')
                this.$refs.GrantButtonRef.instance.option('stylingMode', 'outlined')
            }
        },
        RowsFundingDetailCreated(e) {
            this.$log.debug("Rows Created")
            this.$log.debug(e)
            if (e.rowType == "data" && e.data.highlight == true)
                e.rowElement.style.backgroundColor = "#fcdb03";
            if (e.rowType == "data" && e.data.estimation == true)
                e.rowElement.style.fontStyle = "italic";
        },
        Rozpis() {

            //  this.$log.debug(this.ButtonRozpis)
            //  this.$refs.GrantButtonRef.instance.option('stylingMode', 'outlined')
            //  this.$refs.RozpisButtonRef.instance.option('stylingMode', 'contained')
            if (this.grant != undefined) {
                this.zobrazeni = false;
                this.DatumGrantCislo = this.VypocitejDatumGrantCislo(this.datum, this.grant.id)
                this.$log.debug('Get detail report');
                this.ButtonStylingMode();
                this.GetDetailReport();
            }
        },
        DrawerSelected() {
            var e = window.Drawer;
            if (window.prevElement != undefined && window.prevPosition != undefined)
                window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = "";
            this.$log.debug(window.Drawer);
            this.$log.debug(this.$route.meta.id);
            this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length);
            for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
                // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
                if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
                    e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = "#FF9900";
                    window.prevElement = e;
                    window.prevPosition = x;
                }
            }
        },
        PrilohyClick(data) {
            window.this.$log.debug(data)
            let self = this;
            self.Soubory.length = 0;
            var gen = new window.General(window.channel, window.message);
            var Invoices = data.data.prilohy;
            var x = 0;
            for (var y = 0; y < Invoices.length; y++) {
                gen.GetInvoiceID(Invoices[y].Value.value, function (result) {
                    gen.ListFiles(2, result.Value.value, function (result2) {
                        //window.this.$log.debug(result2);
                        for (; x < result2.List.value.items.length; x++) {
                            self.Soubory[x] = {
                                fileName: result2.List.value.items[x].FileName.value,
                                fileExtension: result2.List.value.items[x].FileExtension.value,
                                fileID: result2.List.value.items[x].FileID.value,
                                fileDate: result2.List.value.items[x].Date.value,
                                fileSizeTXT: result2.List.value.items[x].FileSizeTXT.value,
                            };
                        }
                    }, window.this.ApiError());
                    if (y == Invoices.length)
                        self.popupPrilohy = true;
                }, window.this.ApiError());
            }
        },
        async downloadItem(url, name, suffix) {
            //window.this.$log.debug(suffix)
            suffix = ".pdf";
            axios.get(url, { headers: { "Accept": "application/pdf" }, responseType: "blob" })
                .then(function (response) {
                    switch (suffix) {
                        case ".pdf": {
                            // window.this.$log.debug(response)
                            const blob = new Blob([response.data], { type: "application/" + suffix });
                            const link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.download = name;
                            link.click();
                            // window.this.$log.debug(link)
                            window.URL.revokeObjectURL(link.href);
                            break;
                        }
                    }
                })
                .catch(function (error) {
                    window.this.$log.debug(error);
                    window.this.$log.debug(JSON.stringify(error));
                });
        },
        GetHash(FileName, FileID) {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            gen.GetFileHash(FileID, function (result) {
                //window.this.$log.debug(result);
                self.hash = window.URL1 + "/js/download/" + result.Value.value;
                //window.this.$log.debug(self.hash + " " + FileName)
                self.downloadItem(self.hash, FileName);
                if (result.Error.value.ErrorNum.value != 0)
                    notify({
                        message: self.$t("central-NepodariloSeStahnout") + result.Error.value.ErrorTextCZ.value + "\".",
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    }, "error", 5000);
            }, window.this.ApiError());
        },
        getDisabledDates(args) {
            let vysledek = true;
            this.$log.debug(args);
            for (let povoleny of this.povolene) {
                if ((args.view === "month" && args.date.getDate() === povoleny.getDate() && args.date.getMonth() === povoleny.getMonth() && args.date.getFullYear() === povoleny.getFullYear())
                    || (args.view === "year" && args.date.getMonth() === povoleny.getMonth() && args.date.getFullYear() === povoleny.getFullYear())
                    || (args.view === "decade" && povoleny.getFullYear() === args.date.getFullYear())
                    || (args.view === "century" && povoleny.getFullYear() >= args.date.getFullYear() && povoleny.getFullYear() <= args.date.getFullYear() + 9)
                ) {
                    vysledek = false;
                    break;
                }
            }
            //window.this.$log.debug(args.date)
            //window.this.$log.debug(vysledek)
            return vysledek;
        },
        SetDate(e) {
            window.this.$log.debug(e);
            this.date = new Date();
        },
        ListFunding() {
            let self = this;
            self.granty.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.ListFunding(2, 2, function (result) {
                self.$log.debug(result);
                for (var x = 0; x < result.List.value.items.length; x++) {
                    self.granty[x] = {
                        content: result.List.value.items[x].Text.value + " " + result.List.value.items[x].Name.value,
                        id: result.List.value.items[x].ID.value,
                    };
                }
                self.grant = self.granty[0];
            }, window.this.ApiError());
        },
        ListDates(fundingID) {
            let self = this;
            self.povolene = [];
            var g = new window.Grants(window.channel, window.message);
            g.GetFundingReportDates(fundingID, function (result) {
                for (var x = 0; x < result.List.value.items.length; x++) {
                    self.povolene[x] = result.List.value.items[x].Value.value;
                }
                self.$log.debug(self.DateBox)
                self.DateBox.repaint()
                self.$log.debug('datebox')
            }, window.this.ApiError());
        },
        GridLayoutReset(id) {
          //  this.$log.debug(id)
        //    this.$log.debug(this.dataGrid)
            switch (id) {
                case 'popupgrid': {
                    this.$refs.popupgrid.instance.state(null);
                }
                    break;
                case 'rozpisgrid': {
                    this.$refs.rozpisgrid.instance.state(null);
                }
                    break;
            }
        },
        setSelectedValue(e) {
            window.this.$log.debug(e);
            //window.this.$log.debug(this.datum + ' datum')
            //window.this.$log.debug(this.grant + ' grant')
            switch (e.element.id) {
                case "1":
                    this.datum = e.value;
                    break;
                case "2":
                    if (e.value != null) {
                        this.grant = e.value;
                    }
                    else {
                        e.component.option('value', e.previousValue)
                        this.grant = e.previousValue;
                    }
                    this.ListDates(this.grant.id);
                    break;
            }
            if (this.datum != "" && this.grant != "") {
                window.this.$log.debug('volam granty')
                this.Granty();
            }
        },
        VypocitejDatumGrantCislo(datum, grant) {
            this.$log.debug(datum)
            // Získání hodnoty roku, měsíce a dne
            if (datum != undefined) {


                const year = datum.getFullYear();
                const month = datum.getMonth() + 1; // Měsíce jsou indexované od 0, proto přidáme 1
                const day = datum.getDate();

                this.$log.debug(year)
                this.$log.debug(month)
                this.$log.debug(day)
                // Výpočet grantového čísla
                const grantCislo = (year * 1000000000) + (month * 10000000) + (day * 100000) + grant;
                this.$log.debug(grantCislo)
                return grantCislo;
            }
            else return 0;
        },
        GetFundingDetail(e) {

            if (e.target.hreflang == "true") {
                var self = this;
                const store = dataSource.store();
                store.clear();
                var grants = new window.Grants(window.channel, window.message);
                //window.this.$log.debug(e)     
                //window.this.$log.debug('Volam s id = '+e.target.id)
                grants.GetFundingDetail(e.target.id, function (result) {
                    //window.this.$log.debug(result);
                    if (result.Items.value.items.length > 0) {
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                            //  window.this.$log.debug(result.List.value.items[x]);        
                            store.insert({
                                id: x,
                                source: result.Items.value.items[x].Source.value,
                                category: result.Items.value.items[x].Category.value,
                                description: result.Items.value.items[x].Description.value,
                                date: result.Items.value.items[x].Date.value,
                                value: result.Items.value.items[x].Value.value,
                                prilohydownload: result.Items.value.items[x].Invoices.value.List.value.items.length > 0,
                                prilohy: result.Items.value.items[x].Invoices.value.List.value.items,
                            })
                                .then(() => {
                                    //  window.this.$log.debug(dataObj)
                                    dataSource.reload();
                                }, (error) => { window.this.$log.debug(error); /* ... */ });
                        }
                        self.popupVisible = true;
                    }

                    //var template = result.Template.value
                    //document.getElementById("template").innerHTML = template;
                }, window.this.ApiError());
            }
        },
        GetDetailReport() {
            const store = dataSourceDet.store();
            store.clear();
            var grants = new window.Grants(window.channel, window.message);
            // window.this.$log.debug(e)
            // window.this.$log.debug('Volam s id = ' + e.target.id)
            if (this.grant.id != undefined)
                grants.GetDetailReport(this.grant.id, this.datum, function (result) {
                    window.this.$log.debug(result);

                    if (result.Items.value.items.length > 0) {
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                            //  window.this.$log.debug(result.List.value.items[x]);        
                            store.insert({
                                id: x,
                                variable: result.Items.value.items[x].Variable.value,
                                source: result.Items.value.items[x].Source.value,
                                category: result.Items.value.items[x].Category.value,
                                description: result.Items.value.items[x].Description.value,
                                date: result.Items.value.items[x].Date.value,
                                value: result.Items.value.items[x].Value.value,
                                prilohydownload: result.Items.value.items[x].Invoices.value.List.value.items.length > 0,
                                prilohy: result.Items.value.items[x].Invoices.value.List.value.items,
                                estimation: result.Items.value.items[x].Estimation.value,
                                highlight: result.Items.value.items[x].Highlight.value
                            })
                                .then(() => {
                                    //  window.this.$log.debug(dataObj)
                                    dataSourceDet.reload();
                                }, (error) => { window.this.$log.debug(error); /* ... */ });
                        }
                    }
                    //var template = result.Template.value
                    //document.getElementById("template").innerHTML = template;
                }, window.this.ApiError());
        },
        Granty() {
            // this.$log.debug(e) 
            //  this.$refs.GrantButtonRef.instance.option('stylingMode', 'contained')
            // this.$refs.RozpisButtonRef.instance.option('stylingMode', 'outlined')
            //  this.ButtonRozpis.option('stylingMode', 'outlined')
            this.zobrazeni = true;
            this.ButtonStylingMode();
            var grants = new window.Grants(window.channel, window.message);
            var date = this.datum;
            //window.this.$log.debug(date)
            // window.this.$log.debug(grant)
            if (this.grant != undefined)
                grants.GetFundingReport(this.grant.id, date, function (result) {
                    window.this.$log.debug(result);
                    var template = result.Template.value;
                    for (var x = 0; x < result.Variables.value.items.length; x++) {
                        // window.this.$log.debug(result.Variables.value.items[x].Name.value)
                        if (result.Variables.value.items[x].ValueTXT.value != "")
                            template = template.replaceAll("[" + result.Variables.value.items[x].Name.value + "]", result.Variables.value.items[x].ValueTXT.value);
                        else if (result.Variables.value.items[x].ValueNumeric.value != 0 && result.Variables.value.items[x].HasDetail.value == true)
                            template = template.replaceAll("[" + result.Variables.value.items[x].Name.value + "]", "<a href=\"#\" hreflang=\"true\" id=" +
                                result.Variables.value.items[x].ID.value + ">" + (result.Variables.value.items[x].ValueNumeric.value).toLocaleString("cs-CZ", {
                                    minimumFractionDigits: 2
                                }) + "</a>"); // ,##0.00 
                        else
                            template = template.replaceAll("[" + result.Variables.value.items[x].Name.value + "]", (result.Variables.value.items[x].ValueNumeric.value).toLocaleString("cs-CZ", {
                                minimumFractionDigits: 2
                            }));
                    }
                    template = template.replace(/\[.*?\]/g, "0,00");
                    document.getElementById("template").innerHTML = template;

                }, window.this.ApiError());
        },
        onExporting(e) {
            window.this.$log.debug('test uvnitr onexporting')
            if (e.format === "xlsx") {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet("Companies");
                exportDataGridToExcel({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                    customizeCell: function (options) {
                        const excelCell = options;
                        excelCell.font = { name: 'Arial', size: 12 };
                        excelCell.alignment = { horizontal: 'left' };
                    }
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        const datum = new Date();
                        saveAs(
                            new Blob([buffer], { type: "application/octet-stream" }),
                            "DetailGrantu " + datum.toISOString('cs-CZ').substring(5, 10) + " " + datum.toTimeString('cs-CZ').substring(0, 9) + ".xlsx"
                        );
                    });
                });
                e.cancel = true;
            }



        },
    },
}
</script>

<style scoped>
@media print {
    @page {
        size: landscape;
        /* nebo portrait pro svislý tisk */
    }
}

.selectbox {
    margin-bottom: 20px;
}

#vykaz,
#rozpis {
    margin-top: 20px;
}

[notActive="true"] {
    opacity: 0.25
}

#rozpisgrid {
    margin-top: 20px;
}

#obaleniGridu {
    border-style: ridge;
    border-color: rgba(128, 128, 128, 0.226);
    border-radius: 10px;
    padding: 10px;
}

#nadpisGranty {
    text-align: center;
}

#template {
    background-color: rgba(255, 255, 255, 0.8) !important;
    padding: 10px;
    color: black !important;
    border: 2px var(--colorByThemeLighter) solid;
    overflow: scroll;
}

.ClassOfButton {
    color: none;
    border-color: none !important;
    border: 0px;
}

:deep(.dx-icon) {
    font-size: 20px;
    /* Nastavte velikost fontu podle potřeby */
    height: 4px;
    width: 4px;
}

.ClassOfButton:hover {
    background: var(--colorByThemeLighter);
    border-color: none !important;
    border: 0px;
    color: none;
}

h2#nadpisGranty {
    color: unset !important
}
</style>