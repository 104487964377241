
import { createApp} from 'vue'
import App from '@/App.vue'
// import i18n from './i18n.js'
import VueLogger from 'vuejs3-logger';
import { createI18n } from "vue-i18n"
import router from '@/router'
import axios from 'axios'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueAxios from 'vue-axios'
import "devextreme/ui/html_editor/converters/markdown";
import DxButton from 'devextreme-vue/button';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import DxTreeView from 'devextreme-vue/tree-view';
import DxDrawer from 'devextreme-vue/drawer';
import DxDateBox from 'devextreme-vue/date-box';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxGrouping,
    DxGroupPanel, DxForm,
    DxSearchPanel, DxRequiredRule, DxFormItem, DxLookup, DxNumericRule
    //DxFormat
//    DxPatternRule
} from 'devextreme-vue/data-grid';
import {
  DxPivotGrid,
  DxFieldChooser,
} from 'devextreme-vue/pivot-grid';
import {
  DxTreeList,
  DxColumnChooser,
  DxHeaderFilter,
  DxSelection,
} from 'devextreme-vue/tree-list';
//import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import DataSource from 'devextreme/data/data_source';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxPopup, DxPosition } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DxFileUploader from 'devextreme-vue/file-uploader';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import 'devextreme-vue/text-area';
import {
  DxValidator,
 // DxRequiredRule,
 // DxCompareRule,
 // DxEmailRule,
 // DxPatternRule,
 // DxStringLengthRule,
 // DxRangeRule,
 // DxAsyncRule,
 DxCustomRule
} from 'devextreme-vue/validator';
import {
  DxResponsiveBox, DxLocation, DxCol, DxRow,
} from 'devextreme-vue/responsive-box';
import { DxScrolling } from 'devextreme-vue/data-grid';
import config from "devextreme/core/config"
import './registerServiceWorker'
config({
    thousandsSeparator: ' '
})
//Vue.config.productionTip = false
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}
const i18n = createI18n({
  messages: loadLocaleMessages(),
  locale: 'cs',
  fallbackLocale: 'cs',
  localeDir: 'locales',
  enableLegacy: false,
  runtimeOnly: false,
  compositionOnly: true,
  fullInstall: true
  
})
const isProduction = process.env.NODE_ENV === 'production';
const optionsLogger = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};
const app = createApp(App);
app.config.globalProperties.$decimalLimits = {
  max: 9999999999.99, // ve smlouvach nastaveno 999999999999.99
  min: 0,
};
app.use(router)
.use(i18n)
.use(VueAxios, axios)
.use(VueLogger,optionsLogger)
.use(VueLoading,{
  // options here
})
  //DEVEXTREME COMPONENTS
  .component("DxButton", DxButton)
  .component("DxTextBox", DxTextBox)
  .component("DxTextArea", DxTextArea)
  .component("DxCheckBox", DxCheckBox) 
  .component("DxTreeView", DxTreeView)
  .component("DxDrawer", DxDrawer)
  .component("DxToolbar", DxToolbar)
  .component("DxDataGrid", DxDataGrid)
  .component("DxColumn", DxColumn)
  .component("DxEditing", DxEditing)
  .component("DxPaging", DxPaging)
  .component("DxGrouping", DxGrouping)
  .component("DxGroupPanel", DxGroupPanel)
  .component("DxSearchPanel", DxSearchPanel)
  //.component("DxLookup", DxLookup)
  //.component("DxDropDownOptions", DxDropDownOptions)
  .component("DataSource", DataSource)
  .component("DxSelectBox", DxSelectBox)
  .component("DxPopup", DxPopup)
  .component("DxPosition", DxPosition)
  //.component("DxToolbarItem", DxToolbarItem)
  .component("DxScrollView", DxScrollView)
  .component("DxItem", DxItem)
  .component("DxRequiredRule", DxRequiredRule)
  .component("DxFormItem", DxFormItem)
  .component("DxLookup", DxLookup)
 // .component("DxPatternRule", DxPatternRule)
  .component("DxNumericRule", DxNumericRule)
  .component("DxValidator", DxValidator)
  .component("DxResponsiveBox", DxResponsiveBox)
  .component("DxLocation", DxLocation)
  .component("DxCol", DxCol)
  .component("DxRow", DxRow)
  .component("DxScrolling", DxScrolling)
  .component("DxDateBox", DxDateBox)
  .component("DxFileUploader", DxFileUploader)
  //.component("DxFormat", DxFormat)
  .component("DxForm", DxForm)
  .component("DxDropDownBox", DxDropDownBox)
  .component("DxTreeList", DxTreeList)
  .component("DxColumnChooser", DxColumnChooser)
  .component("DxHeaderFilter", DxHeaderFilter)
  .component("DxSelection", DxSelection)
  .component("DxFieldChooser", DxFieldChooser)
  .component("DxPivotGrid", DxPivotGrid)
  .component("DxCustomRule", DxCustomRule)
  
.mount('#app')










   
    




