<template>
    <DxButton v-if="ValidFileExtension" id="Preview" styling-mode="outlined" type="default" icon="image" @click="togglePopup" />
    <DxPopup
      id="PopupSettingsToolbar"
      v-model:visible="PopupVisible"
      :drag-enabled="true"
      :drag-outside-boundary="false"
      :resize-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :showTitle="true"
      :title="$t('Nahled') + FileName"
      width="auto"
      height="auto"
      :focusStateEnabled="true"
      :shading="true"
      @Hiding="hiding = true; hidden = false"
      @Hidden="hiding = false; hidden = true"
      ref="popup"
    >
      <DxScrollView ref="scrollView" direction="vertical" showScrollbar="always" >
        <div>
          <div v-if="FileExtension === 'pdf'" class="pdf-navigation">
            <div class="pdf-navigation-inner"> <!--v-if="totalPages > 1"-->
              <DxButton
                @click="prevPage"
                height="40px"
                :Text="$t('Predchozi')"
                styling-mode="outlined"
                type="default"
                icon="arrowleft"
                :disabled="currentPage <= 1"
              ></DxButton>
              <DxNumberBox
                :min="1"
                :max="totalPages"
                :value="currentPage"
                @value-changed="searchedPage"
                placeholder=""
                height="35px"
                width="45px"
                style="margin: 0 10px;"
              ></DxNumberBox>
              <span style="margin-bottom: 1px;"> / {{ totalPages }}</span>
              <DxButton
                style="margin-left: 7px"
                @click="nextPage"
                height="40px"
                :Text="$t('Dalsi')"
                styling-mode="outlined"
                type="default"
                icon="arrowright"
                :disabled="currentPage >= totalPages"
              ></DxButton>
              <DxButton
                style="margin-left: 10px;"
                @click="rotatePage"
                height="40px"
                :Text="$t('Otocit')"
                styling-mode="outlined"
                type="default"
                icon="pulldown"
              ></DxButton>
            </div>
          </div>
          <div class="pdf-container">
            <canvas ref="pdfCanvas" v-if="!ShowIMG"></canvas>
            <div v-else class="button-image-container">
              <DxButton
                style="margin-left: 20px; margin-bottom: 10px;"
                @click="rotateIMG"
                height="40px"
                :text="$t('Otocit')"
                styling-mode="outlined"
                type="default"
                icon="pulldown"
              ></DxButton>
              <img :src="FileURL" alt="Fallback Image" ref="fallbackImage" class="fallback-image" />
            </div>
          </div>
        </div>
      </DxScrollView>
    </DxPopup>
  </template>
  
  <script>
import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist/build/pdf';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxNumberBox from 'devextreme-vue/number-box';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;

export default {   
    props: {
        FileName: {
        type: String,
        required: true,
        },
        FileID: {
        type: Number,
        required: true,
        },
  },
  components: {
        DxScrollView,
        DxNumberBox  
    },
  data() {
    return {
      FileExtension: '',
      ValidFileExtension: true,
      ValidImageExtensions: ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp'],
      PopupVisible: false,
      FileURL: '',
      hidden: false,
      hiding: false,
      ShowIMG: false,
      currentPage: 1,
      totalPages: 0,
      pdfDocument: false, 
      rotation: 0, 
      rotationDegree: 0,
      loaderActive: false,
      MaxHeight: 0,
      MaxWidth: 0,
    };
  },
  mounted() {
    this.ValidatingFileExtension();
  },
  methods: {
    open() {
            this.loaderActive=true;
            window.loader = this.$loading.show({
                loader: 'dots'
            });
        },
    hide() {
        this.$log.debug('hide loader')
        this.$log.debug(this.$loading)
        if (this.loaderActive) {
            window.loader.hide()
            this.loaderActive=false;
        }
    },
    ValidatingFileExtension() {
      this.FileExtension = this.FileName.split('.').pop().toLowerCase();
      this.$log.debug(this.FileExtension)
      if (this.FileExtension !== 'pdf' && !this.ValidImageExtensions.includes(this.FileExtension)) {
        this.$log.debug('Unsupported file format');
        this.ValidFileExtension = false;
        return;
      }
    },
    togglePopup() {
      if (this.hidden || !this.hiding) {
        this.hidden = true;
        this.open();
        this.GetHash(this.FileID);
      }
    },
    GetHash(FileID) {
      let self = this;
      var gen = new window.General(window.channel, window.message);
      gen.GetFileHash(
        FileID,
        function (result) {
          window.this.$log.debug(result);
          self.FileURL = window.URL1 + '/js/download/' + result.Value.value;
          self.$log.debug(self.FileURL);
          self.loadPdf();
        },
        window.this.ApiError()
      );
    },
    async loadPdf() {
        if (this.FileExtension !== 'pdf' && this.ValidImageExtensions.includes(this.FileExtension)) {
            this.ShowIMG = true;
            this.$nextTick(() => {
            const img = this.$refs.fallbackImage;
            this.rotationDegree=0;
            if (img) {
                img.onload = () => {
             //     this.$log.debug(viewport.height, 'height')
                const scale = this.calculateScaleForImage(img);
                img.style.width = `${img.naturalWidth * scale}px`;
                img.style.height = `${img.naturalHeight * scale}px`;
                this.hide();
                this.PopupVisible = true;
                };
                img.src = this.FileURL;
            }
            
            });
            return;
        }

        if (this.FileExtension !== 'pdf') {
            this.$log.debug('Unsupported file format');
            return;
        }

        const loadingTask = getDocument(this.FileURL);
        loadingTask.promise.then(pdf => {
            this.pdfDocument = () => pdf;
            this.totalPages = pdf.numPages;
            this.currentPage = 1;
            this.renderPage(this.currentPage);
        }).catch(error => {
            this.hide();
            this.$log.debug('Error loading PDF', error);
        });

        
        },
        async renderPage(pageNumber) {
      if (!this.pdfDocument || typeof this.pdfDocument !== 'function') {
        this.$log.debug('Invalid PDF document');
        return;
      }

      const pdf = this.pdfDocument();
      pdf.getPage(pageNumber).then(page => {
        const scale = this.calculateScale(page);
        const highQualityScale = scale * 1.8; 
        this.$log.debug(this.rotation)
        const viewport = page.getViewport({ scale: highQualityScale, rotation: this.rotation });
        const canvas = this.$refs.pdfCanvas;
        const context = canvas.getContext('2d');
        this.$log.debug(viewport)
        // Nastavíme vyšší rozlišení canvasu
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        this.$log.debug(viewport.height)
        page.render(renderContext).promise.then(() => {
          canvas.style.width = `${viewport.width / 1.8}px`;
          canvas.style.height = `${viewport.height / 1.8}px`;
          this.$log.debug('Page rendered with high quality');
          this.hide();
          this.PopupVisible = true;
        }).catch(error => {
          this.$log.debug('Error rendering page', error);
        });
      }).catch(error => {
        this.$log.debug('Error getting page', error);
      });
    },
    searchedPage(e) {
      if (e.value <= 1 || e.value>this.totalPages) {
        return;
      }
      this.currentPage = e.value;
      this.renderPage(this.currentPage);
    },
    rotateIMG() {
      this.rotationDegree = (this.rotationDegree + 90) % 360; // Otočit o 90 stupňů a zajistit, že hodnota bude v rozmezí 0-360
      const image = this.$refs.fallbackImage;
      image.style.transform = `rotate(${this.rotationDegree}deg)`; 
    },
    rotatePage() {
      this.rotation = (this.rotation + 90) % 360;
      this.renderPage(this.currentPage);
    },
    prevPage() {
    this.rotation=0
      if (this.currentPage <= 1) {
        return;
      }
      this.currentPage -= 1;
      this.renderPage(this.currentPage);
    },
    nextPage() {
    this.rotation=0
      if (this.currentPage >= this.totalPages) {
        return;
      }
      this.currentPage += 1;
      this.renderPage(this.currentPage);
    },
    calculateScale(page) {
      const popupHeight = 150;  
      const popupWidth = 50;  
      const maxAvailableHeight = (window.innerHeight - popupHeight);
      const maxAvailableWidth = (window.innerWidth - popupWidth);   
      const viewport = page.getViewport({ scale: 1 });
      let effectiveRotation = (viewport.rotation + this.rotation) % 360;
      let adjustedViewportHeight = viewport.height;
      let adjustedViewportWidth = viewport.width;

      if (effectiveRotation === 90 || effectiveRotation === 270) {
          adjustedViewportHeight = viewport.width;
          adjustedViewportWidth = viewport.height;
      }
      const heightScale = maxAvailableHeight / adjustedViewportHeight;
      const widthScale = maxAvailableWidth / adjustedViewportWidth;
      const scale = Math.min(heightScale, widthScale);
      const scaledHeight = adjustedViewportHeight * scale;
      const scaledWidth = adjustedViewportWidth * scale;
      if (scaledHeight > maxAvailableHeight || scaledWidth > maxAvailableWidth) {
          return Math.min(maxAvailableHeight / adjustedViewportHeight, maxAvailableWidth / adjustedViewportWidth);
      }
      return scale;
  },
    calculateScaleForImage(img) {
    const popupHeight = 150;  
    const popupWidth = 50;     
    const maxAvailableHeight = (window.innerHeight - popupHeight) ; 
    const maxAvailableWidth = (window.innerWidth - popupWidth) ;   
    const heightScale = maxAvailableHeight / img.naturalHeight;
    const widthScale = maxAvailableWidth / img.naturalWidth;
    const scale = Math.min(heightScale, widthScale);
    const scaledHeight = img.naturalHeight * scale;
    const scaledWidth = img.naturalWidth * scale;
    if (scaledHeight > maxAvailableHeight || scaledWidth > maxAvailableWidth) {
      return Math.min(maxAvailableHeight / img.naturalHeight, maxAvailableWidth / img.naturalWidth);
    }
    return scale;
  }
  }
}
</script>
  
<style scoped>
@media (max-width: 500px) {
  .pdf-navigation-inner {
    scale: 0.85;
  }
}
#rotatebutton{
  margin-left: 20px;
}
#Preview{
  position:relative;
  top: 1px;
}
.fallback-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.button-image-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

canvas {
  transform-origin: top left;
  border: 1px solid black;
}
.pdf-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pdf-navigation-inner {
  display: flex;
  align-items: center;
}

.pdf-container {
  overflow: auto;
  display: flex;
  justify-content: center;
  overflow: auto;
}
</style>