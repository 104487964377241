
export const themesList = [
    {
key: 'Basic Light',
items: [
    {
    groupid: 0,
    itemid: 0,
    id: 1,
    name: "generic.light",
    targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Light/",
    class: "light",
    themeUrl: "Light/",
    simulator: "Web",
    title: "Default",
    Group: 'generic',
        
},
{
    groupid: 0,
    itemid: 1,
    id:2,
    name: "generic.optimal",
    targetUrl: "",
    class: "lighthighlight",
    themeUrl: "lighthighlight/",
    simulator: "Web",
    title: 'Optimal',
    Group: 'Generic',
        
},
   
    {
    groupid: 0,
    itemid: 3,
    id: 4,
    name: "generic.carmine",
    targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Carmine/",
    class: "carmine",
    themeUrl: "Carmine/",
    simulator: "Web",
    title: "Carmine",
    Group: 'generic',
},
   
    {
    groupid: 0,
    itemid: 5,
    id: 6,
    name: "generic.softblue",
    targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/SoftBlue/",
    class: "softblue",
    themeUrl: "SoftBlue/",
    simulator: "Web",
    title: "Light Blue",
    Group: 'generic',
},
    {
    groupid: 0,
    itemid: 7,
    id: 8,
    name: "generic.greenmist",
    targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/GreenMist/",
    class: "greenmist",
    themeUrl: "GreenMist/",
    simulator: "Web",
    title: "Green Mist",
    Group: 'generic',
},
],
},
{
key: 'Basic Dark',
items: [
    {
        groupid: 1,
        itemid: 1,
        id: 2,
        name: "generic.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Dark/",
        class: "dark",
        themeUrl: "Dark/",
        simulator: "Web",
        title: "Dark ",
        Group: 'generic',
    },
    {
        groupid: 1,
        itemid: 6,
        id: 7,
        name: "generic.darkviolet",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/DarkViolet/",
        class: "darkviolet",
        themeUrl: "DarkViolet/",
        simulator: "Web",
        title: "Dark Violet",
        Group: 'generic',
    },
    {
        groupid: 1,
        itemid: 4,
        id: 5,
        name: "generic.darkmoon",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/DarkMoon/",
        class: "darkmoon",
        themeUrl: "DarkMoon/",
        simulator: "Web",
        title: "Dark Moon",
        Group: 'generic',
    },
    
        {
        groupid: 1,
        itemid: 2,
        id: 3,
        name: "generic.contrast",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Contrast/",
        class: "contrast",
        themeUrl: "Contrast/",
        simulator: "Web",
        title: "Contrast",
        Group: 'generic',
    },


]

}
/*,{
    key: 'Generic Compact',
items: [

    {
        groupid: 1,
        itemid: 0,          
        id: 9,    
        name: "light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Light/Compact/",
        class: "light-compact",
        themeUrl: "Light/Compact/",
        simulator: "Web",
        title: "Light Compact",
        Group: 'Generic Compact',
    },

    {
        groupid: 1,
        itemid: 1,
        id: 10,
        name: "dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Dark/Compact/",
        class: "dark-compact",
        themeUrl: "Dark/Compact/",
        simulator: "Web",
        title: "Dark Compact",
        Group: 'Generic Compact',
    },

    {
        groupid: 1,
        itemid: 2,
        id: 11,
        name: "contrast.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/Contrast/Compact/",
        class: "contrast-compact",
        themeUrl: "Contrast/Compact/",
        simulator: "Web",
        title: "Contrast Compact",
        Group: 'Generic Compact',
    },
    ]
},
{
key: 'Material Design',
items: [
    {   
        groupid: 2,
        itemid: 0,
        id: 12,
        name: "material.blue.light",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialBlueLight/",
        class: "material-blue-light",
        themeUrl: "MaterialBlueLight/",
        simulator: "Web",
        title: "Blue Light",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 1,
        id: 13,
        name: "material.blue.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialBlueDark/",
        class: "material-blue-dark",
        themeUrl: "MaterialBlueDark/",
        simulator: "Web",
        title: "Blue Dark",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 2,
        id: 14,
        name: "material.lime.light",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialLimeLight/",
        class: "material-lime-light",
        themeUrl: "MaterialLimeLight/",
        simulator: "Web",
        title: "Lime Light",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 3,
        id: 15,
        name: "material.lime.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialLimeDark/",
        class: "material-lime-dark",
        themeUrl: "MaterialLimeDark/",
        simulator: "Web",
        title: "Lime Dark",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 4,
        id: 16,
        name: "material.orange.light",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialOrangeLight/",
        class: "material-orange-light",
        themeUrl: "MaterialOrangeLight/",
        simulator: "Web",
        title: "Orange Light",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 5,
        id: 17,
        name: "material.orange.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialOrangeDark/",
        class: "material-orange-dark",
        themeUrl: "MaterialOrangeDark/",
        simulator: "Web",
        title: "Orange Dark",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 6,
        id: 18,
        name: "material.purple.light",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialPurpleLight/",
        class: "material-purple-light",
        themeUrl: "MaterialPurpleLight/",
        simulator: "Web",
        title: "Purple Light",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 7,
        id: 19,
        name: "material.purple.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialPurpleDark/",
        class: "material-purple-dark",
        themeUrl: "MaterialPurpleDark/",
        simulator: "Web",
        title: "Purple Dark",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 8,
        id: 20,
        name: "material.teal.light",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialTealLight/",
        class: "material-teal-light",
        themeUrl: "MaterialTealLight/",
        simulator: "Web",
        title: "Teal Light",
        Group: 'Material Design',
    },
    {
        groupid: 2,
        itemid: 9,
        id: 21,
        name: "material.teal.dark",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialTealDark/",
        class: "material-teal-dark",
        themeUrl: "MaterialTealDark/",
        simulator: "Web",
        title: "Teal Dark",
        Group: 'Material Design',
},
]
},
{
key: 'Material Design Compact',
items: [
    {
        groupid: 3,
        itemid: 0,
        id: 22,
        name: "material.blue.light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialBlueLight/Compact/",
        class: "material-blue-light-compact",
        themeUrl: "MaterialBlueLight/Compact/",
        simulator: "Web",
        title: "Blue Light Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 1,
        id: 23,
        name: "material.blue.dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialBlueDark/Compact/",
        class: "material-blue-dark-compact",
        themeUrl: "MaterialBlueDark/Compact/",
        simulator: "Web",
        title: "Blue Dark Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 2,
        id: 24,
        name: "material.lime.light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialLimeLight/Compact/",
        class: "material-lime-light-compact",
        themeUrl: "MaterialLimeLight/Compact/",
        simulator: "Web",
        title: "Lime Light Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 3,
        id: 25,
        name: "material.lime.dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialLimeDark/Compact/",
        class: "material-lime-dark-compact",
        themeUrl: "MaterialLimeDark/Compact/",
        simulator: "Web",
        title: "Lime Dark Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 4,
        id: 26,
        name: "material.orange.light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialOrangeLight/Compact/",
        class: "material-orange-light-compact",
        themeUrl: "MaterialOrangeLight/Compact/",
        simulator: "Web",
        title: "Orange Light Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 5,
        id: 27,
        name: "material.orange.dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialOrangeDark/Compact/",
        class: "material-orange-dark-compact",
        themeUrl: "MaterialOrangeDark/Compact/",
        simulator: "Web",
        title: "Orange Dark Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 6,
        id: 28,
        name: "material.purple.light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialPurpleLight/Compact/",
        class: "material-purple-light-compact",
        themeUrl: "MaterialPurpleLight/Compact/",
        simulator: "Web",
        title: "Purple Light Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 7,
        id: 29,
        name: "material.purple.dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialPurpleDark/Compact/",
        class: "material-purple-dark-compact",
        themeUrl: "MaterialPurpleDark/Compact/",
        simulator: "Web",
        title: "Purple Dark Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 8,
        id: 30,
        name: "material.teal.light.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialTealLight/Compact/",
        class: "material-teal-light-compact",
        themeUrl: "MaterialTealLight/Compact/",
        simulator: "Web",
        title: "Teal Light Compact",
        Group: 'Material Design Compact',
    },
    {
        groupid: 3,
        itemid: 9,
        id: 31,
        name: "material.teal.dark.compact",
        targetUrl: "/Demos/WidgetsGallery/Demo/Toolbar/Overview/Angular/MaterialTealDark/Compact/",
        class: "material-teal-dark-compact",
        themeUrl: "MaterialTealDark/Compact/",
        simulator: "Web",
        title: "Teal Dark Compact",
        Group: 'Material Design Compact',
    },
]
}*/
]


export default {
getThemes() {
return themesList;
},
};

