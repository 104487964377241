export const NovinkyTyp = [
    {
      "idtyp": 1,
      "name": "Osobní",
      "nameEN": "Personal"
    },
    {
      "idtyp": 0,
      "name": "Globální",
      "nameEN": "Global"
    }
  ];
  export default {
    getNovinkyTyp() {
      return NovinkyTyp;
    },
  };
