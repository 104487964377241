<template>
    <div>
        <DxSelectBox :disabled="Disabled" id="employee" ref="pop" :width="Width" :height="Height" :label="defaultLabel" :dropDownOptions="{ minWidth: 330 }"
                    :data-source="zamestnanci" display-expr="content" value-expr="id" :placeholder="$t('central-Placeholder')"
                    @value-changed="setSelectedValue" :search-enabled="true" @focus-in="FocusIn"
                    @input="SearchInput" :value="zamestnanec" noDataText="" class="VyrMajetkuBoxes">
                    <DxValidator v-if="Validation">
                      <DxRequiredRule :message="$t('central-PoleMusiBytVyplneno')" />
                    </DxValidator>
        </DxSelectBox>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'EmployeesComponent',
    props: {
      Disabled: {
        type: Boolean,
        required: true
      },
      EmpId: {
        type: Number,
        required: true
      },
      Label: {
        type: String,
        required: false,
      },
      Width: {
      type: String,
      required: false,
      default: 'auto'
        },
        Height: {
        type: String,
        required: false,
        default: '45px'
      },
      Validation: {
        type: Boolean,
        required: false,
        default: true
      },
      ClearOnClick: {
        type: Boolean,
        required: false,
        default: false
      },

    },
    data() {
      return {
        zamestnanci:[],
        zamestnanec: null,
        prevEmpId: null,
      };
    },
    computed: {
      defaultLabel() {
        return this.Label || this.$t('majetekPrevod-NaZamestnance');
      }
    },
    watch: {
      EmpId(newEmpId) {
        this.handleParentPropChange(newEmpId);
      }
    },
    mounted() {
      this.GetEmployees(this.EmpId)
      this.$log.debug('Mounted '+this.Disabled+' '+this.EmpId);
    },

    methods: {
      FocusIn(){
        if(this.ClearOnClick) this.zamestnanec=null
      },
      handleParentPropChange(newEmpId) {
        this.$log.debug('EmpId prop changed:', this.prevEmpId, '->', newEmpId);
       
        this.prevEmpId = newEmpId; // Update the previous value
        if(newEmpId == null) newEmpId='' 
        this.GetEmployees(newEmpId)
     //   this.zamestnanec=newEmpId
      //  this.$refs.pop.instance.getDataSource().reload(); 
      },
      SearchInput(e) {         
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetEmployees(e)                      
            }, 1000)

        },
      GetEmployees(employee) {
            if (employee.component != undefined)
            {
                var e = employee
                employee = e.component.option("text")
            }
            var gen=new window.General(window.channel,window.message);  
            let self = this;
            self.zamestnanci.length=0;
              //  window.this.$log.debug(employee)
                gen.ListEmp(employee,
                function (result) {
              //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {   
                      if (result.List.value.items[x].Id.value == 0) x++;
                        self.zamestnanci[self.zamestnanci.length]={
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                    };
                      if (x > 20) break;
                    }  
                    if(result.List.value.items.length==1 && result.List.value.items[0].Id.value !=0 ) {
                      self.zamestnanec = self.zamestnanci[0].id
                    }
                     e.component.getDataSource().reload();   
            },window.this.ApiError());    
      },
      setSelectedValue(e) {
      window.this.$log.debug(e);
      switch (e.element.id) {
        case 'employee':
          this.zamestnanec = e.value
          var valueObject = {
              value: e.value,
              element: {
                id: e.element.id
              }
            };
          this.$emit('value-changed', valueObject);
          break;
      }

    },
    },

  };
  </script>
  