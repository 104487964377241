<template>
    <div class="snowflakes" aria-hidden="true">
      <div
        v-for="n in numberOfFlakes"
        :key="n"
        class="snowflake"
        :style="getSnowflakeStyle()"
      >
        {{ getRandomSnowflakeChar() }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'XmasSnowflakes',
    props: {
      numberOfFlakes: {
        type: Number,
        default: 100,
      },
    },
    methods: {
      getRandomSnowflakeChar() {
        const snowflakeChars = ['❄', '❅', '❆', '✻', '✼', '✽'];
        return snowflakeChars[Math.floor(Math.random() * snowflakeChars.length)];
      },
      getSnowflakeStyle() {
        const size = Math.random() * 1.5 + 0.5 + 'em'; // Velikost mezi 0.5em a 2em
        const animationDuration = Math.random() * 10 + 5 + 's'; // Délka animace mezi 5s a 15s
        const animationDelay = Math.random() * -20 + 's'; // Náhodné zpoždění
        const opacity = Math.random() * 0.5 + 0.5; // Opacita mezi 0.5 a 1
        const left = Math.random() * 100 + '%'; // Náhodná pozice zleva

        const isStraightDown = Math.random() < 0.3; // 30% šance, že vločka padá rovně dolů
        const direction = isStraightDown ? 0 : (Math.random() > 0.5 ? 1 : -1); // Směr 0, 1 nebo -1
        const horizontalShift = isStraightDown ? 0 : (Math.random() * 20 + 5); // Posun mezi 5vw a 25vw

        return {
            fontSize: size,
            animationDuration,
            animationDelay,
            opacity,
            left,
            '--direction': direction,
            '--horizontalShift': horizontalShift + 'vw',
        };
        },

    },
  };
  </script>
  
  <style scoped>
  .snowflakes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 5;
  }
  
  .snowflake {
    position: absolute;
    top: -10%;
    color: white;
    animation-name: fall;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(0) translateX(0) rotate(0deg);
    }
    100% {
      transform: translateY(110vh) translateX(calc(20vw * var(--direction))) rotate(360deg);
    }
  }
  </style>
  