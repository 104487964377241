
<template height="100%">
    <HelpLink URLCZ="https://helpdesk.ekois.cz/a/solutions/articles/43000735589?lang=cs" 
  URLEN="https://helpdesk.ekois.cz/a/solutions/articles/43000735589?lang=en" 
  PopisLinku='clanky.Pozadavky'></HelpLink>
    <div id="menu">
        <!-- POPUP Obj Zásobování -->
        <DxPopup id="popup" v-model:visible="popupZmenaGrantu" :show-title="true" :drag-enabled="false" :hide-on-outside-click="false"
            :show-close-button="true" :title="$t('central-EditovatGrantObjednavky')" width="auto" height="auto">
            <div id="pop">
                <div id="inner">
                  <br>
                  <div class="child" v-if="popupZmenaGrantu">
                    <DxSelectBox  :id="5" width="300px" height="45px" :label="$t('central-Grant')"
                            :data-source="granty" display-expr="content" value-expr="id" placeholder="" 
                          :value="grantid" @value-changed="setSelectedValue($event); " class="DxSelectBoxResponsive "  :element-attr="selectBoxAttributes">
                    </DxSelectBox>
                  </div>
                  <div class="child">
                    <DxButton class="button1" id="edit" height="auto" width="auto" type="default" styling-mode="contained"
                      :text="$t('central-Potvrdit')" @click="ChangeOrderGrant()" />
                  </div>
                </div>                   
              </div>
          </DxPopup>
          
        <DxPopup id="popupobj" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="PopupType==1"  v-model:visible="popupObj" :show-title="true" :drag-enabled="true" :hide-on-outside-click="true" 
                :show-close-button="true" :title="$t('pozadavky-DetailObjednavkyZasob')+ kopieText" @hidden="PopupType = 0; urgentniDodani = false; kopieText=''" :resize-enabled="true" min-height="50%" @resize-end="handlePopupChange()"
        >         
        <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="always" ref="dxScrollViewPopupObjZasobovani" @initialized="ToFillNeededCheck('objzasobovani')"> 
            <PozadavkyBase @toggle_scroll="receiveScrollEmit"> 
            <hr class="hrPozadavky">
            <div class="hlavniObsahScrollView">
                <div class="bordersDataGridPozadavkyPopup" width="100%" >
                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                    <DxRow :ratio="0.25" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="xs" />
                        <div class="objcontent">
                            <span class="spanHlavicka"> {{$t('pozadavky-Grant:')}}
                                <span style="font-weight:lighter">
                                    {{String.fromCharCode(160)+ pozGrant }}
                                </span>
                                <DxButton v-if="GrantEdit" icon="background" id="idZmenaGrantuHlavicka"  text=""  :hint="$t('central.EditaceGrantu')" class="clObnovitRozlozeniEdit actionButtonsPozadavkyResizableA dx-button-text"
                                type="default" style="scale: 70%" styling-mode="outlined" @click="popupZmenaGrantu=true;" />
                            </span>
                        </div>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="2" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="2" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="2" :colspan="2" screen="sm" />
                        <DxLocation :row="0" :col="2" :colspan="2" screen="xs" />
                        <div class="objcontent" width="50%" height="50%">
                            <span class="spanHlavicka"> {{ $t('pozadavky-Dodavatel:')}}
                                <span style="font-weight:lighter">
                                    {{String.fromCharCode(160)+ pozDodavatel }}
                                </span>
                            </span>
                        </div>
                        <div class="child">
                  </div>

                    </DxItem>
                    <DxItem>
                        <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                        <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                        <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                        <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />
                        <div class="objcontent" v-if="urgentniDodani">
                            <span class="spanHlavicka"> {{$t('central-Dodani')}}
                                <span style="font-weight:lighter">
                                    {{String.fromCharCode(160)+ pozUrgentniDodani }}
                                </span>
                            </span>
                        </div>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="2" :col="0" :colspan="4" screen="lg" />
                        <DxLocation :row="2" :col="0" :colspan="4" screen="md" />
                        <DxLocation :row="2" :col="0" :colspan="4" screen="sm" />
                        <DxLocation :row="2" :col="0" :colspan="4" screen="xs" />
                        <hr class="PopupDetailDeliciHR">
                        <div id="DataGrid8" width="100%">
                        <DxDataGrid ref="mygridpopupobj"  :height="(vyskaDataGridPopup/2)"  width="100%" column-resizing-mode="widget" :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true" 
                            :show-borders="true" :hoverStateEnabled="true" :rowAlternationEnabled="true" id="DataGrid8detail" :data-source="GridPolozky" 
                             key-expr="Id" :noDataText="$t('central-NenalezenyZadneZaznamy')" @exporting="onExporting">
                            <DxLoadPanel :enabled="true" :text="$t('central-Loading')" />
                            <DxStateStoring
                            :enabled="true"
                            type="localStorage"
                            storage-key="GridPozadavkyObjZas"
                            />
                            <DxExport
                            :enabled="true"
                            :formats="['xlsx']"
                            :allow-export-selected-data="false"
                            :texts="{exportAll: $t('central-ExportVseExcel')}"
                            /> 
                            <DxToolbar >
                                <DxItem
                                name="groupPanel"  location="before" locate-in-menu="auto" 
                                />
                                <DxItem :disabled="DisableCopy"  locate-in-menu="auto" location="after" widget="dxButton" @click="this.DisableCopy = true; CopyRequest();" width="auto">
                                            <DxButton  icon="add" :text="$t('pozadavky-ZalozitNovyPozadavekPodle')" type="default" height="auto" width="auto" styling-mode="outlined" style="scale: 85%;color: inherit; border-color: gray;" 
                                     />      
                                    </DxItem>
                                
                                <DxItem   location="after" locate-in-menu="auto"  widget="dxButton" @click="GridLayoutReset('supply')" width="auto">
                                    <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                    type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('supply')" />      
                                </DxItem>
                                
                                    <DxItem  location="after" locate-in-menu="auto" 
                                            name="exportButton"
                                            />
                                            <DxItem
                                name="searchPanel"  location="after" locate-in-menu="auto" 
                                :visible="true"
                                />
                            </DxToolbar>

                            <DxColumn data-field="Id" :allow-editing="false" :visible="false" :formItem="{ visible: false }" />
                            <DxColumn data-field="description"   :caption="$t('central-Popis')">
                            </DxColumn>
                            <DxColumn data-field="ProductNumber" :column-resizing="true" :min-width="50" :caption="$t('central-KatC')">
                            </DxColumn>
                            <DxColumn data-field="typ" :column-resizing="true" :min-width="50" :caption="$t('central-Zakazka')">
                            </DxColumn>
                            <DxColumn data-field="specifikace" :column-resizing="true" :min-width="50" :caption="$t('central-Specifikace')" placeholder="">
                            </DxColumn>
                            <DxColumn data-field="jednotka" :column-resizing="true" :min-width="50" :caption="$t('central-Jednotka2')">
                            </DxColumn>
                            <DxColumn format=",##0.00" :column-resizing="true" :min-width="50" data-field="pocet" :caption="$t('central-Pocet')">
                            </DxColumn>
                            <DxColumn data-field="mena" :column-resizing="true" :min-width="50" :caption="$t('central-Mena')" placeholder="">
                            </DxColumn>
                            <DxColumn format=",##0.00" :min-width="50" data-field="cena" :caption="$t('central-OdhadCenaZaJednotku')">
        
                            </DxColumn>
                            <DxColumn data-field="AssetNumber" :min-width="50" :caption="$t('central-KMajetkuCislo')">
                            </DxColumn>
                            <DxColumn format=",##0.00" :min-width="50" data-field="celkem" :allow-editing="false" :caption="$t('central-KonecnaCena')" />
                            <DxColumn data-field="specid" :min-width="50" :allow-editing="false" :visible="false"
                                :formItem="{ visible: false }" />
                            <DxColumn data-field="menaid" :min-width="50" :allow-editing="false" :visible="false"
                                :formItem="{ visible: false }" />
                            <DxColumn data-field="OrderNumber" :column-resizing="true" :min-width="50" :caption="$t('Pozadavky.CisloObjednavky')" alignment="right">
                            </DxColumn>
                            <DxColumn data-field="DeliveryNumber" :column-resizing="true" :min-width="50" :caption="$t('Pozadavky.CisloDodaci')" alignment="right">
                            </DxColumn>
                            <DxColumn data-field="LiquidationNumber" :column-resizing="true" :min-width="300" :caption="$t('Pozadavky.CisloFaktury')" cell-template="cellTemp_Liquidation" alignment="right">
                            </DxColumn>
                                <template #cellTemp_Liquidation="{ data:cellInfo}">
                                    <div v-if="cellInfo.data.LiquidationCanDownloadFile==true">
                                        <DxButton class="btnCellTemp_Liquidation" @click="PrilohyClick(cellInfo.data.LiquidationNumberID)"  icon="download"  :text="cellInfo.value"/>
                                    </div>
                                    <div v-else>
                                     {{cellInfo.value}}   
                                    </div>   
                                </template>
                            
                            <DxColumn data-field="InStock" :column-resizing="true" :min-width="50" :caption="$t('Pozadavky.Skladem')" cell-template="cellTemp_InStock" alignment="center">
                            </DxColumn>
                                <template #cellTemp_InStock="{ data: cellInfo }">
                                    <DxCheckBox :value="cellInfo.value" class="centralOutlinedBox" :read-only="true" />
                                </template>
                            <DxColumn data-field="Link" :column-resizing="true" :min-width="150" :caption="$t('Pozadavky.Odkaz')" alignment="left" cell-template="cellTemp_Link">
                            </DxColumn>
                            <template #cellTemp_Link="{ data: cellInfo  }">
                                <a class="clObjOdkaz" :href="cellInfo.value != null && cellInfo.value.startsWith('http') ? cellInfo.value : `https://${cellInfo.value}`" target="_blank" rel="noopener noreferrer"> 
                                   {{ cellInfo.value }}
                                </a>
                            </template>

                            <DxSummary id="idSummary">
                                <DxTotalItem summary-type="count" showInColumn="description"
                                    :display-format="$t('central-PolozekCelkem')+' {0}'" cssClass="polozekCelkem"  />
                                    <DxTotalItem column="pocet" summary-type="sum" showInColumn="pocet"
                                        :display-format="'‎ '" cssClass="sum" />
                                <DxTotalItem column="celkem" summary-type="sum" value-format=",##0.00"
                                    :display-format="$t('central-KonecnaCenaCelkem')+' {0}'" cssClass="cenaCelkem" alignment="right" />
                            </DxSummary>                                
                            <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" />
                            <DxPaging :enabled="false" />
                            <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                            <DxGrouping :auto-expand-all="true" />
                            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                            <DxSearchPanel :width="150" :visible="true" :placeholder="$t('central-Hledat')" />
                        </DxDataGrid>
                        </div>
                    
        
                    </DxItem>
                </DxResponsiveBox>
            </div>

            </div>
            </PozadavkyBase>
            <DxPopup width="25%" height="auto" v-model:visible="popupPrilohyFaktury" :show-title="true" :drag-enabled="false" @hiding="HidingPopupPrilohy" 
        :hide-on-outside-click="true" :show-close-button="true" :title="$t('granty-PrilozeneFaktury')"> 
        <DxScrollView height="auto" direction="vertical" showScrollbar="onScroll">
          <div v-if="this.PrilohyID!=0">
            <Attachments :Disabled="false" :Pozid="this.PrilohyID" :ListFilesfileType="2" :enabledUploadOfFiles="false" :enabledFilesDelete="false" />
         <!--   
          <div class="soubory" v-for="item in Soubory" :key="item">
              <DxButton id="download" icon="download" text="" @click="GetHash(item.fileName, item.fileID)" />
              <a href="#" id={{item.id}} @click="GetHash(item.fileName, item.fileID)">{{ item.fileName }}
              </a>&nbsp;({{ item.fileSizeTXT }}) - {{ $t('granty-NascanovanyDne') }} {{ item.fileDate.toLocaleDateString()
              }}
              <br>
              <br>
            </div>
            -->
          </div>
        </DxScrollView>
      </DxPopup>
            </DxScrollView>
            <!--#endregion-->

        </DxPopup>
            <!-- POPUP Knihovna -->
                <DxPopup id="popupobjknihovna" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType==4)" v-model:visible="popupObjKnihovna" :show-title="true"
                    :drag-enabled="true" :hide-on-outside-click="true" :show-close-button="true" title="Detail objednávky (knihovna)" @hidden="PopupType=0"        :resize-enabled="true" min-height="50%" @resize-end="handlePopupChange()"  >
                    <!--#region Knihovna Objednavka OBSAH-->
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="always" ref="dxScrollViewPopupObjKnihovna" @initialized="ToFillNeededCheck('objknihovna')">

                        <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                        <hr class="hrPozadavky">
                        <div class="hlavniObsahScrollView">
                            <div class="bordersDataGridPozadavkyPopup " >
                            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="0.1" />
                                <DxItem>
                                    <DxLocation :row="0" :col="0" :colspan="4" screen="lg" />
                                    <DxLocation :row="0" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="0" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="0" :col="0" :colspan="4" screen="xs" />
                                    <div class="objcontent">
                                        <span class="spanHlavicka" style="margin-top:0">{{$t('pozadavky-Grant:')}}
                                            <span style="font-weight:lighter">
                                                {{String.fromCharCode(160)+ pozGrant }}
                                            </span>
                                            <DxButton v-if="GrantEdit" icon="background" id="idZmenaGrantuHlavicka"  text=""  :hint="$t('central.EditaceGrantu')" class="clObnovitRozlozeniEdit actionButtonsPozadavkyResizableA dx-button-text"
                                            type="default" style="scale: 70%" styling-mode="outlined" @click="popupZmenaGrantu=true;" />
                                        </span>
                                    </div>
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="1" :col="0" :colspan="4" screen="lg" />
                                    <DxLocation :row="1" :col="0" :colspan="4" screen="md" />
                                    <DxLocation :row="1" :col="0" :colspan="4" screen="sm" />
                                    <DxLocation :row="1" :col="0" :colspan="4" screen="xs" />
                                    <hr class="PopupDetailDeliciHR">
                                    <div  id="DataGrid9" width="100%">
                                    <DxDataGrid ref="mygridpopup" :height="(vyskaDataGridPopup/2)" width="100%" column-resizing-mode="widget" 
                                    :allow-column-reordering="true" :column-auto-width="true"
                                    :allow-column-resizing="true" :hoverStateEnabled="true" :rowAlternationEnabled="true"
                                        id="DataGrid9detail" :data-source="GridPolozky" @exporting="onExporting"
                                        :show-borders="true" key-expr="Id" :noDataText="$t('central-NenalezenyZadneZaznamy')">
                                        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                                        <DxStateStoring
                                        :enabled="true"
                                        type="localStorage"
                                        storage-key="GridPozadavkyObjKnihovna"
                                        />
                                        <DxExport
                                        :enabled="true"
                                        :formats="['xlsx']"
                                        :allow-export-selected-data="false"
                                        :texts="{exportAll: $t('central-ExportVseExcel')}"
                                        />
                                        <DxToolbar >
                                            <DxItem
                                            name="groupPanel" location="before" locate-in-menu="auto" 
                                            />

                                            
                                            <DxItem  location="after" locate-in-menu="auto" widget="dxButton" @click="GridLayoutReset('library')"> 
                                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                                type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('library')" />      
                                            </DxItem>
                                            <DxItem
                                            name="exportButton"
                                            location="after" locate-in-menu="auto" 
                                            />
                                            <DxItem
                                            name="searchPanel" location="after" locate-in-menu="auto" 
                                            :visible="true"
                                            />
                                        </DxToolbar>        
                                        <DxColumn width="60px" data-field="Id" :allow-editing="false" :visible="false" :formItem="{ visible: false }" />   
                                        <DxColumn data-field="description" :caption="$t('central-Titul')">     
                                        </DxColumn>
                                        <DxColumn data-field="isbn" :caption=" $t('central-ISBN/ISSN') " width="70px" :visible=" true " />
                                        <DxColumn data-field="url" width="60px" :caption=" $t('URL') "> </DxColumn>
                                        <DxColumn format=",##0.00" data-field="pocet" :caption="$t('central-Pocet')" width="70px">
                                        </DxColumn>
                                        <DxColumn format=",##0.00" width="130px" data-field="cena" :caption="$t('central-Cena/Jednotka')">
                                        </DxColumn>
                                        <DxColumn data-field="mena" width="60px" :caption="$t('central-Mena')" placeholder="">
                                        </DxColumn>
                                        <DxColumn format=",##0.00" width="130px" data-field="celkem" :caption="$t('central-Celkem')">
                                        </DxColumn>
                                        
                                        <DxColumn data-field="typ" width="120px" :caption=" $t('central-TypDokumentu') " >
                                        </DxColumn>  <!-- + -->  <!-- + -->
                                        <DxColumn data-field="deponovano" width="120px" :caption=" $t('central-DeponovanoU:') "  >
                                        </DxColumn>
                                        <DxColumn data-field="forma" width="70px" :caption=" $t('central-Forma') ">  <!-- + --></DxColumn>
                                        <DxSummary width="100%">
                                        <DxTotalItem summary-type="count" showInColumn="description"
                                            :display-format=" $t('central-PolozekCelkem') + ' {0}' " cssClass="polozekCelkem" />
                                            <DxTotalItem column="pocet" summary-type="sum" showInColumn="pocet"
                                                :display-format=" '‎ ' " cssClass="sum" />
                                        <DxTotalItem column="celkem" summary-type="sum" value-format=",##0.00"
                                            :display-format=" $t('central-SumaCelkem') + ' {0}' " cssClass="cenaCelkem" alignment="center" />
                                        </DxSummary>      
                                        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form"/>
                                        <DxPaging :enabled="false" />
                                        <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                                        <DxGrouping :auto-expand-all="true" />
                                        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                                        <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                                    </DxDataGrid>
                                </div>
                                    
                                    
                                </DxItem>
                            </DxResponsiveBox>
                        </div>
                        </div>
                        </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->      
                </DxPopup>
            <!-- POPUP FAKTURA -->
                <DxPopup id="popupFaktura" @hiding="TableDataPozadavky(); addParamsToLocation({}) " v-if="(PopupType==11)" v-model:visible="popupFaktura" :show-title="true" :drag-enabled="true" :hide-on-outside-click="true"
                    :show-close-button="true" :title="$t('central-DetailFaktury')" @hidden="PopupType=0" :resize-enabled="true">        
                    <!-- #region Faktura OBSAH -->
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="onHover" ref="dxScrollViewPopupFaktura" @initialized="ToFillNeededCheck('faktura')">
                        <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                        <hr class="hrPozadavky">
                        <div class="bordersDataGridPozadavky hlavniObsahScrollView" >
                            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="1.25" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />

                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pozadavky-DatumSplatnosti:') }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(fakDatumSpl)" >
                                                {{ returnDateFormatFromString(fakDatumSpl) }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pozadavky-DatumVystaveni:') }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(fakDatumVys)">
                                                {{ returnDateFormatFromString(fakDatumVys) }}
                                            </div> 
                                        </DxItem>                               
                                        <DxItem >
                                            <DxLocation :row="9" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="9" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="9" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="9" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta" v-show="fakCenaFinalniCZK!=0" > <!--v-show="fakMena!='CZK'"-->
                                                <span class="spanPracovniCestaBold">{{ $t('Pozadavky.FinalniCena')+":"+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="9" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="9" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="9" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="9" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-show="fakCenaFinalniCZK!=0" v-bind:title="fakCenaFinalniCZK"> <!--v-show="fakMena!='CZK'"-->
                                                {{ fakCenaFinalniCZK }}    
                                            </div> 
                                        </DxItem>             
                                    <DxItem >
                                            <DxLocation :row="12" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="12" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="12" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="12" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta" v-show="fakCastkaSchvalovana!='0,00'">
                                                <span class="spanPracovniCestaBold">{{ $t('Pozadavky.SchvalovanaCastka')+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="12" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="12" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="12" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="12" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-show="fakCastkaSchvalovana!='0,00'" v-bind:title="fakCastkaSchvalovana">
                                                <span id="invoiceApproveAmount">{{ fakCastkaSchvalovana }}</span>    
                                            </div> 
                                        </DxItem>
                                        <DxItem >
                                            <DxLocation :row="13" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="13" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="13" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="13" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaLast">
                                                <span class="spanPracovniCestaBold">{{ $t('pozadavky-Dodavatel:')+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="13" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="13" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="13" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="13" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild dxItemPracovniCestaLast" v-bind:title="fakDodavatel">
                                                {{ fakDodavatel }}    
                                            </div> 
                                        </DxItem> 
                                        

                            </DxResponsiveBox>
                            <DxButton width="100%" id="btnZobrazitPolozky" class='cursPointer' @click="zobrazeniPolozky=!zobrazeniPolozky"><span id="textZobrazitButton"><img class="prevent-select" id="imgZobrazitPolozky" :src="getIkonaZobrazitPolozky()" width="20" height="20"
                                alt=""><label id=lblZobrazitButton class='cursPointer'>{{TextZobrazitPolozky+' '+TextFakturaPocetPolozek}}</label><img class="prevent-select" id="imgZobrazitPolozky" :src="getIkonaZobrazitPolozky()" width="20" height="20"
                                alt=""></span></DxButton>
                            <span v-if="!zobrazeniPolozky"></span>
                            <div class="bordersDataGridPozadavky" id="divDataGridInvoice" v-if="zobrazeniPolozky"  @click="zobrazeniPolozky=true">
                                    <DxDataGrid ref="mygridpopupinvoice" :height="(vyskaDataGridPopup/2)-10" width="100%" column-resizing-mode="widget" 
                                        :allow-column-reordering="true" :column-auto-width="true"
                                        :allow-column-resizing="true" :hoverStateEnabled="true" :rowAlternationEnabled="false" @content-ready="handlePopupChange(); ShowOnlyMyRowsInDatagrid('mygridpopupinvoice')"
                                            id="DataGridInvoicedetail" :data-source="GridPolozky" @exporting="onExporting"
                                            :show-borders="true" :noDataText="$t('central-NenalezenyZadneZaznamy')" @row-prepared="RowsInvoiceCreated">
                                            <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                                            <DxStateStoring
                                            :enabled="true"
                                            type="localStorage"
                                            storage-key="GridPozadavkyFaktura"
                                            />
                                            <DxExport
                                            :enabled="true"
                                            :formats="['xlsx']"
                                            :allow-export-selected-data="false"
                                            :texts="{exportAll: $t('central-ExportVseExcel')}"
                                            />
                                            <DxToolbar >
                                                <DxItem
                                                name="groupPanel" location="before" locate-in-menu="auto" 
                                                />

                                                
                                                <DxItem  location="after" locate-in-menu="auto" widget="dxButton" @click="GridLayoutReset('faktura')"> 
                                                    <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                                    type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('faktura')" />      
                                                </DxItem>
                                                <DxItem
                                                name="exportButton"
                                                location="after" locate-in-menu="auto" 
                                                />
                                                <DxItem
                                                name="searchPanel" location="after" locate-in-menu="auto" 
                                                :visible="true"
                                                />
                                            </DxToolbar>        
                                            <DxColumn data-field="Zdroj" :caption="$t('granty-Zdroj')" />   
                                            <DxColumn data-field="Popis" :caption="$t('central-Popis')" width='400' />
                                            <DxColumn format=",##0.00" data-field="Pocet" :caption="$t('central-Pocet')" width="auto" />
                                            <DxColumn data-field="Jednotka" :caption="$t('central-Jednotka')" />     
                                            <DxColumn format=",##0.00" data-field="Castka" :caption="$t('central.CenaVKc')" width="auto" />
                                            <DxColumn format=",##0.00" data-field="CastkaOdhad" :caption="$t('central.CenaOdhad')" width="auto" alignment="right"/>
                                            <DxColumn data-field="CastkaOdhadMena" :caption="$t('central.Mena')" width="auto" alignment="left"/>
                                            <DxColumn data-field="Komentar" :caption="$t('central-Komentar')" />
                                            <DxColumn data-field="KMemuVyjadreni" caption="" width="auto" :visible="false" sort-order="desc" :allow-sorting="true" />     
                                            <DxPaging :enabled="false" />
                                            <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                                            <DxGrouping :auto-expand-all="true" />
                                            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                                            <DxSearchPanel :width="150" :visible="true" :placeholder="$t('central-Hledat')" />
                                    </DxDataGrid>
                                </div>
                            </div>
                        </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->
                </DxPopup>

            <!-- POPUP Vyvojové Dílny -->
                <DxPopup id="popupVD" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType==6)" v-model:visible="popupVD" :show-title="true" :drag-enabled="true" :hide-on-outside-click="true"
                    :show-close-button="true" :title="$t('pozadavky-DetailPozadavkuDoVyvojDilny')" @hidden="PopupType=0"        :resize-enabled="true" > 
                    <!-- #region Vývojové dílny OBSAH -->       
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="onHover" ref="dxScrollViewPopupVyvojDilny" @initialized="ToFillNeededCheck('vyvojdilny')" >
                        
                        <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                        <hr  class="hrPozadavky">
                        <div id="DivPopupVD" class="bordersDataGridPozadavkyPopup hlavniObsahScrollView">                 
                            <div class="container" style="display: flex; align-items: center;">
                                <span class="spanHlavicka" style="margin-top: 0; margin-bottom: 0px; height: auto;">{{$t('pozadavky-Grant:')}} 
                                    <span style="font-weight: lighter;">{{String.fromCharCode(160) + production.grant}}</span> 
                                    <DxButton v-if="GrantEdit" icon="background" height="20px" id="idZmenaGrantuHlavickaProduction" text="" :hint="$t('central.EditaceGrantu')" type="default" styling-mode="outlined" @click="popupZmenaGrantu=true;" />
                                </span>
                            </div>
                            <span class="spanHlavicka"> {{$t('pozadavky-Popis:')}}
                                <span style="font-weight:lighter;">
                                    {{ String.fromCharCode(160)+production.popis }}
                                </span>
                            </span>
                            <span class="spanHlavicka"> {{$t('requestVyvojoveDilny-Oprava') +':'}}
                                    <DxCheckBox style="margin-left: 5px; border-color: #333;" id="checkbox1" :read-only="true" v-model:value="production.oprava"/>
                            </span>
                            <span v-if="production.oprava" class="spanHlavicka"> {{$t('pozadavky-Majetek:')}}
                                <span style="font-weight:lighter;">
                                    {{ String.fromCharCode(160)+production.invc }}
                                </span>
                            </span>
                        </div>
                        </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->
                </DxPopup>

            <!-- POPUP Převod Majetku -->
                <DxPopup id="popupPrevod" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType==2)" v-model:visible="popupPrevod" :show-title="true" :drag-enabled="true" :hide-on-outside-click="true"
                    :show-close-button="true" :title="$t('pozadavky-DetailPrevoduMajetku')" @hidden="PopupType=0"    :resize-enabled="true" min-height="50%" @resize-end="handlePopupChange()">         
                    <!-- #region Prevod Majetku OBSAH -->
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="always" ref="dxScrollViewPopupPrevodMajetku" @initialized="ToFillNeededCheck('prevodmajetku')"> 
                        <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                        <hr class="hrPozadavky">
                        <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView" >          
                        <div>                      
                            <span class="spanHlavicka" style="margin-top:0"> {{ $t('pozadavky-PrevodNa:') }}
                                <span style="font-weight:lighter;">
                                    {{ String.fromCharCode(160)+prevodNa }}
                                </span>
                            </span>
                            
                        </div>
                        <div id="DataGridPrevod" width="100%">
                        <!--<h3 style="font-weight:lighter;  border-style: solid; border-color: #000000; border-top: 0; border-left: 0; border-right: 0; border-width: 1px; width: max-content;">  {{ $t('pozadavky-PrevadenyMajetek:') }}</h3>-->
                        <DxDataGrid ref="mygridpopupprevod" width="100%" :height="(vyskaDataGridPopup/2)" id="DataGridPrevoddetail" column-resizing-mode="widget" :allow-column-reordering="true" :allow-column-resizing="true" :column-auto-width="true"   :hoverStateEnabled="true" :rowAlternationEnabled="true"
                            :data-source="majetekList" :show-borders="true" key-expr="id"
                            :noDataText="$t('central-NenalezenyZadneZaznamy')" @exporting="onExporting"
                            :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}" > 
                            <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                            <DxStateStoring
                                :enabled="true"
                                type="localStorage"
                                storage-key="GridPozadavkyPrevod"
                                />              
                                <DxExport
                                :enabled="true"
                                :formats="['xlsx']"
                                :allow-export-selected-data="false"
                                :texts="{exportAll: $t('central-ExportVseExcel')}"
                                />
                                <DxToolbar >
                                            <DxItem
                                            name="groupPanel"  location="before" locate-in-menu="auto" 
                                            />


                                            
                                            <DxItem  location="after" locate-in-menu="auto"  widget="dxButton" @click="GridLayoutReset('transfer')">
                                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                                type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('transfer')" />      
                                            </DxItem>
                                            <DxItem
                                            name="exportButton"
                                            location="after" locate-in-menu="auto" 
                                            />
                                            <DxItem
                                            name="searchPanel"  location="after" locate-in-menu="auto" 
                                            :visible="true"
                                            />
                                </DxToolbar>     
                            <DxColumn data-field="id" :caption="$t('pozadavky-InvC')" :allow-editing="false" :visible="true"
                                :formItem="{ visible: false }" />
                            <DxColumn data-field="name" :caption="$t('pozadavky-Nazev')">
                            </DxColumn>
                            <DxColumn data-field="date" :caption="$t('pozadavky-Zarazeni')" format="dd. MM. yyyy">
                            </DxColumn>
                            <DxColumn format=",##0.00" data-field="price" :caption="$t('pozadavky-PorizovaciCena')">
                            </DxColumn>
                            <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form"/>
                            <DxPaging :enabled="false" />
                            <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                            <DxGrouping :auto-expand-all="true" />
                            <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                            <DxSearchPanel :width="300" :visible="true" :placeholder="$t('central-Hledat')" />
                        </DxDataGrid>
                        </div>
                        </div>
                        </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->
                </DxPopup>

            <!-- POPUP Vyřazení Majetku -->
                <DxPopup id="popupVyrazeni" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType==3)" v-model:visible="popupVyrazeni" :show-title="true" :drag-enabled="true"
                    :hide-on-outside-click="true" :show-close-button="true" :title="$t('pozadavky-DetailVyrazeniMajetku')" @hidden="PopupType=0"        :resize-enabled="true"   min-height="50%" @resize-end="handlePopupChange()"
            >      <!-- #region Vyřazení majetku OBSAH -->
                        <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="always" ref="dxScrollViewPopupVyrazeniMajetku" @initialized="ToFillNeededCheck('vyrazenimajetku')">
                            <PozadavkyBase @toggle_scroll="receiveScrollEmit"> 
                            <hr class="hrPozadavky">
                            <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView">                  
                            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxCol :ratio="0.5" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                
                                <DxItem>
                                    <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                    <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                    <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="1" :col="0" :colspan="3" screen="xs" />                                                       
                                        <span class="pozcontent spanHlavicka">
                                        <span> {{ $t('pozadavky-Duvod') }}
                                            <span style="font-weight:lighter">
                                                {{':'+String.fromCharCode(160)+ vyrDuvod }}
                                            </span>
                                        </span>
                                    </span>  
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                    <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                                    <span class="pozcontent spanHlavicka">
                                        <span> {{ $t('pozadavky-Zpusob:') }}
                                            <span style="font-weight:lighter">
                                                {{ vyrZpusob }}
                                            </span>
                                        </span>
                                    </span>                                                             
                                </DxItem>
                                <DxItem>
                                    <DxLocation :row="1" :col="0" :colspan="3" screen="lg" />
                                    <DxLocation :row="1" :col="0" :colspan="3" screen="sm" />
                                    <!--<h3> {{ $t('pozadavky-VyrazovanyMajetek:') }} </h3>-->
                                    <hr class="PopupDetailDeliciHR">
                                    <div  id="DataGrid5" width="100%">
                                    <DxDataGrid ref="mygridPopUpVyrazeni" width="100%" height="auto" id="DataGrid5detail" :allow-column-resizing="true" :column-min-width="100" :hoverStateEnabled="true" :rowAlternationEnabled="true"
                                        :data-source="majetekList" :allow-column-reordering="true" :show-borders="true" key-expr="id" column-resizing-mode="widget" :column-auto-width="true"  @content-ready="handlePopupChange()"
                                        :noDataText="$t('central-NenalezenyZadneZaznamy')" :editing-texts="{ saveRowChanges: '*SAVE*', cancelRowChanges: '*CANCEL*',}">
                                        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                                        <DxStateStoring
                                        :enabled="true"
                                        type="localStorage"
                                        storage-key="GridPozadavkyVyrazeni"
                                        />
                                        <DxToolbar >
                                            <DxItem
                                            name="groupPanel" locate-in-menu="auto" 
                                            />
                                            
                                            <DxItem  location="after" locate-in-menu="auto"  widget="dxButton" @click="GridLayoutReset('dispose')">
                                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                                type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('dispose')" />      
                                            </DxItem>
                                            <DxItem
                                            name="exportButton"  location="after" locate-in-menu="auto" 
                                            />
                                            <DxItem
                                            name="searchPanel" location="after" locate-in-menu="auto" 
                                            :visible="true"
                                            />
                                        </DxToolbar>   
                                        <DxColumn data-field="id" :caption="$t('pozadavky-InvC')" :allow-editing="false" :visible="true"
                                            :formItem="{ visible: false }" />
                                        <DxColumn data-field="name" :caption="$t('pozadavky-Nazev')">
                                        </DxColumn>
                                        <DxColumn data-field="date" :caption="$t('pozadavky-Zarazeni')" format="dd. MM. yyyy">
                                        </DxColumn>
                                        <DxColumn format=",##0.00" data-field="price" :caption="$t('pozadavky-PorizovaciCena')">
                                        </DxColumn>
                                        <DxEditing :allow-updating="false" :allow-deleting="false" :allow-adding="false" mode="form" />
                                        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                                    </DxDataGrid>
                                    </div>
                                </DxItem>
                            </DxResponsiveBox>
                            </div>
                            </PozadavkyBase>
                        </DxScrollView>
                        <!--#endregion-->
                </DxPopup>

            <!-- POPUP SMLOUVA -->
                <DxPopup id="popupSmlouva" @hiding="TableDataPozadavky(); addParamsToLocation({}) " v-if=" (PopupType == 12) " v-model:visible=" popupSmlouva " 
                :show-title=" true " :drag-enabled="true" :hide-on-outside-click=" true "
                    :show-close-button=" true " :title=" $t('central-DetailSmlouvy') " @hidden=" PopupType = 0 " :resize-enabled="true"   
        >        <!-- #region Smlouva OBSAH -->
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="onHover" ref="dxScrollViewPopupSmlouva" @initialized="ToFillNeededCheck('smlouva')">
                        <PozadavkyBase @toggle_scroll="receiveScrollEmit" PopisPrilohKey="Smlouvy.PrilohyBezPodpisu">
                        <hr class="hrPozadavky">
                            <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView"  >
                            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="0.65" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-Referent') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.referentTXT">
                                                {{ Smlouva.referentTXT }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-NazevSmlouvy') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.nazev">
                                                {{ Smlouva.nazev }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-Protistrana') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.protistranaTXT ">
                                                {{ Smlouva.protistranaTXT }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-PredmetSmlouvy') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.predmet">
                                                {{ Smlouva.predmet }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-FinancniPlneni') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.cena.toLocaleString('cs-CZ', {minimumFractionDigits: 2}) + ' Kč'">
                                                {{ Smlouva.cena.toLocaleString("cs-CZ", {minimumFractionDigits: 2})+' Kč' }}    
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-TypSmlouvy') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.typSmlouvyTXT">
                                                {{ Smlouva.typSmlouvyTXT }}   
                                            </div> 
                                        </DxItem>
                        </DxResponsiveBox>
                        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="1.25" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        

                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-Podpis') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="this.resultBool(Smlouva.zverejnit)">
                                                {{ this.resultBool(Smlouva.zverejnit) }}   
                                            </div> 
                                        </DxItem>
                                        <DxItem >
                                                <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                                <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                                <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                                <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                                <div class="dxItemPracovniCesta">
                                                    <span class="spanPracovniCestaBold">{{ $t('Smlouvy-FyzickyPodpis') + ':' + String.fromCharCode(160) }}</span>
                                                </div> 
                                            </DxItem>
                                            <DxItem>
                                                <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                                <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                                <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                                <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                                <div class="dxItemPracovniCesta dxItemSpecialLayout dxItemPracovniCestaChild" v-bind:title="this.resultBool(Smlouva.podpis) +String.fromCharCode(160)+'→'+String.fromCharCode(160)+this.textSmlouvaReditelPodpisDodatek(Smlouva.podpis)">
                                                    <div>{{this.resultBool(Smlouva.podpis) +String.fromCharCode(160)}}<i>→{{String.fromCharCode(160)+this.textSmlouvaReditelPodpisDodatek(Smlouva.podpis)}}</i></div>
                                                    <div v-if="Smlouva.podpis==false" class="ToBeSignedAttachments emphasisDiv">
                                                        <Attachments  :Pozid="this.pozId" :FileUploadFileType="5" :ListFilesfileType="5" :FileDeleteFileType="5" LabelHeaderWordKey="Smlouvy.PrilohyKPodpisu" :enabledFilesDelete="false" :enabledUploadOfFiles="false" />
                                                    </div>   
                                                </div>
                                            </DxItem>
                                            <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('Smlouvy-Cjednaci') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="Smlouva.cjednaci">
                                                {{ Smlouva.cjednaci }}
                                            </div> 
                                        </DxItem>
                        </DxResponsiveBox>
                        </div>
                        <!--
                        <hr>
                        <div class="bordersDataGridPozadavky"  >
                            <h3> {{ $t('Smlouvy-Referent')+':' }} <span style="font-weight:lighter"> {{ Smlouva.referentTXT }} </span> </h3>
                            <h3> {{ $t('Smlouvy-NazevSmlouvy')+':' }} <span style="font-weight:lighter">{{ Smlouva.nazev }} </span> </h3>
                            <h3> {{ $t('Smlouvy-Protistrana')+':' }} <span style="font-weight:lighter"> {{ Smlouva.protistranaTXT }} </span> </h3>
                            <h3> {{ $t('Smlouvy-PredmetSmlouvy')+':' }} <span style="font-weight:lighter"> {{ Smlouva.predmet }} </span> </h3>
                            <h3> {{ $t('Smlouvy-FinancniPlneni')+':' }} <span style="font-weight:lighter"> {{ Smlouva.cena.toLocaleString("cs-CZ", {
                                minimumFractionDigits: 2
                            })+' Kč' }} </span> </h3>
                            <h3> {{ $t('Smlouvy-TypSmlouvy')+':' }} <span style="font-weight:lighter"> {{ Smlouva.typSmlouvyTXT }} </span> </h3>
                            <h3>
                                <DxCheckBox style="padding: 5px;" :read-only="true" height="45px" :value=" Smlouva.zverejnit "  />
                                    {{ $t('Smlouvy-Podpis') }} </h3>
                        </div>
                        -->
                        </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->
                </DxPopup>

            <!-- POPUP Pracovní Cesta -->
                    <DxPopup id="popupPracCesta" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType == 13)" v-model:visible="popupPracCesta" 
                    :show-title="true" :drag-enabled="false" :hide-on-outside-click="true"
                        :show-close-button="true" :title="$t('central-DetailPozadavkuPracCesta')" @hidden=" PopupType = 0" >
                        <!-- #region Pracovni Cesta OBSAH -->        
                        <DxScrollView class="dxScrollViewPozadavky dxCustomScrollViewPaddingNegativeTop" direction="vertical" showScrollbar="onHover" ref="dxScrollViewPopupPracCesta" @initialized="ToFillNeededCheck('praccesta')">
                            <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                            <hr class="hrPozadavky">
                               <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView"> 
                                    <div class=" clPozPracCesta divDoplnenaPole" v-if="(this.cis_duvod_potrebyTXT!='') && (this.cis_duvod_potrebyTXT!=undefined)"> <!--DOPLNENA POLE-->
                                        <span > {{ $t('Pozadavky.DoplnenaPoleVPrubehu')}}</span>
                                        
                                        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
                                            <DxRow :ratio="1" />
                                            <DxCol :ratio="1.25" />
                                            <DxCol :ratio="1" />
                                            <DxCol :ratio="1" />
                                                <DxItem >
                                                    <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                                    <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                                    <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                                    <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                                    <div class="dxItemPracovniCesta">
                                                        <span class="spanPracovniCestaBold">{{ $t('pracCesta.UcelPouzitiNazev') + ':' }} </span>
                                                    </div> 
                                                </DxItem>
                                                <DxItem>
                                                    <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                                    <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                                    <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                                    <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                                    <div class="dxItemPracovniCesta dxItemPracovniCestaChild clDoplnenaPoleChild2 " v-bind:title="formDataCarUsage.cis_pouzitiTXT" >
                                                        {{formDataCarUsage.cis_pouzitiTXT }} 
                                                    </div> 
                                                </DxItem>
                                        </DxResponsiveBox>
                                        <br>
                                    </div>
                                
                                <!-- #region Pracovni Cesta Detailní OBSAH -->
                                <div class="clPozPracCesta displayBox" >
                                <span class="clNadpisCesta"> {{ $t('pracCesta.InformaceCesta')}}</span>
                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="0.65" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.Cil') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.cil">
                                                {{ formDataCarUsage.cil }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.Ucel') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.ucel_cesty">
                                                {{ formDataCarUsage.ucel_cesty }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.OdhadKm') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formatStringWithSpaces(formDataCarUsage.odhad_cesta_km) + ' km'">
                                                {{formatStringWithSpaces(formDataCarUsage.odhad_cesta_km) + ' km' }} <!---->
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.DuvodPotreby') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="IsOtherReason() ? formDataCarUsage.cis_duvod_potrebyTXT + ':' + String.fromCharCode(160) + formDataCarUsage.jinyDuvodTXT : formDataCarUsage.cis_duvod_potrebyTXT">
                                                <span v-if="this.IsOtherReason()">{{ formDataCarUsage.cis_duvod_potrebyTXT+':' +String.fromCharCode(160)}}<i>{{formDataCarUsage.jinyDuvodTXT }}</i></span> 
                                                <span v-else>{{ formDataCarUsage.cis_duvod_potrebyTXT }}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.KonaniOd') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="xs" />                          
                                            <span class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCarUsage.konani_od)+String.fromCharCode(160)+$t('pracCesta.KonaniDo') +String.fromCharCode(160)+returnDateFormatFromString(formDataCarUsage.konani_do)">
                                                <span>{{ returnDateFormatFromString(formDataCarUsage.konani_od) }}{{String.fromCharCode(160)+$t('pracCesta.KonaniDo') +String.fromCharCode(160) }}<span style="margin-top: 0;font-weight: normal; align-self: center">{{ returnDateFormatFromString(formDataCarUsage.konani_do) }}</span></span>
                                            </span> 
                                        </DxItem>
                                </DxResponsiveBox>     
                                <span class="clNadpisCesta"> {{ $t('pracCesta.Zamestnanec')}}</span>
                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="0.65" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.RidicPrukazCislo') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.ridicskyprukazcislo">
                                                {{ formDataCarUsage.ridicskyprukazcislo }}
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                                <div class="dxItemPracovniCesta">
                                                    <span class="spanPracovniCestaBold">{{ $t('pracCesta.SkoleniRidicuDo') + ':' }}</span>
                                                </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCarUsage.platnost_ridic_skoleni_do)">
                                                {{ returnDateFormatFromString(formDataCarUsage.platnost_ridic_skoleni_do) }}
                                                <WarningImage v-if="this.formDataCarUsage.vykricnik1" ColorRGBWarningBox="rgb(0, 0, 0)" />
                                                <!--ColorRGBWarningBox="rgb(0, 0, 0)" ColorRGBAnimation="rgb(0, 0, 0)"-->
                                                <!--<div class="divOrangeWarning" v-if="this.formDataCarUsage.vykricnik1">
                                                    <img class="warningIconOrange" src="@/assets/images/alert.png">
                                                    <span class="warningText">{{$t('pracCesta.ZkontrolujtePlatnost')}}</span>
                                                </div>-->
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta"  v-if="(returnDateFormatFromString(formDataCarUsage.platnost_lekar_prohlidka_do)!='') " v-bind:title="$t('pracCesta.LekarskaProhlidkaDo') + ':' ">
                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.LekarskaProhlidkaDo') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-if="(returnDateFormatFromString(formDataCarUsage.platnost_lekar_prohlidka_do)!='')" v-bind:title="returnDateFormatFromString(formDataCarUsage.platnost_lekar_prohlidka_do)">
                                                {{returnDateFormatFromString(formDataCarUsage.platnost_lekar_prohlidka_do) }}
                                                <WarningImage v-if="this.formDataCarUsage.vykricnik2" ColorRGBWarningBox="rgb(0, 0, 0)" />
                                                
                                            </div> 
                                        </DxItem>
                                </DxResponsiveBox>

                               <span class="clNadpisCesta"> {{ $t('pracCesta.MotoroveVozidlo')}}</span>
                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="1.25" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="0.5" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem>
                                            <DxLocation :row="0" :col="0" :colspan="5" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="5" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="5" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="5" screen="xs" />                          
                                            <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                                                <DxRow :ratio="1" />
                                                <DxRow :ratio="1" />
                                                <DxRow :ratio="1" />
                                                <DxRow :ratio="1" />
                                                <DxRow :ratio="1" />
                                                <DxRow :ratio="1" />
                                                <DxCol :ratio="0.65" />
                                                <DxCol :ratio="1" />
                                                <DxCol :ratio="1" />
                                                    <DxItem >
                                                        <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta" >
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.SPZ') + ':' }}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.spz">
                                                            {{ formDataCarUsage.spz }} 
                                                        </div> 
                                                    </DxItem>

                                                    <DxItem >
                                                        <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta">
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.VyrobniZnacka') + ':' }}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.vyrobni_znacka">
                                                            {{ formDataCarUsage.vyrobni_znacka }}
                                                        </div> 
                                                    </DxItem>

                                                    <DxItem >
                                                        <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta">
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.ObjemValcu') + ':' }}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.objem_valcu!=null ? formatStringWithSpaces(formDataCarUsage.objem_valcu)+'cm³': formatStringWithSpaces(formDataCarUsage.objem_valcu)">
                                                            <span>{{ formatStringWithSpaces(formDataCarUsage.objem_valcu) }} <label v-if="formDataCarUsage.objem_valcu!=null">cm³</label></span>
                                                        </div> 
                                                    </DxItem>

                                                    <DxItem >
                                                        <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta">
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.DruhPohonneHmoty') + ':' }}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.palivoTXT">
                                                            {{ formDataCarUsage.palivoTXT }}
                                                        </div> 
                                                    </DxItem>

                                                    <DxItem >
                                                        <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="4" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta">
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.Spotreba') + ':'}}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="4" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="4" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="4" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.spotreba!=null ? formDataCarUsage.spotreba+'l/100km' :formDataCarUsage.spotreba">
                                                            <span>{{ formDataCarUsage.spotreba }} <label v-if="formDataCarUsage.spotreba!=null">l/100km</label></span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem >
                                                        <DxLocation :row="5" :col="0" :colspan="1" screen="lg" />
                                                        <DxLocation :row="5" :col="0" :colspan="1" screen="md" />
                                                        <DxLocation :row="5" :col="0" :colspan="1" screen="sm" />
                                                        <DxLocation :row="5" :col="0" :colspan="1" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta">
                                                            <span class="spanPracovniCestaBold">{{ $t('pracCesta.PlatnostTechnickeDo') + ':'}}</span>
                                                        </div> 
                                                    </DxItem>
                                                    <DxItem>
                                                        <DxLocation :row="5" :col="1" :colspan="2" screen="lg" />
                                                        <DxLocation :row="5" :col="1" :colspan="2" screen="md" />
                                                        <DxLocation :row="5" :col="1" :colspan="2" screen="sm" />
                                                        <DxLocation :row="5" :col="1" :colspan="2" screen="xs" />                          
                                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCarUsage.STK_platnost)">
                                                            {{ returnDateFormatFromString(formDataCarUsage.STK_platnost) }}
                                                            <WarningImage v-if="this.formDataCarUsage.vykricnik3" ColorRGBWarningBox="rgb(0, 0, 0)" />
                                                        </div> 
                                                    </DxItem>

                                            </DxResponsiveBox>
                                        </DxItem>
                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="2" screen="xs" />
                                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxCol :ratio="0.65" />
                                                    <DxCol :ratio="1" />
                                                    <DxCol :ratio="1" />
                                                        <DxItem >
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />                          
                                                            <span class="MarginLeftNegative10 clNadpisCesta"> {{ $t('pracCesta.PojisteniPovinne')}}</span> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta">
                                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.Pojistovna') + ':' }}</span>
                                                            </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.povinne_pojistovna">
                                                                {{ formDataCarUsage.povinne_pojistovna }}
                                                            </div> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                                                <div class="dxItemPracovniCesta">
                                                                    <span class="spanPracovniCestaBold">{{ $t('pracCesta.CisloPojistky') + ':' }}</span>
                                                                </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.povinne_cislo_pojistky">
                                                                {{ formDataCarUsage.povinne_cislo_pojistky }}
                                                            </div> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta">
                                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.PojistenoOd') + ':' }}</span>
                                                            </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCarUsage.povinne_od)+String.fromCharCode(160)+$t('pracCesta.PojistenoDo') +String.fromCharCode(160) + returnDateFormatFromString(formDataCarUsage.povinne_do)">
                                                                <span>{{ returnDateFormatFromString(formDataCarUsage.povinne_od)+String.fromCharCode(160)+$t('pracCesta.PojistenoDo') +String.fromCharCode(160) + returnDateFormatFromString(formDataCarUsage.povinne_do) }}</span>
                                                                <WarningImage v-if="this.formDataCarUsage.vykricnik4" ColorRGBWarningBox="rgb(0, 0, 0)" />
                                                            </div> 
                                                        </DxItem>
                                                </DxResponsiveBox>                         
                                            
                                        </DxItem>
                                        <DxItem >
                                            <DxLocation :row="1" :col="3" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="3" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="3" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="3" :colspan="2" screen="xs" />
                                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm" >
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxRow :ratio="1" />
                                                    <DxCol :ratio="1" />
                                                    <DxCol :ratio="1" />
                                                    <DxCol :ratio="1" />
                                                        <DxItem >
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                                                            <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />                          
                                                            <span class="MarginLeftNegative10 clNadpisCesta"> {{ $t('pracCesta.PojisteniHavarijni')}}</span> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta">
                                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.Pojistovna') + ':' }}</span>
                                                            </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.havarijni_pojistovna">
                                                                {{ formDataCarUsage.havarijni_pojistovna }}
                                                            </div> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                                                <div class="dxItemPracovniCesta">
                                                                    <span class="spanPracovniCestaBold">{{ $t('pracCesta.CisloPojistky') + ':' }}</span>
                                                                </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCarUsage.havarijni_cislo_pojistky">
                                                                {{ formDataCarUsage.havarijni_cislo_pojistky }}
                                                            </div> 
                                                        </DxItem>

                                                        <DxItem >
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta">
                                                                <span class="spanPracovniCestaBold">{{ $t('pracCesta.PojistenoOd') + ':' }}</span>
                                                            </div> 
                                                        </DxItem>
                                                        <DxItem>
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCarUsage.havarijni_od) +String.fromCharCode(160)+$t('pracCesta.PojistenoDo') +String.fromCharCode(160) + returnDateFormatFromString(formDataCarUsage.havarijni_do)">
                                                                <span>{{ returnDateFormatFromString(formDataCarUsage.havarijni_od) +String.fromCharCode(160)+$t('pracCesta.PojistenoDo') +String.fromCharCode(160) + returnDateFormatFromString(formDataCarUsage.havarijni_do) }}</span>
                                                                <WarningImage v-if="this.formDataCarUsage.vykricnik5" ColorRGBWarningBox="rgb(0, 0, 0)" />
                                                            </div> 
                                                        </DxItem>
                                                </DxResponsiveBox>                         
                                                <DxItem >
                                                    <DxLocation :row="2" :col="0" :colspan="5" screen="lg" />
                                                    <DxLocation :row="2" :col="0" :colspan="5" screen="md" />
                                                    <DxLocation :row="2" :col="0" :colspan="5" screen="sm" />
                                                    <DxLocation :row="2" :col="0" :colspan="5" screen="xs" />                          
                                                    <div class="dxItemPracovniCesta  dxItemPracovniCestaChild">
                                                    <span v-if="formDataCarUsage.jinyvlastnik_jmeno!='' || formDataCarUsage.jinyvlastnik_prijmeni!=''"> {{ $t('Vozidla.JinyVlastnikJmenoPrijmeni') + ':' }} {{ formDataCarUsage.jinyvlastnik_jmeno+' '+formDataCarUsage.jinyvlastnik_prijmeni }} </span>
                                                    </div> 
                                                </DxItem>
                                        </DxItem>
                                </DxResponsiveBox>
                            <!--<h3> {{ $t('pracCesta.Cesta')}}</h3>
                                <span> {{ $t('pracCesta.Cil') + ':' }} <span style="font-weight:lighter">{{ formDataCarUsage.cil }} </span> </span>
                                <h3> {{ $t('pracCesta.Ucel') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.ucel_cesty }} </span> </h3>
                                <h3> {{ $t('pracCesta.OdhadKm') + ':' }} <span style="font-weight: lighter"> {{formDataCarUsage.odhad_cesta_km + ' km' }} </span> </h3>
                                <h3> {{ $t('pracCesta.UcelPouzitiNazev') + ':' }} <span style="font-weight: lighter"> {{formDataCarUsage.cis_pouzitiTXT }} </span> </h3>
                               <h3> {{ $t('pracCesta.DuvodPotreby') + ':' }} <span style="font-weight: lighter"> {{formDataCarUsage.cis_duvod_potrebyTXT}} </span> </h3>
                                <div h3 style="display:flex; flex-direction: row; font-weight:normal; margin-top: 0; margin-bottom: 0;"> <h3 style="margin-top: 0;"> {{ $t('pracCesta.KonaniOd') + ':'+String.fromCharCode(160)}}<span style="margin-top: 0; font-weight:normal">{{ returnDateFormatFromString(formDataCarUsage.konani_od) }} </span></h3><h3 style="margin-top: 0;">{{String.fromCharCode(160)+$t('pracCesta.KonaniDo') + ':'+String.fromCharCode(160) }}<span style="margin-top: 0;font-weight: normal; align-self: center">{{ returnDateFormatFromString(formDataCarUsage.konani_do) }}</span></h3></div>
                            <h2> {{ $t('pracCesta.Zamestnanec')}}</h2>
                                <h3> {{ $t('pracCesta.RidicPrukazCislo') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.ridicskyprukazcislo }} </span> </h3>
                                <h3> {{ $t('pracCesta.SkoleniRidicuDo') + ':' }} <span style="font-weight:lighter"> {{ returnDateFormatFromString(formDataCarUsage.platnost_ridic_skoleni_do) }} </span> </h3>
                                <h3> {{ $t('pracCesta.LekarskaProhlidkaDo') + ':' }} <span style="font-weight:lighter"> {{ returnDateFormatFromString(formDataCarUsage.platnost_lekar_prohlidka_do) }} </span> </h3>
                            <h2> {{ $t('pracCesta.MotoroveVozidlo')}}</h2>
                                <h3> {{ $t('pracCesta.SPZ') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.spz }} </span> </h3>
                                <h3> {{ $t('pracCesta.VyrobniZnacka') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.vyrobni_znacka }} </span> </h3>
                                <h3> {{ $t('pracCesta.ObjemValcu') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.objem_valcu }} <label v-if="formDataCarUsage.objem_valcu!=null" id="labelalignedcenter">cm³</label></span> </h3>
                                <h3> {{ $t('pracCesta.DruhPohonneHmoty') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.palivoTXT }} </span> </h3>
                                <h3> {{ $t('pracCesta.Spotreba') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.spotreba }} <label v-if="formDataCarUsage.spotreba!=null" id="labelalignedcenter">l/100km</label></span> </h3>
                                <h3> {{ $t('pracCesta.PlatnostTechnickeDo') + ':' }} <span style="font-weight:lighter"> {{ returnDateFormatFromString(formDataCarUsage.STK_platnost) }} </span> </h3>
                            <h2> {{ $t('pracCesta.PojisteniPovinne')}}</h2>
                                <h3> {{ $t('pracCesta.Pojistovna') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.povinne_pojistovna }} </span> </h3>
                                <h3> {{ $t('pracCesta.CisloPojistky') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.povinne_cislo_pojistky }} </span> </h3>
                                <div h3 style="display:flex; flex-direction: row; font-weight:normal; margin-top: 0; margin-bottom: 0;"> <h3 style="margin-top: 0;"> {{ $t('pracCesta.PojistenoOd') + ':'+String.fromCharCode(160)}}<span style="margin-top: 0; font-weight:normal">{{ returnDateFormatFromString(formDataCarUsage.povinne_od) }} </span></h3><h3 style="margin-top: 0;">{{String.fromCharCode(160)+$t('pracCesta.PojistenoDo') + ':'+String.fromCharCode(160) }}<span style="margin-top: 0;font-weight: normal; align-self: center">{{ returnDateFormatFromString(formDataCarUsage.povinne_do) }}</span></h3></div>
                            <h2> {{ $t('pracCesta.PojisteniHavarijni')}}</h2>
                                <h3> {{ $t('pracCesta.Pojistovna') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.havarijni_pojistovna }} </span> </h3>
                                <h3> {{ $t('pracCesta.CisloPojistky') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.havarijni_cislo_pojistky }} </span> </h3>
                                <div h3 style="display:flex; flex-direction: row; font-weight:normal; margin-top: 0; margin-bottom: 0;"> <h3 style="margin-top: 0;"> {{ $t('pracCesta.PojistenoOd') + ':'+String.fromCharCode(160)}}<span style="margin-top: 0; font-weight:normal">{{ returnDateFormatFromString(formDataCarUsage.havarijni_od) }} </span></h3><h3 style="margin-top: 0;">{{String.fromCharCode(160)+$t('pracCesta.PojistenoDo') + ':'+String.fromCharCode(160) }}<span style="margin-top: 0;font-weight: normal; align-self: center">{{ returnDateFormatFromString(formDataCarUsage.havarijni_do) }}</span></h3></div>
                                <br>
                                <h3 v-if="formDataCarUsage.jinyvlastnik_jmeno!='' || formDataCarUsage.jinyvlastnik_prijmeni!=''"> {{ $t('Vozidla.JinyVlastnikJmenoPrijmeni') + ':' }} <span style="font-weight:lighter"> {{ formDataCarUsage.jinyvlastnik_jmeno+' '+formDataCarUsage.jinyvlastnik_prijmeni }} </span> </h3>                               
                                -->
                            </div>
                            <!-- #endregion -->
                            </div>

                            </PozadavkyBase>
                        </DxScrollView>
                        <!--#endregion-->
                    </DxPopup>
            <!-- POPUP Pracovní Setkání - Oběd - Večeře --> 
                    <DxPopup id="popupPracSetkani" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType==5)" v-model:visible="popupPracSetkani" :show-title="true"
                    :drag-enabled="true" :hide-on-outside-click="true" :show-close-button="true" title="Detail pracovního setkání" @hidden="PopupType=0" :resize-enabled="true" min-height="50%" @resize-end="handlePopupChange()"  >
                    <!--#region Pracovní Setkání OBSAH-->
                    <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="always" ref="dxScrollViewPopupSetkani" @initialized="ToFillNeededCheck('setkani')">
                        <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                            <hr class="hrPozadavky">
                            <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView itemPracovniSetkani"> 
                                <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="1.25" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.Grant') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.grant">{{formData.grant}}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.Zadatel') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.zadatel">{{formData.zadatel}}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.Podnik') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.podnik">{{formData.podnik}}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.DatumKonani') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="this.returnDateFormatFromString( formData.datumKonani)">{{this.returnDateFormatFromString( formData.datumKonani)}}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.Hoste') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="4" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.hoste">{{formData.hoste}}</span>   
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="5" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.zaUOCHB') + ':'+String.fromCharCode(160)}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="5" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.zamestnanci">{{formData.zamestnanci}}</span>    
                                            </div> 
                                        </DxItem>
                        </DxResponsiveBox>
                        <br>
                        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxRow :ratio="1" />
                                    <DxCol :ratio="1.25" />
                                    <DxCol :ratio="1" />
                                    <DxCol :ratio="1" />
                                        <DxItem >
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="0" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.CenaObedVecere') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="0" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani"  v-bind:title="formatStringWithSpaces(formData.cena)+String.fromCharCode(160)+'Kč'">{{formatStringWithSpaces(formData.cena)+String.fromCharCode(160)+"Kč"}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem >
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.Spropitne') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formatStringWithSpaces(formData.spropitne)+String.fromCharCode(160)+'Kč'">{{formatStringWithSpaces(formData.spropitne)+String.fromCharCode(160)+"Kč"}}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem >
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.CenaCelkem') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formatStringWithSpaces(formData.cenaCelkem)+String.fromCharCode(160)+'Kč'">{{formatStringWithSpaces(formData.cenaCelkem)+String.fromCharCode(160)+"Kč"}}</span>
                                            </div> 
                                        </DxItem>

                                        <DxItem >
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                            <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                            <div class="dxItemPracovniCesta">
                                                <span class="itemPracovniSetkani spanPracovniCestaBold">{{ $t('pracSetkani.FormaPlaceni') + ':' }}</span>
                                            </div> 
                                        </DxItem>
                                        <DxItem>
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                            <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                            <div class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                                <span class="itemPracovniSetkani" v-bind:title="formData.typPlatby">{{formData.typPlatby}}</span><!---->
                                            </div> 
                                        </DxItem>
                                    </DxResponsiveBox>
                                    </div>
                                </PozadavkyBase>
                    </DxScrollView>
                    <!--#endregion-->
                    </DxPopup>
            <!-- POPUP Platební Karta --> 
            <DxPopup id="popupPlatKarta" @hiding="TableDataPozadavky(); addParamsToLocation({})" v-if="(PopupType == 7)" v-model:visible="popupPlatKarta" :show-title="true" :drag-enabled="true" :hide-on-outside-click="true"
                :show-close-button="true" :title="$t('Pozadavky.Platkarta.DetailPlatKarty')" @hidden="PopupType = 0" :resize-enabled="true">        
                <!--  #region Vývojové dílny PLATKARTA -->
                <DxScrollView class="dxScrollViewPozadavky" direction="vertical" showScrollbar="onHover" ref="dxScrollViewPopupPlatKarta" @initialized="ToFillNeededCheck('platkarta')">
                    <PozadavkyBase @toggle_scroll="receiveScrollEmit">
                    <hr class="hrPozadavky">
                    <div class="bordersDataGridPozadavkyPopup hlavniObsahScrollView" >
                        <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm"  >
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxRow :ratio="1" />
                                <DxCol :ratio="1.25" />
                                <DxCol :ratio="1" />
                                <DxCol :ratio="1" />
                                    <DxItem >
                                        <DxLocation :row="1" :col="0" :colspan="1" screen="lg" /> 
                                        <DxLocation :row="1" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="1" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="1" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.JmenoAPrijmeniDrzitele') + ":" + String.fromCharCode(160)  }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="1" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="1" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="1" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="1" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCardPayNotice.JmenoAPrijmeni">
                                            {{ formDataCardPayNotice.JmenoAPrijmeni  }}
                                        </div> 
                                    </DxItem>

                                    <DxItem >
                                        <DxLocation :row="2" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="2" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="2" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="2" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.UcelPlatby') + ":" + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="2" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="2" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="2" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="2" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCardPayNotice.UcelPlatby">
                                            {{ formDataCardPayNotice.UcelPlatby }}
                                        </div> 
                                    </DxItem>                               
                                    <DxItem >
                                        <DxLocation :row="3" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="3" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="3" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="3" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta"  > <!--v-show="fakMena!='CZK'"-->
                                            <span  class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.PresnyNazevFirmy') + ":" + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="3" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="3" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="3" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="3" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" > <!--v-show="fakMena!='CZK'"-->
                                            <span v-bind:title="formDataCardPayNotice.DodavatelAdresa">{{formDataCardPayNotice.DodavatelAdresa}}</span>
                                        </div> 
                                    </DxItem>             
                                <DxItem >
                                        <DxLocation :row="4" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="4" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="4" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="4" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta" >
                                            <span  class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.PlnaAdresaFirmy') + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="4" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="4" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="4" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="4" :col="1" :colspan="2" screen="xs" />                          
                                        <div  class="dxItemPracovniCesta dxItemPracovniCestaChild" >
                                            <span v-bind:title="formDataCardPayNotice.JinyDodavatelAdresa">{{ formDataCardPayNotice.JinyDodavatelAdresa }}</span>    
                                        </div> 
                                    </DxItem>
                                    <DxItem >
                                        <DxLocation :row="5" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="5" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="5" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="5" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.Cena') + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="5" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="5" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="5" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="5" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formatStringWithSpaces(formDataCardPayNotice.Cena)+String.fromCharCode(160)+formDataCardPayNotice.MenaTXT">
                                            {{ formatStringWithSpaces(formDataCardPayNotice.Cena)+String.fromCharCode(160)+formDataCardPayNotice.MenaTXT}}    
                                        </div> 
                                    </DxItem>
                                    <DxItem >
                                        <DxLocation :row="6" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="6" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="6" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="6" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.PlacenoDne') + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="6" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="6" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="6" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="6" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="returnDateFormatFromString(formDataCardPayNotice.PlacenoDne) ">
                                            {{ returnDateFormatFromString(formDataCardPayNotice.PlacenoDne) }}    
                                        </div> 
                                    </DxItem>
                                    <DxItem >
                                        <DxLocation :row="7" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="7" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="7" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="7" :col="0" :colspan="1" screen="xs" />                          
                                        <div class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.TypUhrady') + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="7" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="7" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="7" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="7" :col="1" :colspan="2" screen="xs" />                          
                                        <div class="dxItemPracovniCesta dxItemPracovniCestaChild" v-bind:title="formDataCardPayNotice.TypUhradyTXT">
                                            {{ formDataCardPayNotice.TypUhradyTXT }}    
                                        </div> 
                                    </DxItem>
                                    <DxItem >
                                        <DxLocation :row="8" :col="0" :colspan="1" screen="lg" />
                                        <DxLocation :row="8" :col="0" :colspan="1" screen="md" />
                                        <DxLocation :row="8" :col="0" :colspan="1" screen="sm" />
                                        <DxLocation :row="8" :col="0" :colspan="1" screen="xs" />                          
                                        <div v-if="!IdentPlatKartyJeCesta" class="dxItemPracovniCesta">
                                            <span class="spanPracovniCestaBold">{{ $t('Pozadavky.Platkarta.Souhlas') + String.fromCharCode(160) }}</span>
                                        </div> 
                                    </DxItem>
                                    <DxItem>
                                        <DxLocation :row="8" :col="1" :colspan="2" screen="lg" />
                                        <DxLocation :row="8" :col="1" :colspan="2" screen="md" />
                                        <DxLocation :row="8" :col="1" :colspan="2" screen="sm" />
                                        <DxLocation :row="8" :col="1" :colspan="2" screen="xs" />                          
                                        <div v-if="!IdentPlatKartyJeCesta" class="dxItemPracovniCesta dxItemPracovniCestaChild">
                                            <!--{{ formDataCardPayNotice.souhlas }}-->
                                            <span v-if="formDataCardPayNotice.souhlas==1">✔️</span>
                                            <span v-else>❌</span>    
                                        </div> 
                                    </DxItem>
                        </DxResponsiveBox>
                        <br>
                        <hr class="PopupDetailDeliciHR">
                        <div>
                                <DxDataGrid ref="mygridplatkartypol" :height="(vyskaDataGridPopup / 2) - 10" width="100%" column-resizing-mode="widget" 
                                    :allow-column-reordering="true" :column-auto-width="true"
                                    :allow-column-resizing="true" :hoverStateEnabled="true" :rowAlternationEnabled="false" @content-ready="handlePopupChange();"
                                        id="DataGridPlatKartydetail" :data-source="GridPolozky" @exporting="onExporting"
                                        :show-borders="true" :noDataText="$t('central-NenalezenyZadneZaznamy')" >
                                        <DxLoadPanel :enabled="true" :text="$t('central-Loading')"/>
                                        <DxStateStoring
                                        :enabled="true"
                                        type="localStorage"
                                        storage-key="GridPozadavkyPlatKarty"
                                        />
                                        <DxExport
                                        :enabled="true"
                                        :formats="['xlsx']"
                                        :allow-export-selected-data="false"
                                        :texts="{ exportAll: $t('central-ExportVseExcel') }"
                                        />
                                        <DxToolbar >
                                            <DxItem
                                            name="groupPanel" location="before" locate-in-menu="auto" 
                                            />
                                            
                                        
                                            <DxItem  location="after" locate-in-menu="auto" widget="dxButton" @click="GridLayoutReset('platebnikarta')"> 
                                                <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                                type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined" @click="GridLayoutReset('platebnikarta')" />      
                                            </DxItem>
                                            <DxItem
                                            name="exportButton"
                                            location="after" locate-in-menu="auto" 
                                            />
                                            <DxItem
                                            name="searchPanel" location="after" locate-in-menu="auto" 
                                            :visible="true"
                                            />
                                        </DxToolbar>                                        
                                        <DxColumn data-field="Cena" :caption="$t('Pozadavky.Platkarta.Cena')"  format=",##0.00"   />
                                        <DxColumn data-field="Grant" :caption="$t('Pozadavky.Platkarta.Grant')" :visible="false"  alignment="right"/>
                                        <DxColumn data-field="GrantTXT" :caption="$t('Pozadavky.Platkarta.Grant')"  alignment="left"/>
                                        <DxColumn data-field="CountryNum" :caption="$t('Pozadavky.Platkarta.Zeme')" :visible="false" />   
                                        <DxColumn data-field="CountryTXT" :caption="$t('Pozadavky.Platkarta.Zeme')" :visible="IdentPlatKartyJeCesta" />
                                        <DxColumn data-field="DatumOd" :caption="$t('Pozadavky.Platkarta.DatumOd')" :visible="IdentPlatKartyJeCesta"/>
                                        <DxColumn data-field="DatumDo" :caption="$t('Pozadavky.Platkarta.DatumDo')" :visible="IdentPlatKartyJeCesta"/>     
                                        <DxColumn data-field="PolID" :caption="$t('Pozadavky.Platkarta.PolID')" :visible="false" sort-order="asc"  />
                                        <DxColumn data-field="TripID" :caption="$t('Pozadavky.Platkarta.TripID')" :visible="IdentPlatKartyJeCesta" />
                                        <DxColumn data-field="TripTXT" :caption="$t('Pozadavky.Platkarta.TripTXT')" :visible="false"/>
                                        <DxColumn data-field="Mena" :caption="$t('Pozadavky.Platkarta.Mena')" :visible="false"     />
                                        <DxColumn data-field="MenaTXT" :caption="$t('Pozadavky.Platkarta.Mena')"  :visible="false"    />
                                        <DxColumn data-field="ZamID" :caption="$t('Pozadavky.Platkarta.ZamID')" :visible="false"/>
                                        <DxColumn data-field="ZamTXT" :caption="$t('Pozadavky.Platkarta.ZamTXT')" :visible="IdentPlatKartyJeCesta"/>     
                                        <DxPaging :enabled="false" />
                                        <DxGroupPanel :visible="true" :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                                        <DxGrouping :auto-expand-all="true" />
                                        <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                                        <DxSearchPanel :width="150" :visible="true" :placeholder="$t('central-Hledat')" />
                                </DxDataGrid>
                            </div>
                        </div>
                    </PozadavkyBase>
                </DxScrollView>
                <!--#endregion-->
            </DxPopup>
        <!-- začátek formuláře-->
        
        <DxScrollView width="100%" height="calc(100% - 36px)" direction="vertical" showScrollbar="always">
                <div id="container">
                    <div id="inner">
                        <div class="clNadpisAktualniStranky" height="50px">
                            <h2 class="h2nadpis prevent-select nadpisH2Moduly" >{{  $t('pozadavky-VytvoreniNovehoPozadavku') }}</h2>
                        </div>
                       
                        <!--<h2 class="h2nadpis prevent-select nadpisH2Moduly" > {{$t('pozadavky-VytvoreniNovehoPozadavku')}}</h2>-->
                        <div class="child">
                        <DxSelectBox id="vyt" :label="$t('pozadavky-TypPozadavku')" height="45px" :data-source="dataSourceTypPozadavky2" :grouped="true"
                            display-expr="content" v-model="typpozadavek2" placeholder="" 
                            @value-changed=" NewRequest();" :drop-down-options="selectBoxAttributesTYPY" >
                        </DxSelectBox>                       
                        </div>
                        <div class="child">                    
                            <DxButton icon="add"  :text="$t('pozadavky-ZalozitNovyPozadavek')" height="auto" type="default" styling-mode="contained"
                                id="novypozadavek" @click="NewRequest();" :visible="false" />
                        </div>
                        <!--                 
                        <div class="child2">
                            <h3 id="activaheader">Objednávka přes Activu 
                            <DxButton hint="Přejít do e-shopu ACTIVA" icon="cart" text="" height="auto" width="45px" value="link" type="default" styling-mode="contained" id="activa"
                                @click="NewRequestActiva();" />
                            </h3>
                        </div>
                        -->
                    </div>
                </div>               
                <hr class="hrModuly">
                <div class="clNadpisAktualniStranky" height="50px">
                    <h2 class="h2nadpis prevent-select nadpisH2Moduly">{{ $t('pozadavky-ZobrazeniPozadavku')}}</h2>
                </div>
                <DxResponsiveBox :screen-by-width="screen"  width="100%">
                    <DxRow :ratio="0.1" />
                    <DxRow :ratio="0.1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="1" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="xs" />
                        <div class="first-bar item">
                            <div class="dx-fieldset">
                                <div class="dx-field">
                                    <div class="selectbox">
                                        <DxSelectBox @contentReady="SelectBoxVypis"  :id="1" height="45px" :label="$t('pozadavky-Pozadavek')" :data-source="pozadavky" 
                                            display-expr="content" value-expr="id" :value="ValuePozadavek" placeholder=""
                                            @value-changed="this.page=1;setSelectedValue($event); " :drop-down-options="selectBoxAttributes"  >
                                        </DxSelectBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="1" :colspan="1" screen="lg" />
                        <DxLocation :row="0" :col="2" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="2" :colspan="2" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="4" screen="xs" />
                        <div class="second-bar item">
                            <div class="dx-fieldset">
                                <div class="dx-field">
                                    <div class="selectboxZam">
                                        <DxSelectBox :id="2" height="45px" :disabled="!zamvisible" :label="$t('pozadavky-Zamestnanec')" @initialized="GetEmployeesInit($event)"
                                            :data-source="zamestnanci" v-model="zamestnanec" display-expr="content" value-expr="id" :value="ValueZam" 
                                            :placeholder="$t('central-Placeholder')" @value-changed="setSelectedValue($event)" :search-enabled="true"
                                             @input="SearchInput" @opened="ClearValue" noDataText="" @closed="SetValue" :drop-down-options="selectBoxAttributes" >
                                        </DxSelectBox>    
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="2" :colspan="1" screen="lg" />
                        <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="2" screen="sm" />
                        <DxLocation :row="2" :col="0" :colspan="4" screen="xs" />
                        <div class="third-bar item">
                            <div class="dx-fieldset">
                                <div class="dx-field">
                                    <div class="selectbox">
                                        <DxSelectBox :id="3" height="45px" :label="$t('pozadavky-TypPozadavku')" :data-source="dataSourceTypPozadavky1" @initialized="RequestType"
                                            display-expr="content" value-expr="id" :value="ValueTyp" placeholder="" :grouped="true"
                                            @value-changed="this.page=1;setSelectedValue($event); " :drop-down-options="selectBoxAttributesTYPY" >
                                        </DxSelectBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DxItem>
                    <DxItem>
                        <DxLocation :row="0" :col="3" :colspan="1" screen="lg" />
                        <DxLocation :row="1" :col="2" :colspan="2" screen="md" />
                        <DxLocation :row="1" :col="2" :colspan="2" screen="sm" />
                        <DxLocation :row="3" :col="0" :colspan="4" screen="xs" />
                        <div class="fourth-bar item">
                            <div class="dx-fieldset">
                        <!--         <div class="dx-fieldset-header">Stav požadavku</div> -->
                                <div class="dx-field">
                                    <div class="selectbox">
                                        <DxSelectBox :id="4" height="45px" :label="$t('pozadavky-StavPozadavku')" @initialized="RequestStates"
                                            :data-source="stavpozadavky" display-expr="content" value-expr="id" :value="ValueStav"
                                            placeholder="" @value-changed="this.page=1;setSelectedValue($event); " :drop-down-options="selectBoxAttributes" >
                                        </DxSelectBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DxItem>
                    <DxItem width="100%">
                        <DxLocation :row="1" :col="0" :colspan="4" screen="lg" />
                        <DxLocation :row="1" :col="0" :colspan="4" screen="md" />
                        <DxLocation :row="2" :col="0" :colspan="4" screen="sm" />
                        <DxLocation :row="4" :col="0" :colspan="4" screen="xs" />
                        <div class="fifth-bar item" width="100%">
                            <i id="popisekPozadavkyNacistVse" style="padding-left: 10px;">{{ $t('pozadavky-Popisek') }}</i>
                            <hr class="hrModuly">
                            <div class="bordersDataGridPozadavkyModuly" width="100%" >
                            <DxDataGrid ref="mygrid" :height="(vyskaDataGridMain/1.9)" :showScrollbar="true" column-resizing-mode="widget" :allow-column-reordering="true" :allow-column-resizing="true" 
                            :column-auto-width="true" :hoverStateEnabled="true" :rowAlternationEnabled="true" @content-ready="onContentReady"
                                  id="DataGrid" @option-changed="SearchInputPozadavkyGrid" @initialized="MyGridInit"
                                :data-source="rows" :show-borders="true" key-expr="id" @exporting="onExporting"
                                 @row-click="ShowRequest" :noDataText="$t('central-NenalezenyZadneZaznamy')" >
                                 <DxFilterPanel  :visible="true" :texts="{
                                    clearFilter: $t('central-ZrusitFiltr'), createFilter: $t('central-VytvoritFiltr'), filterEnabledHint: $t('central-FiltrVysvetlivka'), saveChanges: 'Povrdit',
                                }"/>
                                 <DxLoadPanel 
                                    :visible="true" 
                                    :text="$t('central-Loading')" 
                                 />
                                 <DxStateStoring
                                :enabled="true"
                                type="localStorage"
                                storage-key="GridPozadavkyZobrazeni"
                                />
                                <DxExport
                                :enabled="true"
                                :formats="['xlsx']"
                                :allow-export-selected-data="false"
                                :texts="{exportAll: $t('central-ExportVseExcel')}"
                                />
                                <DxToolbar width="100%" height="50px">
                                    
                                        <DxItem
                                        name="groupPanel" locate-in-menu="auto" :width="250"  max-width="250px"
                                        />
                                        <DxItem location="before" locate-in-menu="auto" widget="dxCheckBox" @click="TableDataPozadavky()" :width="150">
                                            <DxCheckBox id="checkbox1" v-model:value="checked"  class="centralOutlinedBox"/>{{ $t('central-ZobrazitArchivniPozadavky')}}      
                                        </DxItem>

                                        <DxItem locate-in-menu="auto"  location="after" widget="dxButton" @click="GridLayoutReset('requests')" :width="150">
                                            <DxButton icon="revert" id="ObnovitRozlozeni" height="auto" width="auto" :text="$t('central-ObnovitPuvodniRozlozeni')"
                                            type="success" style="scale: 85%;color: inherit; border-color: gray;" styling-mode="outlined"  />      
                                        </DxItem>
                                        <DxItem
                                        name="exportButton" location="after" locate-in-menu="auto" 
                                        />
                                        <DxItem
                                        name="searchPanel"  location="after" locate-in-menu="auto" :width="150" class="centralOutlinedBox"  
                                        :visible="true" 
                                        />                                      
                                        
                                        
                                        
                                       

                                    </DxToolbar> 
                                
                               
                                <DxFilterBuilderPopup value="text" />
                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true" />
                                <!--<DxPager
                                    visible="true"
                                    :show-info="true"
                                    :info-text="pagerInfoText"
                                    ></DxPager>-->
                                <DxColumn data-field="id" :width="75" caption="ID" :visible="true" :cell-template="idCellTemplate"/>
                                <DxColumn data-field="content" :width="300" :caption="$t('pozadavky-Grid-Sloupec-Nazev')" />
                                <DxColumn :width="150" data-field="owner" :caption="$t('pozadavky-Grid-Sloupec-Zamestnanec')" />
                                <DxColumn :width="160" data-field="reqtypetext" :caption="$t('pozadavky-Grid-Sloupec-Typ')" />
                                <DxColumn :width="100" data-field="reqstatetext" :caption="$t('pozadavky-Grid-Sloupec-Stav')" />
                                <DxColumn :width="100" data-field="lastchange" data-type="date" format="dd. MM. yyyy"
                                    :caption="$t('pozadavky-Grid-Sloupec-PoslZmena')" />
                                <DxColumn :width="160" data-field="reqtypeid" :caption="$t('pozadavky-TypID')" :visible="false" />
                                <DxColumn :width="150" data-field="reqstateid" :caption="$t('pozadavky-StavID')" :visible="false" />
                                <DxPaging :enabled="false" />
                                <DxGroupPanel :visible="true"
                                    :empty-panel-text="$t('central-PretahneteSemProSouhrn')" />
                                <DxGrouping :auto-expand-all="true" />
                                <DxSorting :ascending-text="$t('central.Sorting.ascendingText')" :clear-text="$t('central.Sorting.clearText')" :descending-text="$t('central.Sorting.descendingText')" />
                                <DxSearchPanel :width="150" :visible="true" :placeholder="$t('pozadavky-Grid-Hledat')" />
                            </DxDataGrid>
                                            <br>
                                <DxButton icon="chevrondown" type="button" height="auto" width="auto" :text="$t('central-NacistDalsiZaznamy')+' ('+this.listRequestCallCount+')'" :hint="$t('central-NacistDalsiZaznamy')" class="button4 buttonsDataGridSpodniLista"
                                    v-on:click="Button4Click()" :disabled="disable" />
                                <DxButton icon="arrowdown" type="button" height="auto" width="auto" :text="$t('central-NacistVse')" :hint="$t('central-NacistVse')" class="button4 buttonsDataGridSpodniLista" 
                                    v-on:click="ButtonAllClick()" :disabled="disable" />
                                <br> 
                        </div>  
                        </div>
                    </DxItem>                   
                </DxResponsiveBox>
                
               
        </DxScrollView>


    </div>
</template>
<script>
const optionsDate = {
    dateStyle: 'full'
    //timeStyle: "long"   
}
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { isNull, isUndefined } from 'lodash';
//import HlavickaPoz from '@/components/HlavickaPoz.vue';
import DataSource from 'devextreme/data/data_source';
import PozadavkyBase from './PozadavkyBase.vue';
import WarningImage from '@/components/WarningImage.vue';
import notify from 'devextreme/ui/notify';
import {
  DxSummary,
  DxTotalItem,
  DxStateStoring,
  DxExport,
  DxLoadPanel,
  DxToolbar,
  DxHeaderFilter,
  DxFilterPanel,
  DxFilterBuilderPopup,
  DxFilterRow,
  DxPaging,
  DxSorting
  /*DxPager*/
} from 'devextreme-vue/data-grid';
import arrowUP from "@/assets/svg/arrowUP.svg"
import arrowDOWN from "@/assets/svg/arrowDOWN.svg"
import Attachments from '@/components/Attachments.vue';
import HelpLink from '@/components/HelpLink.vue';

const dataSourceTypPozadavky1 = new DataSource({
    group: 'category',
    // ...
});
const dataSourceTypPozadavky2 = new DataSource({
    group: 'category',
    // ...
});
//const pageSizes = [10, 25, 50, 100];

export default {
    el: '$parentPoz',
    name: 'MenuView',
    components: {
        PozadavkyBase,
        DxSummary,
        DxTotalItem,
        DxStateStoring,
        DxExport,
        DxLoadPanel,
        DxToolbar,
        DxHeaderFilter,
        DxFilterPanel,
        DxFilterBuilderPopup,
        DxFilterRow,
        WarningImage,
        DxPaging,
        DxSorting,
        Attachments,
        HelpLink
        /*DxPager*/
        
    },
    props: ['search', 'ID'],
   
    data() {

        return {
            needFocusToSearchPanel: false,
            formDataCarUsage: {
                ridicskyprukazcislo: 0,
                platnost_ridic_skoleni_do: null,
                platnost_lekar_prohlidka_do: null,
                cil: '',
                odhad_cesta_km: null,
                ucel_cesty: '',
                konani_od: null,
                konani_do: null,
                konani: null,
                spz: '',
                vyrobni_znacka: '',
                objem_valcu: null,
                jinyVlastnik: false,
                jinyvlastnik_jmeno: '',
                jinyvlastnik_prijmeni: '',
                phm_druh: '',
                spotreba: null,
                STK_platnost: null,
                povinne_pojistovna: '',
                povinne_cislo_pojistky: '',
                povinne_od: null,
                povinne_do: null,
                havarijni_pojistovna: null,
                havarijni_cislo_pojistky: '',
                havarijni_od: null,
                havarijni_do: null,
                cis_pouziti: 0,
                cis_duvod_potreby: 0,
                cis_pouzitiTXT:'',
                cis_duvod_potrebyTXT: '',
                pozadavek_cislo: 0,
                cislo_vozidla: 0,
                vlastniksouhlas: null,
                stvrzeno: 0,
                palivoTXT: "",
                vykricnik1: false, 
                vykricnik2: false, 
                vykricnik3: false, 
                vykricnik4: false, 
                vykricnik5: false, 

            },
            Hledat: '',
            msg: '',
            clear: false,
            NiceUrl: false,
            MountingPozadavkyGrid: true,
            MountingNiceUrl: true,
            Smlouva: {
                nazev: "",
                cena: '',
                predmet: '',
                zverejnit: false,
                referent: 0,
                referentTXT: '',
                protistrana: [{ content: '', id: -1 }],
                protistranaTXT: "",
                komentar: '',
                oblast: 0,
                oblastTXT: '',
                typSmlouvy: 0,
                typSmlouvyTXT: '',
                podpis: false,
                cjednaci: ''

            },
          //  SearchPanelVolani: false,
            checked: false,
            DisableCopy: false,
            GrantEdit: false,
            ParamsQuery: {},
            typDodaniContent:'',
            optionsDate,
            texts_OpravduSiPrejeteSmazatZaznam:this.$t('central-OpravduSiPrejeteSmazatZaznam'),
            dataSourceTypPozadavky1,
            dataSourceTypPozadavky2,
            PopupType:0,
            MountingData: true,
            ValuePozadavek: 1,
            ValueTyp: -1,
            ValueStav: 99,
            ValueZam: -666,
            selectboxvypis: '',
            s:0,
            obsah:'',
            page: 1,
            disable: false,
            popupZmenaGrantu: false,
            //popupy pozadavku
            popupVD: false,
            popupObj: false,
            popupObjKnihovna: false,
            popupFaktura: false,
            popupSmlouva: false,
            popupPracCesta: false,
            popupPrevod: false,
            popupVyrazeni: false,
            popupPracSetkani:false,
            popupPlatKarta: false,
            // popupy pozadavku
            popupAlertTemplate: '',
            popupAlert: false,
            GridPolozky: [],
            pozVia: 'Via',
            readOnly: true,
            granty: [],
            grantid: 0,
            RequestState: -10,
            suppliers: '',
            majetekList: [],
            prevodNa: '',
            majetek: '',
            vyrZpusob: '',
            vyrDuvod: '',
            fakZarazeni: '',
            fakDatumSpl: '',
            fakDatumVys: '',
            fakDatumZap: '',
            fakDatumPri: '',
            fakCislo: '',
            fakObj: '',
            fakMena: '',
            fakCenaToPayCZK: '',
            fakCenaFinalniCZK: '',
            fakCisloUctu: '',
            fakTypPlatby: '',
            fakDodavatel: '',
            fakCastkaSchvalovana:0,
            typ: '',
            pozGrant: '',
            pozDodavatel: '',
            urgentniDodani: false,
            pozUrgentniDodani: '',
            popupVisible: false,
            events: [], 
            pozadavky: [{ "content": this.$t('pozadavky-Pozadavek-KmemuVyjadreni'), "id": 1 },
                { "content": this.$t('pozadavky-Pozadavek-PozadavkyZamestnance'), "id": 0 }, { "content": this.$t('pozadavky-Pozadavek-SchvalenePozadavky'), "id": 2 }],
            pozadavek: 1,
            zamvisible: false,
            showModal: false,
            show: false,
            zamestnanci: [{ "content": '', "id": 0 }],
            zamestnanec: 0,
            typpozadavky: [{ "content": this.$t('central-Vsechny'), "id": -1, "category": "fhj" }],
            typpozadavek: -1,
            pozReqtypeId: '',
            typpozadavky2: [],
            typpozadavek2: [],
            stavpozadavky: [{ "content": this.$t('central-Vsechny'), "id": 99 }],
            stavpozadavek: 99,
            objDeponovanou: "",
            filtr: '',
            x: 1, // promenna pro pozadavek vstupni hodnotu
            //popup nastaveni
            positionOf: '',
            production: [],
            cislo_vozidla_k_zobrazeni:0,
            vyskaDataGridPopup:250,
            vyskaDataGridMain:250,
            jazyk: null,
            InstaScroll: false, 
            formData: {
                podnik: "",
                cena: null,
                spropitne: null,
                cenaCelkem: null,
                hoste: '',
                zamestnanci: [],
                komentar: '',
                typPlatby: null,
                datumKonani: null,
                zadatel: "",
                grant: null
            },
            formDataCardPayNotice: {
                JmenoAPrijmeni: null,
                UcelPlatby: null,
                Dodavatel: null,
                DodavatelAdresa: null,
                JinyDodavatelAdresa:null,
                Cena: null,
                Mena: null,
                MenaTXT:null,
                PlacenoDne: null,
                TypUhrady: null,
                TypUhradyTXT:null,
                TypUhradyIDENT:null,
                PlatebniKartaID: null,
                souhlas:null,
                
            },
            kopieText: '',
            ToFill:false,
            zobrazeniPolozky: false,
            screen(width) {
                return (width < 700) ? 'sm' : 'lg';
            },
            emailButtonOptions: {
                icon: 'email',
                text: 'Send',
                onClick: () => {
                    this.$log.debug('click');
                },
            },
            closeButtonOptions: {
                text: 'Close',
                onClick: () => {
                    this.popupVisible = false;
                },
            },
            columns: [
                {
                    label: 'Název',
                    field: 'content',
                },
                {
                    label: 'Zaměstnanec',
                    field: 'owner',
                },
                {
                    label: 'Typ',
                    field: 'reqtypetext',
                },
                {
                    label: 'Stav',
                    field: 'reqstatetext',
                },
                {
                    label: 'Poslední změna',
                    field: 'lastchange',
                    type: "date",
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: "dd.MM.yyyy",
                },
            ],
            rows: [],
            selectBoxAttributes: { 
                minWidth:250,
                minHeight:0    
            },
            selectBoxAttributesTYPY: {
                minWidth: 330,
                minHeight: 0
            },
            scrollToKomentar:false,
            typyKartyUplatneni:[],
            listRequestCallCount:25,
            pagerInfoText:'Strana {0} z {1} ({2} položek)',
            zobrazitJinyDuvodTXT:false,
            CarUsageTypypouziti:[],
            PrilohyID:0,
            popupPrilohyFaktury:false,
            holdTimer: null,
            
        };
    },
    updated() {
        //this.$log.debug(this)
    },
    watch: {
    // Watch for changes in the popup properties
    popupObj: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
    popupObjKnihovna: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
    popupFaktura: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
    popupPrevod: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
    popupVyrazeni: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
    popupPracSetkani: function (newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.handlePopupChange();
      }
    },
  },
    computed: {
        selectboxVypis: function () {
            let self = this;
            return self.$refs.vypis.instance;         
        },
        IdentPlatKartyJeCesta: function(){
            var vysledek;
            if(this.formDataCardPayNotice.TypUhradyIDENT=='PlatKartCesta'){vysledek=true}else{vysledek=false}
            return vysledek;
        },
        TextZobrazitPolozky: function(){
            var vysledek;
            if(this.zobrazeniPolozky==false){vysledek=this.$t('central.Faktura.ZobrazitPolozky')} else {vysledek=this.$t('central.Faktura.SkrytPolozky')}
            return vysledek;
        },
        TextFakturaPocetPolozek: function(){
            return '('+this.GridPolozky.length+')';
        },
        

    },
    created() {
        this.$log.debug(this.$i18n.locale)
        window.pozid = undefined
        

    },
        unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
        },

    mounted() {
        window.addEventListener("resize", this.myEventHandler);
        window.thisPozadavky = this
        this.DrawerSelected()
        this.$log.debug(this.$i18n.locale)
        window.this.$log.debug('menu Mounted')
        window.this.$log.debug(this.$route.params)
        if (isUndefined(this.$route.params.Niceurl)) {
            this.ParamsQuery = this.$route.query
            this.MountingNiceUrl = false
        }
        
        this.ZobrazZam();
        this.GetNiceUrl('', false)
            setTimeout(() => {
                    this.$nextTick(() => {
                        this.myEventHandler();
            });}, 1000);
            this.jazyk=window.jazyk;
            if (window.location.href.includes('&ToFill=1')) {
                //this.$log.debug('URL contains "&ToFill=1"');
                this.ToFill=true;
                } else {
                //this.$log.debug('URL does not contain "&ToFill=1"');
                this.ToFill=false;
                }
    },
    
    methods: {
        onContentReady(e) {
               // this.$log.debug(' volam cont ready')
            if (this.needFocusToSearchPanel) {
            // Find the search panel input
            const searchPanelInput = e.component.element().querySelector('.dx-datagrid-search-panel input');
            if (searchPanelInput) {
                // Set focus to the input
                searchPanelInput.focus();
                // Move cursor to the end of the text
                const length = searchPanelInput.value.length;
                searchPanelInput.setSelectionRange(length, length);
            }
            // Reset the flag
            this.needFocusToSearchPanel = false;
            }
        },
        getIkonaZobrazitPolozky() {
            return this.zobrazeniPolozky ? arrowUP : arrowDOWN;
        },
        receiveScrollEmit() {
            this.$log.debug('RECEIVED toggle_scroll');
            this.$log.debug('localStorage.getItem(aktualniScrollViewRef)',window.localStorage.getItem('aktualniScrollViewRef'));
            //alert('RECEIVED toggle_scroll');
            if (window.localStorage.getItem('aktualniScrollViewRef')) {
                this.ToFill = true; this.InstaScroll = true;
                      this.ToFillNeededCheck(window.localStorage.getItem('aktualniScrollViewRef'));this.$log.debug('ToFillNeededCheck CALLED')
            }              
        
        },
        MyGridInit(e) {
            this.$log.debug(e)
            this.$log.debug('mygridinit')
            this.$log.debug(e.component.option('searchPanel.text'))
            setTimeout(() => {
                this.$refs.mygrid.instance.option('searchPanel.text', '')
            }, 200);
            var defaultInfoText = 'PAGE {0} OF {1} ({2} ITEMS)';
            this.pagerInfoText = defaultInfoText.replace('PAGE', this.$t('central.pagingInfoStrana')).replace('OF', this.$t('central.pagingInfoCelkem')).replace('ITEMS', this.$t('central.pagingInfoPolozky'));    
        },
        ToFillNeededCheck(input) {
            this.$log.debug('ToFillNeededCheck ToFillNeededCheck');
            localStorage.setItem('aktualniScrollViewRef', input);
            this.$log.debug('ToFillNeededCheck A');
            if (this.ToFill) {
                var scrollContainer
                switch (input) {
                    case 'platkarta':
                        scrollContainer = this.$refs.dxScrollViewPopupPlatKarta.instance;
                        break;
                    case 'setkani':
                        scrollContainer = this.$refs.dxScrollViewPopupSetkani.instance;
                        break;
                    case 'praccesta':
                        scrollContainer = this.$refs.dxScrollViewPopupPracCesta.instance;
                        break;
                    case 'smlouva':
                        scrollContainer = this.$refs.dxScrollViewPopupSmlouva.instance;
                        break;
                    case 'vyrazenimajetku':
                        scrollContainer = this.$refs.dxScrollViewPopupVyrazeniMajetku.instance;
                        break;
                    case 'prevodmajetku':
                        scrollContainer = this.$refs.dxScrollViewPopupPrevodMajetku.instance;
                        break;
                    case 'vyvojdilny':
                        scrollContainer = this.$refs.dxScrollViewPopupVyvojDilny.instance;
                        break;
                    case 'faktura':
                        scrollContainer = this.$refs.dxScrollViewPopupFaktura.instance;
                        break;
                    case 'objknihovna':
                        scrollContainer = this.$refs.dxScrollViewPopupObjKnihovna.instance;
                        break;
                    case 'objzasobovani':
                        scrollContainer = this.$refs.dxScrollViewPopupObjZasobovani.instance;
                        break;
                    default:
                        var messageError = "Cannot find the specified case.";
                        return 2;
                }
                if (scrollContainer) {
                    this.$log.debug('ToFillNeededCheck C');
                    var hlavniObsahScrollView = document.getElementsByClassName('hlavniObsahScrollView');
                    this.$log.debug(hlavniObsahScrollView[0].clientHeight)
                    this.$log.debug(hlavniObsahScrollView)
                    this.$log.debug('hlavniObsahScrollView')
                    const positionBottom = scrollContainer.scrollHeight();
                    //const positionAfterContent = hlavniObsahScrollView[0].clientHeight;
                    if(!this.InstaScroll)
                    setTimeout(() => {
                        scrollContainer.scrollTo(positionBottom);
                    }, 2500);
                    else scrollContainer.scrollTo(positionBottom);

                } else {
                    throw new Error(messageError);
                }

            } else {
                this.$log.debug('ToFillNeededCheck X');
                //
            }
            this.InstaScroll=false

        },
        ValidateDatesBusinessTrip() {
            if (this.formDataCarUsage.platnost_ridic_skoleni_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik1 = true
            else this.formDataCarUsage.vykricnik1 = false  
            if(this.formDataCarUsage.platnost_lekar_prohlidka_do < this.formDataCarUsage.konani_do ) this.formDataCarUsage.vykricnik2=true
            else this.formDataCarUsage.vykricnik2 = false 
            if(this.formDataCarUsage.STK_platnost < this.formDataCarUsage.konani_do ) this.formDataCarUsage.vykricnik3=true
            else this.formDataCarUsage.vykricnik3 = false 
            if(this.formDataCarUsage.povinne_do < this.formDataCarUsage.konani_do ) this.formDataCarUsage.vykricnik4=true
            else this.formDataCarUsage.vykricnik4 = false 
            if (this.formDataCarUsage.havarijni_do < this.formDataCarUsage.konani_do) this.formDataCarUsage.vykricnik5 = true  
            else this.formDataCarUsage.vykricnik5 = false      
        },
        onExporting(e) {
            this.$log.debug(e)
            this.$log.debug('test uvnitr onexporting')
            switch (e.element.id) {
                case 'Datagrid':
                    this.msg='Pozadavky'
                    break;
                case 'DataGrid8detail':
                    this.msg = 'Objednavka_zasobovani(' + this.pozId+')'
                    break;
                case 'DataGrid9detail':
                    this.msg = 'Objednavka_knihovna(' + this.pozId + ')'
                    break;
                case 'DataGridPrevoddetail':
                    this.msg = 'Prevod_majetku(' + this.pozId + ')'
                    break;              
            }
            if (e.format === "xlsx") {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet("Export");
                exportDataGridToExcel({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                    customizeCell: function (options) {
                        const excelCell = options;
                        excelCell.font = { name: 'Arial', size: 12 };
                        excelCell.alignment = { horizontal: 'left' };
                        excelCell
                    }
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        const datum = new Date();
                        saveAs(
                            new Blob([buffer], { type: "application/octet-stream" }),
                            this.msg + " " + datum.toISOString('cs-CZ').substring(5, 10) + " " + datum.toTimeString('cs-CZ').substring(0, 9) + ".xlsx"
                        );
                    });
                });
                e.cancel = true;
            }
        },
        myEventHandler(){
         //   this.$log.debug(e);
            this.handlePopupChange()
            this.handleMainViewChange()
          },
        handlePopupChange(){
          //  console.log("handlePopupChange");
             //   this.kopieText=''
                let self = this;
                var elements = document.getElementsByClassName("dx-overlay-content dx-popup-normal dx-popup-draggable dx-resizable");

                    for (var i = 0; i < elements.length; i++) {
                    var element = elements[i];
                    var height = element.clientHeight;

                    if (height > 1) {
            //            console.log("Element height:", height);
                        if (height < 500 && height > 300) {
                            height = 550;
                        } 
                        if (height <= 300) {
                            height = 450;
                        }

                        self.vyskaDataGridPopup = height;
             //           console.log(element); 
                        break;
                    }
                    self.vyskaDataGridPopup = 450;
                    }
            
        },
        handleMainViewChange(){
       //     console.log("handleMainViewChange");
                let self = this;
                var element = document.getElementById("ScrollViewAPP");
                    if (element) {
                //        console.log(element);
                        var height = element.clientHeight;
                        if ((height<500) && (height>300))  {height=550} 
                        if (height<=300) {height=450} 
                        self.vyskaDataGridMain = height;
                        
        
        //    console.log("handleMainViewChange height:", height);
}
            
        },
        
        returnDateFormatFromString(e){
            const date = new Date(e);
            if (isNaN(date.getTime())) { return ''; }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
            var formattedDate = ''
            if (year<=2000){formattedDate = ''}else {formattedDate = `${day}. ${month}. ${year}`;}
            
            
            return formattedDate;

        },
        validReturnValue(input){
            if (typeof input === 'number') {
                this.$log.debug(input);
                if (input == 0){return ""} else {return input}
            } else {return input}

        },
        
        SearchInputPozadavkyGrid(e) {  
            if (e.name === "searchPanel") {
                this.$log.debug('searchpanel') 
                this.$log.debug(e) 
                this.page = 0;
              //  this.SearchPanelVolani=true
              if(!this.MountingData) this.TableDataPozadavky()

            }
            else if ((e.fullName).includes('filterValue') && !isUndefined(e.component.getCombinedFilter())) {     
                this.$log.debug(e)   
                if (e.component.getCombinedFilter().filterValue != this.filtr) {
                    this.$log.debug('filtr') 
                    this.page = 0; 
                    this.TableDataPozadavky()
                }
                this.filtr=e.component.getCombinedFilter().filterValue;
            }        
            else this.filtr=undefined              
        },
        GetRequest() {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            var id = this.$route.params.Niceurl
            Number.isInteger(parseInt(id))
            this.$log.debug(id)
            if (isUndefined(id)) id = 0
            wf.GetRequest(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        if (result.Id.value != 0) {
                            var obj = {
                                id: result.Id.value,
                                reqtype: result.ReqType.value
                            }
                            self.ShowRequest(obj)
                        }

                    }
                                                                      
                }, window.this.ApiError());
        },
        convertNumberFields(obj) {
            if (typeof obj !== 'object') {
                return
            }

            for (var key in obj) {
                if (typeof obj[key] === 'number') {
                    obj[key] = String(obj[key])
                } else {
                    this.convertNumberFields(obj[key])
                }
            }
        },
        GetNiceUrl(url, click) {
            var self = this
            var gen = new window.General(window.channel, window.message);
            self.$log.debug(this.$route.params.Niceurl);
            self.$log.debug(url);
            self.$log.debug(JSON.stringify(url));
            this.convertNumberFields(url)
             
            var niceurlparam = this.$route.params.Niceurl
            self.$log.debug(niceurlparam);
            if (isUndefined(this.$route.params.Niceurl)) niceurlparam = ''
            gen.GetNiceUrl(niceurlparam, JSON.stringify(url),
                function (result) {
                    self.$log.debug(result);
                    if (result.Id.value == 0 && self.MountingNiceUrl) {
                        self.$log.debug(click);
                        self.GetRequest()
                        
                    }
                    
                    else if(result.Error.value.ErrorNum.value==0) {
                        if (url != '') self.NiceUrl = true
                        var r = /\d+/;
                        self.$log.debug(JSON.parse(result.Url.value));
                        self.$log.debug(result.Url.value.match(r)[0]);
                        self.ParamsQuery = JSON.parse(result.Url.value)
                        if (niceurlparam != '' && url == '') {
                            self.pozadavek = parseInt(self.ParamsQuery.Vypis)
                        }
                        else {
                            // self.addParamsToLocation({}, true)
                            self.$route.href = '/' + result.Component.value + '/' + result.NiceUrl.value
                            self.$log.debug(self.$route);
                            history.replaceState(
                                history.state, // je treba zde predavat stav historie, jinak obcas neslo routovat zpatky
                                null,
                                self.$route.path + '/' + result.NiceUrl.value
                            )
                        }
                    }
                    self.$log.debug(self.MountingData);
                    if (self.MountingData) self.QueryParams();
                   // else self.TableDataPozadavky()
                    
                }, window.this.ApiError());
        },
        GetContract(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetContract(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        /*
                          Smlouva: {
                    nazev: "",
                    cena: '',
                    predmet: '',
                    zverejnit: false,
                    referent: 0,
                    protistrana: [{ content: '', id: -1 }],
                    protistranaTXT: "",
                    komentar: '',
                    oblast: 0,
                    typSmlouvy: 0,
                          
                  },
                        */

                        self.Smlouva.nazev = result.Description.value
                        self.Smlouva.cena = result.Price.value
                        self.Smlouva.predmet = result.Subject.value
                        self.Smlouva.referent = result.Referent.value
                        self.Smlouva.referentTXT = result.ReferentTXT.value
                        self.Smlouva.typSmlouvyTXT = result.ContractTypeTXT.value
                        self.Smlouva.komentar = result.Request.value.Comment.value
                        self.Smlouva.zverejnit = result.Post.value
                        self.Smlouva.podpis = result.HandSigned.value
                        self.Smlouva.protistranaTXT = result.CounterPartyTXT.value
                        self.Smlouva.cjednaci = result.RefNumber.value
                    }
                }, window.this.ApiError());
        },
        ChangeOrderGrant() {
            var wh = new window.WorkFlow(window.channel, window.message);
            let self = this;
            wh.ChangeOrderFunding(window.pozId, self.grantid,
                function (result) {
                    self.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        notify({
                            message: self.$t('central-GrantZmenen'),
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                        self.popupZmenaGrantu=false
                        self.$log.debug(self.PopupType)
                        switch(self.PopupType){
                                case 1: case '1':
                                    self.GetOrder(window.pozId)
                                break;
                                case 4: case '4':
                                    self.GetLibraryOrder(window.pozId)
                                break;
                                case 6: case '6':
                                    self.GetProductionOrder(window.pozId)
                                break;
                        }
                        
                    }
                }, window.this.ApiError());
        },
        CopyRequest() {
            var wh = new window.WorkFlow(window.channel, window.message);
            let self = this;
            wh.CopyRequest(window.pozId,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.$route.query.Return=undefined
                        var obj = {
                            id: result.Value.value,
                            reqtype: self.PopupType
                        } 
                        self.popupObj = false;
                        setTimeout(() => {
                            self.ShowRequest(obj)
                            self.$route.path = '/Pozadavky/' + result.Value.value
                            history.pushState(
                                history.state,
                                null,
                                self.$route.path
                            )
                            self.DisableCopy = false        
                        }, 500);
                        self.handlePopupChange();
                        setTimeout(() => {
                            self.$log.debug(window.jazyk)
                        if(window.jazyk=='en') self.kopieText=' — Copy'
                        else self.kopieText=' — Kopie'  
                        notify({
                            message: self.$t('pozadavky-PozadavekZkopirovan'),
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'success', 5000); //'info' - barva templatu, 'success', 'warning', 'error'      
                        }, 1500);
                        
                    }
                }, window.this.ApiError());
        },
        DrawerSelected() {
            var e = window.Drawer
            if (window.prevElement != undefined && window.prevPosition != undefined)
                window.prevElement.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[window.prevPosition].childNodes[0].style.color = ''
            this.$log.debug(window.Drawer)
            this.$log.debug(this.$route.meta.id)
            this.$log.debug(e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length)
            for (var x = 0; x < e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes.length; x++) {
                // this.$log.debug(e.element.childNodes[1].childNodes[0].firstChild.childNodes[0].children[0].childNodes[x].attributes[1].value)
                if (this.$route.meta.id == e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].attributes[1].value) {
                    e.element.childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[x].childNodes[0].style.color = '#FF9900' 
                    window.prevElement = e
                  //  window.localStorage.setItem("refresh-page", 'Menu')
                    window.prevPosition = x
                }
            }
        },
        Preklad() {
            
        },
        DoNotDo(){
            optionsDate
        },
        QueryParams() {
            this.$log.debug('Queryparams')
            window.this.$log.debug(this.$route)
            this.$log.debug(this.ParamsQuery)
            if (Object.keys(this.ParamsQuery).length != 0) {
                if (this.ParamsQuery.Vypis != undefined) {
                    this.ValuePozadavek = parseInt(this.ParamsQuery.Vypis)
                    this.pozadavek = parseInt(this.ParamsQuery.Vypis)
                    window.this.$log.debug('uvnitr')
                    this.ZobrazZam()
                }
                if (this.ParamsQuery.Stav != undefined) {
                    this.ValueStav = parseInt(this.ParamsQuery.Stav)
                    this.stavpozadavek = parseInt(this.ParamsQuery.Stav)
                }
                if (this.ParamsQuery.Typ != undefined) {
                    this.ValueTyp = parseInt(this.ParamsQuery.Typ)
                    this.typpozadavek = parseInt(this.ParamsQuery.Typ)
                    if (this.ParamsQuery.Pozid != undefined && this.ParamsQuery.Pozid != null && this.ParamsQuery.Pozid > 0 ) {
                   // console.log(this.$route.query.Pozid)
                    this.ShowRequest('query')
                }
                  

                }
                if (this.ParamsQuery.Zam != undefined && this.ParamsQuery.Vypis != '1' && this.ParamsQuery.Vypis != 1 ) {
                    this.ValueZam = parseInt(this.ParamsQuery.Zam)
                    this.zamestnanec = parseInt(this.ParamsQuery.Zam)

                }
                if (this.ParamsQuery.Return != undefined) {
                  //  this.DisableCopy=true
                }
                window.this.$log.debug('pred')
                window.this.$log.debug('po')
                
            } 
          this.TableDataPozadavky()
           
        },
        SelectBoxVypis(e) {
        this.selectboxvypis = e.component
          // window.this.$log.debug(e)
           window.this.$log.debug('vypis')
        },
        Button4Click() {
            this.page++;
            this.TableDataPozadavky();
        },
        ButtonAllClick() {
            this.page=0;
            this.disable = true;
            this.rows.length = 0;  
            this.TableDataPozadavky();
        },
        SchvalProces(e) {
            this.$log.debug('schvalproces')
            this.$log.debug(e)
            
           // document.getElementById('ukazka').innerHTML = '';
          //  document.getElementById('ukazka').innerHTML = window.obsah;
           // this.$log.debug(e.component)
           // this.$log.debug(document.getElementById('ukazka'))
            //  this.$log.debug(document.getElementById('ukazka').innerHTML)
                
        },
        onHiding() {
            this.RefreshDataGrid(1);
        },
        NewRequest() {
            window.localStorage.setItem("pozid", '')
            switch (this.typpozadavek2.id) {
                case 1:  this.$router.push('/Objednavka')
                break;
                case 2:  this.$router.push('/Prevodmajetku')
                break;
                case 3:  this.$router.push('/Vyrazenimajetku')
                break;
                case 4:  this.$router.push('/ObjednavkaKnihovna')
                break;
                case 5:  this.$router.push('/Pracovni-obed-vecere')
                break;
                case 6:  this.$router.push('/Vyvojovedilny')
                break;
                case 7:  this.$router.push('/PlatKarta')
                break;
                case 12: this.$router.push('/Smlouva')
                break;
                case 13: this.$router.push('/PracCesta')
                break;
                
                   

            }          
        },
        NewRequestActiva() {
            this.$log.debug("Activa");
            var gen = new window.General(window.channel, window.message);
            gen.GetAPIRequest("Activa", // grant
                function (result) {
                  //  this.$log.debug(result)
                    window.open(
                        result.Value.value,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                   // window.location.href=result.Value.value;
                }, window.this.ApiError());
            
        },
        ClearValue(e) {
            if (e.component.option("text") != '') {
               e.component.reset()
            }
        },
        SetValue(e) {
            
         if (e.component.option("text") == ''){ 
            var self=this
            this.$log.debug(e)
            var gen = new window.General(window.channel, window.message);  
            var EmpID = JSON.parse(window.localStorage.getItem("user")).EmployeeID.value 
            self.zamestnanci.length = 0;
            //  self.$log.debug(employee)
            gen.ListEmp(EmpID,
                function (result) {
                    //  self.$log.debug(result.fResponseObject.result.Result.List.length)
                    //    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                    e.component.option("value", EmpID)    
                    e.component.repaint()
                }, window.this.ApiError());
            
            }
        },
        SearchInput(e) {
            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetEmployees(e)
            }, 1000)

        },
        setSelectedValue(e) {
            if (!this.MountingData) {
            this.page = 1;
            this.$log.debug(e);
            this.$log.debug('setselectedvalue');
            switch (e.element.id) {
                case '1':
                    this.ValueStav=99
                    this.pozadavek = e.value
                    this.ZobrazZam();
                    this.addParamsToLocation({ Vypis: e.value })
                    this.TableDataPozadavky()
                    break;
                case '2':
                    this.zamestnanec = e.value         
                    this.addParamsToLocation({ Zam: e.value })      
                    this.TableDataPozadavky() 
                    break;
                case '3':
                    this.typpozadavek = e.value
                    this.addParamsToLocation({ Typ: e.value })
                    this.TableDataPozadavky()
                    break;
                case '4':
                    this.stavpozadavek = e.value
                    this.TableDataPozadavky()
                    this.addParamsToLocation({ Stav: e.value })
                    break;
                case '5': case 5:
                    this.grantid = e.value
                    break;
            /*    
            case '6':
                this.stavpozadavek = e.value
                break;
            case '7':
                this.stavpozadavek = e.value
                break;
                */
            }
            }
            
            // this.SkipDiacritics(this.typpozadavek2.content)
            //  this.pozadavek=e.value
            // this.selectedValue = e.value;
        },
        addParamsToLocation(params) {
            this.$log.debug(this.$route)
            this.$log.debug(params)
            this.$log.debug(this.clear)
            if (this.$route.query.Return != undefined) {
                // this.$router.push('/' + this.$route.query.Return)
               // if (this.$route.query.Return == 'Dotazovac') this.$router.push(window.RedirectURL)
                if (this.$route.query.Return == 'Aktualne') this.$router.push('/Aktualne')
                else this.$router.back()
                return 1;
            }
            
            if (!this.MountingNiceUrl) {
                this.$route.path = '/Pozadavky'
                this.$route.params.Niceurl = ''

            }
            this.$log.debug(this.$route)
            if (this.clear) {

                history.pushState(
                    history.state,
                    null,
                    this.$route.path
                )
                this.clear = false
            }
            else {
                this.ParamsQuery = { ...this.ParamsQuery, ...params }
                params = this.ParamsQuery
                history.pushState(
                    history.state,
                    null,
                    this.$route.path +
                    '?' +
                    Object.keys(params)
                        .map(key => {
                            return encodeURIComponent(key) + '=' +
                                //encodeURIComponent(params[key]
                                (encodeURIComponent(params[key]) == 'undefined' ? '' : encodeURIComponent(params[key]))
                        })
                        .join('&')
                )
            }
         //   this.ParamsQuery = {}
        },
        ShowRequest(e) {
            this.$log.debug(e)
            let self = this;
            var pozid, reqtype
            
            setTimeout(() => {
                const selection = window.getSelection();
                this.$log.debug()
            if (selection && selection.toString().length > 0) {
                // User is selecting text; do not proceed
                return;
            }
             
            if(e!='query' && isUndefined(e.id)){ // pri otevreni pozadavku klikem
                this.$log.debug("From query 1");
                window.pozId=e.data.id
                self.pozId = e.data.id
                this.$log.debug(e.data.reqtypeid)
                this.PopupType=e.data.reqtypeid
                this.handlePopupChange();
                pozid = e.data.id
                reqtype = e.data.reqtypeid
                this.$route.path = '/Pozadavky/' + e.data.id
                history.pushState(
                    history.state,
                    null,
                    this.$route.path
                )
               
               // this.addParamsToLocation({ Pozid: pozid, Typ: reqtype})
                //this.$router.replace({ path: 'Pozadavky', query: { plan: 'private' } });
                
            }
            else if(e=='query') {  // pri otevreni pozadavku pres url
                pozid = this.$route.query.Pozid
                reqtype = this.$route.query.Typ
                this.PopupType = reqtype
                this.handlePopupChange();
                this.$log.debug("From query");
                self.pozId = pozid
                window.pozId = pozid
            }
            else {       // pri otevreni pozadavku pres kopirovani objednavky
                pozid = e.id
                reqtype = e.reqtype
                this.PopupType = reqtype
                this.handlePopupChange();
                this.$log.debug("Reqtype="+reqtype);
                self.pozId = pozid
                window.pozId = pozid
            }
            
            switch (reqtype) {
                case 1: case '1':
                    this.typ = 'Objednavka'
                    self.popupObj = true;
                    this.GetOrder(pozid)
                    this.$log.debug('volam objednavku')
                    this.$log.debug('po objednavce')
                    break;
                case 11: case '11':
                    this.typ = 'Faktura'
                    self.popupFaktura = true;                   
                    this.GetInvoice(pozid)
                    break;
                case 2: case '2':
                    this.typ = 'Prevodmajetku'
                    self.popupPrevod = true;
                    this.GetAssetTransfer(pozid)
                    break;
                case 3: case '3':
                    this.typ = 'Vyrazenimajetku'
                    self.popupVyrazeni = true;
                    this.GetAssetDispose(pozid)
                    break;
                case 4: case '4':
                    this.typ = 'ObjednavkaKnihovna'
                    self.popupObjKnihovna = true;
                    this.GetLibraryOrder(pozid)
                    break;
                case 5: case '5':
                    this.typ = 'Pracovni-obed-vecere'
                    self.popupPracSetkani = true;
                    this.GetBusinessDinner(pozid)
                    break;
                case 6: case '6':
                    this.typ = 'Vyvojovedilny'
                    self.popupVD = true;
                    this.GetProductionOrder(pozid)
                    break;
                case 12: case '12':
                    this.typ = 'Smlouva'
                    self.popupSmlouva = true;
                    this.GetContract(pozid)
                    break;
                case 13: case '13':
                    this.typ = 'PracCestaSoukrVozidlo'
                    self.popupPracCesta = true;
                    this.GetCarUsageApproval(pozid)
                    break;
                case 7: case '7':
                    this.typ = 'PlatKarta'
                    self.popupPlatKarta = true;
                    this.GetCardPaymentNotice(pozid)
                    break;
                    
            }
            // TODO globalni promenna komentare window.pozKomentar = objKomentar
            
            //self.popupVisible = true; 
        }, 0);
        },
        GetCardPaymentNotice(id) {
            let self = this
            this.GridPolozky.length=0;
            let self2 = this.GridPolozky;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetCardPaymentNotice(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.formDataCardPayNotice.JmenoAPrijmeni=result.CardOwnerTXT.value;
                        self.formDataCardPayNotice.UcelPlatby=result.PurposeTXT.value;
                        self.formDataCardPayNotice.Dodavatel=result.Supplier.value;
                        self.formDataCardPayNotice.DodavatelAdresa=result.SupplierTXT.value;
                        self.formDataCardPayNotice.JinyDodavatelAdresa=result.SupplierOtherTXT.value;
                        self.formDataCardPayNotice.Cena=result.Price.value;
                        self.formDataCardPayNotice.Mena=result.Currency.value;
                        self.formDataCardPayNotice.MenaTXT=result.CurrencyTXT.value;
                        self.formDataCardPayNotice.PlacenoDne=result.DateOfPayment.value;
                        self.formDataCardPayNotice.TypUhrady=result.TypeOfCardUse.value;
                        self.formDataCardPayNotice.TypUhradyTXT=result.TypeOfCardUseTXT.value;
                        self.formDataCardPayNotice.TypUhradyIDENT=result.TypeOfCardUseIDENT.value;
                        self.formDataCardPayNotice.souhlas=result.Consent.value;
                        self.formDataCardPayNotice.PlatebniKartaID=result.CardID.value;
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                        //  self.$log.debug(result.List.value.items[x]);
                        var i = self2.length;
                        var dateFrom=result.Items.value.items[x].DateFrom.value;
                        var dateTo=result.Items.value.items[x].DateTo.value;
                        var cislocesty=result.Items.value.items[x].TripID.value;
                        if (!dateFrom || new Date(dateFrom).getFullYear() < 1900) {
                            dateFrom = '';} else {//
                            }
                        if (!dateTo || new Date(dateTo).getFullYear() < 1900) {
                            dateTo = '';} else {//
                            }
                        dateFrom=self.returnDateFormatFromString(dateFrom);
                        dateTo=self.returnDateFormatFromString(dateTo);
                        if (!cislocesty || cislocesty == 0) {
                            cislocesty = null;
                            }
                        self2[i] = {
                            CountryNum: result.Items.value.items[x].CountryNum.value,
                            CountryTXT: result.Items.value.items[x].CountryTXT.value,
                            DatumOd: dateFrom,
                            DatumDo: dateTo,
                            PolID: result.Items.value.items[x].PolID.value,
                            Grant: result.Items.value.items[x].RequestGroup.value,
                            GrantTXT: result.Items.value.items[x].RequestGroupTXT.value,
                            TripID: cislocesty,
                            TripTXT: result.Items.value.items[x].TripTXT.value,
                            Cena: result.Items.value.items[x].Value.value,
                            ZamID: result.Items.value.items[x].ZamId.value,
                            ZamTXT: result.Items.value.items[x].ZamTXT.value,
                            
                        };
                    }
                    self.RefreshDataGrid(7)
             
                    }
                }, window.this.ApiError());
                this.GetKartyUplatneni();
                

        },
        GetKartyUplatneni(){
            let self = this;
            var gen = new window.General(window.channel, window.message);
                gen.ListLookupDetail('KartyUplatneni', 1, 25,
                    function (result) {
                        window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.typyKartyUplatneni.length;
                        self.typyKartyUplatneni[i] = {
                        content: result.List.value.items[x].Value.value,
                        id: result.List.value.items[x].Id.value,
                        idx: i,
                        ident1: result.List.value.items[x].Ident1.value,
                        };
                    }
                    }, window.this.ApiError());
        },
        GetBusinessDinner(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetBusinessDinner(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.formData.podnik = result.Restaurant.value
                        self.formData.cena = result.Price.value
                        self.formData.spropitne = result.TipValue.value
                        self.formData.cenaCelkem = result.TotalPrice.value
                        self.formData.datumKonani = result.Date.value
                        self.formData.typPlatby = result.Cis_TypyPlateb.value
                        self.formData.hoste = result.Guests.value
                        self.formData.zadatel = result.Request.value.Owner.value                      
                        self.formData.zamestnanci = result.EmployeesTXT.value.items.join(', ');
                        //   self.selectedZamestnanci = result.Employees.value.items
                        // if(result.Employees.value.items.length>0) self.PocetKolonek++
                        self.formData.komentar = result.Request.value.Comment.value
                       // self.currentGrant = result.Request.value.Funding.value
                        self.formData.grant= result.Request.value.FundingTXT.value

                    }
                }, window.this.ApiError());
        },
        GetCarUsageApproval(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            if (self.CarUsageTypypouziti.length == 0) {self.CisTypyPouziti();} // pouze v případě, že pole s číselníkem není naplněno
            wf.GetCarUsageApproval(id,
                function (result) {
                    self.$log.debug(result);
                    self.$log.debug('Vysledek GETCARUSAGEAPPROVAL');
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.formDataCarUsage.ridicskyprukazcislo = result.DriversLicence.value
                        self.formDataCarUsage.platnost_ridic_skoleni_do = result.Driver_Training_To.value
                        self.formDataCarUsage.platnost_lekar_prohlidka_do = result.Doctors_Examination_To.value
                        self.formDataCarUsage.cil = result.Destination.value
                        self.formDataCarUsage.odhad_cesta_km = result.Length_Estimation.value
                        self.formDataCarUsage.ucel_cesty = result.Purpose.value
                        self.formDataCarUsage.konani_od = result.Date_From.value
                        self.formDataCarUsage.konani_do = result.Date_To.value
                        self.formDataCarUsage.cislo_vozidla = result.Ev_Car_ID.value
                        self.cislo_vozidla_k_zobrazeni = result.Ev_Car_ID.value
                        self.formDataCarUsage.cis_pouzitiTXT = result.Cis_PouzitiTXT.value
                        self.formDataCarUsage.cis_duvod_potrebyTXT = result.Cis_Duvod_PotrebyTXT.value
                        self.formDataCarUsage.jinyDuvodTXT = result.Other_ReasonTXT.value
                        self.formDataCarUsage.cis_duvod_potreby = result.Cis_Duvod_Potreby.value
                        //self.formDataCarUsage.spz = result.Ev_Car_ID.value
                        //self.formDataCarUsage.vyrobni_znacka = result.Ev_Car_ID.value
                        self.GetCarFromAPI();
                        
                    }                    
                }, window.this.ApiError());
                
        },
        CisTypyPouziti(){
            let self = this;
            self.CarUsageTypypouziti.length = 0
            var gen = new window.General(window.channel, window.message);
            gen.ListLookupDetail('DuvodyPouziti', 1, 25,
                function (result) {
                    window.this.$log.debug(result);
                    window.this.$log.debug('DuvodyPouziti');
                    for (var x = 0; x < result.List.value.items.length; x++) {
                    //window.this.$log.debug(result.List.value.items[x]);
                        var i = self.CarUsageTypypouziti.length;

                        self.CarUsageTypypouziti[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                            ident: result.List.value.items[x].Ident.value,
                        };
                    }
                }, window.this.ApiError());
        },
        GetCarFromAPI(){
            let self = this;
            self.$log.debug('GetCarFromAPI')
            var pers = new window.Personal(window.channel, window.message);
            pers.GetCarWithEvCarID(self.cislo_vozidla_k_zobrazeni, function (result) {
                window.this.$log.debug(result);
                self.formDataCarUsage.spz = result.Licence_Plate.value;
                self.formDataCarUsage.vyrobni_znacka = result.Manufacturer.value;
                self.formDataCarUsage.objem_valcu = result.Engine_Content.value;
                self.formDataCarUsage.phm_druh = result.cis_FuelType.value;
                self.formDataCarUsage.spotreba = result.Consumption.value;
                self.formDataCarUsage.STK_platnost = result.STK_DateTo.value;
                self.formDataCarUsage.povinne_pojistovna = result.Povinne_Insurance.value;
                self.formDataCarUsage.povinne_cislo_pojistky = result.Povinne_NumOfInsurance.value;
                self.formDataCarUsage.povinne_od = result.Povinne_DateFrom.value;
                self.formDataCarUsage.povinne_do = result.Povinne_DateTo.value;
                self.formDataCarUsage.havarijni_pojistovna = result.Havarijni_Insurance.value;
                self.formDataCarUsage.havarijni_cislo_pojistky = result.Havarijni_NumOfInsurance.value;
                self.formDataCarUsage.havarijni_od = result.Havarijni_DateFrom.value;
                self.formDataCarUsage.havarijni_do = result.Havarijni_DateTo.value;
                self.formDataCarUsage.jinyvlastnik_jmeno = result.Owner_Firstname.value;
                self.formDataCarUsage.jinyvlastnik_prijmeni = result.Owner_Surname.value;
                self.formDataCarUsage.palivoTXT = result.cis_FuelTypeTXT.value;
                self.ValidateDatesBusinessTrip();
            },
            window.this.ApiError());
        },
        SearchDelay(search) {
            let self = this;
            clearTimeout(this.timer)
            // this.$log.debug(search)
            this.timer = setTimeout(() => {
                self.TableData(search);
            }, 1000)

        },
        SearchInputTab: function (e) {
            //  this.$log.debug(e)
            if (e.name === "searchPanel") {
               // this.SearchDelay(e.value)
            }
        },
        RefreshDataGrid(param) {          
            this.$log.debug('this v refreshdatagrid funkci')
            var self=this
            if (param == 1) {
                self.$log.debug('refreshdatagrid pozadavky')
                this.$refs.mygrid.instance.getDataSource().reload()
            }

            if (param == 2) {
                // self.$log.debug(this)
                self.$log.debug('refreshdatagrid knihovna objednavky')
                this.$refs.mygridpopup.instance.getDataSource().reload()
            }

            if (param == 3) {
                self.$log.debug('refreshdatagrid prevod')
                this.$refs.mygridpopupprevod.instance.getDataSource().reload()

            }
            if (param == 4) {
                self.$log.debug('refreshdatagrid vyrazeni')
                //self.$log.debug(this.$refs)
                this.$refs.mygridPopUpVyrazeni.instance.getDataSource().reload()
            }
            if (param == 5) {
                self.$log.debug('refreshdatagrid obj zas')
                this.$refs.mygridpopupobj.instance.getDataSource().reload()
            }
            if (param == 6) {
                self.$log.debug('refreshdatagrid invoice')
                this.$refs.mygridpopupinvoice.instance.getDataSource().reload()
            }
            if (param == 7) {
                self.$log.debug('refreshdatagrid platkarta')
                this.$refs.mygridplatkartypol.instance.getDataSource().reload()
            }

        },

        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        clearEvents() {
            this.events = [];
        },
        OrderDeliveryTypes(id,days) {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('ObjDobaDodani', '', 1, 50,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        if (id == result.List.value.items[x].Id.value) self.typDodaniContent = result.List.value.items[x].Value.value
                    }
                    self.urgentniDodani=true
                    self.pozUrgentniDodani = days + 'd ' +  self.typDodaniContent 
                }, window.this.ApiError());
        },
        GetOrder(orderid) {
            window.NelzeSchvalovat = false
            let self = this
            let self2 = this.GridPolozky;
            self2.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            this.$log.debug("GetOrder " + orderid)

            wf.GetOrder_v3(orderid,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else { 
                    self.grantid = parseInt(result.Request.value.Funding.value) 
                    self.pozGrant = result.Request.value.FundingTXT.value
                    self.pozDodavatel = result.SupplierTXT.value
                    self.objKomentar = result.Request.value.Comment.value
                    self.RequestState= result.Request.value.ReqState.value   
                    if (self.RequestState == 0 || self.RequestState == 4) {
                        self.ListFunding()
                        self.GrantEdit = true
                    }
                    else self.GrantEdit = false    
                        if (result.DateOfDelivery.value > 0) self.OrderDeliveryTypes(result.StateOfDelivery.value, result.DateOfDelivery.value)
                        if (result.Items.value.items.length < 1) window.NelzeSchvalovat = true   
                         else window.NelzeSchvalovat = false            
                    for (var x = 0; x < result.Items.value.items.length; x++) {
                        //  self.$log.debug(result.List.value.items[x]);
                        var i = self2.length;
                        if (result.Items.value.items[x].Currency.value == 0) result.Items.value.items[x].CurrencyTXT.value = 'CZK'
                        self2[i] = {
                            description: result.Items.value.items[x].Description.value,
                            typ: result.Items.value.items[x].Order.value,
                            specifikace: result.Items.value.items[x].ClassificationTXT.value,
                            specid: result.Items.value.items[x].Classification.value,
                            jednotka: result.Items.value.items[x].MeasureUnit.value,
                            pocet: result.Items.value.items[x].Amount.value,
                            mena: result.Items.value.items[x].CurrencyTXT.value,
                            menaid: result.Items.value.items[x].Currency.value,
                            cena: result.Items.value.items[x].UnitPrice.value,
                            celkem: result.Items.value.items[x].TotalCZK.value ,
                            AssetNumber: result.Items.value.items[x].AssetNumber.value,
                            ProductNumber: result.Items.value.items[x].ProductNum.value,
                            Id: result.Items.value.items[x].Id.value,
                            OrderNumber: result.Items.value.items[x].OrderNumber.value,
                            DeliveryNumber: result.Items.value.items[x].DeliveryNumber.value,
                            LiquidationNumber: result.Items.value.items[x].LiquidationNumber.value,
                            LiquidationNumberID: result.Items.value.items[x].LiquidationNumberID.value,
                            LiquidationCanDownloadFile: result.Items.value.items[x].LiquidationCanDownloadFile.value,
                            InStock: result.Items.value.items[x].InStock.value,
                            Link: result.Items.value.items[x].Link.value,
                        };
                    }
                        self.RefreshDataGrid(5)
                                
                    }
                }, window.this.ApiError());
        },
        ListFunding() {
            let self = this;
            self.granty.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.ListFunding(1, 3,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        self.granty[x] = {
                            content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
                            //  number: result.List.value.items[x].Text.value,
                            id: result.List.value.items[x].ID.value,
                        };
                    }
                  //  self.grantid = self.granty[0].id;
                }, window.this.ApiError());
        },
        GetLibraryOrder(orderid) {
            window.NelzeSchvalovat = false
            let self = this
            let self2 = this.GridPolozky;
            self2.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetLibraryOrder_v2(orderid,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.objKomentar = result.Request.value.Comment.value
                        self.pozGrant = result.Request.value.FundingTXT.value      
                        self.grantid = parseInt(result.Request.value.Funding.value) 
                        var RequestState= result.Request.value.ReqState.value
                        if (RequestState == 0 || RequestState == 4) {
                        self.ListFunding()
                        self.GrantEdit = true
                        }
                        else self.GrantEdit = false  
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                            //  self.$log.debug(result.List.value.items[x]);
                            var i = self2.length;
                            if (result.Items.value.items[x].Currency.value == 0) result.Items.value.items[x].CurrencyTXT.value = 'CZK'
                            self2[i] = {
                                mena: result.Items.value.items[x].CurrencyTXT.value,
                                description: result.Items.value.items[x].Title.value,
                                pocet: result.Items.value.items[x].Amount.value,
                                menaid: result.Items.value.items[x].Currency.value,
                                cena: (result.Items.value.items[x].UnitPrice.value),
                                celkem: (result.Items.value.items[x].TotalCZK.value),
                                //cena: (result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
                                // celkem: (result.Items.value.items[x].Amount.value * result.Items.value.items[x].UnitPrice.value).toLocaleString('cs-CZ'),
                                Id: result.Items.value.items[x].Id.value,
                                deponovano: result.Items.value.items[x].DepositedAtTXT.value,
                                forma: result.Items.value.items[x].FormTXT.value,
                                isbn: result.Items.value.items[x].ISBN_ISSN.value,
                                url: result.Items.value.items[x].Url.value,
                                typ: result.Items.value.items[x].TypeOfDocumentTXT.value, 
                            };
                        }
                        self.RefreshDataGrid(2)
                    }
                }, window.this.ApiError());
                
        },
        GetInvoice(orderid) {
            window.NelzeSchvalovat = false
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            let self2 = this.GridPolozky
            self2.length=0;
            wf.GetInvoice(orderid,
                function (result) {
                    self.$log.debug(result);
                    self.$log.debug('GetInvoice');
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        //if (result.List.value.items.ClassificationTXT.value.getFullYear() == 1899){result=null} else 
                        { self.fakZarazeni = result.ClassificationTXT.value }
                        //if (result.List.value.items.DateDue.value.getFullYear() == 1899){result=null} else 
                        { self.fakDatumSpl = result.DateDue.value }
                        //if (result.List.value.items.DateIssued.value.getFullYear() == 1899){result=null} else 
                        { self.fakDatumVys = result.DateIssued.value }
                        //if (result.List.value.items.DatePayment.value.getFullYear() == 1899){result=null} else   
                        { self.fakDatumZap = result.DatePayment.value }
                        //if (result.List.value.items.DateReceived.value.getFullYear() == 1899){result=null} else   
                        { self.fakDatumPri = result.DateReceived.value }
                        self.fakCislo = result.Number.value
                        self.fakObj = result.Order.value
                        self.fakMena = result.Currency.value
                        self.fakCenaToPayCZK = result.PriceToPayCZK.value
                        self.fakCenaFinalniCZK = (result.FinalPriceCZK.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
                        self.fakCisloUctu = result.PaymentAccountTXT.value
                        self.fakTypPlatby = result.PaymentTypeTXT.value
                        self.fakDodavatel = result.SupplierTXT.value
                        var EmpID = '.'+JSON.parse(window.localStorage.getItem("user")).EmployeeID.value+'.' 
                        self.fakCastkaSchvalovana=0;
                        for (var x = 0; x < result.Items.value.items.length; x++) {
                            //  self.$log.debug(result.List.value.items[x]);
                            var i = self2.length;
                            var estimatedPriceRes
                            if (result.Items.value.items[x].OriginalPrice.value==0) {estimatedPriceRes=''} else {estimatedPriceRes=(result.Items.value.items[x].OriginalPrice.value) }
                            var inputDedicatedTo =(result.Items.value.items[x].DedicatedTo.value)
                            var kMemuVyjadreniRes;
                            kMemuVyjadreniRes= inputDedicatedTo.includes(EmpID);
                            if (kMemuVyjadreniRes) {self.fakCastkaSchvalovana=self.fakCastkaSchvalovana+result.Items.value.items[x].Price.value}          
                            self2[i] = {
                                Zdroj: result.Items.value.items[x].Funding.value,
                                Castka: result.Items.value.items[x].Price.value,
                                CastkaOdhad: estimatedPriceRes,
                                CastkaOdhadMena: result.Items.value.items[x].OriginalCurrency.value,
                                KMemuVyjadreni: kMemuVyjadreniRes,
                                TEST:EmpID, 
                                Popis: result.Items.value.items[x].Name.value,
                                Pocet: result.Items.value.items[x].Amount.value,
                                Jednotka: result.Items.value.items[x].MeasureUnit.value,
                                Komentar: result.Items.value.items[x].Comment.value,
                            };
                        }
                        self.fakCastkaSchvalovana=(self.fakCastkaSchvalovana).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
                        self.RefreshDataGrid(6)                        
                    }
                }, window.this.ApiError());
        },
        GetProductionOrder(orderid) {
            this.$log.debug('volam getproductionorder')
            window.NelzeSchvalovat = false
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetProductionOrder(orderid,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.production.popis = result.Description.value;
                        self.production.invc = result.AssetNumber.value;
                        self.production.oprava = result.Repair.value;   
                        self.production.grant = result.Request.value.FundingTXT.value; 
                        self.grantid = parseInt(result.Request.value.Funding.value) 
                        var RequestState= result.Request.value.ReqState.value
                        if (RequestState == 0 || RequestState == 4) {
                        self.ListFunding()
                        self.GrantEdit = true
                        }
                        else self.GrantEdit = false  
                    }
                }, window.this.ApiError());
                
        },
        GetAssetTransfer(orderid) {
            window.NelzeSchvalovat = false
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            self.majetekList.length = 0
            wf.GetAssetTransfer(orderid,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.prevodNa = result.TargetEmployeeTXT.value
                        for (var x = 0; x < result.List.value.items.length; x++) {

                            self.majetekList[x] = {
                                id: result.List.value.items[x].Id.value,
                                name: result.List.value.items[x].Name.value,
                                price: result.List.value.items[x].Price.value,
                                date: result.List.value.items[x].From.value,
                            };
                        }
                        self.RefreshDataGrid(3);
                   
                    }
                }, window.this.ApiError());
        },
        GetAssetDispose(orderid) {
            window.NelzeSchvalovat = false
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            self.majetekList.length = 0
            wf.GetAssetDispose(orderid,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.majetek = result.AssetItem.value
                        self.vyrZpusob = result.MethodTXT.value
                        self.vyrDuvod = result.Reason.value
                        self.majetekList[0] = {
                            id: result.AssetItem.value.Id.value,
                            name: result.AssetItem.value.Name.value,
                            price: result.AssetItem.value.Price.value,
                            date: result.AssetItem.value.From.value,
                        };
                       self.RefreshDataGrid(4);
                    }    
                }, window.this.ApiError());
        },
        GetEmployeesInit(e) {
            this.$log.debug(e)
            var self = this;
            var employee = '';
            if (this.$route.query.Zam != undefined)
                employee = this.$route.query.Zam
            var gen = new window.General(window.channel, window.message);
            self.zamestnanci.length = 0;
            //  self.$log.debug(employee)
            gen.ListEmp(':MYSUB:LIMIT' + employee,
                function (result) {
                        self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                    e.component.repaint();
                }, window.this.ApiError());
        },
        GetEmployees(e) {
            this.$log.debug(e)
            var self = this;
            var employee = e.component.option("text")
            var gen = new window.General(window.channel, window.message);  
            self.zamestnanci.length = 0;
            //  self.$log.debug(employee)
            gen.ListEmp(':MYSUB:LIMIT'+employee,
                function (result) {
                    //  self.$log.debug(result.fResponseObject.result.Result.List.length)
                    //    self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.zamestnanci[self.zamestnanci.length] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };

                    }
                    e.component.getDataSource().reload();
                }, window.this.ApiError());
        },
        open() {
            window.loader = this.$loading.show({
                loader: 'dots'
            });
        },
        hide() {
            window.loader.hide()
        },
        ZobrazZam() {
            let self = this
            //   window.this.$log.debug(this.pozadavek.content+' zobrazzam')
            if (self.pozadavek == 1) self.zamvisible = false
            else self.zamvisible = true
        },
        TableDataPozadavky() {
             //   this.$log.debug('search panel volani promenna '+this.SearchPanelVolani)
                window.this.$log.debug('TABLE DATA POZADAVKY')
                var wh = new window.WorkFlow(window.channel, window.message);
                let self = this;
                let Request = self.pozadavek;
                let EmpID = 0
                //  window.this.$log.debug(self.zamestnanec +' idecko')
                this.$refs.mygrid.instance.beginCustomLoading(this.$t("central-Loading"))
               // this.$refs.mygrid.instance. beginCustomLoading
                let RequestType = self.typpozadavek;
                let State = self.stavpozadavek;
                let Search = this.Hledat;
                window.this.$log.debug('Request ' + Request)
                window.this.$log.debug('EmpID ' + EmpID)
                window.this.$log.debug('State ' + State)
                window.this.$log.debug('RequestType ' + RequestType)
                window.this.$log.debug('Search ' + Search)
                window.this.$log.debug('Page ' + this.page)
                if(this.page!=0)
                this.disable = false
                if (this.page == 1  || this.page == 0) {
                    window.this.$log.debug('nulovani')
                    self.rows.length = 0;
                }
                 if (this.checked) Search = Search + ":ARCHIV"  
                window.this.$log.debug(this.$refs.mygrid.instance.getCombinedFilter())  
                if(!isUndefined(this.$refs.mygrid.instance.getCombinedFilter())) {
                this.page = 0    
                this.disable = true;
                self.rows.length = 0;
            }
             if (self.zamvisible == true && !isNull(self.zamestnanec) && !isUndefined(self.zamestnanec)) EmpID = self.zamestnanec;
           
                wh.ListRequests(Request, EmpID, State, RequestType, Search, this.page, this.listRequestCallCount,
                    function (result) {
                        window.this.$log.debug(result);
                        if (result.List.value.items.length == 0 || result.List.value.items.length < 10) {
                            self.disable = true;
                        }
                        for (var x = 0; x < result.List.value.items.length; x++) {

                            var i = self.rows.length;

                            self.rows[i] = {
                                content: result.List.value.items[x].Content.value,
                                owner: result.List.value.items[x].OwnerText.value,
                                reqtypetext: result.List.value.items[x].ReqTypeText.value,
                                reqstatetext: result.List.value.items[x].ReqStateText.value,
                                reqtypeid: result.List.value.items[x].ReqType.value,
                                reqstateid: result.List.value.items[x].ReqState.value,
                                lastchange: result.List.value.items[x].LastChange.value,
                                id: result.List.value.items[x].id.value
                            };
                        }
                        self.RefreshDataGrid(1)
                        self.$refs.mygrid.instance.endCustomLoading()
                    if (!self.MountingNiceUrl){
                        self.GetNiceUrl(self.ParamsQuery, true)
                    } 
                        self.MountingData = false
                        self.MountingNiceUrl = false  
                        self.needFocusToSearchPanel = true;
                        // self.hide();
                    }, window.this.ApiError());

        },

        RequestType(e) {
         //   let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            const store1 = dataSourceTypPozadavky1.store();
            const store2 = dataSourceTypPozadavky2.store();
            store1.clear()
            store2.clear()
            wh.ListReqTypes(true,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        //  window.this.$log.debug(result.List.value.items[x]);        

                            store1.insert({
                                content: result.List.value.items[x].Value.value,
                                id: result.List.value.items[x].Id.value,
                                category: result.List.value.items[x].Detail.value,

                            })
                                .then(
                                    () => {
                                       // window.this.$log.debug(dataObj)
                                        dataSourceTypPozadavky1.reload();
                                    },
                                    (error) => { window.this.$log.debug(error) /* ... */ }
                                );
                }
                    e.component.repaint()
                }, window.this.ApiError());
            wh.ListReqTypes(false,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        //  window.this.$log.debug(result.List.value.items[x]);        
                        if (x > 0) {

                            store2.insert({
                                content: result.List.value.items[x].Value.value,
                                id: result.List.value.items[x].Id.value,
                                category: result.List.value.items[x].Detail.value,

                            })
                                .then(
                                    () => {
                                     //   window.this.$log.debug(dataObj)
                                        dataSourceTypPozadavky2.reload();
                                    },
                                    (error) => { window.this.$log.debug(error) /* ... */ }
                                );
                        }
                    }
                    e.component.repaint()
                }, window.this.ApiError());

        },
        RequestStates(e) {
            window.this.$log.debug(e)
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            self.stavpozadavky.length = 0
            wh.ListReqStates(
                function (result) {
                    //   self.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  self.$log.debug(result.List.value.items[x]);
                        var i = self.stavpozadavky.length;

                        self.stavpozadavky[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }
                    //self.ValueStav = parseInt(self.$route.query.Stav)
                    //e.component.option('value', self.ValueStav)
                    e.component.repaint()
                }, window.this.ApiError());

        },
         GridLayoutReset(id) {
            this.$log.debug('GridLayoutReset '+id)
            this.$log.debug('GridLayoutState '+this.$refs.mygrid.instance.state)
            this.$log.debug(this.$refs.mygrid.instance)
            
            this.handleMainViewChange();
            switch (id) {
                case 'transfer':{
                    this.$refs.mygridpopupprevod.instance.state(null);
                    
                }
                    break;
                case 'dispose':{
                     this.$refs.mygridPopUpVyrazeni.instance.state(null);
                     
                }
                    break;
                case 'supply':{ 
                    this.$refs.mygridpopupobj.instance.state(null);
                    
                }
                    break;
                case 'library':{
                     this.$refs.mygridpopup.instance.state(null);
                     
                }
                break;
                case 'faktura':{
                     this.$refs.mygridpopupinvoice.instance.state(null);
                     this.handlePopupChange();
                     
                }
                break;
                case 'platebnikarta':{
                     this.$refs.mygridplatkartypol.instance.state(null);
                     this.handlePopupChange();
                     
                }
                     break;
                case 'requests':{
                    this.$refs.mygrid.instance.state(null);
                    /*var defaultColumnWidths = [];
                    var myGrid = this.$refs.mygrid.instance;

                    // Assuming you have defined your columns in the grid's configuration
                    var columns = myGrid.option("columns");

                    columns.forEach(function (column) {
                    defaultColumnWidths.push(column.width);
                    });

                    console.log(defaultColumnWidths);
                    myGrid.option("columns", columns); // Update the columns with default widths
                    this.handleMainViewChange();

                     this.$refs.mygrid.instance.refresh();
                     setTimeout(() => {
                            
                                    var element = document.getElementById("ScrollViewAPP");
                                    element.style.height = (element.clientHeight + 35) + "px";
                    }, 1000);*/
                }
                    break;
                  

            }
    
            
    },
    oldformatStringWithSpaces(input) {
        if ((typeof input === 'number') || (typeof input === 'string' && !isNaN(parseFloat(input)) && isFinite(input))) {
  const cleanedString = input.toString().replace(/[^0-9]/g, '');

  // Initialize the result string
  let result = '';

  // Iterate over the cleaned string and add spaces every three characters
  for (let i = cleanedString.length - 1, count = 0; i >= 0; i--) {
      result = cleanedString[i] + result;
      count++;

      if (count % 3 === 0 && i > 0) {
        result = ' ' + result;
      }
    }

  return result;
} else return input;
},
formatStringWithSpaces(input) {
    if ((typeof input === 'number') || (typeof input === 'string' && !isNaN(parseFloat(input)) && isFinite(input))) {
        let parts = input.toString().split('.');
        let cleanedString = parts[0].replace(/[^0-9]/g, '');

        // Initialize the result string
        let result = '';

        // Iterate over the cleaned string and add spaces every three characters
        for (let i = cleanedString.length - 1, count = 0; i >= 0; i--) {
            result = cleanedString[i] + result;
            count++;

            if (count % 3 === 0 && i > 0) {
                result = ' ' + result;
            }
        }

        // Append the decimal part back if it exists
        if (parts.length > 1) {
            result += ',' + parts[1];
        }

        return result;
    } else return input;
},
ShowOnlyMyRowsInDatagrid(inputDatagridRefString){
    switch (inputDatagridRefString) {
                case 'mygridpopupinvoice':{
                    //this.$refs.mygridpopupinvoice.instance.filter(['KMemuVyjadreni', '=', true]);
                }
                    break;
    }
},
resultBool(inputbool){
    var result;
    if (inputbool==true) {result=this.$t('pracSetkani.Ano')} else {result=this.$t('pracSetkani.Ne')}
    return result;

},
textSmlouvaReditelPodpisDodatek(inputbool){
            var vysledek;
            if(inputbool==false){vysledek=this.$t('Smlouvy.TextPodpisReditelNE')} else {vysledek=this.$t('Smlouvy.TextPodpisReditelANO')}
            return vysledek;
},
RowsInvoiceCreated(e){
    this.$log.debug("Rows Created")
    this.$log.debug(e)
    this.$log.debug(e.column)
    if(e.rowType == "data" && e.data.KMemuVyjadreni == true) 
    e.rowElement.style.backgroundColor = "#fcdb03";
        
      },
IsOtherReason(){
                    var itemIdToFind = this.formDataCarUsage.cis_duvod_potreby;
                    var vysledek=false;
                    let foundItem = this.CarUsageTypypouziti.find(item => item.id === itemIdToFind);
                    if (foundItem!=undefined){
                    this.$log.debug(foundItem,'FOUNDITEM');
                    if ((this.formDataCarUsage.cis_duvod_potreby == foundItem.id) && ((foundItem.ident).toUpperCase() == "OTHERREASON")) {
                        vysledek = true;
                    }
                    else {
                        vysledek = false;
                    }}
            return vysledek;
        },
        PrilohyClick(id) {
        window.this.$log.debug('PrilohyClick',id)
      this.PrilohyID=id
      window.this.$log.debug(id)
      this.popupPrilohyFaktury = true;
      //this.HidingPopupPrilohy()
    },
    HidingPopupPrilohy(){
        this.$nextTick(() => {
            this.$refs.mygridpopupobj.instance.getDataSource().reload()
          });

    },
    startHold(event, rowData) {
      this.holdTimer = setTimeout(() => {
        this.holdAction(rowData);  
      }, 2000);
    },
    endHold() {
      if (this.holdTimer) {
        clearTimeout(this.holdTimer);
        this.holdTimer = null;
      }
    },
    holdAction(rowData) {
      console.log("Long press detekován: ", rowData);
      notify({
          message: this.$t('central.textZkopirovan')+' '+rowData.id,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
      navigator.clipboard.writeText(rowData.id)
    },
    idCellTemplate(container, options) {
      const rowData = options.data;      
      const idCellElement = document.createElement('div');
      idCellElement.innerHTML = rowData.id;
      idCellElement.onmousedown = (event) => this.startHold(event, rowData);
      idCellElement.onmouseup = this.endHold;
      idCellElement.onmouseleave = this.endHold;
      container.append(idCellElement);
    }
       
    },
}
</script>

<style>
.flexboxcontainer{
  display: flex;
  align-items: center;
}
:deep(.dx-scrollable-content){
  padding-bottom:4px !important;
}
/* GLOBALNI PRO VSECHNY KOMPONENTY */
:root{
    --colorDoplnenaPole: rgb(253, 205, 115);
}
#idZmenaGrantuHlavickaProduction{
    margin-bottom: 0px !important;
    scale: 0.7;
}
#duvod{
    margin-right: 20px;
}
#DataGrid8,#DataGrid9,#DataGrid5,#DataGridPrevod{ 
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   border-color: rgba(150, 150, 150, 0.75) !important;
}
#DataGrid8detail,#DataGrid9detail,#DataGridPrevoddetail{min-height: 250px !important;}
#DataGrid5detail{min-height: 150px;}
#jazyk{
    margin-right: 0px;
}
#schvaltable {
  border: 1px dotted black;
  border-radius: 5px;
  padding: 2px;
  color: #000000 !important;
}
#idtd {
  border: 1px solid black;
  border-radius: 2px;
  
}
#idth{
  border: 1.25px solid black;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 2px;
}
#komentar{
    margin-right: 150px;
}
.child2{
    margin-right: 100px;
    float: right;
}
.button4,#buttonAll{
    margin-bottom: 10px;
}

@media screen and (max-width:400px){
    .buttonsDataGridSpodniLista, .buttonsDataGridHorniLista{
        width:27.5px !important;
    }
    .actionButtonsPozadavkyResizableA{
        width:27px !important;
        max-height: 50px;
    }
   .actionButtonsPozadavkyMargin{
        margin-right:5px !important;
    } 
    .actionButtonsPozadavkyResizableB, .btnTelSeznamActionA{
        width:27.5px !important;
        max-height: 50px;
    }    
    .dx-button-text{
        font-size:small !important;
        white-space: nowrap !important;
        line-height: 15px !important;
    }
    .dx-checkbox-container{
        font-size:xx-small !important;
    }
}
@media screen and (max-width:700px) {
    .nadpisH2Pozadavky{
    padding-top: 0px !important;
    font-size:small !important;
    }
    .nadpisH2Moduly,#popisekPozadavkyNacistVse{
        font-size:small !important;
    }
    .textyOutsideOfBoxes{
        font-size:xx-small !important;
    }
    .dx-button-has-text .dx-button-content {
  padding-top: 3px;
  padding-bottom: 3px;
}
}
@media screen and (max-width:700px) and (min-width:401px){
    .dx-button-text{
        font-size:small !important;
        white-space: break-spaces !important;
        line-height: 13px  !important;
    }
        .dx-checkbox-container{
        font-size:smaller !important;
    }
    }
#container {
    overflow: hidden;
    margin-bottom: 10px;
}

#inner {
    margin-top: 10px;
    overflow: hidden;
}
#vyt{
    margin-left: 20px;
}
.child {
    margin-top: 10px;
    margin-right: 20px;
    float: left;
}
.dx-button-text{
    line-height: 27px;
}
.dx-toolbar.dx-toolbar-items-container {
    height: 56px !important;
    overflow: visible;
}
.dx-icon-search{
    top: 0% !important;
}
.dx-theme-material-typography h2{
    font-size: 36px;
}
.dx-theme-material-typography h3 {
    font-size: 18px;
}
#close {
  border-color: transparent;
}
.actionsbuttons div {
    float: left;
    clear: both; 
    flex-direction: row;
}
.actionsbuttons.dx-template-wrapper.dx-item-content.dx-box-item-content{
    flex-direction: row;
}
.buttonclass{
    margin-right: 10px;
}
.buttons1 {
    margin-bottom: 10px ;
    display: inline-block;
}
.actionsPozadavky{
    display: inline-block;
}
#editovat{
    margin: 20px;
    margin-left: 0px;
}
#content{
    --width: 100%;
    width: calc(var(--width) - 245px);    
}
@media screen and (min-width: 1550px){
    .selectbox {
        min-width: 180px;
        width: 300px;
        /*rest of the styles*/
    }
    .selectboxZam {
        min-width: 200px;
        width: 320px;
        /*rest of the styles*/
    }
}
#h2{
    margin-bottom: 15px;
}


.dx-field {
    margin: 0 0 20px;
}

.dx-item.dx-toolbar-item.dx-toolbar-label{
    width: 15000px;
}


.dx-button{
    margin-right: 5px;
}
.dx-textarea {
    margin-bottom: 20px;
}

.dx-texteditor-label {
    font-size: 50px; /* Nefunguje */
}
.dx-searchbox .dx-icon-search {
    top: 0%;
}

.dx-template-wrapper {
    height: 100%;
}
body {
    margin: 0px;
}

#textBlock {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.6em;
}
  #ResponsiveBoxItemActionButtons{display:flex;flex-direction: row !important; flex-wrap: nowrap; padding: 10px 10px 10px 10px;}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 300px;
}

    .dx-list {
        width: inherit;
    }
    #mmenu {
        background-color: #6fb3db;
        border: 1px solid #CCCCCC;
        padding: 10px;
        
     
    }
    .polozekCelkem{
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .cenaCelkem {
            position: absolute;
            margin-right: 7px;
            right: 0;
            top: 5;
    }
    .sum {
    position: relative;
    }
    .bordersDataGridPozadavky{
        box-sizing: border-box;
    border-style: ridge;
    border-color: rgba(128, 128, 128, 0.075);
    border-radius: 10px;
    margin: 0 auto 0 auto;
    padding: 15px;
    width:97.5%;
    margin-bottom: 5px;
    }
    .bordersDataGridPozadavkyPopup,.bordersDataGridPozadavkyModuly{
    padding: 0 10px;
    width:100%;
    margin-bottom: 5px;   
    }
    .PopupDetailDeliciHR{
        color: var(--colorByThemeLighterHeaders);
        width: 97.5%;
    }
    #DataGrid{
        width:100%;
    }
    
    .prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.nadpisH2Moduly{
    text-align: left !important; 
    padding-left: 15px !important;
    margin-left: 0px !important;
    margin-bottom: 10px !important; 
}
.nadpisH2Pozadavky{
    text-align: left !important; 
    padding-left: 0px !important;
    margin-left: 125px !important;
    margin-bottom: 5px !important;
    padding-top: 10px !important;
    height: 45px !important; 
    font-size:large;
}
.textyOutsideOfBoxes{
    font-size:normal;
}
#link{float: left;}
.SpodniMenuUPozadavku{
    display: absolute;
    flex-direction: column;
    flex-wrap: wrap;
    border-style: solid;
    border-color: rgba(66, 66, 66, 0.5);
    border-left: 0;
    border-right: 0;
    border-top: 0;
   border-bottom: 0;
   border-width: 1px;
   border-radius: 2px;
    background-color: rgba(80, 80, 80, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width:100%;
    height:max-content !important;
    padding: 10px;
    margin-top: 250px;
    margin: 2px; 
}
.SpodniMenuDetailPozadavku{display:flex !important;
    flex-direction: row !important ;
    flex-wrap: nowrap !important;
    border-style: solid !important;
    border-color: rgba(66, 66, 66, 0.5) !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
   border-bottom: 0 !important;
   border-width: 1px !important;
   border-radius: 2px !important;
    background-color: rgba(80, 80, 80, 0.3)  !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    width:100% !important;
    margin-top: 250px !important;
    margin: 2px !important; 
    position: sticky !important;
    position: -webkit-sticky !important;
    bottom: 0.25rem !important;
    z-index: 99;
}
.fontAndColor{
    font-family: Arial, Helvetica, sans-serif !important;
}
#ResponsiveBoxItem{padding: 10px;
padding-left: 0px;}
.PrilohyPozadavky{ 
    border-style: dashed; 
    border-color: rgba(128, 128, 128, 0.4);
    padding: 0px 10px 0px;
    border-radius: 5px;
    border-width: 2px;
    background-color: rgba(255, 174, 0, 0.001);
    /*color: #000000;*/
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    user-select: none;
    /*width: 500px !important;*/ /* roztahuje vsechno */
    }
    .PrilohyPozadavkyNadpis{
        margin-top: -1px;
        font-weight: lighter;
        font-size:small !important;
        font-family: Arial, Helvetica, sans-serif !important;
        width:max-content;
    }

    #PopisVD{top: 0; margin-top: 0px;}
    .dx-scrollable-scroll-content{
        background-color: #003366 !important;
    border-radius:5px !important;
    }
    .dx-scrollable-scroll-content:active {
    cursor: grabbing !important;}
    .dx-scrollbar-hoverable{ border-radius: 5px !important;
        cursor:pointer !important;}
        .dx-scrollbar-hoverable:active {
    cursor: grabbing !important;}
 
    .h3{color: #000000;}
    .margin10left{margin-left: 10px;}
    .overflowXAuto{overflow-x:auto;}
    .lighterWeightFont{font-weight: lighter;}
    .clPozPracCesta > h3, .clPozPracCesta > div {padding-left:10px       
    }
    #idSummary{float:left}
    .dx-item.dx-toolbar-item.dx-toolbar-label{width: auto !important;}
.CestaBox {
     padding: 5px;
 }
 .inlineflexCesta,#odhadkm{
    display: inline-flex;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: normal;
 }
 #labelkm{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    padding-left: 5px;
}
.dxItemPracovniCesta{
    border: 1px solid rgb(172, 172, 172);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1;
    padding-top:4px;
    padding-bottom:4px;
    display:flex;
    flex-direction:row !important;
}
.dxItemPracovniCesta.dxItemSpecialLayout{
    border: 1px solid rgb(172, 172, 172);
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1;
    padding-top:4px;
    padding-bottom:4px;
    display:flex;
    flex-direction:column !important;
}
.dxItemPracovniCestaLast{border-bottom: 0;}
.dxItemPracovniCestaChild{
    align-self: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
}
.spanPracovniCestaBold{
    font-weight: 550
}
.MarginLeftNegative10{margin-left:-10px}
.divDoplnenaPole{
    background-color: var(--colorDoplnenaPole);
}
/*.dxCustomScrollViewPaddingNegativeTop {margin:-5px !important}*/
/*.dx-popup-content > .dxCustomScrollViewPaddingNegativeTop {padding:0px !important}*/
.dx-popup-content {padding:10px !important}
.clNadpisCesta{
    color: var(--colorByTheme);
    font-weight:bold;
    font-size: 14px
}
.clDoplnenaPoleChild2 {   margin-right: 10px;}
.clObnovitRozlozeniEdit:hover{
    background-color: var(--colorByThemeLighter) !important

}
.spanHlavicka{margin-top: 5px; margin-bottom: 0; font-weight:550;display: flex; margin-bottom:5px;}
#idZmenaGrantuHlavicka{    /* margin-top: -5px !important; */
    padding-top: 0px !important;
    margin-bottom: 10px !important;
    /* right: 0; */
    height: auto;
    width: auto;
    /* overflow: auto; */
    max-height: 20px;}
#divDataGridInvoice{
    margin-top: 10px !important;
}
#launcher-frame{
    max-width: 60px !important;
    min-width: 60px !important;
    height:45px !important;
}
#invoiceApproveAmount{
    color:#000000;
    font-weight:bold;
}
.hrPozadavky{
    width: 95%;
    color: var(--colorByThemeLighter);
}
hr.hrModuly{
    width: 100%;
    color: var(--colorByThemeLighter); 
}
img#imgZobrazitPolozky{
    position:static;
}
span#textZobrazitButton{
    margin: 0 auto 0 auto;
    display: flex;
    align-content: flex-end;
    }
#btnZobrazitPolozky{
   /* background-image: 
    repeating-linear-gradient(45deg, #f9f9f991 0px, #e8f0fff2 15px, transparent 15px, transparent 30px), 
    repeating-linear-gradient(45deg, #f9f9f991 0px, #e8f0fff2 15px, transparent 15px, transparent 30px)
background-position: left 0 top 0, right 0 top 0;
background-size: 30% 100%, 30% 100%;
background-repeat: no-repeat;*/
background-color: rgb(253, 205, 115);
width: auto !important
}
.cursPointer{
    cursor:pointer;
}
#lblZobrazitButton{
    margin: 0 15px 0 15px;
}
.clCheckBoxForma{
    background-color: #80808045;
    border: 1px solid #808080;
    padding: 0 0 0 1%;
    margin-top: 5px !important;
    border-radius: 5px;
 }
 .padding5TopBottom{
    padding-top: 5px;
  padding-bottom: 5px;
 }
 #actionbuttons{z-index: 10;}
 .DxSelectBoxResponsive{margin: 5px;}

 /* clNadpisAktualniStranky*/
 .clNadpisAktualniStranky h2{
    margin: 10px 5px !important;
    
}
.clNadpisAktualniStranky{
    display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-style: solid;
  border-color: rgba(66, 66, 66, 0.25);
  border-left: 0;
    border-left-width: 0px;
  border-right: 0;
    border-right-width: 0px;
  border-top: 0;
    border-top-width: 0px;
  border-bottom: 0;
    border-bottom-width: 0px;
  border-width: 1px;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /*background-color: var(--colorByThemeLighterHeaders);/*rgba(0, 10, 114, 0.1);rgba(80, 80, 80, 0.2);*/
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 99%;
  margin-top: 250px;
  margin: 2px;
    margin-top: 2px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0.5rem;
}
.hrDelici{
    margin-bottom: 0px;
    margin-top: 5px !important;
}
.centralOutlinedBox
{  
    border:  var(--colorByThemeLighterHeaders) solid 1px !important;
    color: var(--colorByThemeLighterHeaders)
}
.centralOutlinedBoxNoTopBorder
{  
border-top: none !important;   
}

.centralOutlinedBox:hover
{  
    border: 1px var(--colorByThemeLighterHeaders) solid !important;   
}
/*
.centralOutlinedBoxNoTopBorder:hover
{  
border-top: none !important;   
} */
.itemPracovniSetkani{
    font-size: 14px;
}
.btnCellTemp_Liquidation{
    width: 100%;
    height: 20px;
}
</style>
<style scoped>
.ToBeSignedAttachments{
    margin-left: -5px;
}
.emphasisDiv{
  background-color: var(--colorByThemeLighter35);
  padding: 5px;
  width: 100%;
}
</style>
