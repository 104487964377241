<template>
  <div>
    <DxSelectBox :disabled="Disabled" id="suppliers" :width="Width" height="45px" :label="defaultLabel"
      :search-enabled="true" :data-source="suppliers" display-expr="content" :placeholder="$t('central-Placeholder')"
      :search-timeout="1000" @value-changed="setSelectedValue" @input="SearchInput" noDataText="" :value="supplier[0]"
      :accept-custom-value="true" @customItemCreating="customItemCreating($event, 1)" :show-clear-button="SmazBtnVisibility" :maxLength="150">
      <DxValidator>
        <DxRequiredRule v-if="Validation" :message="$t('central-PoleMusiBytVyplneno')" />
      </DxValidator>
    </DxSelectBox>
  </div>
</template>

<script>
import DataSource from 'devextreme/data/data_source';
//import { suppliersData } from '@/data.js';
import { isNull, isUndefined } from 'lodash';

const suppliersDataSource = new DataSource({
  store: {
    data: '', // suppliersData,
    type: 'array',
    key: 'id',
  },
});

export default {
  name: 'SuppliersComponent',
  props: {
    Disabled: {
      type: Boolean,
      required: true,
    },
    Supplier: {
      type: Object,
      required: true,
    },
    Width: {
      type: String,
      required: false,
      default: 'auto'
    },
    Validation: {
      type: Boolean,
      required: false,
      default: true,


    },
    Label: {
      type: String,
      required: false,
    },
    SmazBtnVisibility:{
      type:Boolean,
      required:false,
      default: false,
    }
    
  },
  data() {
    return {
      suppliers: [],
      supplier: [],
      prevSupplier: null,
    };
  },
  mounted() {
    this.$log.debug('Mounted ' + this.Disabled + ' ' + this.Supplier);
  },
  computed: {
    defaultLabel() {
      return this.Label || this.$t('central-ObjednatOd');
    }
  },
  watch: {
    Supplier(newSupplier) {
      this.handleParentPropChange(newSupplier);
    },
  },
  methods: {
    GetSupplier(result) {
      this.$log.debug(result)
      this.suppliers.length = 0;
      /*
      self.suppliers[0] = {
        content: 'Libovolný dodavatel',
        id: 0,
       // event: 'listAddr',
      };
      */
      this.suppliers[0] = {
        content: result.content,
        id: result.id,
      };
      this.supplier[0] = this.suppliers[0]
      this.$log.debug(this.suppliers)
    },
    SearchInput(e) {
      let self = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        self.ListAddr(e);
      }, 1000);
    },
    ListAddr(supp) {
      let self = this;
      if (supp.component != undefined) {
        var e = supp;
        supp = e.component.option('text');
      }
      var gen = new window.General(window.channel, window.message);
      window.this.$log.debug(supp);
      self.suppliers.length = 0;
      gen.ListAddr(supp, 1, 25, function (result) {
        window.this.$log.debug(result);
    /*    self.suppliers[0] = {
          content: 'Libovolný dodavatel',
          id: 0,
          event: 'listAddr',
        };
        */
        for (var x = 0; x < result.List.value.items.length; x++) {
          
         // var i = self.suppliers.length;
          self.suppliers[x] = {
            content: result.List.value.items[x].Value.value,
            id: result.List.value.items[x].Id.value,
            event: 'listAddr',
          };
        }
        e.component.getDataSource().reload();
      }, window.this.ApiError());
    },
    customItemCreating(data, id) {
      window.this.$log.debug(data);
      window.this.$log.debug(data.text + ' text');
      if (!data.text || data.text=='' ) {
        data.customItem = null;
        this.supplier[0] = null
        return;
      }

      if (id == 1) {
        const newItem = {
          content: data.text,
        };

        data.customItem = suppliersDataSource
          .store()
          .insert(newItem)
          .then(() => suppliersDataSource.load())
          .then(() => newItem)
          .catch((error) => {
            throw error;
          });
        this.supplier[0] = { content: data.text, id: 0, event: 'itemCreating' };
      }
    },
    setSelectedValue(e) {
      window.this.$log.debug(e);
      switch (e.element.id) {
        case 'suppliers':
          this.supplier[0] = e.value;
          if (isUndefined(this.supplier)) {
           
            if (isUndefined(this.supplier.id)) this.supplier[0].id = 0;
          }
          if(isNull(e.value))  this.supplier[0] = null;
          var valueObject = {
            value: e.value,
            element: {
              id: e.element.id,
              
            }
          };
          this.$emit('value-changed', valueObject);
          break;
      }
    },
    handleParentPropChange(newSupplier) {
      this.$log.debug('Supplier prop changed:', this.prevSupplier, '->', newSupplier);
        this.GetSupplier(newSupplier)
        // Perform any necessary actions when the EmpId prop changes
      this.prevSupplier = newSupplier; // Update the previous value
    },
  },
};
</script>
