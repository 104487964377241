<template>
    <div class="divOrangeWarning">
        <img class="warningIconOrange" src="@/assets/images/alert.png" >
        <span class="warningText">{{translatedString}}</span>
    </div>  
</template>
  
<script>
//import notify from 'devextreme/ui/notify';

export default {
    name: 'WarningImage',
    components: {

    },
    props: {
        Disabled: {
            type: Boolean,
            required: false
        },
        RequestID: {
            type: Number,
            required: false
        },
        PopisChybyKEY: {
            type: String,
            required: false,
            default: 'pracCesta.ZkontrolujtePlatnost'
        },
        ColorRGBWarningBox: {
            type: String,
            required: false,
            default: 'rgb(255, 136, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        ColorRGBAnimation: {
            type: String,
            required: false,
            default: 'rgb(255, 136, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        WidthIcon:{
            type: String,
            required: false,
            default: '16px'
        },
        HeightIcon:{
            type: String,
            required: false,
            default: '16px'
        },
        WidthMessage: {
            type: String,
            required: false,
            default: 'auto' 
        },
        TextColor: {
            type: String,
            required: false,
            default: 'rgb(255, 255, 255)' // Ensure the default value is a string and enclosed in quotes 
        },
        BackgroundColor: { 
            type: String,
            required: false,
            default: 'rgb(0, 0, 0)' // Ensure the default value is a string and enclosed in quotes
        },
        Position: {
            type: String,
            required: false,
            default: 'absolute' // Ensure the default value is a string and enclosed in quotes
        },
        Border: {
            type: String,
            required: false,
            default: 'none' // Ensure the default value is a string and enclosed in quotes
        },
        TextPadding: {
            type: String,
            required: false,
            default: '5px 0' // Ensure the default value is a string and enclosed in quotes
        },
        Cursor: {
            type: String,
            required: false,
            default: 'text' // Ensure the default value is a string and enclosed in quotes
        },

    },
    watch: {
    },
    computed: {
        translatedString() {
        return this.$t(this.PopisChybyKEY);
    }        

    },
    data() {
        return {
        };
    },
    mounted() {
        this.MountedSetup()      
    },
    
    methods: {
        MountedSetup() {
            if (this.ColorRGBWarningBox != this.ColorRGBWarningBox.default) {
                document.documentElement.style.setProperty('--colorOfTextInfoBoxByUserInput', this.ColorRGBWarningBox);
            }
            if (this.ColorRGBAnimation != this.ColorRGBAnimation.default) {
                document.documentElement.style.setProperty('--colorOfBackgroundBlinkByUserInput', this.ColorRGBAnimation);
            }
            if (this.WidthIcon != this.WidthIcon.default) {
                document.documentElement.style.setProperty('--widthOfIconByUserInput', this.WidthIcon);
            }
            if (this.HeightIcon != this.HeightIcon.default) {
                document.documentElement.style.setProperty('--heightOfIconByUserInput', this.HeightIcon);
            }         
            if (this.WidthMessage != this.WidthMessage.default) {
                document.documentElement.style.setProperty('--widthMessageByUserInput', this.WidthMessage);
            }   
            if (this.TextColor != this.TextColor.default) {
                document.documentElement.style.setProperty('--textColorByUserInput', this.TextColor);
            }   
            if (this.BackgroundColor != this.BackgroundColor.default) {
                this.$log.debug('backgroundcolor nastavovani' + this.BackgroundColor)
                this.$log.debug(this.BackgroundColor)
                document.documentElement.style.setProperty('--backgroundColorByUserInput', this.BackgroundColor);
            } 
            if (this.Position != this.Position.default) {
                document.documentElement.style.setProperty('--positionByUserInput', this.Position);
            } 
            if (this.Border != this.Border.default) {
                document.documentElement.style.setProperty('--borderByUserInput', this.Border);
            } 
            if (this.TextPadding != this.TextPadding.default) {
                document.documentElement.style.setProperty('--textPaddingByUserInput', this.TextPadding);
            } 
            if (this.Cursor != this.Cursor.default) {
                document.documentElement.style.setProperty('--cursorByUserInput', this.Cursor);
            } 
            
        },
       
    },

};
</script>
<style scoped>
element {
    --colorOfBackgroundBlinkByUserInput: rgb(255, 136, 0);
}
.divOrangeWarning{display:flex;flex-direction:row;align-self:center;
    position: relative; /*var(--positionByUserInput) /* Změna na absolute */
}
.divOrangeWarning .warningText{
  visibility: hidden;
  width: var(--widthMessageByUserInput);
  background-color: var(--backgroundColorByUserInput);
  color: var(--textColorByUserInput);
  border-radius: 6px;
  padding: var(--textPaddingByUserInput);
  padding-left:5px;
  padding-right: 5px;
  position: var(--positionByUserInput);
  z-index: 9999;
  border: var(--borderByUserInput);
  border-width: thin;
  border-color: rgb(255, 136, 0);
  font-size: 13px;
  text-align: center;
} 
.divOrangeWarning:hover .warningText {
  visibility: visible;
  cursor: var(--cursorByUserInput);
}
.divOrangeWarning:hover{
    cursor:pointer;
}
.warningIconOrange{border-radius: 10%;padding:1.5px; 
    height:var(--heightOfIconByUserInput);
    width:var(--widthOfIconByUserInput);
    align-self: center;margin-left: 5px;
    animation: blinkingwarning 3s linear infinite; 
}
@keyframes blinkingwarning {
  50% {
   visibility:hidden;
   background-color: var(--colorOfBackgroundBlinkByUserInput);
  }
  100% {
    visibility:visible;
  }
}
@keyframes blinkingBorder {
  50% {
   visibility:hidden;
   border-color: var(--colorOfBackgroundBlinkByUserInput);
  }
  100% {
    visibility:visible;
  }
}
</style>
  