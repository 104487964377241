<template>
    <div height="calc(100% - 57px)">
        <div id="h2ab">
            <DxButton icon="back" :text="$t('majetekVyrazeni-VratitSeZpet')" height="auto" type="outlined"
                styling-mode="contained" id="link" @click="$router.push('Pozadavky')" />
            <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-if="zakladani">{{ $t('pracSetkani.Zalozeni') }}
            </h2>
            <h2 class="nadpisH2Pozadavky fontAndColor prevent-select " v-else>{{ $t('pracSetkani.Zalozeni') }}{{ poz + ' ('+state+')' }} </h2>
        </div>
        <form action="your-action" @submit="onFormSubmit($event)">
            <div id="obaleniFormulare">
                <div id="obalenigrid">
                    <DxResponsiveBox single-column-screen="sm" id="SmlouvaResponsiveBox">
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxRow :ratio="1" />
                        <DxCol :ratio="1" />
                        <DxCol :ratio="1" />
                        <DxCol :ratio="1" />
                       

                        <DxItem>
                            <DxLocation :row="0" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />

                            <div class="SmlouvaBox">
                                <div class="dx-field">
                                    <DxTextBox :disabled="stateid != 4" :id="1" width='auto' height="45px"
                                        :label="$t('pracSetkani.Podnik')" placeholder="" @value-changed="setSelectedValue"
                                        :value="formData.podnik" v-model:value="formData.podnik">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracSetkani.VyplntePodnik')" />
                                        </DxValidator>
                                    </DxTextBox>

                                </div>
                            </div>
                        </DxItem>

                        <DxItem width="400px">
                                <DxLocation :row="1" :col="2" :colspan="1" screen="lg" />
                                <DxLocation :row="1" :col="2" :colspan="1" screen="md" />
                                <DxLocation :row="2" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="2" :col="0" :colspan="3" screen="xs" />

                                <div class="SmlouvaBox" width='auto'>
                                    <DxDateBox :disabled="stateid != 4" :id="2" :label="$t('pracSetkani.DatumKonani')"
                                    v-model:value="formData.datumKonani"  Width='auto'
                                        height="45px" @value-changed=" setSelectedValue($event);" :accept-custom-value="false" :open-on-field-click="true">
                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracSetkani.VyplnteDatum')" />
                                        </DxValidator>
                                        </DxDateBox>
                                </div>
                            </DxItem>
                             <DxItem>
                                <DxLocation :row="1" :col="0" :colspan="2" screen="lg" />
                                <DxLocation :row="1" :col="0" :colspan="2" screen="md" />
                                <DxLocation :row="3" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="3" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <div class="flexboxcontainer">
                                            <FundingInstructions  :FundingID="objGrant[0].id" Ident="Pozadavky5"> </FundingInstructions>                         
                                            <DxSelectBox style="flex-grow: 1" :disabled="stateid != 4" :id="3" width="auto" height="45px" :label="$t('central-Grant')"
                                                    :data-source="granty" display-expr="content" value-expr="id" placeholder="" 
                                                    :value="currentGrant" @value-changed="setSelectedValue($event);" >
                                            <DxValidator >
                                            <DxRequiredRule :message="$t('central-GrantMusiBytVyplnen')" />
                                            </DxValidator>
                                        </DxSelectBox>
                                    </div>
                                </div>
                            </DxItem>

                        <DxItem>
                            <DxLocation :row="3" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="3" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="4" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="4" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                <DxTextArea style="margin-bottom: 0px;" :disabled="stateid != 4" :id="4" height="100px"
                                    :label="$t('pracSetkani.Hoste')" placeholder=""
                                    @value-changed="setSelectedValue" :value="formData.hoste">
                                    <DxValidator>
                                        <DxRequiredRule v-if="Vyplnte" :message="$t('pracSetkani.Vyplnte')" />
                                    </DxValidator>
                                </DxTextArea>
                            </div>
                        </DxItem>
                        <DxItem>
                                    <DxLocation :row="4" :col="0" :colspan="3" screen="lg" />
                                    <DxLocation :row="4" :col="0" :colspan="3" screen="md" />
                                    <DxLocation :row="5" :col="0" :colspan="3" screen="sm" />
                                    <DxLocation :row="5" :col="0" :colspan="3" screen="xs" />
                                    <div class="SmlouvaBox">     
                                        <DxTagBox :disabled="stateid != 4" :id="5" :showSelectionControls="true" :selectAllText="$t('central-OznacitVse')" :search-enabled="true" 
                                        @opened="ClearDataSourceTagBox"  :placeholder="$t('central-Placeholder')"
                                         :data-source="zamestnanci" display-expr="content" value-expr="id" @input="SearchInput" noDataText=""
                                         :selectedItems="selectedZamestnanci" @selectionChanged="TagBoxSelection" :label="$t('pracSetkani.Zamestnanci')"  ref="tagBoxA"  >
                                                 <DxValidator>
                                                    <DxRequiredRule v-if="Vyplnte" :message="$t('pracSetkani.Vyplnte')" />
                                                </DxValidator>
                                        </DxTagBox>
                                    </div>
                        </DxItem>
                        <DxItem>
                            <DxLocation :row="5" :col="0" :colspan="3" screen="lg" />
                            <DxLocation :row="5" :col="0" :colspan="3" screen="md" />
                            <DxLocation :row="6" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="6" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                <DxSelectBox :disabled="stateid != 4" :id="6" width="auto" height="45px"
                                    :label="$t('pracSetkani.TypPlatby')" :data-source="TypyPlateb" display-expr="content"
                                    value-expr="id" placeholder="" :value="formData.typPlatby"
                                    @value-changed=" setSelectedValue($event);" >
                                    <DxValidator>
                                        <DxRequiredRule :message="$t('pracSetkani.VyplnteTypPlatby')" />
                                    </DxValidator>
                                </DxSelectBox>
                            </div>
                        </DxItem>

                        <DxItem>
                            <DxLocation :row="6" :col="0" :colspan="1" screen="lg" />
                            <DxLocation :row="6" :col="0" :colspan="1" screen="md" />
                            <DxLocation :row="7" :col="0" :colspan="3" screen="sm" />
                            <DxLocation :row="7" :col="0" :colspan="3" screen="xs" />
                            <div class="SmlouvaBox">
                                <br>
                                <DxNumberBox height="45px" :disabled="stateid != 4" :id="7"
                                    :label="$t('pracSetkani.Cena')" placeholder=""
                                    @value-changed="setSelectedValue" v-model:value="formData.cena"
                                    style="display: inline-flexbox" 
                                    :format="{
                                        type: 'fixedPoint',
                                        precision: 2,
                                        thousandSeparator: ' ',
                                        decimalSeparator: ','
                                    }" :step="0">
                                    <DxValidator>
                                        <DxRequiredRule :message="$t('pracSetkani.VyplnteCenu')" />
                                        <DxCustomRule
                                                :validation-callback="validateNumberHigherThanZERO"
                                                :message="this.$t('Validator.CenaMusiBytVyssiNezNula')"
                                                :reevaluate="true"
                                            />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>

                        </DxItem>
                        <DxItem>
                                <DxLocation :row="6" :col="1" :colspan="1" screen="lg" />
                                <DxLocation :row="6" :col="1" :colspan="1" screen="md" />
                                <DxLocation :row="8" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="8" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <br>
                                    <DxNumberBox height="45px" :disabled="stateid != 4" :id="8"
                                        :label="$t('pracSetkani.Spropitne')" placeholder=""
                                        @value-changed="setSelectedValue" :value="formData.spropitne"
                                        style="display: inline-flexbox;" :format="{
                                            type: 'fixedPoint',
                                            precision: 2,
                                            thousandSeparator: ' ',
                                            decimalSeparator: ','
                                        }" :step="0">
                                        <DxValidator>
                                        <DxCustomRule
                                                :validation-callback="validateNumberPositive"
                                                :message="this.$t('Validator.SpropitneMusiBytKladne')"
                                                :reevaluate="true"
                                            />
                                            
                                    </DxValidator>
                                    </DxNumberBox>
                                </div>

                            </DxItem>
                            <DxItem>
                                <DxLocation :row="6" :col="2" :colspan="1" screen="lg" />
                                <DxLocation :row="6" :col="2" :colspan="1" screen="md" />
                                <DxLocation :row="9" :col="0" :colspan="3" screen="sm" />
                                <DxLocation :row="9" :col="0" :colspan="3" screen="xs" />
                                <div class="SmlouvaBox">
                                    <br>
                                    <DxNumberBox height="45px" :disabled="stateid != 4" :id="9" :readOnly="false"
                                        :label="$t('pracSetkani.CenaCelkem')" placeholder=""
                                        @value-changed="setSelectedValue" v-model:value="formData.cenaCelkem"
                                        style="display: inline-flexbox;"
                                        :validation-status="validationStatus" :format="{
                                            type: 'fixedPoint',
                                            precision: 2,
                                            thousandSeparator: ' ',
                                            decimalSeparator: ','
                                        }" :step="0">

                                        <DxValidator>
                                            <DxRequiredRule :message="$t('pracSetkani.VyplnteCenuCelkem')" />
                                            <DxCustomRule
                                                :validation-callback="validateNumber"
                                                :message="$t('pracSetkani.ValidaceCelkem')"
                                            />
                                            <DxCustomRule
                                                :validation-callback="validateNumberHigherThanZERO"
                                                :message="this.$t('Validator.CenaMusiBytVyssiNezNula')"
                                                :reevaluate="true"
                                            />
                                    </DxValidator> 
                                    </DxNumberBox>
                                </div>

                            </DxItem>
                    </DxResponsiveBox>
                </div>
            </div>
            <div id="obaleniFormulare2" padding-top="5px" v-if="!zakladani">          
              <DxResponsiveBox single-column-screen="sm" id="DruhyResponsiveBox" class="ResponsiveBoxClass" v-if="!h" width="100%">
                <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxRow :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="0.5" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
                    <DxCol :ratio="1" />
              <DxItem>
                        <DxLocation :row="0" :col="3" :colspan="3" screen="lg" />
                        <DxLocation :row="0" :col="3" :colspan="3" screen="md" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="1" :col="0" :colspan="6" screen="xs" />       
                <div>
                          <Attachments :Disabled="stateid != 4" :Pozid="this.poz" :FileUploadFileType="1" :ListFilesfileType="1" :FileDeleteFileType="1"/>
                      </div> 

                </DxItem>

                <DxItem>
                        <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                        <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="sm" />
                        <DxLocation :row="0" :col="0" :colspan="6" screen="xs" />
                  <div class="dx-field" v-if="!zakladani">
                
                    <DxTextArea class="DxSelectBoxResponsive" :disabled="stateid != 4" :id="10" :label="$t('majetekPrevod-Komentar')" width="auto" :height="100" @value-changed="setSelectedValue" v-model:value="formData.komentar"
                      placeholder="" />
                  </div>
                </DxItem>
              </DxResponsiveBox>
            </div>

            <DxResponsiveBox single-column-screen="sm" 
                class="SpodniMenuDetailPozadavku">
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxRow :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />
                <DxCol :ratio="1" />

                <DxItem>
                    <DxLocation :row="0" :col="0" :colspan="2" screen="lg" />
                    <DxLocation :row="0" :col="0" :colspan="2" screen="md" />
                    <DxLocation :row="0" :col="0" :colspan="3" screen="sm" />
                    <DxLocation :row="0" :col="0" :colspan="3" screen="xs" />
                    <div v-if="zakladani" id="ResponsiveBoxItemActionButtons">
                        <DxButton icon="add" id="vytvorit" height="auto" width="auto"
                            :text="$t('pracSetkani.Zalozit')" :use-submit-behavior="true" type="success" @value-changed="CheckValidation" />

                    </div>
                </DxItem>

                <DxItem>
                    <DxLocation :row="1" :col="0" :colspan="5" screen="lg" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="md" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="sm" />
                    <DxLocation :row="1" :col="0" :colspan="5" screen="xs" />
                    <DxScrollView direction="horizontal" showScrollbar="always" width="100%" v-if="!zakladani">
                    <div id="ResponsiveBoxItemActionButtons" v-if="!zakladani">
                        <div class="child actionButtonsPozadavkyMargin" v-for="item in actionsPozadavky" :key="item" id="actionbuttons">
                            <DxButton class="button1 actionButtonsPozadavkyResizableA" height="100%" width="auto" type="default"
                                styling-mode="contained" :text="item.nazev" :hint="item.nazev" :id="item.id" @click="SetState(item.id)"
                                :class="item.class" :icon="item.icon"
                                v-show="(item.nazev != 'Schválit') && (item.nazev != 'Vrátit') && (item.nazev != 'Zamítnout')" />
                        </div>
                    </div>
                    </DxScrollView>

                </DxItem>
            </DxResponsiveBox>

        </form>



    </div>
</template>

<script>
//import axios from 'axios'
import notify from 'devextreme/ui/notify';
import FundingInstructions from '@/components/FundingInstructions.vue';
import DxTagBox from 'devextreme-vue/tag-box';
import Attachments from '@/components/Attachments.vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { isNull, isUndefined } from 'lodash';
import DxValidator, {
    DxCustomRule
} from 'devextreme-vue/validator';
import DxScrollView from 'devextreme-vue/scroll-view';
export default {
    name: "PracSetkaniView",

    components: {
        FundingInstructions,
        Attachments,
        DxTextBox,
        DxNumberBox,
        DxTagBox,
        DxValidator,
        DxCustomRule,
        DxScrollView
    },

    data() {
        return {
            //  pattern: /^\d{}/i,
            formData: {
                podnik: "",
                cena: null,
                spropitne: null,
                cenaCelkem: null,
                hoste: '',
                zamestnanci:[],
                komentar: '',
                typPlatby: null,
                datumKonani: null,

            },
            volaniFunkce: false,
            validationStatus: "valid",
            selectedZamestnanci: [],
            zamestnanci:[],
            granty: [],
            currentGrant: null,
            TypyPlateb: [],
            PocetKolonek: 7,  // kolikrat se zavola setselectedvalue, pokud je komentar vyplneny, dava se PocetKolonek++
            MountingData: false,
            stateid: 4,
            state: 'Uložený',
            popupAlert: false,
            popupAlertTemplate: '',
            actionsPozadavky: [],
            Via: [{ content: '', id: -1 }],
            komentar: '',
            suppliers: [],
            supplier: "",
            vias: [],
            poz: 0,
            zakladani: true,
            Vyplnte: true,

        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        window.MountCount = 0
    },
    mounted() {
        this.PlatbyTypy();
        this.ListVias();
        this.MountingData = true
        this.ListFunding()  
        if (window.localStorage.getItem("pozid") != '' && window.history.state.back!=null) { // funguje tak, ze pokud je null tak se jedna o redirect z jine webove stranky
            this.zakladani = false
            this.poz = window.localStorage.getItem("pozid")
            this.DisplayRequest();

        }

    },

    methods: {
        CheckValidation() {
            this.$log.debug(this.$refs.tagBoxA.instance.option('value'))
            this.$log.debug(this.formData.hoste != '')
            if (this.formData.hoste != '' || this.$refs.tagBoxA.instance.option('value').length!=0 ) {
                this.Vyplnte = false;
            }
            else this.Vyplnte = true
        },
        validateNumber(e) {
            this.$log.debug(e)    
            var result = this.formData.cena + this.formData.spropitne == this.formData.cenaCelkem;
            if (result) this.validationStatus = 'valid'
            else this.validationStatus = 'invalid'
            this.$log.debug(this.formData.cena + this.formData.spropitne+' = '+ this.formData.cenaCelkem)
            this.$log.debug(result)
            return result;
        },
        ClearDataSourceTagBox(e) {
            this.$log.debug(e);
            this.$log.debug('clear tagbox')    
            this.$log.debug(this.$refs.tagBoxA.instance.option('selectedItems'))     
            const selectedItems = this.$refs.tagBoxA.instance.option('selectedItems');
            this.$log.debug(this.zamestnanci)
            this.zamestnanci = this.zamestnanci.filter(employee => {
                return selectedItems.some(selectedEmployee => selectedEmployee.id === employee.id);
            });
        },
        TagBoxSelection(e) {
            this.$log.debug('tagboxselection')
            this.$log.debug(e)
       
            if (e.component.option("text") != '') {
                this.CheckValidation()
                e.component.repaint()
                e.component.focus()
                     this.$log.debug(self.$refs)
              //  this.$log.debug(self.$refs.tagBoxA.instance.option('selectedItems'))
                
            }
       //     if(e.component.content()!=null)
        //      e.component.content().querySelector('.dx-list-select-all').style.display = 'none';
        },
        SearchInput(e) {

            let self = this;
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                self.GetEmployees(e)
            }, 1000)

        },
        GetEmployees(employee) {
            this.$log.debug('volam list empl')
            if (employee.component != undefined) {
                var e = employee
                employee = e.component.option("text")
            }

            var gen = new window.General(window.channel, window.message);
            let self = this;
            this.$log.debug(this.selectedZamestnanci)
            //self.zamestnanci.length = 0;
            //  window.this.$log.debug(employee)
            if (!self.isEmployeeInDataSource(employee)) {
                gen.ListEmp(employee,
                    function (result) {
                        //  window.this.$log.debug(result.fResponseObject.result.Result.List.length)
                        window.this.$log.debug(result);
                        for (var x = 0; x < result.List.value.items.length; x++) {
                            if (result.List.value.items[x].Id.value == 0) x++; {
                                if (!self.isEmployeeInDataSource(result.List.value.items[x].Id.value))
                                    self.zamestnanci[self.zamestnanci.length] = {
                                        content: result.List.value.items[x].Value.value,
                                        id: result.List.value.items[x].Id.value,
                                    };
                            }
                            if (x > 20) break;
                        }
                        e.component.getDataSource().reload();
                    }, window.this.ApiError());
            }
        },
        isEmployeeInDataSource(id) {
            this.$log.debug('prochazim pole')
            for (var i = 0; i < this.zamestnanci.length; i++) {
                if (this.zamestnanci[i].id == id) {
                    return true;
                }
            }
            return false;
        },
        ListFunding() {
            let self = this;
            self.granty.length = 0;
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.ListFunding(1, 3,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        self.granty[x] = {
                            content: result.List.value.items[x].Text.value + ' ' + result.List.value.items[x].Name.value,
                            //  number: result.List.value.items[x].Text.value,
                            id: result.List.value.items[x].ID.value,
                        };
                    }
                    self.granty[self.granty.length] = {
                        content: self.$t('central-GrantNevybran'),
                        id: 0,
                    }

                    if (self.h && result.List.value.items.length == 1)
                        self.currentGrant = self.granty[0].id;
                    //  self.Specifikace()
                  //  self.ListVias()
                }, window.this.ApiError());
        },
        SetState(stav, alert) {
            window.this.$log.debug(alert + ' alert<<<??>')
            let self = this;
            var wh = new window.WorkFlow(window.channel, window.message);

            if (stav == -1) {
                window.this.$log.debug('konec')
                this.popupAlert = true
                this.popupAlertTemplate = 'SmazatPoz'
                return 1;
            }
            if (alert == true) stav = -1
          //  self.open();
            wh.SetState(self.poz, stav, '',
                function (result) {
                    window.this.$log.debug(result);
                    if (result.ErrorNum.value != 0) {
                        notify({
                            message: result.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'
                    }
                    else {
                        self.DisplayRequest();
                        if (result.ErrorNum.value == 0 && (stav == -1 || stav == 0 || stav == 4)) self.$router.push('/Pozadavky?Vypis=0')

                        var stavText;
                        switch (stav) {
                            case -1: stavText = self.$t('pozadavky-StavZrusen')
                                break;
                            case 0: stavText = self.$t('pozadavky-StavKeSchvaleni')
                                break;
                            case 4: stavText = self.$t('pozadavky-StavUlozeny')
                                break;
                        }
                        notify({
                            message: self.$t('pozadavky-UvedenDoStavu') + stavText + '".',
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'success', 5000);
                    }
                 //   self.hide();
                }, window.this.ApiError());

        },
        GetBusinessDinner(id) {
            let self = this
            var wf = new window.WorkFlow(window.channel, window.message);
            wf.GetBusinessDinner(id,
                function (result) {
                    self.$log.debug(result);
                    if (result.Error.value.ErrorNum.value != 0) {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',

                            },
                        }, 'error', 5000); //'info' - barva templatu, 'success', 'warning', 'error'

                    }
                    else {
                        self.formData.podnik = result.Restaurant.value
                        self.formData.cena = result.Price.value
                        if (result.TipValue.value != null && result.TipValue.value!=0) {
                           self.formData.spropitne = result.TipValue.value
                            self.PocetKolonek++ 
                        }
                        self.formData.cenaCelkem = result.TotalPrice.value
                        self.formData.datumKonani = result.Date.value
                        self.formData.typPlatby = result.Cis_TypyPlateb.value
                        self.formData.hoste = result.Guests.value
                        
                        if (result.Employees.value.items.length > 0) {
                            self.PocetKolonek++
                            for (var x = 0; x < result.Employees.value.items.length; x++){
                                self.GetEmployees(result.Employees.value.items[x])
                            }
                            
                            setTimeout(() => {
                                self.selectedZamestnanci = result.Employees.value.items;
                                // self.zamestnanci
                                self.$refs.tagBoxA.instance.option('value', self.selectedZamestnanci);
                                self.$refs.tagBoxA.instance.option('selectedItems', self.selectedZamestnanci);
                                self.$refs.tagBoxA.instance.repaint();                
                            }, 500);
                            

                        }
                        if(self.formData.hoste!='') self.PocetKolonek++
                        self.formData.komentar = result.Request.value.Comment.value
                        self.currentGrant = result.Request.value.Funding.value
                        if (self.formData.komentar != '') self.PocetKolonek++
                    }
                }, window.this.ApiError());
        },
        ListVias() {
            let self = this;
            self.vias.length = 0;
            var gen = new window.General(window.channel, window.message);
            gen.ListLookup('REQUEST_VIAS[2]', '', 1, 50,
                function (result) {
                    window.this.$log.debug(result);
                    /*
                    for (var x = 0; x < result.List.value.items.length; x++) {
          
                      //  window.this.$log.debug(result.List.value.items[x]);
                      var i = self.vias.length;
                      self.vias[i] = {
                        content: result.List.value.items[x].Value.value,
                        id: result.List.value.items[x].Id.value,
                      };
                    }
                    */
                    window.this.$log.debug(self.zakladani);
                    if (!self.zakladani) self.GetBusinessDinner(self.poz)
                    //  self.Via[0] = self.vias[0]
                    //else

                }, window.this.ApiError());

        },
        validateNumberPositive(e) {
                    this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value >= 0;
                    //this.validationStatus = isValid ? 'valid' : 'invalid';
                    return isValid;
        },
        validateNumberHigherThanZERO(e){
            this.$log.debug(e.value+' celkova cena')
                    const isValid = e.value > 0;
                    //this.validationStatus = isValid ? 'valid' : 'invalid';
                    return isValid;
        },
        DisplayRequest() {
            window.this.$log.debug('display request')
            let self = this;
            self.actionsPozadavky.length = 0;
            var wh = new window.WorkFlow(window.channel, window.message);
            wh.GetRequest(self.poz,
                function (result2) {
                    window.this.$log.debug(result2);
                    self.state = result2.ReqStateText.value;
                    self.stateid = result2.ReqState.value;
                    for (var x = 0; x < result2.Actions.value.items.length; x++) {
                        var id = result2.Actions.value.items[x].Id.value;
                        var colorClass = id === 0 ? "buttonKeSchvaleni" : (id === 4 ? "buttonUlozitProUpravy" : (id === -1 ? "buttonSmazat" : "buttonDefault"));
                        var iconName = id === 0 ? "upload" : (id === 4 ? "floppy" : (id === -1 ? "trash" : ""));
                        self.actionsPozadavky[x] = {
                            nazev: result2.Actions.value.items[x].Value.value,
                            id: result2.Actions.value.items[x].Id.value,
                            class: colorClass,
                            icon: iconName,
                            pozid: self.poz
                        };
                    }
                }, window.this.ApiError()); // window.this.$log.debug('chyba')); //
        },
        onFormSubmit(e) {

            window.this.$log.debug(e.submitter.attributes[0].ownerDocument.activeElement.id)
            window.this.$log.debug(e)
            this.$log.debug(this.$refs.tagBoxA.instance.option('value'))
            this.SetBusinessDinner()
            e.preventDefault();
        },
        PlatbyTypy() {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            self.TypyPlateb.length = 0
            gen.ListLookup('TypyPlateb', '', 1, 25,
                function (result) {
                    window.this.$log.debug(result);
                    for (var x = 0; x < result.List.value.items.length; x++) {

                        //  window.this.$log.debug(result.List.value.items[x]);
                        var i = self.TypyPlateb.length;
                        self.TypyPlateb[i] = {
                            content: result.List.value.items[x].Value.value,
                            id: result.List.value.items[x].Id.value,
                        };
                    }

                }, window.this.ApiError());
        },
        setSelectedValue(e) {
            window.MountCount++
            window.this.$log.debug(e);
            window.this.$log.debug(window.MountCount + 'MountCount');
            window.this.$log.debug(this.PocetKolonek + 'Dokolika');
            if (window.MountCount == this.PocetKolonek) this.MountingData = false // tohle neni uplne dobry napad delat
            switch (e.element.id) {
                case '1':
                    if (e.value == '') return 1;
                    break;
                case '2':
                    if (e.value == '') return 1;
                    break;
                case '3':
                    if (e.value == null) return 1;
                    this.currentGrant = e.value;
                    break;
                case '4':
                    this.formData.hoste = e.value
                    //this.komentar = e.value
                    break;
                case '6':
                    //this.komentar = e.value
                    this.formData.typPlatby = e.value;
                    break;
                case '7':
                    //this.zverejnit = e.value
                    this.formData.cena = e.value
                    if(e.value!=null)
                        this.formData.cenaCelkem = this.formData.cena + this.formData.spropitne
                    this.volaniFunkce=true
                    break;
                case '8':
                    this.formData.spropitne = e.value
                     if (e.value != null)
                        this.formData.cenaCelkem = this.formData.cena + this.formData.spropitne
                     this.volaniFunkce = true
                    break;
                case '9':
                    // this.formData.cenaCelkem = e.value
                    if (this.formData.cenaCelkem == this.formData.cena + this.formData.spropitne) this.validationStatus = 'valid'
                    if (this.validationStatus != 'invalid' && !this.volaniFunkce) this.SetBusinessDinner()
                        return 2;
                case 'employee':
                    this.selectedZamestnanci.push(e.value)
                    this.$refs.tagBoxA.instance.option('value', this.selectedZamestnanci)
                    //this.zverejnit = e.value
                    break;
                case 'suppliers':
                    if (e.value == null) {
                        this.$log.debug('asdasdasd')
                        break;
                    }
                    if (isUndefined(e.value.event)) return 1;
                    this.formData.protistrana = e.value
                    //this.zverejnit = e.value
                    break;
            }
            this.CheckValidation();
            if (!isUndefined(e.value) && !isNull(e.value) && !this.zakladani && !this.MountingData && !isNull(e.value) && this.validationStatus != 'invalid')
                this.SetBusinessDinner()
            // this.SkipDiacritics(this.typpozadavek2.content)
            //  this.pozadavek=e.value
            // this.selectedValue = e.value;
        },
        SetBusinessDinner() {
            var self = this;
            var wh = new window.WorkFlow(window.channel, window.message);
            this.$log.debug(this.formData)
            this.$log.debug(this.$refs.tagBoxA.instance.option('value'))
            var spropitne = self.formData.spropitne
            if(spropitne ==null) spropitne=0
            wh.SetBusinessDinner(self.poz, self.formData.podnik , self.formData.hoste, self.formData.datumKonani, self.currentGrant, self.formData.typPlatby, self.formData.cena,
                spropitne, self.formData.cenaCelkem, self.$refs.tagBoxA.instance.option('value'), self.formData.komentar,
                function (result) {
                    window.this.$log.debug(result);
                    self.volaniFunkce = false
                    if (result.Error.value.ErrorNum.value == 0) {
                        //console.log(self.poz)
                        if (self.poz == 0) {
                            self.poz = result.Value.value
                            window.localStorage.setItem("pozid", result.Value.value)
                            self.zakladani = false
                            self.MountingData = false
                            notify({
                                message: self.$t('pracSetkani.Zalozeno'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        }
                        else {
                            notify({
                                message: self.$t('pracSetkani.ZmenyUlozeny'),
                                position: {
                                    my: 'center top',
                                    at: 'center top',
                                },
                            }, 'success', 3000);
                        }
                        self.DisplayRequest()
                    }
                    else {
                        notify({
                            message: result.Error.value.ErrorTextCZ.value,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 3000);

                    }
                }, window.this.ApiError());
        },
    }
};
</script>



<style scoped> .SmlouvaBox {
     padding: 5px;
 }
.ResponsiveBoxClass{
      padding: 10px;
}
#DruhyResponsiveBox{
  border-style: ridge;
  border-color: rgba(128, 128, 128, 0.1);
  border-radius: 10px;
  
  background-color: rgba(128, 128, 128, 0.075);
  flex: none !important;
}
#obaleniFormulare2{
  padding:10px;
}
#obalenigrid{
  padding:10px;
}


 #SmlouvaResponsiveBox {
     border-style: ridge;
     border-color: rgba(128, 128, 128, 0.1);
     border-radius: 10px;
     background-color: rgba(128, 128, 128, 0.075);
     padding: 5px;
 }

 #checkboxzverejnit {
     padding-top: 25px;

 }

 .checkboxzverejnitZvyraz {
     padding: 5px;
     border-style: ridge;
     border-color: rgba(46, 46, 46, 0.1);
     border-radius: 10px;
     background-color: rgba(170, 3, 3, 0.459);
     width: max-content;
     font-weight: bold;
 }
 .buttonDefault:hover,
 .buttonUlozitProUpravy:hover,
 .buttonKeSchvaleni:hover,
 .buttonSmazat:hover,
 .buttonKEditaci:hover {
     background-color: var(--colorByThemeLighter);
     border: 0px;

 }

 .buttonKeSchvaleni {
     background: #5cb83c;
     border-color: none;
     border: 0px;
 }

 .buttonUlozitProUpravy {
     background: #3cb8b8;
     border-color: none;
     border: 0px;
 }

 .buttonSmazat {
     background: #d9534f;
     border-color: none;
     border: 0px;
 }

 .buttonKEditaci {
     background: #ffcc33;
     border-color: none;
     border: 0px;
 }

 .buttonDefault {
     background: none;
     border-color: none;
     border: 0px;
 }
 </style>