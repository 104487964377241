<template>
    <div>
        <DxButton id="Importbutton" :text="$t('pozadavky-Import')" type="default" height="45px" styling-mode="outlined"
            icon="import" :hint="$t('pozadavky-Importovat')" @click="togglePopup" ref="ProcessExcelButton" />
        <DxPopup id="PopupSettingsToolbar" v-model:visible="popup" :drag-enabled="false" :hide-on-outside-click="true"
            :show-close-button="false" :showTitle="false" width="auto" height="auto" :focusStateEnabled="true"
            :shading="false" :animation="animationSettingsPopup" @Hiding="hiding = true; hidden = false"
            @Hidden="hiding = false; hidden = true">
            <DxPosition :at=PositionAT :my=PositionMY :collision=PositionCollision of="#Importbutton" />
            <div>
                <div style="margin-bottom: 10px; ">
                    <div style="font-size: 12px;">
                    <template  v-if="jazyk === 'en'">
                        <p>First <strong>fill in the item specification</strong>  and then <strong>upload an
                                excel file </strong>(.xls, .xlsx):</p>
                        <ul>
                            <li><strong>custom file</strong> - accurate data transfer is not guaranteed (must be
                                checked)</li>
                            <li><strong>template</strong> - fill/copy the data into the template (more reliable data
                                import)</li>
                        </ul>
                    </template>
                    <template v-else>
                        <p>Nejdříve <strong> vyplňte specifikaci </strong> položek a poté <strong>nahrajte
                                excelový soubor</strong> (.xls, .xlsx):</p>
                        <ul>
                            <li><strong>vlastní soubor</strong> - není zaručen přesný přenos dat (nutné zkontrolovat)
                            </li>
                            <li><strong>šablona</strong> - vyplňte/zkopírujte data do šablony (spolehlivější import dat)
                            </li>
                        </ul>
                    </template>
                </div>
                    <hr>
                </div>

                <div style="display: flex; align-items: center;">

                    <DxSelectBox id="spec" width="200px" height="40px" :label="$t('pozadavky-Specifikace')"
                        :data-source="Classification" display-expr="content" value-expr="id" placeholder=""
                        :value="currentSpecifikace" @value-changed="setSelectedValue($event);">
                    </DxSelectBox>

                    <div class="uploader-container">
                        <DxFileUploader :Disabled="Disabled" :multiple="false" ref="uploader" :max-file-size="104857600"
                            @value-changed="AttachmentValidation" height="40px" width="200px" upload-mode="instantly"
                            :upload-file="ProcessExcel" :upload-button-text="$t('central-Nahrat')" :label-text="''"
                            :ready-to-upload-message="''" :uploaded-message="$t('central-SouborNahran')"
                            :selectButtonText="$t('central-VybratSoubory')"
                            :upload-failed-message="$t('central-SouborSeNenahral')" :show-file-list="false"
                            @upload-error="handleUploadError" :allowed-file-extensions="ValidExtensions" />
                    </div>
                    <DxButton id="sablona" type="default" height="40px" :Text="$t('pozadavky-Sablona')"
                        styling-mode="outlined" icon="download" @click="DownloadTemplate" ref="ProcessExcelButton"
                        :hint="$t('pozadavky-SablonaHint')" />
                </div>
            </div>

        </DxPopup>
    </div>
</template>

<script>
import axios from "axios";
import notify from 'devextreme/ui/notify';
import { isNull } from 'lodash';
export default {
    name: 'ProcessExcelOrder',
    components: {

    },
    props: {
        RequestID: {
            type: Number,
            required: true,
        },
        Type: {
            type: String,
            required: true,
        },
        PositionAT: {
            type: String,
            required: false,
            default: 'left top'
        },
        PositionMY: {
            type: String,
            required: false,
            default: 'left bottom'
        },
        PositionCollision: {
            type: String,
            required: false,
            default: "fit none"
        },
        Classification: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    watch: {

    },
    data() {
        return {
            jazyk: window.localStorage.getItem("jazyk"),
            animationConfig: {
                show: { type: 'fade', from: 0, to: 1, duration: 300 },
                hide: { type: 'fade', from: 1, to: 0, duration: 300 },

            },
            currentSpecifikace: null,
            popup: false,
            Disabled: true,
            hidden: false,
            hiding: false,
            ValidExtensions: ['.xls', '.xlsx', '.xlsm'],
            animationSettingsPopup: {
                show: {
                    type: 'pop',
                    duration: 300,
                    from: {
                        scale: 0.55
                    }
                },
                hide: {
                    type: 'pop',
                    duration: 300,
                    to: {
                        opacity: 0,
                        scale: 0.55
                    },
                    from: {
                        opacity: 1,
                        scale: 1
                    }
                }
            },
        };
    },
    computed: {


    },
    mounted() {
        //  this.LoadTexts()
    },

    methods: {
        DownloadTemplate() {
            if (this.jazyk=='en'){
                window.location.href="/Order_template.xlsx"; 
            }
            else {
                window.location.href="/Obj_sablona.xlsx";
            }
        },
        async downloadItem(url, name, suffix) {
            window.this.$log.debug(suffix)
            suffix = '.pdf'
            axios.get(url, { headers: { 'Accept': 'application/pdf' }, responseType: 'blob' })
                .then(function (response) {
                    switch (suffix) {
                        case '.pdf': {
                            window.this.$log.debug(response)
                            const blob = new Blob([response.data], { type: 'application/' + suffix });
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = name;
                            link.click();
                            window.this.$log.debug(link)
                            window.URL.revokeObjectURL(link.href);
                            break;
                        }
                    }
                })
                .catch(function (error) {
                    window.this.$log.debug(error)
                    window.this.$log.debug(JSON.stringify(error))
                })
        },
        GetHash(FileName, FileID) {
            let self = this;
            var gen = new window.General(window.channel, window.message);
            gen.GetFileHash(FileID,

                function (result) {
                    window.this.$log.debug(result);
                    self.$log.debug(window.URL1 + '/js/download/' + result.Value.value)
                    self.hash = window.URL1 + '/js/download/' + result.Value.value;
                    window.this.$log.debug(self.hash + " " + FileName)
                    self.downloadItem(self.hash, FileName);

                }, window.this.ApiError());
        },
        setSelectedValue(e) {
            window.this.$log.debug(e);
            switch (e.element.id) {
                case 'spec':
                    this.currentSpecifikace = e.value
                    if (isNull(e.previousValue)) this.$refs.uploader.instance.option('disabled', false)
                    break;
            }
        },
        togglePopup() {
            if (this.hidden || !this.hiding) {
                this.$log.debug('volam popip')
                this.popup = !this.popup;
                this.hidden = true
            }
        },
        ProcessExcel(file) {
            this.$log.debug(file)
            var self = this
            var format, SysMessage, UserMessage, AssistMessage
            var wf = new window.WorkFlow(window.channel, window.message);
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                window.this.$log.debug(theFile.name);
                return function (e) {
                    var arrayBuffer = e.target.result;
                    var binaryString = '';
                    var bytes = new Uint8Array(arrayBuffer);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binaryString += String.fromCharCode(bytes[i]);
                    }
                    switch (self.Type) {
                        case 'Order': {
                            var Specification = self.currentSpecifikace
                            format = '{ "orderItems": [ { "description": "", "currency": "", "unitPrice": "", "price": "", "quantity": "", "catalogNumber": "" }] }'
                            SysMessage = 'Try to be as precise as possible. Provide answer in JSON format only.';
                            UserMessage = 'Please fill information from the provided JSON structure only. Return all the items provided from the excel string. Return only items, not all strings written in the excel, it might be some headers/footers,comments. Return only items that have quantity>0. Ensure the response contains the full JSON and is not truncated. Return JSON without code fences. Return the message in Czech language. Currency is for example CZK, USD, EUR. Not found elements let blank "", but quantity, price, unitPrice return as 0, if not found. Double check the results you are providing, so that there are no mistakes or nonsenses. ';
                            AssistMessage = ''
                        }
                            break;
                    }
                    self.$refs.uploader.instance.option('disabled', true);
                    self.$emit('start-loading', true);
                    self.popup = false
                    wf.GetDataFromDocument(binaryString, theFile.name, format, SysMessage, UserMessage, AssistMessage,
                        function (result) {
                            self.$log.debug(result);
                            if (result.Error.value.ErrorNum.value == 0) {
                                wf.ImportOrderItems(self.RequestID, Specification, result.Value.value,
                                    function (result2) {
                                        self.$log.debug(result2);
                                        if (result2.Error.value.ErrorNum.value == 0) {
                                            /*
                                            notify({
                                                message: self.$t('Objednavka.PolozkyNacteny'),
                                                position: {
                                                    my: 'center top',
                                                    at: 'center top',
                                                },
                                            }, 'success', 3000);
                                            */
                                            self.$emit('data-loaded', true);
                                        }
                                        else {
                                            notify({
                                                message: result2.Error.value.ErrorTextCZ.value,
                                                position: {
                                                    my: 'center top',
                                                    at: 'center top',
                                                },
                                            }, 'error', 3000);
                                        }
                                        self.$emit('data-loaded', false);
                                        self.$refs.uploader.instance.option('disabled', false);
                                    }, window.this.ApiError());
                            }
                            else {
                                notify({
                                    message: result.Error.value.ErrorTextCZ.value,
                                    position: {
                                        my: 'center top',
                                        at: 'center top',
                                    },
                                }, 'error', 3000);
                                self.$emit('data-loaded', false);
                                self.$refs.uploader.instance.option('disabled', false);
                            }

                        }, window.this.ApiError());
                }
            })(file);
            reader.readAsArrayBuffer(file);
        },
        handleUploadError(e) {
            // Handle the upload error
            alert('Upload failed: ' + e.error.message);
        },
        AttachmentValidation(e) {
            if (this.isResetting) {
                this.isResetting = false;
                return;
            }

            this.$log.debug(e);
            const files = e.value;
            const invalidExtensionFiles = files.filter(file => {
                const extension = '.' + file.name.split('.').pop().toLowerCase();
                return !this.ValidExtensions.includes(extension);
            });
            const invalidSizeFiles = files.filter(file => file.size > this.MaxFileSize);

            if (invalidExtensionFiles.length > 0) {
                const invalidExtensionFileNames = invalidExtensionFiles.map(file => file.name).join(', ');
                notify({
                    message: this.$t('Prilohy.NespravnaPripona') + `${invalidExtensionFileNames}`,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 10000);
            }

            if (invalidSizeFiles.length > 0) {
                const invalidSizeFileNames = invalidSizeFiles.map(file => file.name).join(', ');
                notify({
                    message: this.$t('Prilohy.PrekrocenaMaxVelikost') + `${invalidSizeFileNames}`,
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 10000);
            }

            if (invalidExtensionFiles.length === 0 && invalidSizeFiles.length === 0) {
                e.component.upload();
            }
            this.isResetting = true;
            this.$nextTick(() => {
                e.component.reset();
            });
        },
    }
}
</script>
<style scoped>
#Importbutton {
    color: inherit;
    scale: 85%;
}

#sablona {
    justify-content: flex-end;
}

.uploader-container {
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: flex-start;
    /* Align horizontally (same start point) */
}

:deep(.dx-fileuploader-input-wrapper::before) {
    padding-top: 0px;
}

:deep(.dx-button-has-text .dx-button-content) {
    padding: 4px 18px;
}

:deep(.dx-fileuploader-input-wrapper) {

    border: 3px dashed transparent;
}

:deep(.dx-fileuploader-input-wrapper) {
    padding-top: 7px;
    border: 3px dashed transparent;
}
</style>