<template>
<!-- <DxButton v-if="ButtonVisible" id="buttoninstruction" icon="minus" type="default" styling-mode="contained"
        width="40px" @click="TogglePopup" />
-->    
    <DxPopup ref="popupinstructions" :title="$t('granty-Pokyny')" width="300px" height="auto" :showCloseButton="false"
        v-model:visible="PopupVisible" :drag-enabled="true" :focusStateEnabled="true" :shading="false">
        <DxToolbarItem 
            widget="dxButton" 
            :options="buttonOptions" 
            location="after">
        </DxToolbarItem>
        <DxPosition at="left top" my="left top" collision="fit" of="#napovedaText" :offset="{ y: 0, x: -120 }" />
        <!--<DxPosition at="left center" my="right center" collision="fit" of="#zmenavzhled"  :offset="{ y: -22, x: -5 }"/> -->
        <div>
            <div v-for="item in Instrukce" :key="item">
                <div class="htmlObsahDetail" >
                    <div v-html="item.html"></div>
                    <a class="htmlObsahZamText"> {{ item.zamTXT }}</a>
                </div>
            </div>
        </div>
    </DxPopup>

</template>

<script>
//import notify from 'devextreme/ui/notify';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
export default {
    name: 'FundingInstructions',
    components: {
        DxPopup,
        DxToolbarItem
    },
    props: {
        FundingID: {
            type: Number,
            required: true
        },
        Ident: {
            type: String,
            required: false,
            default: ''
        },
    },
    watch: {
        FundingID(newFundingID) {
            this.$log.debug(newFundingID)
            this.GetFundingInstruction();
        },
    },
    data() {
        return {
            buttonOptions: {
                text: '',
                icon: 'minus',
                onClick: this.TogglePopup
            },
            ButtonVisible: false,
            PopupVisible: false,
            EditPopis: '',
            converted: '',
            Instrukce: [],
            zamestnanec: null,
            prevEmpId: null,
        };
    },
    computed: {


    },
    mounted() {
        //  this.GetFundingInstruction();
    },

    methods: {

        TogglePopup(e){
            var popupInstance=this.$refs.popupinstructions.instance
            var currentHeight = popupInstance.option('height');
            this.$log.debug(currentHeight)
            if (currentHeight > 50 || currentHeight== 'auto' ) {
                popupInstance.option('height', 50);
                e.component.option('icon', 'plus');
            } else {
                popupInstance.option('height', 'auto');
                e.component.option('icon', 'minus');
            }
        },      
        cp1250ByteToUnicode(byteValue) {
        if (byteValue < 0x80) {
            return String.fromCharCode(byteValue);
        }
        const cp1250Map = {
            0x80: '\u20AC', // EURO SIGN
            0x82: '\u201A', // SINGLE LOW-9 QUOTATION MARK
            0x84: '\u201E', // DOUBLE LOW-9 QUOTATION MARK
            0x85: '\u2026', // HORIZONTAL ELLIPSIS
            0x86: '\u2020', // DAGGER
            0x87: '\u2021', // DOUBLE DAGGER
            0x89: '\u2030', // PER MILLE SIGN
            0x8A: '\u0160', // LATIN CAPITAL LETTER S WITH CARON
            0x8B: '\u2039', // SINGLE LEFT-POINTING ANGLE QUOTATION MARK
            0x8C: '\u015A', // LATIN CAPITAL LETTER S WITH ACUTE
            0x8D: '\u0164', // LATIN CAPITAL LETTER T WITH CARON
            0x8E: '\u017D', // LATIN CAPITAL LETTER Z WITH CARON
            0x8F: '\u0179', // LATIN CAPITAL LETTER Z WITH ACUTE
            0x91: '\u2018', // LEFT SINGLE QUOTATION MARK
            0x92: '\u2019', // RIGHT SINGLE QUOTATION MARK
            0x93: '\u201C', // LEFT DOUBLE QUOTATION MARK
            0x94: '\u201D', // RIGHT DOUBLE QUOTATION MARK
            0x95: '\u2022', // BULLET
            0x96: '\u2013', // EN DASH
            0x97: '\u2014', // EM DASH
            0x99: '\u2122', // TRADE MARK SIGN
            0x9A: '\u0161', // LATIN SMALL LETTER S WITH CARON
            0x9B: '\u203A', // SINGLE RIGHT-POINTING ANGLE QUOTATION MARK
            0x9C: '\u015B', // LATIN SMALL LETTER S WITH ACUTE
            0x9D: '\u0165', // LATIN SMALL LETTER T WITH CARON
            0x9E: '\u017E', // LATIN SMALL LETTER Z WITH CARON
            0x9F: '\u017A', // LATIN SMALL LETTER Z WITH ACUTE
            0xA0: '\u00A0', // NO-BREAK SPACE
            0xA1: '\u02C7', // CARON
            0xA2: '\u02D8', // BREVE
            0xA3: '\u0141', // LATIN CAPITAL LETTER L WITH STROKE
            0xA4: '\u00A4', // CURRENCY SIGN
            0xA5: '\u0104', // LATIN CAPITAL LETTER A WITH OGONEK
            0xA6: '\u00A6', // BROKEN BAR
            0xA7: '\u00A7', // SECTION SIGN
            0xA8: '\u00A8', // DIAERESIS
            0xA9: '\u00A9', // COPYRIGHT SIGN
            0xAA: '\u015E', // LATIN CAPITAL LETTER S WITH CEDILLA
            0xAB: '\u00AB', // LEFT-POINTING DOUBLE ANGLE QUOTATION MARK
            0xAC: '\u00AC', // NOT SIGN
            0xAD: '\u00AD', // SOFT HYPHEN
            0xAE: '\u00AE', // REGISTERED SIGN
            0xAF: '\u017B', // LATIN CAPITAL LETTER Z WITH DOT ABOVE
            0xB0: '\u00B0', // DEGREE SIGN
            0xB1: '\u00B1', // PLUS-MINUS SIGN
            0xB2: '\u02DB', // OGONEK
            0xB3: '\u0142', // LATIN SMALL LETTER L WITH STROKE
            0xB4: '\u00B4', // ACUTE ACCENT
            0xB5: '\u00B5', // MICRO SIGN
            0xB6: '\u00B6', // PILCROW SIGN
            0xB7: '\u00B7', // MIDDLE DOT
            0xB8: '\u00B8', // CEDILLA
            0xB9: '\u0105', // LATIN SMALL LETTER A WITH OGONEK
            0xBA: '\u015F', // LATIN SMALL LETTER S WITH CEDILLA
            0xBB: '\u00BB', // RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK
            0xBC: '\u013D', // LATIN CAPITAL LETTER L WITH CARON
            0xBD: '\u02DD', // DOUBLE ACUTE ACCENT
            0xBE: '\u013E', // LATIN SMALL LETTER L WITH CARON
            0xBF: '\u017C', // LATIN SMALL LETTER Z WITH DOT ABOVE
            0xC0: '\u0154', // LATIN CAPITAL LETTER R WITH ACUTE
            0xC1: '\u00C1', // LATIN CAPITAL LETTER A WITH ACUTE
            0xC2: '\u00C2', // LATIN CAPITAL LETTER A WITH CIRCUMFLEX
            0xC3: '\u0102', // LATIN CAPITAL LETTER A WITH BREVE
            0xC4: '\u00C4', // LATIN CAPITAL LETTER A WITH DIAERESIS
            0xC5: '\u0139', // LATIN CAPITAL LETTER L WITH ACUTE
            0xC6: '\u0106', // LATIN CAPITAL LETTER C WITH ACUTE
            0xC7: '\u00C7', // LATIN CAPITAL LETTER C WITH CEDILLA
            0xC8: '\u010C', // LATIN CAPITAL LETTER C WITH CARON
            0xC9: '\u00C9', // LATIN CAPITAL LETTER E WITH ACUTE
            0xCA: '\u0118', // LATIN CAPITAL LETTER E WITH OGONEK
            0xCB: '\u00CB', // LATIN CAPITAL LETTER E WITH DIAERESIS
            0xCC: '\u011A', // LATIN CAPITAL LETTER E WITH CARON
            0xCD: '\u00CD', // LATIN CAPITAL LETTER I WITH ACUTE
            0xCE: '\u00CE', // LATIN CAPITAL LETTER I WITH CIRCUMFLEX
            0xCF: '\u010E', // LATIN CAPITAL LETTER D WITH CARON
            0xD0: '\u0110', // LATIN CAPITAL LETTER D WITH STROKE
            0xD1: '\u0143', // LATIN CAPITAL LETTER N WITH ACUTE
            0xD2: '\u0147', // LATIN CAPITAL LETTER N WITH CARON
            0xD3: '\u00D3', // LATIN CAPITAL LETTER O WITH ACUTE
            0xD4: '\u00D4', // LATIN CAPITAL LETTER O WITH CIRCUMFLEX
            0xD5: '\u0150', // LATIN CAPITAL LETTER O WITH DOUBLE ACUTE
            0xD6: '\u00D6', // LATIN CAPITAL LETTER O WITH DIAERESIS
            0xD7: '\u00D7', // MULTIPLICATION SIGN
            0xD8: '\u0158', // LATIN CAPITAL LETTER R WITH CARON
            0xD9: '\u016E', // LATIN CAPITAL LETTER U WITH RING ABOVE
            0xDA: '\u00DA', // LATIN CAPITAL LETTER U WITH ACUTE
            0xDB: '\u0170', // LATIN CAPITAL LETTER U WITH DOUBLE ACUTE
            0xDC: '\u00DC', // LATIN CAPITAL LETTER U WITH DIAERESIS
            0xDD: '\u00DD', // LATIN CAPITAL LETTER Y WITH ACUTE
            0xDE: '\u0162', // LATIN CAPITAL LETTER T WITH CEDILLA
            0xDF: '\u00DF', // LATIN SMALL LETTER SHARP S
            0xE0: '\u0155', // LATIN SMALL LETTER R WITH ACUTE
            0xE1: '\u00E1', // LATIN SMALL LETTER A WITH ACUTE
            0xE2: '\u00E2', // LATIN SMALL LETTER A WITH CIRCUMFLEX
            0xE3: '\u0103', // LATIN SMALL LETTER A WITH BREVE
            0xE4: '\u00E4', // LATIN SMALL LETTER A WITH DIAERESIS
            0xE5: '\u013A', // LATIN SMALL LETTER L WITH ACUTE
            0xE6: '\u0107', // LATIN SMALL LETTER C WITH ACUTE
            0xE7: '\u00E7', // LATIN SMALL LETTER C WITH CEDILLA
            0xE8: '\u010D', // LATIN SMALL LETTER C WITH CARON
            0xE9: '\u00E9', // LATIN SMALL LETTER E WITH ACUTE
            0xEA: '\u0119', // LATIN SMALL LETTER E WITH OGONEK
            0xEB: '\u00EB', // LATIN SMALL LETTER E WITH DIAERESIS
            0xEC: '\u011B', // LATIN SMALL LETTER E WITH CARON
            0xED: '\u00ED', // LATIN SMALL LETTER I WITH ACUTE
            0xEE: '\u00EE', // LATIN SMALL LETTER I WITH CIRCUMFLEX
            0xEF: '\u010F', // LATIN SMALL LETTER D WITH CARON
            0xF0: '\u0111', // LATIN SMALL LETTER D WITH STROKE
            0xF1: '\u0144', // LATIN SMALL LETTER N WITH ACUTE
            0xF2: '\u0148', // LATIN SMALL LETTER N WITH CARON
            0xF3: '\u00F3', // LATIN SMALL LETTER O WITH ACUTE
            0xF4: '\u00F4', // LATIN SMALL LETTER O WITH CIRCUMFLEX
            0xF5: '\u0151', // LATIN SMALL LETTER O WITH DOUBLE ACUTE
            0xF6: '\u00F6', // LATIN SMALL LETTER O WITH DIAERESIS
            0xF7: '\u00F7', // DIVISION SIGN
            0xF8: '\u0159', // LATIN SMALL LETTER R WITH CARON
            0xF9: '\u016F', // LATIN SMALL LETTER U WITH RING ABOVE
            0xFA: '\u00FA', // LATIN SMALL LETTER U WITH ACUTE
            0xFB: '\u0171', // LATIN SMALL LETTER U WITH DOUBLE ACUTE
            0xFC: '\u00FC', // LATIN SMALL LETTER U WITH DIAERESIS
            0xFD: '\u00FD', // LATIN SMALL LETTER Y WITH ACUTE
            0xFE: '\u0163', // LATIN SMALL LETTER T WITH CEDILLA
            0xFF: '\u02D9', // DOT ABOVE
        };
        return cp1250Map[byteValue] || '';
    },
    SimpleRtfToHtml(rtf) {
    this.$log.debug('volamsimplertfconvertor');

    let output = '';
    let i = 0;
    const len = rtf.length;

    let stack = [];
    let ignorable = false;
    let ucSkip = 1;
    let skipCount = 0;

    // Flags for text formatting
    let bold = false;
    let italic = false;
    let underline = false;
    let inList = false;

    // Function to apply formatting
    const applyFormatting = (text) => {
        if (bold) {
            text = `<b>${text}</b>`;
        }
        if (italic) {
            text = `<i>${text}</i>`;
        }
        if (underline) {
            text = `<u>${text}</u>`;
        }
        return text;
    };

    while (i < len) {
        const char = rtf[i];

        if (char === '{') {
            stack.push({ ignorable, ucSkip, inList });
            i++;
            if (rtf[i] === '\\') {
                i++;
                let controlWord = '';
                while (i < len && /[a-zA-Z*]/.test(rtf[i])) {
                    controlWord += rtf[i];
                    i++;
                }

                if (controlWord === '*') {
                    ignorable = true;
                } else if (
                    controlWord.startsWith('f') ||  // Ignore font tags
                    controlWord === 'fonttbl' ||
                    controlWord === 'stylesheet' ||
                    controlWord === 'colortbl'
                ) {
                    ignorable = true;
                }

                // Skip the rest of the control word
                while (i < len && (rtf[i] !== ' ' && rtf[i] !== '\\' && rtf[i] !== '{' && rtf[i] !== '}')) {
                    i++;
                }
                if (i < len && rtf[i] === ' ') {
                    i++;
                }
            }
        } else if (char === '}') {
            const state = stack.pop();
            ignorable = state.ignorable;
            ucSkip = state.ucSkip;
            inList = state.inList;
            i++;
        } else if (char === '\\') {
            i++;
            if (i >= len) {
                break;
            }
            let controlWord = '';
            let param = '';

            if (rtf[i] === '\\' || rtf[i] === '{' || rtf[i] === '}') {
                if (!ignorable && skipCount === 0) {
                    output += rtf[i];
                }
                i++;
            } else if (rtf[i] === '*') {
                ignorable = true;
                i++;
                while (i < len && /[a-zA-Z]/.test(rtf[i])) {
                    i++;
                }
                while (i < len && /\d/.test(rtf[i])) {
                    i++;
                }
                if (i < len && rtf[i] === ' ') {
                    i++;
                }
            } else if (rtf[i] === '\'') {
                const hexCode = rtf.substr(i + 1, 2);
                const code = parseInt(hexCode, 16);
                if (!ignorable && skipCount === 0) {
                    // Replace character and apply formatting
                    const formattedChar = applyFormatting(this.cp1250ByteToUnicode(code));
                    output += formattedChar;
                }
                i += 3;
            } else {
                // Process control words
                while (i < len && /[a-zA-Z]/.test(rtf[i])) {
                    controlWord += rtf[i];
                    i++;
                }

                // Read optional negative sign
                if (i < len && rtf[i] === '-') {
                    param += '-';
                    i++;
                }

                // Read numeric parameter
                while (i < len && /\d/.test(rtf[i])) {
                    param += rtf[i];
                    i++;
                }

                // Skip optional space
                if (i < len && rtf[i] === ' ') {
                    i++;
                }

                if (controlWord === 'uc') {
                    ucSkip = parseInt(param) || 1;
                } else if (controlWord === 'u') {
                    let code = parseInt(param);
                    if (!ignorable && skipCount === 0) {
                        if (code < 0) {
                            code += 65536;
                        }
                        const unicodeChar = String.fromCharCode(code);
                        const formattedChar = applyFormatting(unicodeChar);
                        output += formattedChar;
                    }
                    skipCount = ucSkip;
                } else if (!ignorable && skipCount === 0) {
                    // Text formatting
                    if (controlWord === 'b') {
                        bold = param !== '0';
                    } else if (controlWord === 'i') {
                        italic = param !== '0';
                    } else if (controlWord === 'ul') {
                        underline = param !== '0';
                    } else if (controlWord === 'ulnone') {
                        underline = false;  // Turn off underline
                    } else if (controlWord === 'par' || controlWord === 'line') {
                        output += '\n';
                    } else if (controlWord === 'tab') {
                        output += ' ';
                    } else if (controlWord === 'emdash') {
                        output += '—';
                    } else if (controlWord === 'endash') {
                        output += '–';
                    } else if (controlWord === 'bullet') {
                        if (!inList) {
                            output += '<ul>';
                            inList = true;
                        }
                        // Nahradíme odrážku větší tečkou
                        output += '<li>• ';
                    } else if (controlWord === 'pntext') {
                        output += '</li>';
                    } else if (controlWord === 'pard' && inList) {
                        output += '</ul>';
                        inList = false;
                    }
                    // Ignore other control words
                }
            }
        } else {
            if (!ignorable && skipCount === 0) {
                let formattedText = applyFormatting(char);
                output += formattedText;
            } else if (skipCount > 0) {
                skipCount--;
            }
            i++;
        }
    }

    // Replace newlines with HTML line breaks
    output = output.replace(/\n/g, '<br/>');

    // Remove extra blank lines (multiple <br/>) and leave only one between paragraphs or items
    output = output.replace(/(<br\/>\s*){3,}/g, '<br/><br/>');

    // Ensure that list items (starting with "•") always start on a new line
    output = output.replace(/(<br\/>\s*)*•/g, '<br/>•');

    // Trim any leading or trailing <br/> to avoid unnecessary spaces at the start or end
    output = output.replace(/^\s*<br\/>/, '').replace(/<br\/>\s*$/, '');

    // Replace multiple spaces with a single space
    output = output.replace(/ {2,}/g, ' ');

    // Trim leading and trailing spaces
    output = output.trim();

    this.$log.debug(output);

    return output;
},
GetFundingInstruction() {
            var self = this
            self.Instrukce.length = 0
            var grants = new window.Grants(window.channel, window.message);
            grants.GetFundingInstruction(self.FundingID, self.Ident, function (result) {
                window.this.$log.debug('fundinginstruction');
                window.this.$log.debug(result);
                if (result.List.value.items.length > 0) {
                    for (var x = 0; x < result.List.value.items.length; x++) {
                        self.Instrukce[x] = {
                            id: x,
                            value: result.List.value.items[x].Value.value,
                            detail: result.List.value.items[x].Detail.value,
                            zamID: result.List.value.items[x].ZamID.value,
                            zamTXT: result.List.value.items[x].ZamText.value,
                            html: self.SimpleRtfToHtml(result.List.value.items[x].Value.value)
                            
                        }
                    }
                    self.PopupVisible = true
                    self.ButtonVisible = true
                }
                else {
                    self.PopupVisible = false
                    self.ButtonVisible = false
                }
            }, window.this.ApiError());

        },

    },

};
</script>
<style scoped>
#buttoninstruction {
    margin-bottom: 5px;
    margin-left: 5px;
}
.htmlObsahDetail{
    border: 1px solid black ;
    border-left: 0;
    border-right: 0;
    border-bottom: 1;
    border-top: 0;
    word-break: keep-all
}
.htmlObsahZamText{
    display: inline-block;
    text-align: right;
    width: 100%;
    font-style: italic;
    font-size: smaller;

}
</style>
